import * as React from "react";
import useDocumentTitle from "../../../../common/hooks/useDocumentTitle";
import {getIntlMsg} from "../../../../../constants/utils";
import {FormattedMessage, injectIntl} from "react-intl";
import {useParams, withRouter} from "react-router";
import useEntityManager from "../../../../common/hooks/useEntityManager";
import {useDispatch, useSelector} from "react-redux";
import Item from "./item";
import Pagination from "../../../common/pagination/Pagination";
import ListSubHeader from "../../../common/subheader/ListSubheader";
import Spinner from "../../../../common/utils/Spinner";


const UserDetails = injectIntl((props) => {

    const dispatch = useDispatch();

    const {intl} = props;
    const {module, page, secondLevelPage} = useParams();
    useDocumentTitle(getIntlMsg(intl, `app.label.${page}`));


    const params = !secondLevelPage ? {options: [module]}
        : secondLevelPage === 'pending' ? {options: [module, 'status', 'pending']}
            : secondLevelPage === 'specimen_done' ? {options: [module, 'status', 'specimen_done']}
                : {options: [module, 'status', 'done']}


    const {} = useEntityManager(params, page)
    const {items, loading} = useSelector(state => ({
        loading: state.requestReducer.loadingItems,
        items: state.requestReducer.items,
    }));

    return (
        <React.Fragment>
            <ListSubHeader/>
            {loading ? <Spinner status={'primary'} width={100} height={100}/> :
                <>
                    {items?.content && items?.content.map((item, i) => <Item {...item} key={i}/>)}
                </>
            }
            <Pagination/>
        </React.Fragment>
    );
})


export default React.memo(withRouter(UserDetails))
