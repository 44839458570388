import * as React from "react";
import useDocumentTitle from "../../../../../common/hooks/useDocumentTitle";
import actions from "../../../../../../thunks/settings/categoryThunks";
import {getIntlMsg} from "../../../../../../constants/utils";
import {injectIntl} from "react-intl";
import {useParams, withRouter} from "react-router";
import {connect, useDispatch, useSelector} from "react-redux";
import {reduxForm} from "redux-form";
import {validate} from "./validator";
import FormItems from "./Items";


const IndicatorForm = injectIntl((props) => {
    const {intl, handleSubmit, pristine, valid, submitting, reset, setFormView} = props;
    const {page} = useParams();
    useDocumentTitle(getIntlMsg(intl, `app.label.${page}`));

    const dispatch = useDispatch();

    const {item, loading} = useSelector(state => ({
        loading: state.testIndicatorReducer.loadingItem,
        item: state.testIndicatorReducer.item,
    }));


    const onFormSubmit = element => {

        dispatch(actions.saveOrUpdateItem({
            options: ['indicators'], intl, action: item && item?.id ? 'update' : 'save', item:element,
        }))
        dispatch(reset())
        setFormView(false)
    };


    return (
        <div className="tab-pane fade show active" id="indicator" role="tabpanel" aria-labelledby="indicator">
            <form onSubmit={handleSubmit(onFormSubmit)}>
                <div>
                    <FormItems {...{validate, loading, pristine, valid, submitting, item}}/>
                </div>
            </form>
        </div>
    );
});

const mapStateToProps = ({testIndicatorReducer: {item}}) => {
    const defaultState = {status: true}
    return {
        initialValues: item !==null ? {...item} : {...defaultState}
    }
};

export default React.memo(withRouter(connect(mapStateToProps,)(reduxForm({
    form: "testIndicator",
    enableReinitialize: true,
    validate,
})(IndicatorForm))));

