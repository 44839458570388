import types from "../../types/request/actionTypes"


/**
 * @Author Rikenson JACQUES (The Tiger ^(..)^)
 * */
const addDiagnostic = () => {
    return {
        type: types.ADD_DIAGNOSTIC,
        payload: true
    };
};


/**
 * @Author Rikenson JACQUES (The Tiger ^(..)^)
 * */
const addDiagnosticFailed = () => {
    return {
        type: types.ADD_DIAGNOSTIC_FAILED,
        payload: false
    };
};


/**
 * @Author Rikenson JACQUES (The Tiger ^(..)^)
 * ---------------------------------------------------
 * */
const addDiagnosticSucceeded = params => {
    const {payload} = params;

    return {
        type: types.ADD_DIAGNOSTIC_SUCCEEDED,
        payload
    }
};


/**
 * @Author Rikenson JACQUES (The Tiger ^(..)^)
 * */
const addResult = () => {
    return {
        type: types.ADD_RESULT,
        payload: true
    };
};


/**
 * @Author Rikenson JACQUES (The Tiger ^(..)^)
 * */
const addResultFailed = () => {
    return {
        type: types.ADD_RESULT_FAILED,
        payload: false
    };
};


/**
 * @Author Rikenson JACQUES (The Tiger ^(..)^)
 * ---------------------------------------------------
 * */
const addResultSucceeded = params => {
    const {payload} = params;

    return {
        type: types.ADD_RESULT_SUCCEEDED,
        payload
    }
};



export default {
    addDiagnostic,
    addDiagnosticFailed,
    addDiagnosticSucceeded,

    addResult,
    addResultFailed,
    addResultSucceeded,

}