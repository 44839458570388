import * as React from "react";
import {getQActions} from "../../../../constants/options/qActionValues";
import {useContext} from "react";
import Thumbnail from "./Thumbnail";
import {GlobalContext} from "../../../index";


const QuickModuleAction = props => {

    const {} = props

    const {institution: {acronym}} = useContext(GlobalContext);


    return (
        <div id="kt_quick_actions" className="offcanvas offcanvas-left p-10">
            <div className="offcanvas-header d-flex align-items-center justify-content-between pb-10">
                <h3 className="font-weight-bold m-0">Quick Actions
                    <small className="text-muted font-size-sm ml-2">finance &amp; reports</small></h3>
                <a href="#" className="btn btn-xs btn-icon btn-light btn-hover-primary" id="kt_quick_actions_close">
                    <i className="ki ki-close icon-xs text-muted"/>
                </a>
            </div>
            <div className="offcanvas-content pr-5 mr-n5">

                {/*Module element goes here*/}
                {getQActions(acronym).map((action, i) => <Thumbnail {...action} key={i}/>)}


            </div>
        </div>
    )
}

export default React.memo(QuickModuleAction)

