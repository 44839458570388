import React from 'react'
import * as XLSX from 'xlsx';

const UploadCSV = props => {

    const {format, setData} = props


    const processData = dataString => {
        const dataStringLines = dataString.split(/\r\n|\n/);
        const headers = dataStringLines[0].split(/,(?![^"]"(?:(?:[^"]"){2})[^"]$)/);

        const list = [];

        for (let i = 1; i < dataStringLines.length; i++) {
            const row = dataStringLines[i].split(/,(?![^"]"(?:(?:[^"]"){2})[^"]$)/);
            if (headers && row.length === headers.length) {
                const obj = {};
                for (let j = 0; j < headers.length; j++) {
                    let d = row[j];
                    if (d.length > 0) {
                        if (d[0] === '"')
                            d = d.substring(1, d.length - 1);
                        if (d[d.length - 1] === '"')
                            d = d.substring(d.length - 2, 1);
                    }
                    if (headers[j]) {
                        obj[headers[j]] = d;
                    }
                }

                // remove the blank rows
                if (Object.values(obj).filter(x => x).length > 0) {
                    list.push(obj);
                }
            }
        }

        setData(format(list))

    }


    const handleFileUpload = e => {
        const file = e.target.files[0];
        const reader = new FileReader();
        reader.onload = (evt) => {
            const bstr = evt.target.result;
            const wb = XLSX.read(bstr, {type: 'binary'});
            const wsname = wb.SheetNames[0];
            const ws = wb.Sheets[wsname];
            const data = XLSX.utils.sheet_to_csv(ws, {header: 1});
            processData(data);
        };
        reader.readAsBinaryString(file);
    }


    return (
        <React.Fragment>
            <label htmlFor="csvId" className="float-right ml-4">
                <span className="btn btn-primary">
                    <span className="material-icons orange600">cloud_upload</span>
                </span>
            </label>
            <input
                className="btn btn-primary btn-sm btn-cta waves-effect text-center m-b-20"
                type="file"
                id="csvId"
                accept=".csv,.xlsx,.xls"
                onChange={handleFileUpload}
                style={{visibility: "hidden"}}
            />
        </React.Fragment>
    )
}


export default UploadCSV;