import * as React from "react";
import {FormattedMessage} from "react-intl";
import moment from "moment";
import CSVButton from "../../../../../../../common/utils/csvbutton";


const MapperDistribution = props => {

    const {groupByDateCounter} = props

    const elements = buildReportTable(groupByDateCounter);
    const summary = getTotalRow(elements)


    return (

        <div className="card card-custom">
            <div className="card-header">
                <div className="card-title">
                    <h3 className="card-label">
                        <div>
                            <FormattedMessage id="app.label.request_by_date_type_status"/>

                        </div>
                        <small>
                            <FormattedMessage id="app.label.request_by_date_type_status_desc"/>
                        </small>
                    </h3>
                    <div className="pull-right">
                        <CSVButton {...{items: elements, dataType: 'test_report'}}/>
                    </div>

                </div>
            </div>
            <div className="card-body">
                <table className="table">
                    <thead>
                    <tr>
                        <th><FormattedMessage id="app.label.date"/></th>
                        <th className="text-primary"><FormattedMessage id="app.label.ag_plus"/></th>
                        <th className="text-success"><FormattedMessage id="app.label.ag_minus"/></th>
                        <th className="text-primary"><FormattedMessage id="app.label.pcr_plus"/></th>
                        <th className="text-success"><FormattedMessage id="app.label.prc_minus"/></th>
                        <th><FormattedMessage id="app.label.ag_total"/></th>
                        <th><FormattedMessage id="app.label.pcr_total"/></th>
                        <th><FormattedMessage id="app.label.total"/></th>
                    </tr>
                    </thead>
                    <tbody>
                    {elements.map((element, i) =>
                        <tr className="text-left">
                            <td>
                                {moment(element.currentDate).format('DD/MM/yyyy')}
                            </td>
                            <td>{element.agPlus || 0}</td>
                            <td>{element.agMinus || 0}</td>
                            <td>{element.pcrPlus || 0}</td>
                            <td>{element.pcrMinus || 0}</td>
                            <td>{element.agTotal || 0}</td>
                            <td>{element.pcrTotal || 0}</td>
                            <td className="font-weight-bold">{element.total || 0}</td>
                        </tr>
                    )}
                    <tr className="font-weight-bolder font-size-h3 bg-secondary">
                        <td></td>
                        <td className="text-primary">{summary?.sumAgPositive}</td>
                        <td className="text-success">{summary?.sumAgNegative}</td>
                        <td className="text-primary">{summary?.sumPcrPositive}</td>
                        <td className="text-success">{summary?.sumPcrNegative}</td>
                        <td>{summary?.totalAg}</td>
                        <td>{summary?.totalPcr}</td>
                        <td>{summary?.totalTest}</td>
                    </tr>
                    </tbody>
                </table>
            </div>
        </div>

    )
}


const buildReportTable = items => {

    let agValues = {};
    let pcrValues = {};
    let agTotal = 0;
    let pcrTotal = 0;

    let row = []
    const reportTable = []
    if (items)
        for (const [key, value] of Object.entries(items)) {
            const currentDate = new Date(key).toISOString().split('T')[0];
            for (const [eKey, eVal] of Object.entries(value)) {
                if (eKey === 'Ag')
                    for (const [fKey, fVal] of Object.entries(eVal)) {
                        if (fKey === 'POSITIVE')
                            agValues.agPlus = fVal
                        if (fKey === 'NEGATIVE')
                            agValues.agMinus = fVal
                        if (fKey === 'UNKNOWN')
                            agValues.agUnknown = fVal
                        agTotal += fVal
                    }

                if (eKey === 'PCR')
                    for (const [fKey, fVal] of Object.entries(eVal)) {
                        if (fKey === 'POSITIVE')
                            pcrValues.pcrPlus = fVal
                        if (fKey === 'NEGATIVE')
                            pcrValues.pcrMinus = fVal
                        if (fKey === 'UNKNOWN')
                            pcrValues.pcrUnknown = fVal
                        pcrTotal += fVal
                    }
            }
            row = {
                currentDate,
                ...agValues,
                ...pcrValues,
                agTotal,
                pcrTotal,
                total: agTotal + pcrTotal
            }
            reportTable.push(row)
        }
    return reportTable
}

const getTotalRow = items => {

    const sumAgPositive = items
        .map(item => item?.agPlus ? parseInt(item?.agPlus) : 0)
        .reduce((prev, curr) => prev + curr, 0);

    const sumAgNegative = items
        .map(item => item?.agMinus ? parseInt(item?.agMinus) : 0)
        .reduce((prev, curr) => prev + curr, 0);


    const sumPcrPositive = items
        .map(item => item?.pcrPlus ? parseInt(item?.pcrPlus) : 0)
        .reduce((prev, curr) => prev + curr, 0);

    const sumPcrNegative = items
        .map(item => item?.pcrMinus ? parseInt(item?.pcrMinus) : 0)
        .reduce((prev, curr) => prev + curr, 0);


    const row = {
        sumAgPositive, sumAgNegative, sumPcrPositive, sumPcrNegative,
        totalAg: sumAgPositive + sumAgNegative,
        totalPcr: sumPcrPositive + sumPcrNegative,

    }

    return {...row, totalTest: row.totalAg + row.totalPcr}
}

export default React.memo(MapperDistribution)

