/**
 * Created by inglouidor on 3/15/21.
 */
import React from 'react'
import {DoughnutChartjs} from "../charts";
import {FormattedMessage} from "react-intl";
import TableList from '../../../../../../common/tables';
import {addToObject} from '../../../../../../../../constants/utils'

const Staff = ({indicator}) => {


    let data=[];

    if(indicator && indicator.staff) {


        for(let i=0; i < Object.keys(indicator.staff).length; i++){

            let obj=Object.keys(indicator.staff)[i]
            let d = Object.values(indicator.staff)[i];

            data.push(addToObject(d,"site",obj,i))

        }

    }


    const legend = {
        display: true,
        position: "bottom",
        labels: {
            fontColor: "#323130",
            fontSize: 14
        }
    };


    const statusData = {
        datasets: data && data.map(value=>{
            return{
                data: [value.C19_STAFF_CONFIRMED_POSITIVE],
                backgroundColor: ['#ff6384','#36a2eb','#cc65fe', '#7D43DD','#FF4220','#6C2AFF','#ffce56'
                ]
            }}),

        labels:
            ["C19_STAFF_CONFIRMED_POSITIVE"]
    };


    const columns = [
        {
            title: 'Site',
            field: 'site', type: 'text'
        },
        {
            title: 'C19T',
            field: 'C19_STAFF_CONFIRMED_POSITIVE', type: 'text'
        },
        {
            title: "%",
            field: 'percentage', type: 'text'
        },
    ]



    return (
        <React.Fragment>
            <div className="col-sm-6 pt-4">

                <div className="card card-custom">
                    <div className="card-header">
                        <div className="card-title">
                            <h3 className="card-label">
                                <FormattedMessage id="app.label.staff"/> {" "}
                            </h3>
                        </div>
                    </div>
                    <div className="card-body">
                        <div>
                            <DoughnutChartjs data={statusData} legend={legend}/>
                        </div>
                    </div>
                </div>

            </div>

            <div className="col-sm-6 pt-4">

                <TableList data={data} columns={columns} title="Personnel"/>

            </div>

        </React.Fragment>)
}

export default Staff