import React from "react"
import {withRouter} from "react-router";
import {FormattedMessage, injectIntl} from "react-intl";
import ListOptions from "../../../../../common/utils/ListOptions";

let Item = injectIntl(props => {

    const {id, name, description, rate, status, setCurrencyFormView} = props

    return (
        <tr className="datatable-row" style={{left: "0px"}}>

            <td className="datatable-cell">
                <span style={{width: "137px"}}>
                    {name || ''}
                </span>
            </td>

            <td className="datatable-cell">
               <span style={{width: "300px"}}>
                    {description || ''}
                </span>
            </td>

            <td className="datatable-cell">
              <span style={{width: "137px"}}>
                    {rate || ''}
                </span>
            </td>


            <td className="datatable-cell">
                <span style={{width: "137px"}}>
                    <span
                        className={`label font-weight-bold label-lg  label-light-${status ? 'success' : 'danger'} label-inline`}>
                        {status
                            ? <FormattedMessage id="app.label.online"/>
                            : <FormattedMessage id="app.label.offline"/>
                        }
                    </span>
                </span>
            </td>

            <ListOptions {...{id, entities: 'currencies'}} viewAction={setCurrencyFormView}/>
        </tr>

    )
})
export default React.memo(withRouter(Item))
