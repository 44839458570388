import moment from 'moment';

export class Diagnostic {

    constructor(params) {
        if (params) {
            this.labsReceptionDate = new Date(params?.labsReceptionDate)
            this.specimens = this.filterSpecimen(params?.name, params)
        }
    }


    filterSpecimen(name, params) {
        if (params) {
            switch (name) {

                case 'PCR':
                    return [
                        {
                            category: {id: params?.oropharyngeal?.split(';')[0]},
                            collectionDate: new Date(params?.oropharyngealDate),
                            result: params?.oropharyngeal?.split(';')[1],
                        },
                        {
                            category: {id: params?.nasopharyngee?.split(';')[0]},
                            collectionDate: new Date(params?.nasopharyngeeDate),
                            result: params.nasopharyngee?.split(';')[1],
                        }
                    ]
                case 'Ag':
                    return [
                        {
                            category: {id: params?.nasopharyngee?.split(';')[0]},
                            collectionDate: new Date(params?.nasopharyngeeDate),
                            result: params.nasopharyngee?.split(';')[1],
                        }
                    ]
                default:
                    return [
                        {
                            category: {id: params?.serum?.split(';')[0]},
                            collectionDate: new Date(params?.serumDate),
                            result: params?.serum?.split(';')[1],
                        }
                    ]
            }
        }
    }

}

export class DiagnosticMapper {

    constructor(params) {
        if (params) {
            this.id = params?.id;
            this.analyses = this.filterSpecimens(params?.testType?.name, params)
            this.oropharyngealDate = moment(params?.diagnostic?.specimens[0]?.collectionDate).format('yyyy-MM-DD')
            this.nasopharyngeeDate = moment(params?.diagnostic?.specimens[1]?.collectionDate).format('yyyy-MM-DD')
            this.serumDate = moment(new Date()).format('yyyy-MM-DD')
            this.labsReceptionDate = moment(params?.diagnostic?.labsReceptionDate).format('yyyy-MM-DD');
        }
    }


    filterSpecimens(name, params) {
        if (params) {
            switch (name) {

                case 'PCR':
                    return {
                        oropharyngeal: `${params?.diagnostic?.specimens[0]?.category?.id};${params?.diagnostic?.specimens[0]?.result}`,
                        nasopharyngee: `${params?.diagnostic?.specimens[1]?.category?.id};${params?.diagnostic?.specimens[1]?.result}`
                    }
                case 'Ag':
                    return {
                        nasopharyngee: `${params?.diagnostic?.specimens[0]?.category?.id};${params?.diagnostic?.specimens[0]?.result}`
                    }
                default:
                    return {serum: `${params?.diagnostic?.specimens[0]?.category?.id};${params?.diagnostic?.specimens[0]?.result}`}
            }
        }
    }
}
