import * as React from "react";
import {useContext} from "react";
import {FormattedMessage} from "react-intl";
import {GlobalContext} from "../../../index";
import Loader from "react-loader-spinner";


const FormFooter = props => {

    const {
        loading, item, pristine, submitting, reset, valid, savingOrUpdating
    } = props
    const {institution: {acronym}} = useContext(GlobalContext);


    return (
        <div className={!valid || pristine ? 'd-none' : ''}>
            <button type="submit"
                    className={`btn btn-primary ${submitting ? 'spinner spinner-white spinner-left' : ''} font-weight-bold`}>
                {savingOrUpdating ?
                    <>
                    <span>
                        <FormattedMessage id="app.label.wait" defaultMessage="Step"/>
                    </span>
                        <Loader type="Puff" color="#ffff" height={20} width={20}/>
                    </>
                    : < FormattedMessage id={(loading && item && item.id) ? "app.label.updating"
                        : (submitting && item && !item.id) ? "app.label.saving"
                            : (!loading && item && item.id) ? "app.label.update" : "app.label.save"}
                                         disabled={pristine || submitting}
                                         defaultMessage={`Save`} onClick={reset}/>}
            </button>
            <button className="btn btn-outline-primary font-weight-bold ml-3" type="button"
                    onClick={reset}>
                <FormattedMessage id={`app.label.cancel`} defaultMessage={`Cancel`}/> {" "}
            </button>
        </div>

    );
}

export default React.memo(FormFooter)
