import React from "react"
import {injectIntl} from "react-intl";
import {Style} from './pageHeaderStyle'
import moment from "moment";



const NoticePageHeader = injectIntl(props => {
    const {item} = props;
    // moment.locale('fr');

    return (
        <div className="report_header">
            <h3 style={Style.notice.subheaderWrapper}>
                {/*<div style={Style.notice.logo.css}>*/}
                {/*    <img src={Style.notice.logo.src} alt={Style.notice.logo.alt} style={Style.notice.logo.img} width={'40px'}/>*/}
                {/*</div>*/}
                ZL/{item?.specimenCenter?.name?.toUpperCase()}<br></br>
                {moment().format('LLL')}
            </h3>

        </div>
    )
})


const PageHeaderWithoutZlonly = injectIntl(props => {

    const {zlOnly, item} = props
    const logos = [
        {
            src: '/assets/media/logos/mspp.png',
            alt: 'MSPP',
            css: {
                width: `120px`,
                marginTop: "60px",
                marginLeft: "70px",
                display: `${zlOnly ? 'none' : 'block'}`,
            },
            logo: {width: "90px"},
            img: {width: "90px"}
        },
        {
            src: '/assets/media/logos/zl_logo.png',
            alt: 'ZL',
            css: {
                width: `${zlOnly ? '50px' : '200px'}`,
                marginTop: "80px",
                marginRight: "50px",
                marginLeft: `${zlOnly ? '10px' : '0'}`
            },
            logo: {width: "120px"},
            img: {width: "120px"}
        }
    ]

    const styles = {
        headerWrapper: {
            width: "400px",
            margin: "20px auto",
            display: "flex",
        },
        subheaderWrapper: {
            width: "700px",
            margin: "0 auto",
            textAlign: "center",
            fontSize: "16px",
            fontFamily: "Helvetica Neue"
        },

    }


    return (
        <div className="report_header">

            <div style={styles.headerWrapper}>
                {logos.map((logo, i) =>
                    <div key={i} style={logo.css}>
                        <img src={logo.src} alt={logo.alt} style={logo.img}/>
                    </div>
                )}
            </div>
            <h3 style={styles.subheaderWrapper}>
                <h3 style={{fontWeight: "bold"}}>HOPITAL UNIVERSITAIRE DE MIREBALAIS (HUM)</h3>
                <div>Laboratoire Régional de Référence Stephen Robert et Pilar Crespi Robert</div>
                <div>Laboratoire de Microbiologie et de Biologie Moléculaire (BSL-2, BSL3)</div>
                <hr/>
            </h3>

        </div>
    )
});


const PageHeader = injectIntl(props => {

    const {zlOnly, item} = props

    return (
        <div className="report_header">
            <div className="report_header">
                {zlOnly ? <NoticePageHeader item={item} zlOnly={zlOnly}/> :
                    <PageHeaderWithoutZlonly item={item} zlOnly={zlOnly}/>}
            </div>
        </div>
    )
});


export default PageHeader

