import types from "../../actions/types/request/actionTypes";
import initialState from "../../../constants/utils/initialState";

const reducer = (state = initialState.requestReducer, action) => {

        switch (action.type) {

            /**
             * LOAD REQUEST LIST
             * */
            case types.RESET_CART:
            case types.RESET_REQUEST:
            case types.RESET_RESULT:
            case types.RESET_RECEPTION:
            case types.RESET_SPECIMEN:
            case types.RESET_LABORATORY:
            case types.RESET_NOTIFICATION:
            case types.RESET_ARCHIVED:
            case types.RESET_REQUEST_STATUS_RESULTS:
            case types.RESET_UNFINISHED:
                return {...state, item: null, loadingItems: false, status: null};

            /**
             * LOAD REQUEST LIST
             * */
            case types.LOAD_REQUESTS:
            case types.LOAD_REQUESTS_STATUS_CONFIRMED:
            case types.LOAD_REQUESTS_STATUS_RECEPTION:
            case types.LOAD_REQUESTS_STATUS_CART:
            case types.LOAD_REQUESTS_STATUS_SPECIMENS:
            case types.LOAD_REQUESTS_STATUS_LABORATORY:
            case types.LOAD_REQUESTS_STATUS_RESULTS:
            case types.LOAD_REQUESTS_STATUS_NOTIFICATION:
            case types.LOAD_REQUESTS_STATUS_ARCHIVED:
            case types.LOAD_REQUESTS_STATUS_UNFINISHED:
                return {...state, loadingItems: action.payload};


            case types.LOAD_REQUESTS_SUCCEEDED:
            case types.LOAD_REQUESTS_STATUS_CONFIRMED_SUCCEEDED:
            case types.LOAD_REQUESTS_STATUS_RECEPTION_SUCCEEDED:
            case types.LOAD_REQUESTS_STATUS_CART_SUCCEEDED:
            case types.LOAD_REQUESTS_STATUS_SPECIMENS_SUCCEEDED:
            case types.LOAD_REQUESTS_STATUS_LABORATORY_SUCCEEDED:
            case types.LOAD_REQUESTS_STATUS_RESULTS_SUCCEEDED:
            case types.LOAD_REQUESTS_STATUS_NOTIFICATION_SUCCEEDED:
            case types.LOAD_REQUESTS_STATUS_ARCHIVED_SUCCEEDED:
            case types.LOAD_REQUESTS_STATUS_UNFINISHED_SUCCEEDED:
                return {...state, item: null, items: action.payload, loadingItems: false};

            case types.LOAD_REQUESTS_FAILED:
            case types.LOAD_REQUESTS_STATUS_CONFIRMED_FAILED:
            case types.LOAD_REQUESTS_STATUS_RECEPTION_FAILED:
            case types.LOAD_REQUESTS_STATUS_CART_FAILED:
            case types.LOAD_REQUESTS_STATUS_SPECIMENS_FAILED:
            case types.LOAD_REQUESTS_STATUS_LABORATORY_FAILED:
            case types.LOAD_REQUESTS_STATUS_RESULTS_FAILED:
            case types.LOAD_REQUESTS_STATUS_NOTIFICATION_FAILED:
            case types.LOAD_REQUESTS_STATUS_ARCHIVED_FAILED:
            case types.LOAD_REQUESTS_STATUS_UNFINISHED_FAILED:
                return {...state, loadingItems: action.payload};


            /**  LOAD REQUEST BY A SPECIFIC PROPERTY CASE */
            case types.LOAD_REQUEST:
                return {...state, loadingItem: true};
            case types.LOAD_REQUEST_SUCCEEDED:
                return {...state, item: action.payload, loadingItem: false};
            case types.LOAD_REQUEST_FAILED:
                return {...state, loadingItem: false};


            case types.LOAD_CURRENCY:
                return {...state, loadingCurrency: true};
            case types.LOAD_CURRENCY_SUCCEEDED:
                return {...state, currency: action.payload, loadingCurrency: false};
            case types.LOAD_CURRENCY_FAILED:
                return {...state, loadingCurrency: false};



            case types.LOAD_CART_SUMMARY:
                return {...state, loadingCartSummary: true};
            case types.LOAD_CART_SUMMARY_SUCCEEDED:
                return {...state, cartSummary: action.payload, loadingCartSummary: false};
            case types.LOAD_CART_SUMMARY_FAILED:
                return {...state, loadingCartSummary: false};



            /**  SAVE REQUEST CASE */
            case types.SAVE_REQUEST:
            case types.SAVE_REQUEST_PAY:
                return {...state, savingOrUpdating: true, status: null};
            case types.SAVE_REQUEST_SUCCEEDED:
            case types.SAVE_REQUEST_PAY_SUCCEEDED:
                return {
                    ...state, items: [...state.items, action.payload],
                    status: 400,
                    item: action.payload,
                    savingOrUpdating: false
                };
            case types.SAVE_REQUEST_FAILED:
            case types.SAVE_REQUEST_PAY_FAILED:
                return {...state, savingOrUpdating: false, status: null};



            /**  UPDATE REQUEST CASE  */

            case types.UPDATE_REQUEST:
            case types.UPDATE_REQUEST_PAY:
            case types.UPDATE_REQUEST_UPDATE_DIAGNOSTIC:
            case types.UPDATE_REQUEST_NOTIFICATION_SYMPTOMS_COMORBIDITY:
            case types.UPDATE_REQUEST_NOTIFICATION_RISK_FACTOR:
            case types.UPDATE_REQUEST_UPDATE_INVESTIGATOR:
                return {...state, savingOrUpdating: true};
            case types.UPDATE_REQUEST_PAY_SUCCEEDED:
            case types.UPDATE_REQUEST_UPDATE_DIAGNOSTIC_SUCCEEDED:
                return {
                    ...state,
                    items: state.items.map(item => item.id === action.payload.id ? action.payload : item),
                    item: action.payload,
                    savingOrUpdating: false,
                    status: 400

                };

            case types.UPDATE_REQUEST_SUCCEEDED:
                return (action.payload.state === 'NOTIFICATION' || action.payload.notificationSheet !== null) ?
                    {
                        ...state,
                        items: state.items.map(item => item.id === action.payload.id ? action.payload : item),
                        item: action.payload,
                        savingOrUpdating: false,
                        // status: 400
                    }:
                    {
                        ...state,
                        items: state.items.map(item => item.id === action.payload.id ? action.payload : item),
                        item: action.payload,
                        savingOrUpdating: false,
                        status: 400

                    } ;


            case types.UPDATE_REQUEST_NOTIFICATION_SYMPTOMS_COMORBIDITY_SUCCEEDED:
            case types.UPDATE_REQUEST_NOTIFICATION_RISK_FACTOR_SUCCEEDED:
            case types.UPDATE_REQUEST_UPDATE_INVESTIGATOR_SUCCEEDED:
                return {
                    ...state,
                    item: action.payload,
                    savingOrUpdating: false,
                    status: 200
                };

            case types.UPDATE_REQUEST_FAILED:
            case types.UPDATE_REQUEST_PAY_FAILED:
            case types.UPDATE_REQUEST_UPDATE_DIAGNOSTIC_FAILED:
            case types.UPDATE_REQUEST_NOTIFICATION_SYMPTOMS_COMORBIDITY_FAILLED:
            case types.UPDATE_REQUEST_NOTIFICATION_RISK_FACTOR_FAILLED:
            case types.UPDATE_REQUEST_UPDATE_INVESTIGATOR_FAILLED:
                return {...state, savingOrUpdating: false};


            /**  ADD SPECIMEN REQUEST CASE  */

            case types.ADD_DIAGNOSTIC:
                return {...state, savingOrUpdating: true, status: null};
            case types.ADD_DIAGNOSTIC_SUCCEEDED:
                return {
                    ...state,
                    items: state.items.map(item => item.id === action.payload.id ? action.payload : item),
                    savingOrUpdating: true,
                    status: 400,
                };
            case types.ADD_DIAGNOSTIC_FAILED:
                return {...state, savingOrUpdating: false, status: null};



            /**  ADD SPECIMEN REQUEST CASE  */

            case types.ADD_RESULT:
                return {...state, savingOrUpdating: true, status: null,};
            case types.ADD_RESULT_SUCCEEDED:
                return {
                    ...state,
                    items: state.items.map(item => item.id === action.payload.id ? action.payload : item),
                    item: action.payload,
                    savingOrUpdating: false,
                    status: 400,
                };
            case types.ADD_RESULT_FAILED:
                return {...state, savingOrUpdating: false, status: null};



            /**  DELETE REQUEST CASE  */
            case types.DELETE_REQUEST:
                return {...state, deleting: true};
            case types.DELETE_REQUEST_SUCCEEDED:
                return (action.payload.status === 200) ?
                    {...state, items: state.items.filter(item => item.id !== action.payload.item.id), deleting: false}
                    : state;
            case types.DELETE_REQUEST_FAILED:
                return {...state, deleting: false};


            /**  CHANGE REQUEST STATUS CASE  */
            case types.CHANGE_REQUEST_CHANGE_STATUS:
                return {...state, deleting: true, status: null};
            case types.CHANGE_REQUEST_CHANGE_SUCCEEDED:
                return (action.payload.status === 200 && action.payload.item.state !== 'NOTIFICATION') ?
                    {
                        ...state,
                        items: state.items.filter(item => item.id !== action.payload.item.id),
                        item: null,
                        status: 200,
                        deleting: false
                    }
                    : {
                        ...state,
                        items: state.items.map(item => item.id === action.payload ? action.payload : item),
                        item: null,
                        status: 'notify',
                        deleting: false
                    }
            case types.CHANGE_REQUEST_CHANGE_FAILED:
                return {...state, deleting: false, status: null};


            case types.CHANGE_REQUEST_ARCHIVE_ALL_SUCCEEDED:
                return {...state, status: 'result'}
            case types.CHANGE_REQUEST_ARCHIVE_ALL_FAILLED:
                return {...state, status: ''}


            default:
                return state;
        }
    }
;

export default reducer;
