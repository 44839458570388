import * as React from "react";
import {useState} from "react";
import {useParams, withRouter} from "react-router";
import pages from "../../../../constants/options/pages";
import TestApplicantList from "./applicants";
import CartReport from "./cart";


const ReportsPageManager = () => {
    const {secondLevelPage} = useParams();
    const [view, setView] = useState('')


    switch (secondLevelPage) {
        case pages.REQUEST_REPORT_APPLICANT_PAGE:
            return <TestApplicantList {...{setView, view}}/>
        case pages.CART_REPORT_PAGE:
            return <CartReport/>
        default:
            return null

    }
}

export default React.memo(withRouter(ReportsPageManager))

