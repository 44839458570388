import * as React from "react";
import {FormattedMessage, injectIntl} from "react-intl";
import {withRouter} from "react-router";
import FormItems from "./Items";


const SocialInfosForm = injectIntl((props) => {

    const {item} = props;

    return (

        <div className="card card-custom">
            <div className="card-header">
                <div className="card-title">
                    <span className="card-icon">
                        <i className="flaticon2-indent-dots text-primary"/>
                    </span>
                    <h3 className="card-label">
                        <FormattedMessage id="app.label.social_infos"/>{" "}
                        <small>
                            <FormattedMessage id="app.label.social_infos_desc"/>
                        </small>
                    </h3>
                </div>
            </div>
            <div className="card-body">
                <FormItems {...{item}}/>
            </div>
        </div>

    );
});


export default React.memo(withRouter(SocialInfosForm))

