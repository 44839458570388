/**
 * Created by inglouidor on 3/16/21.
 */
import React from 'react'
import {Doughnut, Line,Bar} from "react-chartjs-2";


export const DoughnutChartjs = ({legend, data}) => {


    return (<Doughnut data={data} legend={legend}/>)

}


export const LineChartjs = ({data}) => {


    return ( <Line
        data={data}
        options= {{
            title: {
            display: true,
            text: ''
        }
        }}
    />)
}
export const BarChartjs = ({data}) => {


    return ( <Bar
        data={data}
        options= {{
            scales: {
                xAxes: [{
                    stacked: true
                }],
                yAxes: [{
                    stacked: true
                }]
            }

        }}
    />)
}