import * as React from "react";
import {useContext} from "react";
import useDocumentTitle from "../../../../../../common/hooks/useDocumentTitle";
import ModalFormFooter from "../../../../../../common/view/ModalFormFooter";
import {Result, ResultMapper} from "../../../../../../../constants/models/result";
import actions from "../../../../../../../thunks/request/thunks";
import {getIntlMsg} from "../../../../../../../constants/utils";
import {FormattedMessage, injectIntl} from "react-intl";
import {useParams, withRouter} from "react-router";
import {connect, useDispatch, useSelector} from "react-redux";
import {reduxForm} from "redux-form";
import {validate} from "./validator";
import FormItems from "./Items";
import {DiagnosticMapper} from "../../../../../../../constants/models/diagnostic";
import {GlobalContext} from "../../../../../../index";
import {Modal} from 'react-bootstrap';

const ResultFormModal = injectIntl((props) => {
    const {intl, handleSubmit, pristine, valid, submitting, reset} = props;
    const {page} = useParams();
    useDocumentTitle(getIntlMsg(intl, `app.label.${page}`));
    const {hideModal, modalIsOpen} = useContext(GlobalContext);


    const dispatch = useDispatch()
    const {item, status} = useSelector(state => ({
        item: state.requestReducer?.item,
        status: state.requestReducer?.status,
    }))

    const params = {intl, options: ['requests']}

    const onFormSubmit = element => {
        const model = new Result(element)

        if (item) {
            dispatch(actions.addResult({options: ['requests', 'result', item?.id], intl, item: model}))
            // dispatch(actions.resetItem(params))
            // dispatch(reset())
        }
    };

    const ResetForm = () => {
        reset('diagnosticResultForm');
        dispatch(actions.resetItem(params))
        hideModal('result')
    }

    if (status === 400) ResetForm()
    return (

        <Modal
            show={modalIsOpen.name === "result" ? modalIsOpen.open : false}
            onHide={() => hideModal('result')}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    <FormattedMessage id={`app.label.new_result_form`}/>{" "}
                    <FormattedMessage id={`app.label.for`}/>{" "}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <form onSubmit={handleSubmit(onFormSubmit)}>
                    <div>
                        <FormItems {...{validate, loading: false, pristine, valid, submitting, item}}/>
                        <ModalFormFooter {...{
                            loading: false,
                            item: {},
                            pristine,
                            valid,
                            submitting,
                            reset,
                            hideModal,
                            moduleName: "result"
                        }}/>
                    </div>
                </form>

            </Modal.Body>

        </Modal>
    );
});

const mapStateToProps = ({requestReducer: {item}}) => {

    const initVal = {...new DiagnosticMapper(item), ...new ResultMapper(item)};
    const defaultState = {}

    return {
        initialValues: item !== null ? {...initVal} : {...defaultState}
    }
};

export default React.memo(withRouter(connect(mapStateToProps,)(reduxForm({
    form: "diagnosticResultForm",
    enableReinitialize: true,
    // validate,
})(ResultFormModal))));

