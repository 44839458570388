import React from 'react'
import PageHeader from "../../../../common/report/PageHeader";
import {FormattedMessage} from "react-intl";
import moment from 'moment'
import PageFooter from "../../../../common/report/PageFooter";
import NotificationHeaderHeader from "../../../../common/report/NotificationHeader";
import {ComorbidityMapper, RequestMapper, SymptomsMapper} from "../../../../../../constants/models/request";
import Personal from './personal'
import Symptom from "./symptom";


const styles = {
    wrapper: {
        width: "100%",
        margin: "0px auto",
        fontFamily: "Helvetica Neue",
        paddingTop: '40px',
        paddingBottom: '40px'

    },

    rowThreeColumn: {
        display: 'flex',
        marginRight: '2px', marginLeft: '3px',
        columnSize: {
            width: '325px'
        },
        columnSizeHeader: {
            width: '500px'
        }
    },
    titleWeight: {fontWeight: 'bold', fontSize: '16px'},
    threeColumnTd: {width: "325px"},
    rowMargin: {marginTop: "150px"},
    textLabel: {fontSize: '15px', marginLeft: '5px', fontWeight: 'normal'},
    textTitle: {fontSize: '16px'},
    headerTitle: {
        background: '#000000',
        color: '#FFFFFF',
        fontSize: '20px'
    }

}
const ContactsDomc = [
    {no: 1}, {no: 2}, {no: 3}, {no: 4}, {no: 5},
]


class RequestForm extends React.Component {


    render() {

        const {item} = this.props;

        const applicant = item ? new RequestMapper(item) : null;
        const sympts = item ? new SymptomsMapper(item?.notificationSheet) : null;
        const comorbi = item ? new ComorbidityMapper(item?.notificationSheet?.comorbidityHabits) : null;

        return (

            <div className="request_form">
                <NotificationHeaderHeader {...{item}}/>
               <Personal item={item}/>
               <Symptom item={item}/>

                <PageFooter {...{item}}/>


            </div>
        )
    }
}

export default RequestForm;

