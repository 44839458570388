import React from "react"
import {withRouter} from "react-router";
import AsyncInputSearch from "../../../../common/utils/AsyncInputSearch";
import CheckBoxFilter from "../../../../common/utils/CheckBoxFilter";
import {injectIntl} from "react-intl";
import {getIntlMsg} from "../../../../../constants/utils";
import actions from "../../../../../thunks/common/coreThunks";
import {useDispatch} from "react-redux";
import SearchbarButton from "../../../common/buttons/SearchbarButton";

let Searchbar = injectIntl(props => {


    const dispatch = useDispatch();
    const {options} = props
    const criteria = {options: ['partners', 'names']};
    const resetCriteria = {options: ['partner']};


    return (
            <div className="bg-danger-o-20 pt-5 pb-5 align-items-center d-flex">
                <div className="col-sm-6">
                    <AsyncInputSearch {...{options}}/>
                </div>

                <div className="col-sm-3">
                    <CheckBoxFilter {...{criteria}} i18nable={true}/>
                </div>

                <div className="col-sm-3">
                    <CheckBoxFilter {...{criteria}} i18nable={true}/>
                </div>
            </div>

    );
})
export default React.memo(withRouter(Searchbar))
