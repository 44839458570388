import React from "react"
import {FormattedMessage, injectIntl} from "react-intl";
import actions from "../../../../thunks/common/coreThunks";
import {useDispatch} from "react-redux";


const DeleteItem = injectIntl(props => {

    const {intl, id, entities} = props
    const dispatch = useDispatch();
    const params = {intl, item: {id: id}, options: [entities]}


    return (
        <div className="modal fade show" id={entities} tabIndex="-1" aria-labelledby={entities}
             style={{display: "none", paddingRight: "15px"}} aria-modal="true" role="dialog">
            <div className="modal-dialog modal-dialog-centered modal-sm" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id={entities}>
                            <FormattedMessage id={`app.label.delete_alert`}/>
                        </h5>
                    </div>
                    <div className="modal-body ">
                        <h3 className="text-primary font-weight-normal">
                            <FormattedMessage id={`app.label.removing_confirm_msg`}/>
                        </h3>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-light-dark font-weight-bold" data-dismiss="modal">
                            <FormattedMessage id={`app.label.no`}/>
                        </button>
                        <button type="button" className="btn btn-primary font-weight-bold"
                                onClick={()=>dispatch(actions.removeItem(params))} data-dismiss="modal">
                            <FormattedMessage id={`app.label.yes`}/>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
});


export default DeleteItem

