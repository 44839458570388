import * as React from "react";
import useDocumentTitle from "../../../../common/hooks/useDocumentTitle";
import {getIntlMsg} from "../../../../../constants/utils";
import {injectIntl} from "react-intl";
import {useParams, withRouter} from "react-router";
import CurrencyList from "./listing";
import CurrencyForm from "./form";
import {useState} from "react";


const CurrencyPageManager = injectIntl((props) => {
    const {intl} = props;
    const {module} = useParams();
    useDocumentTitle(getIntlMsg(intl, `app.label.${module}`));
    const [currencyFormView, setCurrencyFormView] = useState(false)

    return (
        <div className="tab-pane fade" id="currency" role="tabpanel" aria-labelledby="currency">
            <div className="cared card-custom pl-0">
                {currencyFormView && <CurrencyForm {...{setCurrencyFormView}}/>}
                <CurrencyList {...{currencyFormView, setCurrencyFormView}}/>
            </div>
        </div>
    );
})

export default React.memo(withRouter(CurrencyPageManager))

