export default {


    "app.label.updated_succeeded": "Modification réussie!",
    "app.label.updated_failed": "Modification échouée!",

    "app.label.deleted_succeeded": "Supression réussie!",
    "app.label.deleted_failed": "Supression échouée!",

    "app.label.saved_succeeded": "Sauvegarde réussie!",
    "app.label.saved_failed": "Sauvegarde échouée!",

    "app.label.add_diagnostic_succeeded": "Prélevement sauvegardé avec succes!",
    "app.label.add_diagnostic_faild": "Sauvegarde prélevement échouée!",


    "app.label.add_result_succeeded": "Résultat sauvegardé avec succes!",
    "app.label.add_result_faild": "Sauvegarde résultat échouée!",
    "app.label.today_date": "Date du jour",
    "app.label.date_of_specimen": "Date Prélevement",
    "app.label.test_request_type": "Test demandé",
    "app.label.reports_management": "Gestion des Rapports",

    "app.label.result_menu_options": "Options Résultats",
    "app.label.archive_all_results": "Arvhiver Tous Les Résultats",
    "app.label.print_all_results": "Imprimer Tous Les Résultats",
    "app.label.traveler_en": "VOYAGEUR",
    "app.label.staff": "Personnel",
    "app.label.staff_en": "STAFF",
    "app.label.request_type": "Type Demande",
    "app.label.current_id": "ID",
    "app.placeholder.current_id": "Saisissez ID",
    "app.label.complete_notification_sheet": "Completer notification",
    "app.label.adjust_notification_sheet": "Ajuster notification",
    "app.label.register_notification_sheet": "Enregistrer notification",


    "app.label.traveler": "Voyageur",


    "app.label.send_to_RESULTS": " ",
    "app.label.caution_send_to_SPECIMENS": " ",
    "app.label.send_to_SPECIMENS": " ",
    "app.label.caution_send_to_CART": " ",
    "app.label.caution_send_to_RESULTS": " ",
    "app.label.caution_send_to_ARCHIVED": " ",
    "app.label.send_to_ARCHIVED": " ",
    "Haiti": " ",
    "United States": " ",


    "app.label.importation": "Imporatation Données",
    "app.label.test_indicators": "Indicateurs des tests covid19",
    "app.label.county": "Département",
    "app.label.district": "Arrondissement",
    "app.label.municipality": "Commune",
    "app.label.sub_municipality": "Sec. Communalle",
    "app.label.personal_infos_long": "Informations Individuelles",
    "app.label.social_infos_long": "Informations Démographiques",


    "app.label.all_test_indicators": "Tous les indicateurs de test covid19",
    "app.label.indicator": "Indicateur",
    "app.label.collection_date": "Date Collection",
    "app.label.quantity": "Quantité",
    "app.label.site": "Lieux Collection",
    "app.label.error_fields": "Champs Erronés",



    "app.label.required": "Cette valeur est obligatoire!",
    "app.label.length_longer": "La valeur saisie est trop courte!",
    "app.label.length_shorter": "La valeur saisie est trop longue!",
    "app.label.date_lower": "Veuillez choisir une date antérieure!",
    "app.label.date_greater": "Veuillez choisir une date ultérieure!",
    "app.label.greater": "Valeur doit être plus grande!",
    "app.label.lower": "Valeur doit être plus petite!",


    "app.label.en": "English",
    "app.label.management": "Gestion",
    "app.label.fr": "Francais",
    "app.label.categories": "Catégories",
    "app.label.ht": "Kreyòl",
    "app.label.capacity": "Capacité",
    "app.label.about": "À Propos",
    "app.label.team": "Équipe",
    "app.label.contact": "Contact",
    "app.label.rate": "Taux",
    "app.label.item": "Element",
    "app.label.undefined": "Page Introuvable",
    "app.label.content": "a_content_to_fill",
    "app.label.dishes": "Plats",
    "app.label.event_title": "Titre Evenement",
    "app.label.help": "Aides",
    "app.label.all_users": "Utilisateurs",
    "app.label.users": "Utilisateurs",
    "app.label.all_roles": "Roles",
    "app.label.roles": "Roles",
    "app.label.blank": " ",
    "app.label.modules": "Modules",
    "app.label.remove_phone": "Supprimer Téléphone",
    "app.label.remove_account": "Supprimer Compte",
    "app.label.entity_is_empty": "Aucun resultat à afficher",
    "app.label.greetings": "Salut",
    "app.label.today": "Aujourd'hui",
    "app.label.home": "Accueil",
    "app.label.description": "Description",
    "app.placeholder.description": "Saisissez une description",
    "app.label.entity_name": "Entité",
    "app.placeholder.entity_name": "Saisissez une entite",
    "app.label.copyright": " 2020 Tous droits Réservés. Realisé par complétude numérique.",
    "app.label.version": "Version",
    "app.label.dashboard": "Tableau de bord",
    "app.label.report": "Rapports",
    "app.label.MALE": "Homme",
    "app.label.male": "Homme",
    "app.label.FEMALE": "Femme",
    "app.label.female": "Femme",
    "app.label.for": "pour",
    "app.label.general": "Général",
    "app.label.emails": "Courriels",
    "app.label.click_to_add_new_email": "Ajouter un courriel",
    "app.label.remove_email": "Supprimer courriel",
    "app.label.header": "En tête",
    "app.label.user_account": "Utilisateurs",
    "app.label.notifications": "Notifications",
    "app.label.notification": "Notifications",
    "app.label.user_manager": "Gestion Utilisateurs",
    "app.label.faq": "FAQ",
    "app.label.sign_out": "Déconnexion",
    "app.label.my_account": "Mon Compte",
    "app.label.my_account_desc": "Profile Utilisateur",
    "app.label.my_task": "Mes tâches",
    "app.label.my_task_desc": "Les tâches à accomplir",
    "app.label.login": "Connéxion",
    "app.label.profile": "Profile Utilisateur",
    "app.label.user": "Utilisateur",
    "app.label.accounts": "Comptes Utilisateurs",
    "app.label.account": "Compte Utilisateur",
    "app.label.apps": "Apps",
    "app.label.entity": "Entité",

    "app.label.administrator": "Administrateur",
    "app.label.admin": "Administrateur",
    "app.label.cashier": "Caissier",
    "app.label.receptionist": "Receptioniste",
    "app.label.archivist": "Archiviste",
    "app.label.lab_technician": "Technicien de laboratoire",
    "app.label.lab_technician_supervisor": "Technicien de laboratoire Sup.",
    "app.label.result_provider": "Éditeur de résultats",
    "app.label.data_officer": "Officer de données",
    "app.label.financial_officer": "Responsable Financier",
    "app.label.leadership_member": "Membre du leadership",


    "app.label.other": "Autre",
    "app.label.OTHER": "Autre",
    "app.label.FREE": "GRATUIT",
    "app.label.page_not_found": "Oops! Page Introuvable .",
    "app.label.use_inputs_to_filter": "Utilisez les champs pour filtrer les données du tableau",
    "app.label.report_desc": "( Générer & Imprimer rapports )",
    "app.label.export_to_csv": "Exporter CSV",
    "app.label.details": "Détails",
    "app.label.download": "Télécharger",
    "app.label.loading": "Chargement en cours...",
    "app.label.cart_empty": "Aucune demande à payer",
    "app.label.cart_empty_desc": "Il n'y a aucune demande en attente dans la caisse.",
    "app.label.specimens_empty": "Aucune demande prête pour prélèvement",
    "app.label.specimens_empty_desc": "Il n'y a aucune demande en attente dans la queue de prélèvement.",
    "app.label.results_empty": "Aucune demande prête pour résultat",
    "app.label.results_empty_desc": "Il n'y a aucune demande en attente dans la queue de résultat.",
    "app.label.reception_empty": "Aucune demande",
    "app.label.reception_empty_desc": "Il n'y a aucune demande en attente.",
    "app.label.notification_empty": "Aucune demande à notifier",

    "app.label.notification_empty_desc": "Il n'y a aucune demande en attente fiche de notification.",
    "app.label.notification": "Fiche de notification.",

    "app.label.archived_empty_desc": "Il n'y a aucune demande archivée.",
    "app.label.restore_to": "Restaurer dans",
    "app.label.notification-form": "Formulaire de notification",
    "app.label.registration": "Formulaire de demande",
    "app.label.about_zanmi_lasante": "À Propos",
    "app.label.team_med_infos": "L'équipe",
    "app.label.contact_contact": "Contact",
    "app.label.obs_dashboard": "DB Observation",


    "app.label.reception_list": "Liste des demandes à traiter.",
    "app.label.cart_list": "Liste des demandes à valider.",
    "app.label.specimens_list": "Les demandes en attente de prélèvement.",
    "app.label.results_list": "Les demandes en attente de résultat",
    "app.label.notification_list": "Les demandes en attente fiche de notification.",
    "app.label.laboratory_list": "Les demandes en attente de résultat de laboratoire",


    "app.label.caution_send_to_laboratory": "Voulez-vous vraiement envoyer cette demande au laboratoire ?",
    "app.label.caution_send_to_reception": "Voulez-vous vraiement envoyer cette demande à la reception ?",
    "app.label.caution_send_to_cart": "Voulez-vous vraiement envoyer cette demande à la caisse?",
    "app.label.caution_send_to_specimens": "Voulez-vous vraiement envoyer cette demande au prélèvement ?",
    "app.label.caution_send_to_results": "Voulez-vous vraiement envoyer cette demande à la queue de résultat ?",
    "app.label.caution_send_to_notification": "Voulez-vous vraiement envoyer cette demande à la fiche de notification ?",
    "app.label.caution_send_to_archived": "Voulez-vous vraiement archiver cette demande ?",


    "app.label.send_to_reception": "Envoyer à la récéption",
    "app.label.send_to_archived": "Envoyer à l'archive",
    "app.label.send_to_cart": "Envoyer à la caisse",
    "app.label.send_to_specimens": "Envoyer au prélèvement",
    "app.label.send_to_results": "Envoyer à la queue de résultat",
    "app.label.send_to_notification": "Envoyer à fiche de notification",
    "app.label.fill_sheet": "Remplir fiche de notification",
    "app.label.all_request_for_notification": "Les demandes prêtes à notifier",
    "app.label.choose_test_type": "Selectionz type",
    "app.label.choose_specimen_center": "Selectionz centre de prélèvement",
    "app.label.print_tag": "Imprimer Tag",
    "app.label.prepare_result": "Préparer Résultat",
    "app.label.send_to_mssp": "Envoyer à MSPP",
    "app.label.archived": "Demandes Archivés",
    "app.label.archived_empty": "Aucune demande archivés",
    "app.label.archived_list": "Liste des demandes archivés",


    "app.label.laboratory_empty": "Aucune demande au laboratoire",
    "app.label.laboratory_empty_desc": "Il n'ya aucunne demande en attente au laboratoire",


    "app.label.user_role_notfound": "Contactez l'administration",
    "app.label.user_setup_undone": "Les nouveaux utilisateurs doivent avoir au moins un rôle pour pouvoir acceder au système.",
    "app.label.assessment.create": "Créer une",
    "app.label.messages": "Messages",
    "app.label.settings": "Paramètres",
    "app.label.settings_desc": "Gestion des paramètres",
    "app.label.assessor.title": "Titre",
    "app.label.id_value": "No. Identifiant",
    "app.label.code": "Code",
    "app.label.nationality": "Nationalité",
    "app.label.personal_infos": "Individuel",
    "app.label.personal_infos_desc": "Les informations individuelles",
    "app.label.age": "Âge",
    "app.placeholder.age": "Saisissez une âge",
    "app.label.list": "Liste",
    "app.label.country": "Pays",
    "app.label.others": "Autres",
    "app.label.yes": "Oui",
    "app.label.no": "Non",
    "app.label.name": "Nom",
    "app.placeholder.name": "Saisissez un nom",
    "app.label.unknown": "Inconnu",
    "app.label.activate": "Activer",
    "app.label.deactivate": "Desactiver",
    "app.label.full_name": "Nom Complet",
    "app.label.reset_password": "Réinitialiser mot de passe",
    "app.label.id_type": "Type ID",
    "app.label.CIN": "CIN",
    "app.placeholder.CIN": "Saisissez un CIN",
    "app.label.NIF": "NIF",
    "app.placeholder.NIF": "Saissez un NIF",
    "app.label.passport": "PASSPORT",
    "app.label.actions": "Actions",
    "app.label.acronym": "Acronyme",
    "app.placeholder.acronym": "Saisissez une acronyme",
    "app.label.toggle_aside": "Basculer de côté",
    "app.label.specimen": "Prélèvement",
    "app.label.specimens": "Prélèvements",
    "app.label.test": "Analyse",
    "app.label.area_code": "Area Code",
    "app.placeholder.area_code": "+509",
    "app.label.phone_no": "No. Téléphone",
    "app.label.click_to_add_new_phone": "Cliquez pour ajouter un nouveau no. de téléphone",
    "app.label.click_to_add_new_bank_account": "Cliquez pour ajouter un nouveau compte bancaire",
    "app.label.bank_accounts": "Comptes bancaires",
    "app.label.account_no": "No. compte",
    "app.placeholder.account_no": "Saisissez No. comptes bancaires",
    "app.label.phones": "No. de téléphone",
    "app.placeholder.phone_no": "Saisissez No. Téléphone",
    "app.label.txnable": "Créditable",
    "app.label.txn_type": "Type transaction",
    "app.label.billing": "Facturation",
    "app.label.bank": "Banque",
    "app.label.mobile": "Mobile",
    "app.label.references": "Tables de référence",
    "app.label.name_required": "Veuillez saisir un nom",
    "app.label.description_length_longer": "Veuillez saisir une description text plus longue",
    "app.label.delete_alert": "Attention, Supression!",
    "app.label.removing_confirm_msg": "Effacer definitivement ?",


    // Header
    "app.label.hotel": "Hotel",
    "app.label.restaurant": "Restaurant",
    "app.label.catering": "Service Traiteur",
    "app.label.events": "Événements",
    "app.label.global_settings": "Paramètres Généraux",
    "app.label.security": "Sécurité",
    "app.label.security_management": "Gest. de la Sécurité",
    "app.label.dashboards": "Tableaux de bord",
    "app.label.all_dashboards": "Visualiser Stats.",
    "app.label.reports": "Rapports",
    "app.label.system_reports": "Tous les rapports",
    "app.label.booking": "Réservation",
    "app.label.booking_new": "Enregistrer Réservation",
    "app.label.booking_edit": "Editer Réservation",
    "app.label.quick_actions": "Actions Rapide",
    "app.label.send_to_specimen_que": "Envoyer à la queue de prélèvement",
    "app.label.send_to_laboratory": "Envoyer au laboratoire",


    // subheader
    // common
    "app.label.system_messages": "Messages non lus",
    //Hotel
    "app.label.bookings": "Réservations",
    "app.label.booking_desc": "Gestion réservations",

    "app.label.stays_moments": "Réception",
    "app.label.stays_moments_desc": "Séjours & Moments",

    "app.label.room_service": "Serv. Chambre",
    "app.label.room_service_desc": "Gestion chambres",

    "app.label.customers": "Clientel",
    "app.label.customer_management_desc": "Service a la clientel",

    "app.label.administration": "Administration",
    "app.label.administration_desc": "Administration Gnle.",

    "app.label.moments": "Moments",
    "app.label.instant_chat": "Messages Instantanés",
    "app.label.send": "Envoyer",
    "app.label.type_message": "Tapez un message",
    "app.label.global_dashboard": "Dashboard combiné",
    "app.label.security_dashboard": "Dashboard Sécurité",
    "app.label.financial_dashboard": "Dashboard Finance",
    "app.label.leadership_dashboard": "Dashboard Leadership",


    //USER
    "app.label.user_profile": "Profil Utilisateur",
    "app.label.reception": "Réception",
    "app.label.cart": "Caisse",
    "app.label.results": "Résultats",
    "app.label.notification_sheet": "Fiche de Notification",
    "app.label.print_form": "Imprimer Formulaire",
    "app.label.print_receipt": "Imprimer Reçu",
    "app.label.register_specimen": "Enregistrer Prélèvement",
    "app.label.archive_request": "Archiver Demande",
    "app.label.confirm_reception": "Confirmer Reception",
    "app.label.adjust_request": "Ajuster Demande",


    //HR
    "app.label.hr": "Resources Humaine",


    //PAYROLL
    "app.label.payroll": "Payroll",

    //PAYROLL
    "app.label.website": "Site Web",

    //NOTIFICATIONS
    "app.label.last_notifications": "Les dernières notifications",

    //Request
    "app.label.passport_id": "No.Passport",
    "app.placeholder.passport_id": "Saisissez No. Passport",
    "app.label.phone_invalid": "Format: 0000-0000",
    "app.label.appointment_date": "Date Rendez-Vous",
    "app.label.appointment_date_acc": "D. Rendez-Vous",
    "app.label.appointment_hour": "Heure Rendez-Vous",
    "app.label.choose_appointment_hour": "Selectionnez heure",
    "app.label.request_management": "Gestion des Demandes",
    "app.label.covid19_request": "Demande de Test Covid19",
    "app.label.requests": "Demande de Test Covid19",
    "app.label.request_add": "Demander Test Covid19",
    "app.label.all_request": "Toutes les demandes",
    "app.label.pending_request": "Demandes en cours",
    "app.label.request_add_options": "Enregistrer une nouvelle demande de test Covid19",
    "app.label.follow_up": "Prise en charge",
    "app.label.follow-up": "Prise en charge",
    "app.label.ping_applicant": "Alerter applicant",
    "app.label.progress_level": "Niveau Progression",
    "app.label.requests_list": "Liste des demandes de test covid19",
    "app.label.total": "Total",
    "app.label.billing_infos": "Paiement",
    "app.label.billing_infos_desc": "Les informations de paiement",
    "app.label.request_infos": "Demande",
    "app.label.request_infos_desc": "Les informations sur la demande",
    "app.label.payment_Type": "Modalité de Paiement",
    "app.label.MONCASH": "MONCASH",
    "app.label.DEPOSIT": "DÉPOT BANCAIRE",
    "app.label.CASH": "CASH",
    "app.label.free": "GRATUIT",
    "app.label.trx_code": "# Transaction",
    "app.placeholder.trx_code": "Saisissez # Transaction",
    "app.label.test_type": "Type Test",
    "app.label.request_by_status": "Distribution des demandes par status",
    "app.label.request_by_status_desc": "Les demandes classées par status",


    "app.label.unfinished": "Demandes Inachevées",
    "app.label.unfinished_list": "Liste des demandes inachevées",
    "app.label.unfinished_empty": "Aucune demande inachevée",
    "app.label.unfinished_empty_desc": "Il n'y a aucune demande inachevée a traiter",

    "app.label.request_by_date_type_status": "Distribution des demandes par Date/Type/Statut",
    "app.label.request_by_date_type_status_desc": "Les demandes classées  par Date/Type/Statut",


    "app.label.date": "Date",
    "app.label.ag_plus": "Ag Positif",
    "app.label.ag_minus": "Ag Négatif",
    "app.label.pcr_plus": "PCR Positif",
    "app.label.prc_minus": "PCR Négatif",
    "app.label.ag_total": "Total Ag",
    "app.label.pcr_total": "Total PCR",


    //Request

    "app.label.how_do_you_want_result": "Comment souhaitez-vous recevoir votre résultat ?",
    "app.label.byMail": "Par couriel",
    "app.label.onSite": "Á partir du centre de prélèvement",
    "app.label.maturity": "Maturité",
    "app.label.baby": "Bébé",
    "app.label.month_for_baby": "Mois (pour les < 1 an)",
    "app.placeholder.month_for_baby": "Mois (pour les < 1 an)",
    "app.label.new_request": "Nouvelle demande",
    "app.label.new_request_options": "Enregistrer une nouvelle demande",
    "app.label.all_request_list": "List des demandes de test covid19",
    "app.label.requests_dashboard": "Dashboard des demandes",
    "app.label.observations_dashboard": "Dashboard Observation",
    "app.label.requests_dashboard_desc": "Dashboard Demande Test Covid19",
    "app.label.test_management": "Gestion Tests Covid19",
    "app.label.covid19_test": "Demande de Test Covid19",
    "app.label.test_add": "Effectuer un prelevement",
    "app.label.all_test": "Tous les tests",
    "app.label.pending_test": "Test dans laboratoire",
    "app.label.test_add_options": "Enregistrer un nouveau test Covid19",
    "app.label.observations": "Formulaire d'observation",
    "app.label.social_infos": "Démographie",
    "app.label.social_infos_desc": "Les informations démographiques",
    "app.label.symptoms_signs": "Signes/Symptoms et Comorbidités",
    "app.label.travel_history": "Facteurs de risque/Voyage",
    "app.label.diagnostic": "Diagnostics",
    "app.label.investigator": "Investigateur",
    "app.label.new_request_add": "Ajouter Nouveau",
    "app.label.new_request_add_options": "Enregistrer une nouvelle demande",
    "app.label.add_new_request": "Nouvelle demande de test covid19",
    "app.label.request": "Demande de test covid19",
    "app.label.request_form_desc": "Formulaire d'enregistrement / Edition de demande de test covid19",
    "app.label.tests_performed": "Tests Effectués",


    // Categories
    "app.label.specimen_type": "Types Prélèvement",
    "app.label.choose_destination": "Selectionner Déstination",
    "app.label.choose_nationality": "Selectionner Nationalité",
    "app.label.result_sheet": "Fiche de Résultat",
    "app.label.print_result": "Imprimer Résultat",
    "app.label.adjust_result": "Ajuster Résultat",
    "app.label.adjust_result_of": "Ajuster le résultat de",
    "app.label.technician_signature": "Signature Téchnicien",
    "app.label.supervisor_signature": "Signature Superviseur",
    "app.label.remark": "Remarque",
    "app.placeholder.remark": "Remarque",
    "app.label.all_categories": "Toutes les catégories",
    "app.label.category_form": "Fomulaire Eregistrement / Edition des catégories",
    "app.label.test_indicator_form": "Fomulaire Eregistrement / Edition des indicateurs de test covid19",


    //observations

    "app.label.observations_dashboard_desc": "Dashboard des observations",
    "app.label.observation_management": "Gestion des Observations",
    "app.label.all_observations": "Toutes les Observations",

    //Patients
    "app.label.all_patients": "Tous les patients",
    "app.label.new_patient": "Nouveau patient",
    "app.label.all_patient_list": "List des patients",
    "app.label.patient": "Patient",
    "app.label.all_patients_options": "Visualiser liste patient",
    "app.label.new_result_form": "Enregistrer résultat",
    "app.label.register_result": "Enregistrer résultat",




    "app.label.c19_confirmed_isolation": "# Total confirmés mise en isolement",
    "app.label.c19_confirmed_quarantine": "# Total confirmés en suivis en quarantaine",
    "app.label.c19_followed_up": "Total confirmés pris en charge en general",
    "app.label.c19_rtr": "# Tests Rapides effectués",
    "app.label.c19_rtr_positive": "# Tests Rapides positifs",
    "app.label.c19_gxprt": "# de GeneXpert effectués",
    "app.label.c19_gxprt_positive": "# de GeneXpert positifs",
    "app.label.c19_pcr": "# de PCR effectués",
    "app.label.c19_pcr_positive": "# de PCR positifs",
    "app.label.c19_confirmed_exited": "# Patients confirmés exeatés  ",
    "app.label.c19_confirmed_died": "# Patients  confirmés décedes",
    "app.label.c19_staff_confirmed_positive": "# total de staff positifs",



    //Symptoms and Signs
    "app.label.person_has_symptoms": "La personne est symptomatique ?",
    "app.label.signs_symptoms": "Signes et Symptômes",

    "app.label.fever": "Fièvres",
    "app.label.aches": "Courbatures ",
    "app.label.cough": "Toux ",

    "app.label.asthenia": "Asthénie",
    "app.label.diarrhea": "Diarrhée",
    "app.label.headache": "Céphalée",

    "app.label.Loss_of_taste_agueusia": "Perte du gout (Agueusie)",
    "app.label.anosmia": "Anosmie",
    "app.label.curvatures": "Curvatures ",
    "app.label.cephalea": "Céphalée",
    "app.label.ageusia": "Ageusie",

    //Comorbidities and Habits
    "app.label.comorbidity_habits": "Comorbidités & Habitudes",
    "app.label.hta": "HTA",
    "app.label.respiratory_illnesses": "Maladies respiratoires sévères",
    "app.label.cancer": "Cancer",
    "app.label.hiv": "Immunodéficience/VIH",
    "app.label.tb": "TB",
    "app.label.Liver_cirrhosis": "Cirrhose du foie",
    "app.label.malnutrition": "Malnutrition",
    "app.label.heart_disease": "Maladie Cardio-vasculaire",
    "app.label.neuromuscular_diseases": "Maladies neurologique ou neuromusculaire chroniques",
    "app.label.kidney_disease": "Maladie rénale",
    "app.label.obesity_bmi_grt_35": "Obésité (IMC>35)",
    "app.label.allergic": "Allergique",
    "app.label.smoking": "Fumeur",
    "app.label.alcoholic": "Alcoolique",
    "app.label.other_habits_comorbidity": "Autres cormorbidités et habitudes",
    "app.label.other_symptoms_signs": "Autres signes et symptômes",
    "app.placehlder.other_symptoms_signs": "Inserez autres signes et symptômes",
    "app.placeholder.other_habits_comorbidity": "Inserez les autres cormorbidités et habitudes",
    "app.placeholder.other_symptoms_signs": "Inserez les autres signes et symptômes",


    // Diagnistic
    "app.label.specimen_nasopharyngee": "Prélèvement Nasopharyngé",
    "app.placeholder.specimen_nasopharyngee": "Prélèvement Nasopharyngé",
    "app.label.specimen_oropharyngeal": "Prélèvement Oropharyngé",
    "app.placeholder.specimen_propharyngeal": "Prélèvement Oropharyngé",
    "app.label.specimen_serum": "Prélèvement Serum",
    "app.placeholder.specimen_serum": "Prélèvement Serum",
    "app.placeholder.specimen_serum_date": "Prélèvement Serum",
    "app.placeholder.specimen_nasopharyngee_date": "Prélèvement Serum",
    "app.label.test_specimen": "Echantillon Prélevé",
    "app.label.specimen_date": "Date de prélevement",
    "app.label.laboratory_specimen_reception_date": "Date de réception au laboratoire",
    "app.placeholder.laboratory_specimen_reception_date": "Date de réception au laboratoire",
    "app.label.positive": "Positif",
    "app.label.negative": "Negatif",
    "app.label.pcr_result": "Résultat PCR",
    "app.label.ag_result": "Résultat AG",
    "app.label.pcr_result_date": "Date Résultat PCR",
    "app.label.result_pcr_date": "Date Résultat PCR",
    "app.label.result_ag_date": "Date Résultat Ag",
    "app.placeholder.pcr_result_date": "Date Résultat PCR",
    "app.label.light": "Cas léger",
    "app.label.moderate": "Cas modéré",
    "app.label.strict": "Cas sévère",
    "app.label.clinical_evolution": "Evolution clinique du patient",
    "app.label.isolation_location": "Isolement et prise en charge",
    "app.label.isolation_home": "Domicile",
    "app.label.isolation_hospital": "Institution",
    "app.label.investigator_institution": "Institution",
    "app.label.institution": "Institution",
    "app.placeholder.institution": "Inserez nom institution",
    "app.placeholder.investigator_institution": "Inserez nom institution",
    "app.label.hospitalization_date": "Date Hospitalisation",
    "app.label.ventilation_status": "Ventilation mécanique",
    "app.label.intensive_care_status": "Soins Intensifs",
    "app.label.ventilation_day_counter": "Durée ventilation(En Jours)",
    "app.placeholder.ventilation_day_counter": "Durée de ventilation",
    "app.label.intensive_care_day_counter": "Durée en soin intensif(En Jours)",
    "app.placeholder.intensive_care_day_counter": "Inserez une durée en soin intensif (En Jours)",
    "app.label.exit_pcr_result": "Résultat PCR a la sortie",
    "app.label.exit_test_result": "Résultat Labo à la sortie",
    "app.label.patient_released_state": "Sortie du patient",
    "app.label.restored": "Rétabli",
    "app.label.dead": "Décédé",
    "app.label.locality": "Localité",
    "app.placeholder.locality": "Saisissez Localité",
    "app.label.loss_of_sight": "Perte de vue",
    "app.label.released_date": "Date exéat",
    "app.label.death_date": "Date décès",
    "app.label.investigator_firstname": "Prénom",
    "app.placeholder.investigator_firstname": "Inserer un prénom",
    "app.label.investigator_phone": "No. Téléphone",
    "app.placeholder.investigator_phone": "Saisissez un No. de téléphone",
    "app.label.firstname": "Prénom",
    "app.placeholder.firstname": "Saisissez un prénom",
    "app.label.nickname": "Pseudonyme",
    "app.placeholder.nickname": "Saisissez un pseudonyme",
    "app.label.investigator_lastname": "Nom",
    "app.placeholder.investigator_lastname": "Inserez un nom",
    "app.label.lastname": "Nom",
    "app.placeholder.lastname": "Saisissez un nom",
    "app.label.gender": "Sexe",
    "app.placeholder.gender": "Selectionnez un sex",
    "app.label.dob": "Date de naissance",
    "app.label.dob_acc": "Date de naiss.",
    "app.label.passport_no": "No. Passeport.",
    "app.placeholder.dob": "Selectionnez une date de naisssance",
    "app.label.investigator_observation": "Observations",
    "app.label.payed": "Payé",
    "app.placeholder.investigator_observation": "Ecrire les observations ici...",
    "app.placeholder.specimen_propharyngeal_date": "Ecrire les observations ici...",


    //Travel
    "app.label.contact_14_days": "Notion de contact → 14 jours avant apparition des symptomes",
    "app.label.contact_with_contaminated": "Contact étroit avec un cas confirmé de COVID-19",
    "app.label.contact_with_traveled_contaminated": "Contact avec une personne qui a voyagé dans un pays ou une ville à risque de COVID-19",
    "app.label.visit_covid19_health_center": "Visite dans un établissement de prise en charge de COVID-19",
    "app.label.contact_with_covid19_dead": "Contact étroit avec une personne décédée de la COVID-19",
    "app.label.remove_contact": "Supprimer Contact",
    "app.label.travel_history_14_days": "Histoire de voyage → 14 jours avant apparition des symptomes",
    "app.label.traveled_to_covid19_countries": "Histoire de voyage ou de séjour dans un pays ou une ville affectée par le COVID-19",
    "app.label.covid_contact_id": "ID (NIF/CIN)",
    "app.placeholder.covid_contact_id": "Saisissez un ID (NIF/CIN)",
    "app.label.covid_contact_full_name": "Prénom et Nom",
    "app.placeholder.covid_contact_full_name": "Saisissez Prénom et Nom",
    "app.label.covid_contact_first_touch": "Date 1er contact",
    "app.placeholder.covid_contact_first_touch": "Date 1er contact",
    "app.label.covid_contact_last_touch": "Date dernier contact",
    "app.placeholder.covid_contact_last_touch": "Date dernier contact",
    "app.label.trip_country": "Pays",
    "app.placeholder.trip_country": "Pays",
    "app.label.trip_city": "Ville",
    "app.placeholder.trip_city": "Saisissez nom ville",
    "app.label.trip_arrival_date": "Date d’arrivée au pays/ville visité(e)",
    "app.placeholder.trip_arrival_date": "Date d’arrivée au pays/ville visité(e)",
    "app.label.trip_departure_date": "Date de départ du pays/ville visité (e )",
    "app.placeholder.trip_departure_date": "Date de départ du pays/ville visité (e )",
    "app.label.trip_local_country_cate": "Date d’entrée en Haiti (si visite est hors d’Haiti)",
    "app.placeholder.trip_local_country_cate": "Date d’entrée en Haiti (si la ville visitée est hors d’Haiti)",
    "app.label.add_contact": "Ajouter contact",
    "app.label.add_trip": "Ajouter voyage",
    "app.label.remove_trip": "Supprimer voyage",
    "app.placeholder.full_name": "Tapez nom complet",
    "app.label.address": "Addresse",
    "app.placeholder.address": "Tapez une addresse",
    "app.label.phone": "Téléphone",
    "app.placeholder.phone": "Saisissez un No. de téléphone",


    // Specimen centers
    "app.label.all_specimen_centers": "Tous les centres de prélèvement",
    "app.label.specimen_centers": "Centres de prélèvement",
    "app.label.specimen_center_form": "Fomulaire Eregistrement / Edition des centres de prélèvement",
    "app.placeholder.capacity": "Capacité",
    "app.label.delay": "Délais prélèvement",
    "app.label.specimen_center": "Centre prélèvement",
    "app.placeholder.delay": "Capacité",

    // currencies
    "app.label.all_currencies": "Tous les taux de change",
    "app.label.currencies": "Taux de change",
    "app.label.currency_form": "Fomulaire Eregistrement / Edition des taux",
    "app.placeholder.rate": "Taux",


    // item price
    "app.label.all_item_prices": "Tous les prix des items",
    "app.label.item_prices": "Prix des items",
    "app.label.item_price_form": "Fomulaire Eregistrement / Edition des prix",
    "app.label.amount": "Montant",
    "app.label.discount": "Rabais",
    "app.label.discountable": "Statut Rabais",
    "app.placeholder.amount": "Montant a payer",
    "app.placeholder.discount": "Montant Rabais",
    "app.label.txn_code": "Code transaction",
    "app.label.payment_type": "Mode de Paiment",
    "app.label.departure": "Date Départ",
    "app.placeholder.departure": "Selectionnez une date départ",
    "app.label.destination": "Déstination",
    "app.placeholder.destination": "Selectionnez une destination",
    "app.label.symptoms": "Sypmtomes",
    "app.label.do_you_have_symptoms": "Symptômatique ?",


    // iinstitutions
    "app.label.all_institutions": "Institutions",
    "app.label.institutions": "Institutions",
    "app.label.institutions_list": "Liste des institutions",
    "app.label.add_new_institution": "Nouvelle institution",
    "app.label.institution_form_desc": "Formulaire d'enregistrement / Edition institution",





    // Reports
    "app.label.laboratory_code": "Code Laboratoire",
    "app.label.laboratory_code_acc": "Code Lab",
    "app.label.new_specimen_form": "Enregistrer prélèvement",
    "app.label.result_ag": "Résultat Ag",
    "app.label.result_pcr": "Résultat PCR",
    "app.label.laboratory": "Laboratoire",




    // Data Sharing
    "app.label.sharing": "Partage de données",
    "app.label.sharing_management": "Gestion partage de données",
    "app.label.data_by_status": "Statut des données partagées",
    "app.label.data_by_status_db_desc": "Distribution des données partagées/statut",
    "app.label.partners": "Partenaires",
    "app.label.partners_list": "Tous les partenaires",
    "app.label.add_new_partner": "Nouveau partenaire",



    // Action keys
    "app.label.edit": "Editer",
    "app.label.add": "Ajouter",
    "app.label.display": "Afficher",
    "app.label.delete": "Supprimer",
    "app.label.save": "Sauvegarder",
    "app.label.save_acronym": "Sauveg...",
    "app.label.cancel": "Annuler",
    "app.label.update": "Modifier",
    "app.label.updating": "Modification en cours",
    "app.label.registering": "Enregistrement en cours",
    "app.label.saving": "Sauvegarde en cours ...",
    "app.label.add_new": "Enregistrer",
    "app.label.level": "Niveau",
    "app.label.one": "1 (Un)",
    "app.label.two": "2 (Deux)",
    "app.label.three": "3 (Trois)",


    // Status key
    "app.label.online": "Actif",
    "app.label.1": "Actif",
    "app.label.offline": "Inactif",
    "app.label.0": "Inactif",
    "app.label.new": "Nouveau",
    "app.label.current": "En Cours",
    "app.label.pending": "En Attente",
    "app.label.done": "Terminé",
    "app.label.true": "Ouvert",
    "app.label.false": "Fermé",
    "app.label.avis": "Avis",
    "app.label.benefit": "Bénéfice",
    "app.label.tma_start": "TMA Initial",
    "app.label.Intermediary_commission": "Commission Int.",


    //Table keys
    "app.label.go_to": "Aller à la",
    "app.label.page": "Page",
    "app.label.first_page": "Première page",
    "app.label.last_page": "Dernière page",
    "app.label.backward": "Page précédante",
    "app.label.forward": "Page suivante",
    "app.label.options": "Options",
    "app.label.creator": "Créé par",
    "app.label.created": "Créé le",
    "app.label.all": "Tous",
    "app.label.detail": "Détails",
    "app.label.selected_row": "Lignes selectionées",
    "app.label.search_criteria": "Inserer critères de recherche",
    "app.label.search_options": "Utilisez la barre de recherche pour filtrer les resultats",


    // List Label
    "app.label.FULL_REFUND": "CAPITAL + INTERET",


    // Form Label

    "app.label.required_fields": "Les champs en rouge avec un * sont obligatoires",
    "app.label.field_required": "Saisissez une valeur",


    "app.label.rooms": "Chambres",


    "app.label.commission_credit_desc": "Desc. avis de crédit comission",
    "app.label.commission_credit_desc_length_longer": "Nom trop court",
    "app.label.commission_credit_desc_length_shorter": "Nom trop long",
    "app.label.commission_credit_desc_required": "Veuillez saisir un nom",


//    Alerts
    "app.label.obligation_categories_saved_succeeded": "Sauvegarde Catégorie d'obligation reussie!",
    "app.label.obligation_categories_saved_failed": "Sauvegarde Catégorie d'obligation echouée!",


    "app.label.role": "Role",
    "app.placeholder.choose_role": "Choisissez un role",
    "app.placeholder.admin": "Administrateur",
    "app.placeholder.cashier": "Caissier",
    "app.placeholder.manager": "Manager",
    "app.placeholder.guest": "Visiteur",

    "app.label.status": "Statut",
    "app.placeholder.active": "Actif",
    "app.placeholder.blocked": "Bloqué",


    "app.label.username": "Pseudo",
    "app.placeholder.username": "Choisissez un pseudo",

    "app.label.email": "E-Mail",
    "app.placeholder.email": "Inserer un e-mail",
    "app.placeholder.specimenCenter": "Inserer le  centre de prélèvement",
    "app.placeholder.laboratoryCode": "Inserer le  Code du laboratoire",
    "app.label.specimenCenter": "centre de prélèvement",
    "app.label.specimen_center_acc": "C. prélèvement",
    "app.label.laboratoryCode": "Code du laboratoire",
    "app.label.specimenDate": "Date de prélèvement",
    "app.placeholder.specimenDate": "Inserer la Date de prélèvement",
    "app.placeholder.comment": "Inserer une remarque",
    "app.placeholder.testRequest": "Inserer le Test demandé",
    "app.label.comment": "Remarque",
    "app.label.testRequest": "Test demandé",
    "app.label.result": "résultat",
    "app.placeholder.result": "Inserer le résultat",
    "app.placeholder.identifier": "Inserer le No. de passeport",
    "app.label.identifier": "No. de passeport",
    "app.label.POSITIVE": "Positif",
    "app.label.NEGATIVE": "Négatif",
    "app.label.specimenType": "Type de prélèvement",
    "app.placeholder.specimenType": "Inserer le type de prélèvement",
    "app.label.print": "Imprimer",
    "app.label.formResult": "Préparer résultat",
    "app.label.confirmed": "Confirmé",
    "app.label.wait": "patientez s'il vous plait...",

    "app.label.firstTooltip": "Première page",
    "app.label.lastTooltip": "Dernière page",
    "app.label.previousTooltip": "page précédente",
    "app.label.nextTooltip": "page suivante",
    "app.label.labelRowsSelect": "lignes",
    "app.label.labelDisplayedRows": "{from}-{to} à {count}",
    "app.label.searchTooltip": "Chercher",
    "app.label.searchPlaceholder": "Chercher",
    "app.label.event": "Un événement",

    "app.label.next": "Suivant",
    "app.label.becoming": "A devenir",
    "app.label.request_by": "Distribution des demandes",
    "app.label.covid_indicator": "Suivi Indicateur Covid",
    "app.label.end": "Fin",
    "app.label.start": "Début",
    "app.label.healthCenter": "Site",
    "app.label.new_cart": "Nouvelle demande a payer",
    "app.label.registration-cart": "Nouvelle demande a payer",
    "app.label.null": "Inconnu",
    "app.label.symptoms_start_date": "Date début",
    "app.label.feel-sheet": "Feuille de notification",
    "app.label.print_cart": "Imprimer reçu",
    "app.label.adjust_cart": "Ajuster caisse",
    "app.label.GDS": "GDS",
    "app.label.USD": "USD",
    "app.label.currency_type": "Type de monnaie",
    "app.label.cashier_repport": "Rapport de caisse",



};
