import * as React from "react";
import {FormattedMessage} from "react-intl";
import {withRouter} from "react-router";
import {useDispatch} from "react-redux";
import {reset} from 'redux-form';


const ModalFormFooter = ({valid, name, moduleName, componentName, hideModal}) => {

    const dispatch = useDispatch();
    const closeModal = () => {
        hideModal(moduleName)
        dispatch(() => reset(name))

    }

    return (
        <div className="modal-footer">

            <button className="btn btn-outline-primary btn-sm" type="button" onClick={() => closeModal()}>
                <FormattedMessage id={`app.label.cancel`}/>
            </button>

            <button type="submit" className={`btn btn-primary btn-sm mr-3 font-weight-bold ${!valid && 'd-none'}`}
                    data-toggle="modal" data-target={componentName}>
                < FormattedMessage id={"app.label.save"} defaultMessage={`Save`} onClick={dispatch(() => reset(name))}/>
            </button>

        </div>

    );
}

export default React.memo(withRouter(ModalFormFooter))
