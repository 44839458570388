import * as React from "react";
import {FormattedMessage, injectIntl} from "react-intl";
import {withRouter} from "react-router";
import ChangeStateModal from "../../../../../common/modal/ChangeState";
import {getIntlMsg} from "../../../../../../../constants/utils";
import {useContext, useRef} from "react";
import {GlobalContext} from "../../../../../../index";
import {useReactToPrint} from "react-to-print";
import {Link, useParams} from "react-router-dom";
import {useDispatch} from "react-redux";
import actions from "../../../../../../../thunks/common/coreThunks";
import Notification from "../../../reports/notify";


const NotificationBtn = injectIntl(props => {

    const {showModal, institution: {acronym}} = useContext(GlobalContext);

    const {intl, item, handleStateChange} = props;
    const tagRef = useRef();

    const handlePrintNotify = useReactToPrint({
        content: () => tagRef.current,
    });

    const {page} = useParams();
    const dispatch = useDispatch()
    const params = {intl, item: {id: item?.id}, options: ['requests']}

    const loadCurrentElement = (type) => {
        dispatch(actions.loadItem(params))
        showModal(type)
    }


    return (
        <div className="btn-group btn-group-sm" aria-label="Basic example">


            {item?.notificationSheet !== null ?
                <button type="button" className="btn btn-light-primary" onClick={handlePrintNotify}>
                    <FormattedMessage id={`app.label.print`}/>
                </button> : null}

            <Link to={`/${acronym}/requests/feel-sheet/${item?.id}/notification`}
                  className="btn btn btn-sm btn-light-primary">
                <FormattedMessage
                    id={`app.label.${item?.state === 'SPECIMENS' || item?.diagnostic?.specimens != null ? 'adjust_notification_sheet' : item?.notificationSheet ? 'complete_notification_sheet' : 'register_notification_sheet'}`}/>
            </Link>

            {item?.diagnostic !== null || item?.state === "SPECIMENS" ? null :item?.notificationSheet !== null ?
                <button type="button" className="btn btn-light-primary"
                        data-toggle="modal" data-target={`#specimens${item?.id}`}
                        title={getIntlMsg(intl, `app.label.send_to_specimens`)}>
                    <FormattedMessage id={`app.label.send_to_specimens`}/>
                </button>:null}


            {/*{item?.diagnostic && !item?.diagnostic?.result && item?.testType?.name === 'Ag' &&*/}
            {/*<button type="button" className="btn btn btn-sm btn-light-primary"*/}
            {/*        onClick={()=>loadCurrentElement('result')}>*/}
            {/*    <FormattedMessage id="app.label.register_result"/>*/}
            {/*</button>*/}
            {/*}*/}


            {/*{item?.diagnostic?.result &&*/}
            {/*<button type="button" className="btn btn btn-sm btn-light-primary"*/}
            {/*        data-toggle="modal" data-target={`#results${item?.id}`}*/}
            {/*        title={getIntlMsg(intl, `app.label.send_to_results`)}>*/}
            {/*    <FormattedMessage id="app.label.send_to_results"/>*/}
            {/*</button>*/}
            {/*}*/}


            {/*{item?.diagnostic && !item?.diagnostic?.result && item?.testType?.name === 'PCR' &&*/}
            {/*<button type="button" className="btn btn btn-sm btn-light-primary"*/}
            {/*        data-toggle="modal" data-target={`#laboratory${item?.id}`}*/}
            {/*        title={getIntlMsg(intl, `app.label.send_to_laboratory`)}>*/}
            {/*    <FormattedMessage id="app.label.send_to_laboratory"/>*/}
            {/*</button>*/}
            {/*}*/}

            <div style={{display: "none", overflow: "hidden", height: 0}}>
                <Notification ref={tagRef} {...{item}}/>
            </div>

            <ChangeStateModal {...{title: 'specimens', handleStateChange, id: item?.id}}/>
            <ChangeStateModal {...{title: 'laboratory', handleStateChange, id: item?.id}}/>
        </div>

    );

});


export default React.memo(withRouter(NotificationBtn))
