import * as React from "react";
import ReactDOM from "react-dom";
import {Provider} from "react-redux";
import axios from "axios";
import Keycloak from "keycloak-js";
import "react-toastify/dist/ReactToastify.css";
import {ToastContainer} from "react-toastify";
import configureStore, {history} from "./redux/store/index";
import initialState from "./constants/utils/initialState";
import Layout from "./components";
import ScriptTag from 'react-script-tag';

const store = configureStore(initialState);




const app = (
    <>

    <Provider store={store}>
        <ToastContainer autoClose={2000} position={"top-center"}/>
        <Layout history={history}/>

        <ScriptTag type="text/javascript" src="/assets/js/prismjs.bundle.js?v=2.0.6"/>
        <ScriptTag type="text/javascript" src="/assets/js/scripts.bundle.js?v=2.0.6"/>
        <ScriptTag type="text/javascript" src="/assets/js/fullcalendar.bundle.js?v=2.0.6"/>
        <ScriptTag type="text/javascript" src="/assets/js/widgets.js?v=2.0.6"/>
        <ScriptTag type="text/javascript" src="/assets/js/bootstrap-datepicker.js?v=2.0.7"/>
        <ScriptTag type="text/javascript" src="/assets/js/datapicker.js"/>

    </Provider>


    </>
);

// ReactDOM.render(app, document.getElementById("kt_body"));

const kc = new Keycloak('/keycloak.json');

kc.init({
    onLoad: 'check-sso',
    promiseType: 'native',
    checkLoginIframe: false,
    silentCheckSsoRedirectUri: window.location.origin + '/silent-check-sso.html',
    pkceMethod: 'S256',
})
    .then(authenticated => {
        if (authenticated) {
            store.getState().keycloak = kc;
            ReactDOM.render(app, document.getElementById("kt_body"));
        } else {
            console.warn("not authenticated!");
            kc.login();

        }
    });

axios.interceptors.request.use(config => (
    kc.updateToken(5)
        .then(() => {
            config.headers.Authorization = 'Bearer ' + kc.token;
            return Promise.resolve(config)
        })
        .catch(kc.login)
));



