import React from "react"
import PageManager from "../../components/pages";
import GlobalHomePage from "../../components/pages/modules/obsheet/dashboard";

const routes = [
    {
        path: "/",
        exact: true,
        main: () => <GlobalHomePage/>
    },
    {
        path: "/:institution",
        exact: true,
        main: () => <PageManager/>
    },
    {
        path: "/:institution/:module",
        exact: true,
        main: () => <PageManager/>
    },
    {
        path: "/:institution/:module/:page",
        exact: true,
        main: () => <PageManager/>
    },
    {
        path: "/:institution/:module/:page/:secondLevelPage",
        exact: true,
        main: () => <PageManager/>
    },
    {
        path: "/:institution/:module/:page/:secondLevelPage/:action",
        exact: true,
        main: () => <PageManager/>
    },
    {
        path: "/:institution/:module/:page/:secondLevelPage/:action/:other",
        exact: true,
        main: () => <PageManager/>
    },
];

export default routes;