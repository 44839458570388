import * as React from "react";
import {FormattedMessage} from "react-intl";
import moment from "moment";
import CSVButton from "../../../../../../../common/utils/csvbutton";


const MapperDistribution = props => {

    const {items} = props

    const elements = buildChartElement(items)


    return (

        <div className="card card-custom">
            <div className="card-header">
                <div className="card-title">
                    <h3 className="card-label">
                        <div>
                            Rapport de caisse global
                        </div>
                    </h3>
                    <disv className="pull-right">
                        {/*<CSVButton {...{items: elements, dataType: 'test_report'}}/>*/}
                    </disv>

                </div>
            </div>
            <div className="card-body">
                <table className="table">
                    <thead>
                    <tr>
                        <th><FormattedMessage id="app.label.date"/></th>
                        <th className="text-primary">Total Ag</th>
                        <th className="text-success">Total PCR</th>
                        <th className="text-primary">Total GDS</th>
                        <th className="text-success">Total USD</th>
                    </tr>
                    </thead>
                    <tbody>
                    {elements?.map(summary =>
                        <tr className="font-weight-bolder font-size-h3 bg-secondary">
                            <td> {moment(new Date()).format('DD/MM/yyyy')}</td>
                            <td className="text-primary">{summary?.sumAg}</td>
                            <td className="text-success">{summary?.sumPcr}</td>
                            <td className="text-primary">{summary?.sumGds}</td>
                            <td className="text-success">{summary?.sumUsd}</td>
                        </tr>)}
                    </tbody>
                </table>
            </div>
        </div>

    )
}


const buildChartElement = (items) => {

    let labels = [];
    let values = [];
    let sumAg = 0;
    let sumPcr = 0;
    let sumGds = 0;
    let sumUsd = 0;
    if (items)
        for (const [key, value] of Object.entries(items)) {
            console.log("==============================", value)
            if (value.agCounter > 0) {
                sumAg = sumAg + value.agCounter;
            } else {
                sumPcr = sumPcr + value.pcrCounter;
            }
            if (value.gdsAmount > 0) {
                sumGds = sumGds + value.gdsAmount;
            } else {
                sumUsd = sumUsd + value.usdAmount;
            }
        }

    return [{sumAg: sumAg, sumPcr: sumPcr, sumUsd: sumUsd, sumGds: sumGds}]
}


export default React.memo(MapperDistribution)

