import React from "react"
import {injectIntl} from "react-intl";


const PageHeader = injectIntl(props => {

    const {item} = props;
    const styles = {
        subheaderWrapper: {
            width: "700px",
            margin: "0 auto",
            textAlign: "center",
            fontFamily: "Helvetica Neue"
        },
    }

    const qrCode = `/assets/media/qrcodes/${item?.qrCode}`

    return (
        <div className="report_footer d-flex" style={{width: "500px", margin: "0 auto", position: "relative"}}>
            {/*<div style={{position: "absolute", left: "-180px", top:"-55px"}}>*/}
            {/*    <img src={qrCode} alt="qrcode" style={{width: "120px", height: "120px"}}/>*/}
            {/*</div>*/}
            <h3 style={{...styles.subheaderWrapper, fontSize: "12px"}}>
                <div>Route de Saut d’eau, Rue chatulé, Mirebalais, Centre, Haiti</div>
                <div>Tels: +509 2226-1006 / +509 4444-0000</div>
                <div>emails: covid19@zanmilasante.org /contact@zanmilsante.org</div>
            </h3>

        </div>
    )
});


export default PageHeader

