/**
 * Created by inglouidor on 3/15/21.
 */
import React from 'react'
import {DoughnutChartjs, BarChartjs} from "../charts";
import {FormattedMessage} from "react-intl";
import TableList from '../../../../../../common/tables';
import {addToObject} from '../../../../../../../../constants/utils'


const Followup = ({indicator}) => {



    // let data=[{site:'CANGE',C19_CONFIRMED_EXITED:20,C19_CONFIRMED_DIED:50,color:"#8e5ea2"},{site:'HSN',C19_CONFIRMED_EXITED:3,C19_CONFIRMED_DIED:10,color:"#3e95cd"},{site:'HUM',C19_CONFIRMED_EXITED:3,C19_CONFIRMED_DIED:10,color:"#10CC75"}];

    let data = [];

    if (indicator && indicator.becoming) {

        for (let i = 0; i < Object.keys(indicator.becoming).length; i++) {

            let obj = Object.keys(indicator.becoming)[i]
            let d = Object.values(indicator.becoming)[i];

            data.push(addToObject(d, "site", obj, i))
        }

    }

    const color = ["#8e5ea2", "#3e95cd", "#10CC75"];

    const statusData = {
        labels: ["C19_CONFIRMED_EXITED", "C19_CONFIRMED_DIED"],
        datasets: data && data.map((value, i) => {
            return {
                label: value.site,
                backgroundColor: color[i],
                data: [value.C19_CONFIRMED_EXITED, value.C19_CONFIRMED_DIED]
            }
        })
    };

    const columns = [
        {
            title: 'Site',
            field: 'site', type: 'text'
        },
        {
            title: 'Conf Ex',
            field: 'C19_CONFIRMED_EXITED', type: 'text'
        },
        {
            title: 'Conf D',
            field: 'C19_CONFIRMED_DIED', type: 'text'
        },
    ]


    return (
        <React.Fragment>
            <div className="col-sm-6 pt-4">

                <div className="card card-custom">
                    <div className="card-header">
                        <div className="card-title">
                            <h3 className="card-label">
                                <FormattedMessage id="app.label.becoming"/> {" "}
                            </h3>
                        </div>
                    </div>
                    <div className="card-body">
                        <div>
                            <BarChartjs data={statusData}/>
                        </div>
                    </div>
                </div>

            </div>

            <div className="col-sm-6 pt-4">

                <TableList data={data} columns={columns} title="Tableau"/>

            </div>

        </React.Fragment>)
}

export default Followup