import {typeNameFormatter} from "../../../../constants/utils";

/**
 * @Author Rikenson JACQUES (The Tiger ^(..)^)
 * -----------------------------------------------
 * */
export const switchItemValue = params => {
    const {actionParams, options, value} = params;
    const currentParams = actionParams ? actionParams : options;
    return {type: `RESET_${typeNameFormatter(currentParams)}`, value};
};


/**
 * @Author Rikenson JACQUES (The Tiger ^(..)^)
 * ---------------------------------------------------
 * */
export const setChangeItemStatus = params => {

    const {actionParams, options, item} = params;
    const currentParams = actionParams ? actionParams : options;

    return {type: `CHANGE_${typeNameFormatter(currentParams)}_STATUS`, item};
};


/**
 * @Author Rikenson JACQUES (The Tiger ^(..)^)
 * */
export const setChangeItemStatusSucceeded = params => {
    const {actionParams, options, status, item} = params;
    const currentParams = actionParams ? actionParams : options;
    const payload = {item, status};
    return {
        type: `CHANGE_${typeNameFormatter(currentParams)}_SUCCEEDED`, payload
    };
};



/**
 * @Author Rikenson JACQUES (The Tiger ^(..)^)
 * */
export const setChangeItemStatusFailed = params => {
    const {actionParams, options} = params;
    const currentParams = actionParams ? actionParams : options;
    return {type: `CHANGE_${typeNameFormatter(currentParams)}_FAILED`, payload: false};
};






/**
 * @Author Rikenson JACQUES (The Tiger ^(..)^)
 * -----------------------------------------------
 * */
export const clearItem = params => {
    const {actionParams, options} = params;
    const currentParams = actionParams ? actionParams : options;
    return {type: `RESET_${typeNameFormatter(currentParams)}`};
};


/**
 * @Author Rikenson JACQUES (The Tiger ^(..)^)
 * */
export const setLoadedItemSucceeded = params => {
    const {actionParams, options, payload} = params;
    const currentParams = actionParams ? actionParams : options;
    return {
        type: `LOAD_${typeNameFormatter(currentParams)}_SUCCEEDED`, payload
    };
};


/**
 * @Author Rikenson JACQUES (The Tiger ^(..)^)
 * */
export const setLoadedItemFailed = params => {
    const {actionParams, options} = params;
    const currentParams = actionParams ? actionParams : options;
    return {type: `LOAD_${typeNameFormatter(currentParams)}_FAILED`, payload: false};
};


/**
 * @Author Rikenson JACQUES (The Tiger ^(..)^)
 * */
export const setLoadedItem = params => {
    const {actionParams, options} = params;
    const currentParams = actionParams ? actionParams : options;
    return {type: `LOAD_${typeNameFormatter(currentParams)}`, payload: true};
};

/**
 * @Author Rikenson JACQUES (The Tiger ^(..)^)
 * */
export const setLoadedItemsSucceeded = params => {
    const {actionParams, options, payload} = params;
    const currentParams = actionParams ? actionParams : options;
    return {
        type: `LOAD_${typeNameFormatter(currentParams, false)}_SUCCEEDED`, payload
    };
};


/**
 * @Author Rikenson JACQUES (The Tiger ^(..)^)
 * */
export const setLoadedItemsFailed = params => {
    const {actionParams, options} = params;
    const currentParams = actionParams ? actionParams : options;
    return {type: `LOAD_${typeNameFormatter(currentParams, false)}_FAILED`, payload: false};
};

/**
 * @Author Rikenson JACQUES (The Tiger ^(..)^)
 * ---------------------------------------------
 * */
export const setLoadedItems = params => {

    const {actionParams, options} = params;
    const currentParams = actionParams ? actionParams : options;
    return {type: `LOAD_${typeNameFormatter(currentParams, false)}`, payload: true};
};


/**
 * @Author Rikenson JACQUES (The Tiger ^(..)^)
 * */
export const setUploadSucceeded = params => {
    const {actionParams,options, payload} = params;
    const currentParams = actionParams ? actionParams : options;
    return {
        type: `UPLOAD_${typeNameFormatter(currentParams)}_SUCCEEDED`, payload
    };
};


/**
 * @Author Rikenson JACQUES (The Tiger ^(..)^)
 * */
export const setUploadFailed = params => {
    const {actionParams, options} = params;
    const currentParams = actionParams ? actionParams : options;
    return {type: `UPLOAD_${typeNameFormatter(currentParams)}_FAILED`, payload: false};
};


/**
 * @Author Rikenson JACQUES (The Tiger ^(..)^)
 * */
export const setUpload = params => {
    const {actionParams, options} = params;
    const currentParams = actionParams ? actionParams : options;
    return {type: `UPLOAD_${typeNameFormatter(currentParams)}`, payload: true};
};


/**
 * @Author Rikenson JACQUES (The Tiger ^(..)^)
 * ---------------------------------------------------
 * */
export const setLoadItemsListOfValue = params => {
    const {actionParams, options,items} = params;
    const currentParams = actionParams ? actionParams : options;
    return {type: `LOAD_${typeNameFormatter(currentParams)}_LIST_OF_VALUE`, items};
};


/**
 * @Author Rikenson JACQUES (The Tiger ^(..)^)
 * */
export const setSavedOrUpdatedItem = params => {
    const {actionParams, options, action} = params;
    const currentParams = actionParams ? actionParams : options;
    return {
        type: `${(action === "save") ? 'SAVE_' : (action === "upload") ? '' : 'UPDATE_'}${typeNameFormatter(currentParams)}`,
        payload: true
    };
};


/**
 * @Author Rikenson JACQUES (The Tiger ^(..)^)
 * */
export const setSavedOrUpdatedItemFailed = params => {
    const {actionParams, options, action} = params;
    const currentParams = actionParams ? actionParams : options;
    return {
        type: `${(action === "save") ? 'SAVE_' : (action === "upload") ? '' : 'UPDATE_'}${typeNameFormatter(currentParams)}_FAILED`,
        payload: false
    };
};


/**
 * @Author Rikenson JACQUES (The Tiger ^(..)^)
 * ---------------------------------------------------
 * */
export const setSavedOrUpdatedItemSucceeded = params => {
    const {actionParams, options, action, payload} = params;
    const currentParams = actionParams ? actionParams : options;

    return action === "save"
        ? {type: `SAVE_${typeNameFormatter(currentParams)}_SUCCEEDED`, payload}
        : action === 'upload' ? {type: `${typeNameFormatter(currentParams)}_SUCCEEDED`, payload}
            : {type: `UPDATE_${typeNameFormatter(currentParams)}_SUCCEEDED`, payload};
};


/**
 * @Author Rikenson JACQUES (The Tiger ^(..)^)
 * -----------------------------------------------------------
 * */
export const setDeletedItemSucceeded = params => {
    const {actionParams, options, status, item} = params;
    const currentParams = actionParams ? actionParams : options;
    const payload = {item, status};

    return {type: `DELETE_${typeNameFormatter(currentParams)}_SUCCEEDED`, payload};
};

/**
 * @Author Rikenson JACQUES (The Tiger ^(..)^)
 * */
export const setDeletedItemFailed = params => {
    const {actionParams, options} = params;
    const currentParams = actionParams ? actionParams : options;
    return {type: `DELETE_${typeNameFormatter(currentParams)}_FAILED`, payload: false};
};

/**
 * @Author Rikenson JACQUES (The Tiger ^(..)^)
 * */
export const setDeletedItem = params => {
    const {actionParams, options} = params;
    const currentParams = actionParams ? actionParams : options;
    return {
        type: `DELETE_${typeNameFormatter(currentParams)}`, payload: true
    };
};


/**
 * @Author Rikenson JACQUES (The Tiger ^(..)^)
 * -----------------------------------------------------------
 * */
export const setSwitchItem = params => {

    return {type: `SWITCH_LOCALE`, payload: params};
};
