import * as React from "react";
import {useContext} from "react";
import {FormattedMessage, injectIntl} from "react-intl";
import {withRouter, useParams} from "react-router";
import ChangeStateModal from "../../../../../common/modal/ChangeState";
import {getIntlMsg} from "../../../../../../../constants/utils";
import {Link} from "react-router-dom";
import {GlobalContext} from "../../../../../../index";


const NotificationBtn = injectIntl(props => {


    const {intl, id, handleStateChange,item} = props;
    const {module, page} = useParams();

    const handlePrintForm = () => {
    }
    const handlePrintReceipt = () => {
    }

    const {userSession: {roles}, institution:{acronym}} = useContext(GlobalContext);


    return (

        <div className="col-md-12 float-right">
        <div className="btn-group btn-group-sm float-right col-md-12" role="group" aria-label="Basic example">
            <button type="button" className="btn btn-light-primary" onClick={handlePrintForm}>
                <FormattedMessage id={`app.label.print_form`}/>
            </button>
            <button type="button" className="btn btn-light-primary" onClick={handlePrintReceipt}>
                <FormattedMessage id={`app.label.send_to_mssp`}/>
            </button>
            <button type="button" className="btn btn-light-primary"
                    data-toggle="modal" data-target={`#specimens`}
                    title={getIntlMsg(intl, `app.label.send_to_specimens`)}>
                <FormattedMessage id={`app.label.send_to_specimens`}/>
            </button>

            <Link to={`/${acronym}/${module}/notification-form/${item?.id}/edit`}
                  type="button" className="btn btn-light-primary"
                  title={getIntlMsg(intl, `app.label.fill_sheet`)}>
                <FormattedMessage id={`app.label.fill_sheet`}/>

            </Link>


            <ChangeStateModal {...{id, title: 'specimens', handleStateChange}}/>
        </div>
        </div>

    );

});


export default React.memo(withRouter(NotificationBtn))
