import React, {useState} from "react"
import {change, Field} from "redux-form";
import {FormattedMessage} from 'react-intl';
import {renderCheckbox, renderField, renderRadio} from "../../../../common/utils/FormComponent";
import {withRouter} from "react-router";
import {useDispatch, useSelector} from "react-redux";
import {DateUtil} from "../../../../../constants/utils";
import {validate} from './validator'

let FormItems = ({touched, error, symptomForm, symptoms, cormorbidities}) => {

    const [symptomEntities, setSymptomEntities] = useState([])
    const [comorbidityEntities, setComorbidityEntities] = useState([])

    const {observation} = useSelector(state => ({
        observation: state.form?.observationForm?.values,
    }));
    const dispatch = useDispatch()

    const handlerAdOrEditSymptom = (obj) => {
        let objs = [];

        const entity = {
            category: {id: obj.id},
            result: obj?.status?.toUpperCase()
        }

        if (symptomEntities.length > 0) {
            const index = symptomEntities.findIndex(value => value.category.id === obj.id);
            if (index >= 0) {
                objs = [...symptomEntities, entity]
                objs.splice(index, 1);
            } else {
                objs = [...symptomEntities, entity]
            }
        } else {
            objs = [...symptomEntities, entity]
        }
        setSymptomEntities(objs);
        dispatch(change("symptomForm", "signsSymptoms", objs));
    }

    const handlerAdOrEditComorbidity = (obj) => {
        let objs = [];

        const entity = {
            category: {id: obj.id},
            result: obj.status
        }

        if (comorbidityEntities.length > 0) {
            const index = comorbidityEntities.findIndex(value => value.category.id === obj.id);
            if (index >= 0) {
                objs = [...comorbidityEntities, entity]
                objs.splice(index, 1);
            } else {
                objs = [...comorbidityEntities, entity]
            }
        } else {
            objs = [...comorbidityEntities, entity]
        }
        setComorbidityEntities(objs);
        dispatch(change("symptomForm", "comorbidityHabits", objs));

    }

    return (
        <div className={`custom_fieldset mt-4`}>

            <div className="row mt-3">
                <div className="col-sm-4">
                    <div className="form-group">
                        <label>
                            <b><FormattedMessage id={`app.label.person_has_symptoms`}/></b>
                            <span className="text-primary"> * </span> :
                        </label>
                        <div className="radio-inline">
                            {['yes', 'no'].map((element, i) =>
                                <label className="radio radio-lg" key={i}>
                                    <Field type="radio" component="input" name="symptoms" value={element}/>
                                    <span/>
                                    <FormattedMessage id={`app.label.${element}`}/>
                                </label>
                            )}
                        </div>
                        {touched && error &&
                        <span className="form-text text-primary">
                            <FormattedMessage id={error}/>
                        </span>}
                    </div>
                </div>


                {symptomForm?.symptoms === 'yes' && <div className="col-sm-8">
                    <Field className="form-control form-control-sm" id="symptoms_start_date" name="symptomsStartDate"
                           component={renderField} type="date" label="symptoms_start_date"
                           placeholder="app.placeholder.symptoms_start_date" validate={validate.error} required/>
                </div>}
            </div>


            <hr/>
            {symptomForm?.symptoms === 'yes'?
            <div className="row mt-3">
                <div className="col-sm-12 mb-3">
                    <h4><FormattedMessage id={`app.label.signs_symptoms`}/></h4>
                </div>
            </div>:null}
            {symptomForm?.symptoms === 'yes' ? <>

                <div className="row mt-3">
                    {symptoms.map((item, i) =>
                        <div className="col-sm-4" key={i}>

                            <div className="form-group">
                                <label>
                                    <b><FormattedMessage id={`app.label.${item.code}`}/></b>
                                    <span className="text-primary"> * </span> :
                                </label>
                                <div className="radio-inline">
                                    {item.status.map((el, ii) =>

                                        <label className="radio" key={i}>
                                            <Field type="radio" component="input" name={item.code} value={el}
                                                   onChange={() => handlerAdOrEditSymptom({id: item.id, status: el})}/>
                                            <span/>
                                            <FormattedMessage id={`app.label.${el}`}/>
                                        </label>
                                    )}
                                </div>

                            </div>
                            {touched && error &&
                            <span className="form-text text-primary">
                            <FormattedMessage id={error}/>
                        </span>}

                        </div>
                    )}
                </div>

                <div className="row mt-2">
                    <div className="col-sm-8">
                        <Field
                            className="form-control form-control-sm" id="other" name="otherSymptoms"
                            component={renderField} type="text" label="other_symptoms_signs"
                            placeholder="other_symptoms_signs" validate={validate.error}/>
                    </div>
                </div>
                <hr/>

                <div className="row mt-2">
                    <div className="col-sm-12 mb-3">
                        <h4><FormattedMessage id={`app.label.comorbidity_habits`}/></h4>
                    </div>
                    {cormorbidities.map((item, i) =>
                        <div className="col-sm-2" key={i}>
                            <Field component={renderCheckbox}
                                   onChange={(e) => handlerAdOrEditComorbidity({id: item.id, status: 'YES'})}
                                   name={item.code} label={item.code} id={item}
                                   validate={validate.error}
                            />
                        </div>
                    )}

                    <div className="col-sm-12">
                        <Field className="form-control form-control-sm" id="other" name="otherHabits"
                               component={renderField} type="text" label="other_habits_comorbidity"
                               placeholder="other_habits_comorbidity" validate={validate.error}/>
                    </div>
                </div>
            </> : null}
        </div>

    );
}
export default React.memo(withRouter(FormItems))
