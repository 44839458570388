import * as React from "react";
import {useContext} from "react";
import {FormattedMessage} from "react-intl";
import {GlobalContext} from "../../../index";


const QuickUserAction = props => {

    const {} = props

    const {userSession: {roles, name, email,gender, keycloak}, institution} = useContext(GlobalContext);


    return (
        <div id="kt_quick_user" className="offcanvas offcanvas-left p-10">
            <div className="offcanvas-header d-flex align-items-center justify-content-between pb-5">
                <h3 className="font-weight-bold m-0">
                    <FormattedMessage id={`app.label.user_profile`}/>
                    <small className="text-muted font-size-sm ml-2">
                        {0} <FormattedMessage id={`app.label.messages`}/>
                    </small>
                </h3>
                <a href="#" className="btn btn-xs btn-icon btn-light btn-hover-primary" id="kt_quick_user_close">
                    <i className="ki ki-close icon-xs text-muted"/>
                </a>
            </div>

            <div className="offcanvas-content pr-5 mr-n5">
                <div className="d-flex align-items-center mt-5">
                    <div className="symbol symbol-100 mr-5">
                        <div className="symbol-label"
                             style={{backgroundImage: `url(/assets/media/avatars/${gender==='male'?'male.png':'wonman.png'})`}}/>
                        <i className="symbol-badge bg-success"/>
                    </div>
                    <div className="d-flex flex-column">
                        <a href="#" className="font-weight-bold font-size-h5 text-dark-75 text-hover-primary">
                            {name}
                        </a>
                        <div className="text-muted mt-1">
                            <ul>
                                {roles && roles.map((role, key) =>
                                    <li key={key}>
                                        <FormattedMessage id={`app.label.${role.toLowerCase()}`}/>
                                        {key === roles.length - 1 ? "" : ", "}
                                    </li>
                                )}
                            </ul>

                        </div>
                        <div className="navi mt-1">
                            <a href="#" className="navi-item">
								<span className="navi-link p-0 pb-2">
									<span className="navi-icon mr-1">
										<span className="svg-icon svg-icon-lg svg-icon-primary">
											<svg width="24px" height="24px"
                                                 viewBox="0 0 24 24" version="1.1">
												<g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
													<rect x="0" y="0" width="24" height="24"/>
													<path
                                                        d="M21,12.0829584 C20.6747915,12.0283988 20.3407122,12 20,12 C16.6862915,12 14,14.6862915 14,18 C14,18.3407122 14.0283988,18.6747915 14.0829584,19 L5,19 C3.8954305,19 3,18.1045695 3,17 L3,8 C3,6.8954305 3.8954305,6 5,6 L19,6 C20.1045695,6 21,6.8954305 21,8 L21,12.0829584 Z M18.1444251,7.83964668 L12,11.1481833 L5.85557487,7.83964668 C5.4908718,7.6432681 5.03602525,7.77972206 4.83964668,8.14442513 C4.6432681,8.5091282 4.77972206,8.96397475 5.14442513,9.16035332 L11.6444251,12.6603533 C11.8664074,12.7798822 12.1335926,12.7798822 12.3555749,12.6603533 L18.8555749,9.16035332 C19.2202779,8.96397475 19.3567319,8.5091282 19.1603533,8.14442513 C18.9639747,7.77972206 18.5091282,7.6432681 18.1444251,7.83964668 Z"
                                                        fill="#000000"/>
													<circle fill="#000000" opacity="0.3" cx="19.5" cy="17.5" r="2.5"/>
												</g>
											</svg>
										</span>
									</span>
									<span className="navi-text text-muted text-hover-primary">{email}</span>
								</span>
                            </a>
                        </div>
                    </div>
                </div>
                <div className="separator separator-dashed mt-8 mb-5"/>
                <div className="navi navi-spacer-x-0 p-0">
                    {/*<Link to={`/`} className="navi-item">*/}
                    {/*    <div className="navi-link">*/}
                    {/*        <div className="symbol symbol-40 bg-light mr-3">*/}
                    {/*            <div className="symbol-label">*/}
                    {/*				<span className="svg-icon svg-icon-md svg-icon-danger">*/}
                    {/*					<svg width="24px" height="24px"*/}
                    {/*                         viewBox="0 0 24 24" version="1.1">*/}
                    {/*						<g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">*/}
                    {/*							<rect x="0" y="0" width="24" height="24"/>*/}
                    {/*							<path*/}
                    {/*                                d="M18,2 L20,2 C21.6568542,2 23,3.34314575 23,5 L23,19 C23,20.6568542 21.6568542,22 20,22 L18,22 L18,2 Z"*/}
                    {/*                                fill="#000000" opacity="0.3"/>*/}
                    {/*							<path*/}
                    {/*                                d="M5,2 L17,2 C18.6568542,2 20,3.34314575 20,5 L20,19 C20,20.6568542 18.6568542,22 17,22 L5,22 C4.44771525,22 4,21.5522847 4,21 L4,3 C4,2.44771525 4.44771525,2 5,2 Z M12,11 C13.1045695,11 14,10.1045695 14,9 C14,7.8954305 13.1045695,7 12,7 C10.8954305,7 10,7.8954305 10,9 C10,10.1045695 10.8954305,11 12,11 Z M7.00036205,16.4995035 C6.98863236,16.6619875 7.26484009,17 7.4041679,17 C11.463736,17 14.5228466,17 16.5815,17 C16.9988413,17 17.0053266,16.6221713 16.9988413,16.5 C16.8360465,13.4332455 14.6506758,12 11.9907452,12 C9.36772908,12 7.21569918,13.5165724 7.00036205,16.4995035 Z"*/}
                    {/*                                fill="#000000"/>*/}
                    {/*						</g>*/}
                    {/*					</svg>*/}
                    {/*				</span>*/}
                    {/*            </div>*/}
                    {/*        </div>*/}
                    {/*        <div className="navi-text">*/}
                    {/*            <div className="font-weight-bold">My Account</div>*/}
                    {/*            <div className="text-muted">Profile info*/}
                    {/*                <span*/}
                    {/*                    className="label label-light-danger label-inline font-weight-bold">update</span>*/}
                    {/*            </div>*/}
                    {/*        </div>*/}
                    {/*    </div>*/}
                    {/*</Link>*/}


                    <span className="navi-item mt-2">
						<span className="navi-link">
							<span className="btn btn-sm btn-light-primary font-weight-bolder py-3 px-6"
                                  onClick={keycloak.logout}>
                                <FormattedMessage id={`app.label.sign_out`}/>
                            </span>
						</span>
					</span>
                </div>
            </div>
        </div>
    )
}

export default React.memo(QuickUserAction)

