import types from "../../actions/types/observation/actionTypes";
import initialState from "../../../constants/utils/initialState";

const reducer = (state = initialState.observationReducer, action) => {

        switch (action.type) {

            /**
             * LOAD OBSERVATION LIST
             * */
            case types.RESET_OBSERVATION:
                return {...state, item: {}};

            /**
             * LOAD OBSERVATION LIST
             * */
            case types.LOAD_OBSERVATIONS:
                return {...state, loadingItems: action.payload};
            case types.LOAD_OBSERVATIONS_SUCCEEDED:
                return {...state, items: action.payload, loadingItems: false};
            case types.LOAD_OBSERVATIONS_FAILED:
                return {...state, loadingItems: action.payload};


            /**  LOAD OBSERVATION BY A SPECIFIC PROPERTY CASE */
            case types.LOAD_OBSERVATION:
                return {...state, loadingItem: true};
            case types.LOAD_OBSERVATION_SUCCEEDED:
                return {...state, item: action.payload, loadingItem: false};
            case types.LOAD_OBSERVATION_FAILED:
                return {...state, loadingItem: false};


            /**  SAVE OBSERVATION CASE */
            case types.SAVE_OBSERVATION:
                return {...state, savingOrUpdating: true};
            case types.SAVE_OBSERVATION_SUCCEEDED:
                return {
                    ...state, items: [...state.items, action.payload],
                    item: action.payload,
                    savingOrUpdating: false
                };
            case types.SAVE_OBSERVATION_FAILED:
                return {...state, savingOrUpdating: false};



            /**  UPDATE OBSERVATION CASE  */

            case types.UPDATE_OBSERVATION:
                return {...state, savingOrUpdating: true};
            case types.UPDATE_OBSERVATION_SUCCEEDED:
                return {
                    ...state,
                    items: state.items.map(item => item.id === action.payload.id ? action.payload : item),
                    item: action.payload,
                    savingOrUpdating:false
                };
            case types.UPDATE_OBSERVATION_FAILED:
                return {...state, savingOrUpdating: false};



            /**  DELETE OBSERVATION CASE  */
            case types.DELETE_OBSERVATION:
                return {...state, deleting: true};
            case types.DELETE_OBSERVATION_SUCCEEDED:
                return (action.payload.status === 200) ?
                    {...state, items: state.items.filter(item => item.id !== action.payload.item.id), deleting: false}
                    : state;
            case types.DELETE_OBSERVATION_FAILED:
                return {...state, deleting: false};


            default:
                return state;
        }
    }
;

export default reducer;
