import * as React from 'react'
import * as validator from "../../../../../../constants/utils/validator";
import {FormattedMessage} from "react-intl";

export const validate = values => {
    const errors = {};
    errors.labsReceptionDate = !values.labsReceptionDate
    errors.nasopharyngee = !values.nasopharyngee
    errors.oropharyngeal = !values.oropharyngeal ? 'app.label.required' : ''
    // errors.description = validator.validateString('description', values.description, 3, 150);
    return errors
};

export const renderError = ({meta: {touched, error}}) =>
    touched && error ?
        <small className="has-error" style={{fontSize: "12px", color: 'red'}}>
            <FormattedMessage id={`app.label.${error}`} defaultMessage={error}/>
        </small>
        : false


