import {useContext, useEffect} from "react";
import {GlobalContext} from "../../index";

function useSecondaryToggler(module) {

    const {institution: {acronym, description}} = useContext(GlobalContext);


    useEffect(() => {

        // const a = document.getElementsByClassName(".aside-nav");


        // Array.from(a).forEach(function (element) {
        //     alert(element)
        // });
    });


}


export default useSecondaryToggler;
