import * as React from "react";
import {FormattedMessage, injectIntl} from "react-intl";
import {useParams, withRouter} from "react-router";
import actions from "../../../../../../thunks/common/coreThunks";
import {useDispatch, useSelector} from "react-redux";
import Item from "./Item";
import SearchBar from "./SearchBar";
import {useEffect} from "react";
import Spinner from "../../../../../common/utils/Spinner";
import TablePager from "../../../../common/pagination/TablePager";


const CategoryList = injectIntl((props) => {

    const dispatch = useDispatch();
    const {intl, formView, setFormView} = props;
    const {page, id} = useParams();

    const criteria = {options: ['categories']};
    const options = {options: ['categories', 'names']};

    useEffect(() => {
        dispatch(actions.loadItems(criteria));
    }, [dispatch]);

    const {loading, items} = useSelector(state => ({
        loading: state.categoryReducer.loadingItems,
        items: state.categoryReducer.items,
    }));

    return (
        <React.Fragment>
            <h5 className="text-black-50 mb-5">
                <FormattedMessage id="app.label.all_categories"/>
            </h5>
            <SearchBar {...{formView, setFormView, options}}/>
            <div
                className="datatable datatable-bordered datatable-head-custom datatable-default datatable-primary datatable-loaded"
                id="kt_datatable_0" style={{}}>
                <table className="datatable-table" style={{display: "block"}}>
                    <thead className="datatable-head">
                    <tr className="datatable-row" style={{left: "0px"}}>

                        <th className="datatable-cell datatable-cell-sort">
                        <span style={{width: "137px"}}>
                            <FormattedMessage id={`app.label.name`}/>
                        </span>
                        </th>

                        <th className="datatable-cell datatable-cell-sort">
                        <span style={{width: "300px"}}>
                            <FormattedMessage id={`app.label.description`}/>
                        </span>
                        </th>

                        <th className="datatable-cell datatable-cell-sort">
                        <span style={{width: "137px"}}>
                            <FormattedMessage id={`app.label.entity`}/>
                        </span>
                        </th>

                        <th className="datatable-cell datatable-cell-sort">
                        <span style={{width: "137px"}}>
                            <FormattedMessage id={`app.label.status`}/>
                        </span>
                        </th>

                        <th data-autohide-disabled="false"
                            className="datatable-cell datatable-cell-sort">
                            <span style={{width: "125px"}}>Actions</span>
                        </th>
                    </tr>
                    </thead>
                    <tbody className="datatable-body" style={{}}>
                    {loading ?
                        <tr>
                            <td>
                                <Spinner/>
                            </td>
                        </tr>
                        : items.map((item, i) => <Item {...{...item, setFormView}} key={i}/>)}
                    </tbody>
                </table>
                <TablePager/>
            </div>
        </React.Fragment>
    );
})

export default React.memo(withRouter(CategoryList))

