/**
 NOTE  get internationalized text by a specific key
 *     @param intl
 *     @param key
 *     @return string
 */
import app from "./config";
import moment from 'moment'
import  {injectIntl, createIntl, createIntlCache} from "react-intl"
import Messages from "../messages"

export const getIntlMsg = (intl, key) => {
    return intl.formatMessage({id: key});
};



const cache = createIntlCache()

export const intl = loc => {

    return createIntl({
        locale: loc,
        messages: Messages[loc]
    }, cache)
}


/**
 NOTE  Uppercase the first letter of a string
 *     @param  s : string
 *     @return string
 */
export const capitalize = s => {
    if (typeof s !== "string") return "";
    return s.charAt(0).toUpperCase() + s.slice(1);
};


/**
 NOTE  Uppercase the first letter of a string
 *     @return string
 * @param firstName
 * @param lastName
 */
export const nameAcronym = (firstName, lastName) => {
    return firstName.charAt(0).toUpperCase() + lastName.charAt(0).toUpperCase()
};


/**
 NOTE  Uppercase the first letter of a string
 *     @param  apiName : string
 *     @return string
 */
export const getRootUrl = apiName => {

    switch (apiName) {

        case "specimenU":
            return app.defaultRootUrl;

        case "tma":
            return app.tmaRootUrl;

        default:
            return app.defaultRootUrl;
    }
};


export const toggleItemActivation = (e, activeParams) => {
    const {classElement, activationClass} = activeParams;
    const items = document.getElementsByClassName(classElement);
    for (const item of items) {
        item.classList.remove(activationClass)
    }
    e.currentTarget.classList.add(activationClass)
}


/**
 NOTE  Uppercase the first letter of a string
 *     @param  options : Array
 *     @param singular
 *     @return string
 */
export const typeNameFormatter = (options, singular = true) => {
    let typeName = ``

    options.forEach((element, key) => {
        let current = element;

        if (key < (options.length)) {
            current = element.endsWith('ies') && singular ? element.replace("ies", 'y')
                : element.endsWith('s') && singular ? replaceAt(element, element.length - 1, '')
                    : element
        }


        typeName += `${current.toUpperCase().toUpperCase()}${key < (options.length - 1) ? '_' : ''}`;
    })

    return typeName.replaceAll("-", "_");
};

const replaceAt = (element, index, replacement) => {
    return element.substr(0, index) + replacement + element.substr(index + 1)
}


export const getUserRoles = (clientId, resourceAccess) => {


    let modules = new Map(Object.entries(resourceAccess));
    let foundModule = null;
    modules.forEach((value, key) => {
        if (key === clientId)
            foundModule = value;
    });
    return foundModule.roles;
};


export const getSeparatedValues = (values, separator) => {
    let reqOptions = ''

    values.forEach((element, key) => {
        reqOptions += `${element.toString().toLowerCase()}${key < (values.length - 1) ? separator : ''}`;
    })
    return reqOptions;
};


export const userSession = ({
    clientId,
    tokenParsed: {resource_access},
    idTokenParsed: {name, given_name, family_name}
}) => {


    return {
        roles: getUserRoles(clientId, resource_access),
        fullName: name,
        firstName: given_name,
        lastName: family_name,
    };


};


export const getBase64 = (file) => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result.split("base64,")[1]);
        reader.onerror = error => reject(error);
    });
}

export const getLoadFileProperties = async(e) => {
    const targetFile = e.target.files[0]
    const fileName = targetFile.name;

    if (targetFile) {
        const val = await getBase64(targetFile)
        return val ? {
                'size': targetFile.size,
                'fileName': fileName.substring(0, fileName.lastIndexOf('.')),
                'fileType': fileName.substring(fileName.lastIndexOf('.') + 1),
                'uploadedFile': val
            } : {}
    } else {
        return null;
    }
}

export const buildReport = (categories, items) => {
    let reports = [];
    categories && categories.forEach((category) => {
        let amount = 0, customerCounter = 0;
        items && items.forEach((item) => {
            if (category.name === item.categoryName) {
                amount += item.amount;
                customerCounter++;
            }
        })
        reports.push({category: category.name, amount, customerCounter})
    });
    return reports;
};


export const DateUtil = {

    ageFromDate: function (currentDate) {

        if (currentDate) {
            return new Date().getUTCFullYear() - new Date(currentDate).getUTCFullYear();
        }
        return null
    },

    dateFromAge: function (age) {
        if (age) {
            const baseDate = new Date();
            baseDate.setUTCFullYear(baseDate.getUTCFullYear() - age);
            return baseDate.toISOString().split('T')[0];
        }
        return null
    }

}


export const getDateFromDateTime = (element) => {
    return new Date(element).toISOString().split('T')[0]
}

export const getHours = (startDate) => {
    let hrs = [];

    let currentHour = moment(new Date()).format('H');
    currentHour = parseInt(currentHour) * 100;

    if (startDate) {

        const d2 = new Date(startDate)
        const d1 = new Date();
        let diff = d2.getTime() - d1.getTime();
        let daydiff = Math.round(diff / (1000 * 60 * 60 * 24));


        let hourStart = daydiff >= 0 ? 800 : currentHour;

        for (let i = hourStart; i <= 1600; i += 60) {
            let mins = i % 100;
            let hours = parseInt(i / 100);

            if (mins > 59) {
                mins = 0;
                hours += 1;
                i = hours * 100;
            }

            let vacation = ' AM';
            let hr='';

            if (hours > 12) {
                vacation = ' PM';
                hours %= 13;
                hours++;
            } else {
                hours %= 0+""+13;

            }

            if(hours<10){
                hr=0+""+hours;
            }else {
                hr=hours;
            }
            hrs.push({code: `${hr}:${'00'}`, name: `${hr}:${'00'} ${vacation}`})
        }

    }
    return hrs;
}


export const Localization = (language) => ({
    grouping: {
        groupedBy: "Grouped By:",
        placeholder: "Drag headers here to group by"
    },
    pagination: {
        labelDisplayedRows: getIntlMsg(intl(language), `app.label.labelDisplayedRows`),
        labelRowsPerPage: "Lignes par page:",
        labelRowsSelect: getIntlMsg(intl(language), `app.label.labelRowsSelect`),
        firstTooltip: getIntlMsg(intl(language), `app.label.firstTooltip`),
        lastTooltip: getIntlMsg(intl(language), `app.label.lastTooltip`),
        previousTooltip: getIntlMsg(intl(language), `app.label.previousTooltip`),
        nextTooltip: getIntlMsg(intl(language), `app.label.nextTooltip`),
    },
    body: {
        filterRow: {searchText: "tetetetet"},
        emptyDataSourceMessage: getIntlMsg(intl(language), `app.label.emptyDataSourceMessage`),
        editRow: {
            saveTooltip: getIntlMsg(intl(language), `app.label.save`),
            cancelTooltip: getIntlMsg(intl(language), `app.label.cancel`),
        },
        addTooltip: getIntlMsg(intl(language), `app.label.add`),
        deleteTooltip: getIntlMsg(intl(language), `app.label.delete`),
        editTooltip: getIntlMsg(intl(language), `app.label.edit`),
    },
    toolbar: {
        searchTooltip: getIntlMsg(intl(language), `app.label.searchTooltip`),
        searchPlaceholder: getIntlMsg(intl(language), `app.label.searchPlaceholder`),
    }
})


export const addToObject = function (obj, key, value, index) {

    var temp = {};
    var i = 0;

    for (var prop in obj) {
        if (obj.hasOwnProperty(prop)) {

            if (i === index && key && value) {
                temp[key] = value;
            }
            temp[prop] = obj[prop];

            // Increase the count
            i++;

        }
    }

    if (!index && key && value) {
        temp[key] = value;
    }

    return temp;

};







