import * as React from "react";
import {Link} from "react-router-dom";


const pageSize = 6;

const TablePager = () => {
    return pageSize > 0 && (
        <div className="datatable-pager datatable-paging-loaded">
            <ul className="datatable-pager-nav my-2 mb-sm-0">
                <li>
                    <a title="First"
                       className="datatable-pager-link datatable-pager-link-first datatable-pager-link-disabled"
                       data-page="1" disabled="disabled">
                        <i className="flaticon2-fast-back"/>
                    </a>
                </li>
                <li>
                    <a title="Previous"
                       className="datatable-pager-link datatable-pager-link-prev datatable-pager-link-disabled"
                       data-page="1" disabled="disabled">
                        <i className="flaticon2-back"/>
                    </a>
                </li>
                <li style={{display: "none"}}>
                    <input type="text" className="datatable-pager-input form-control" title="Page number"/>
                </li>
                <li>
                    <a className="datatable-pager-link datatable-pager-link-number
                    datatable-pager-link-active" data-page="1" title="1">1
                    </a>
                </li>
                <li>
                    <a className="datatable-pager-link datatable-pager-link-number" data-page="2" title="2">2</a>
                </li>
                <li>
                    <a className="datatable-pager-link datatable-pager-link-number" data-page="3" title="3">3</a>
                </li>
                <li>
                    <a title="Next" className="datatable-pager-link datatable-pager-link-next" data-page="2">
                        <i className="flaticon2-next"/>
                    </a>
                </li>
                <li>
                    <a title="Last" className="datatable-pager-link datatable-pager-link-last" data-page="10">
                        <i className="flaticon2-fast-next"/>
                    </a>
                </li>
            </ul>
            <div className="datatable-pager-info my-2 mb-sm-0">
                <div className="dropdown bootstrap-select datatable-pager-size" style={{width: "60px"}}>
                    <select className="selectpicker datatable-pager-size" title="Select page size" data-width="60px"
                            data-container="body" data-selected="10">
                        <option value="5">3</option>
                        <option value="10">10</option>
                        <option value="20">20</option>
                        <option value="30">30</option>
                        <option value="50">50</option>
                        <option value="100">100</option>
                    </select>

                    <div className="dropdown-menu ">
                        <div className="inner show" role="listbox" id="bs-select-3" tabIndex="-1">
                            <ul className="dropdown-menu inner show" role="presentation"/>
                        </div>
                    </div>
                </div>
                <span className="datatable-pager-detail">Showing 1 - 10 of 100</span></div>
        </div>
    )
}

export default React.memo(TablePager)

