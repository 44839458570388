import React from "react"
import {Field} from "redux-form";
import {renderField} from "../../../../../../common/utils/FormComponent";
import {withRouter} from "react-router";
import SocialItems from "../../core/SocialItems";

let FormItems = () => {
    return (
        <div className={`custom_fieldset mt-4`}>

            <div className="row mt-3">

                <div className="col-sm-8 mt-3">
                    <Field className="form-control form-control-sm" id="current_id" name="currentId"
                           component={renderField} type="text" label="passport_id"
                           placeholder="passport_id" required/>
                </div>
                <SocialItems/>
            </div>
        </div>

    );
}
export default React.memo(withRouter(FormItems))
