/**
 * Created by inglouidor on 3/15/21.
 */
import React, {useState} from 'react'
import {FormattedMessage, injectIntl} from "react-intl";
import {getIntlMsg} from "../../../../../../../constants/utils";
import ScriptTag from "react-script-tag"
import moment from 'moment'
import SearchForm from './SearchForm';
import {useDispatch, useSelector} from "react-redux";

const paths = [
    {icon: "query_stats", label: "covid_indicator"},
    {icon: "query_stats", label: "request_by"},
]

const Filter = injectIntl(props => {
    const {setLabel, label, indicator, intl,values}=props
    const dispatch = useDispatch();
    let sites = [];


    if (indicator && indicator.followUp) {

        let newdata = Object.keys(indicator.followUp);


        for (let i = 0; i < newdata.length; i++) {

            let obj = Object.keys(indicator.followUp)[i]


            sites.push(obj)
        }
    }




    return (
        <div className="card card-custom">


            <div className="card-header flex-wrap border-0 pt-6 pb-0">

                <div className="card-title">
                    <h2 className="card-label">
                    <span className="d-block pt-2">
                        <FormattedMessage id={`app.label.${label}`}/>
                        </span>
                    </h2>
                </div>

                {label === "covid_indicator" ? <>
                        <SearchForm sites={sites} values={values}/>

                    <ScriptTag type="text/javascript" src="/assets/js/datapicker.js"/>
                    </> : null}


                <div className="d-flex align-items-center flex-wrap">
                    <div className="dropdown dropdown-inline" data-toggle="tooltip" data-placement="top">
                        <a
                            className="btn btn-fixed-height btn-primary font-weight-bolder font-size-sm px-5 my-1"
                            data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <span className="material-icons mr-2">more_vert</span>
                            <FormattedMessage id={`app.label.indicator`}/>
                        </a>

                        <div className="dropdown-menu dropdown-menu-md dropdown-menu-right p-0 m-0">
                            <ul className="navi navi-hover">
                                {paths.map((path, i) =>
                                    <li className="navi-item" key={i}>
                                        <a className="navi-link" onClick={() => setLabel(path.label)}>
                                            <span className="material-icons mr-4">{path.icon}</span>
                                            <span className="navi-text">
                                                <span className="">
                                                    <FormattedMessage id={`app.label.${path.label}`}/>
                                                </span>
                                            </span>
                                        </a>
                                    </li>
                                )}
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
})

export default React.memo(Filter)
