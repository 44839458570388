import React from "react";
import useDocumentTitle from "../../../../common/hooks/useDocumentTitle";
import {getIntlMsg} from "../../../../../constants/utils";
import {injectIntl} from "react-intl";
import {useParams, withRouter} from "react-router";
import useEntityManager from "../../../../common/hooks/useEntityManager";
import {useSelector} from "react-redux";
import Spinner from "../../../../common/utils/Spinner";
import Item from "./travelItem";
import EmptyCallout from "./travelItem/callout";
import RequestSubHeader from "../subheader/RequestSubheader";
import StaffItem from "./staffItem";
import ListSubHeader from "../../../common/subheader/ListSubheader";
import ResultSubheader from "../subheader/ResultSubheader";
import CartSubheader from "../subheader/CartSubheader";

const RequestList = injectIntl((props) => {


    const {intl, setView, view} = props;
    const {module, page} = useParams();
    useDocumentTitle(getIntlMsg(intl, `app.label.${page}`));


    const params = !page ? {options: [module]}
        : page === 'reception' ? {options: [module, 'status', 'reception']}
            : page === 'cart' ? {options: [module, 'status', 'cart']}
                : page === 'archived' ? {options: [module, 'status', 'archived']}
                    : page === 'specimens' ? {options: [module, 'status', 'specimens']}
                        : page === 'notification' ? {options: [module, 'status', 'notification']}
                            : page === 'laboratory' ? {options: [module, 'status', 'laboratory']}
                                : page === 'unfinished' ? {options: [module, 'status', 'unfinished']}
                                    : {options: [module, 'status', 'results']}


    const {} = useEntityManager(params, page)
    const {items, loading,status} = useSelector(state => ({
        loading: state.requestReducer?.loadingItems,
        items: state.requestReducer?.items || [],
        resultFormInfo: state?.form?.resultForm?.values || '',
        status: state.requestReducer?.status,
    }))



    const customStyle = {fontSize: "50px", height: "300px", textAlign: "center"}

    return (
        <React.Fragment>
            {getPageSubHeader(page, items)}
            <div className="d-flex flex-column-fluid">
                <div className="container">
                    {loading ? <Spinner status={'primary'} {...{customStyle}}/> :
                        items && items.length === 0
                            ? <EmptyCallout/>
                            : items?.map(item => getItemByType(item))
                    }
                </div>
            </div>
        </React.Fragment>
    );
})


const getPageSubHeader = (page, items) => {
    switch (page) {
        case 'reception':
            return <RequestSubHeader {...{items}}/>
        case 'results':
            return <ResultSubheader {...{items}}/>
        case 'cart':
            return <CartSubheader {...{items}}/>
        default:
            return <ListSubHeader {...{items, btnOptions:{}}}/>
    }
}


const getItemByType = item => {
    switch (item?.type?.toLowerCase()) {
        case 'traveler':
            return <Item {...{item}} key={0}/>
        case 'staff':
            return <StaffItem {...{item}} key={1}/>
        default:
            return null
    }
}


export default React.memo(withRouter(RequestList))
