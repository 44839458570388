import React from "react"
import {Field, FieldArray} from "redux-form";
import {renderField} from "../../../../../common/utils/FormComponent";
import {withRouter} from "react-router";
import renderEmail from "../params/email";

let FormItems = ({validate}) => {

    return (
        <div className={`custom_fieldset mt-4`}>

            <div className="row mt-3">
                <div className="col-sm-12">
                    <FieldArray name="emails" component={renderEmail}/>
                </div>
            </div>

            <div className="row mt-3">
                <div className="col-sm-3">
                    <Field className="form-control form-control-sm" id="investigator_firstname"
                           name="investigator_firstname" component={renderField} type="text"
                           label="investigator_firstname" placeholder="investigator_firstname"
                           validate={validate.error}/>
                </div>
            </div>
        </div>

    );
}
export default React.memo(withRouter(FormItems))
