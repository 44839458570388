import * as React from "react";
import useDocumentTitle from "../../../../common/hooks/useDocumentTitle";
import {getIntlMsg} from "../../../../../constants/utils";
import {injectIntl} from "react-intl";
import {useParams, withRouter} from "react-router";
import CategoryList from "./listing";
import CategoryForm from "./form";
import {useState} from "react";


const CategoryPageManager = injectIntl((props) => {
    const {intl} = props;
    const {module} = useParams();
    useDocumentTitle(getIntlMsg(intl, `app.label.${module}`));
    const [formView, setFormView] = useState(false)

    return (
        <div className="tab-pane fade" id="category" role="tabpanel" aria-labelledby="category">
            <div className="cared card-custom pl-0">
                {formView && <CategoryForm {...{setFormView}}/>}
                <CategoryList {...{formView, setFormView}}/>
            </div>
        </div>
    );
})

export default React.memo(withRouter(CategoryPageManager))

