import * as React from "react";
import {useState} from "react";
import {useParams, withRouter} from "react-router";
import pages from "../../../../../../constants/options/pages";
import RequestTravelerForm from "../traveler";
import RequestStaffForm from "../staff";


const RequestFormDispatcher = () => {
    const {secondLevelPage} = useParams();
    const [view, setView] = useState('')

    switch (secondLevelPage) {
        case pages.REQUEST_TRAVELER_REGISTRATION_PAGE:
            return <RequestTravelerForm {...{setView, view}}/>
        case pages.REQUEST_STAFF_REGISTRATION_PAGE:
            return <RequestStaffForm {...{setView, view}}/>
        default:
            return null

    }
}

export default React.memo(withRouter(RequestFormDispatcher))

