const actionTypes = {


    /**
     * @Author Rikenson JACQUES (The Tiger ^(..)^)
     * RESET OBSERVATION ACTION TYPES
     * -------------------------------------------
     * */
    RESET_OBSERVATION: "RESET_OBSERVATION",


    /**
     * @Author Rikenson JACQUES (The Tiger ^(..)^)
     * LOAD OBSERVATIONS ACTION TYPES
     * -------------------------------------------
     * */
    LOAD_OBSERVATIONS: "LOAD_OBSERVATIONS",
    LOAD_OBSERVATIONS_SUCCEEDED: "LOAD_OBSERVATIONS_SUCCEEDED",
    LOAD_OBSERVATIONS_FAILED: "LOAD_OBSERVATIONS_FAILED",


    /**
     * @Author Rikenson JACQUES (The Tiger ^(..)^)
     * LOAD OBSERVATIONS STATUS OUT ACTION TYPES
     * -------------------------------------------
     * */
    LOAD_PENDING_OBSERVATIONS: "LOAD_PENDING_OBSERVATIONS",
    LOAD_PENDING_OBSERVATIONS_SUCCEEDED: "LOAD_PENDING_OBSERVATIONS_SUCCEEDED",
    LOAD_PENDING_OBSERVATIONS_FAILED: "LOAD_PENDING_OBSERVATIONS_FAILED",


    /**
     * @Author Rikenson JACQUES (The Tiger ^(..)^)
     * LOAD OBSERVATIONS STATUS DONE ACTION TYPES
     * -------------------------------------------
     * */
    LOAD_SPECIMEN_DONE_OBSERVATIONS: "LOAD_SPECIMEN_DONE_OBSERVATIONS",
    LOAD_SPECIMEN_DONE_OBSERVATIONS_SUCCEEDED: "LOAD_SPECIMEN_DONE_OBSERVATIONS_SUCCEEDED",
    LOAD_SPECIMEN_DONE_OBSERVATIONS_FAILED: "LOAD_SPECIMEN_DONE_OBSERVATIONS_FAILED",


    /**
     * @Author Rikenson JACQUES (The Tiger ^(..)^)
     * LOAD OBSERVATIONS STATUS DONE ACTION TYPES
     * -------------------------------------------
     * */
    LOAD_DONE_OBSERVATIONS: "LOAD_DONE_OBSERVATIONS",
    LOAD_DONE_OBSERVATIONS_SUCCEEDED: "LOAD_DONE_OBSERVATIONS_SUCCEEDED",
    LOAD_DONE_OBSERVATIONS_FAILED: "LOAD_DONE_OBSERVATIONS_FAILED",


    /**
     * @Author Rikenson JACQUES (The Tiger ^(..)^)
     * LOAD SINGLE OBSERVATION ACTION TYPES
     * -------------------------------------------
     * */
    LOAD_OBSERVATION: "LOAD_OBSERVATION",
    LOAD_OBSERVATION_SUCCEEDED: "LOAD_OBSERVATION_SUCCEEDED",
    LOAD_OBSERVATION_FAILED: "LOAD_OBSERVATION_FAILED",


    /**
     * @Author Rikenson JACQUES (The Tiger ^(..)^)
     * SAVE OBSERVATION ACTION TYPES
     * -------------------------------------------
     * */
    SAVE_OBSERVATION: "SAVE_OBSERVATION",
    SAVE_OBSERVATION_SUCCEEDED: "SAVE_OBSERVATION_SUCCEEDED",
    SAVE_OBSERVATION_FAILED: "SAVE_OBSERVATION_FAILED",


    /**
     * @Author Rikenson JACQUES (The Tiger ^(..)^)
     * UPDATE OBSERVATION ACTION TYPES
     * -------------------------------------------
     * */
    UPDATE_OBSERVATION: "UPDATE_OBSERVATION",
    UPDATE_OBSERVATION_SUCCEEDED: "UPDATE_OBSERVATION_SUCCEEDED",
    UPDATE_OBSERVATION_FAILED: "UPDATE_OBSERVATION_FAILED",


    /**
     * @Author Rikenson JACQUES (The Tiger ^(..)^)
     * DELETE OBSERVATION ACTION TYPES
     * -------------------------------------------
     * */
    DELETE_OBSERVATION: "DELETE_OBSERVATION",
    DELETE_OBSERVATION_SUCCEEDED: "DELETE_OBSERVATION_SUCCEEDED",
    DELETE_OBSERVATION_FAILED: "DELETE_OBSERVATION_FAILED",

};


export default actionTypes;
