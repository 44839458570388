import * as React from "react";
import {injectIntl} from "react-intl";
import {withRouter} from "react-router";
import {validate} from "../validator";
import FormItems from "./Items";


const BillingForm = injectIntl((props) => {
    const {item} = props;

    return (
        <div className="tab-pane fade show active" id="billing" role="tabpanel" aria-labelledby="billing">
            <div>
                <FormItems {...{validate, item}}/>
            </div>
        </div>
    );
});

const mapStateToProps = ({institutionReducer: {item}}) => {
    const defaultState = {}
    return {
        initialValues: item !== null ? {...item} : {...defaultState}
    }
};


export default React.memo(withRouter(BillingForm))