import * as React from "react";
import {FormattedMessage, injectIntl} from "react-intl";
import {withRouter} from "react-router";
import FormItems from "./Items";
import FormFooter from "../../../../../common/form/FormFooter";
import {useEffect} from "react";
import actions from "../../../../../../../thunks/common/coreThunks";
import {useHistory, useLocation} from "react-router-dom";
import {useSelector} from "react-redux";


const BillingInfosForm = injectIntl((props) => {
    const {item, loading, pristine, submitting, reset, valid, touched, error,resetItem, dispatch,history,module,savingOrUpdating} = props;

    useEffect(() => {
            dispatch(actions.loadItem({options:['currencies']}))
    }, []);


    return (

        <div className="card card-custom">
            <div className="card-header">
                <div className="card-title">
                    <span className="card-icon">
                        <i className="flaticon2-paper text-primary"/>
                    </span>
                    <h3 className="card-label">
                        <FormattedMessage id="app.label.billing_infos"/>{" "}
                        <small>
                            <FormattedMessage id="app.label.billing_infos_desc"/>
                        </small>
                    </h3>
                </div>
            </div>
            <div className="card-body">
                <FormItems {...{item, touched, error}}/>
                <FormFooter {...{item, loading, pristine, submitting, reset, valid,resetItem, dispatch,history,module,savingOrUpdating}}/>
            </div>
        </div>

    );
});


export default React.memo(withRouter(BillingInfosForm))

