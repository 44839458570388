import * as React from "react";
import {FormattedMessage, injectIntl} from "react-intl";
import {withRouter} from "react-router";
import {validate} from "../validator";
import FormItems from "./Items";
import FormFooter from "../../../../../common/form/FormFooter";


const BaseForm = injectIntl((props) => {

    const {item, touched, error} = props;
    return (


        <div className="card card-custom">
            <div className="card-header">
                <div className="card-title">
                    <span className="card-icon">
                        <i className="flaticon2-hangouts-logo text-primary"/>
                    </span>
                    <h3 className="card-label">
                        <FormattedMessage id="app.label.request_infos"/>{" "}
                        <small><FormattedMessage id="app.label.request_infos_desc"/></small>
                    </h3>
                </div>
            </div>
            <div className="card-body">
                <FormItems {...{item, touched, error}}/>
            </div>
        </div>
    );
});


export default React.memo(withRouter(BaseForm))

