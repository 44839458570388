import React, {useContext} from "react"
import {withRouter} from "react-router";
import AsyncInputSearch from "../../../../../common/utils/AsyncInputSearch";
import CheckBoxFilter from "../../../../../common/utils/CheckBoxFilter";
import {injectIntl} from "react-intl";
import {getIntlMsg} from "../../../../../../constants/utils";
import actions from "../../../../../../thunks/common/coreThunks";
import {useDispatch} from "react-redux";
import {GlobalContext} from "../../../../../index";
import UploadCSV from "../../../../../common/utils/csvuploader";
import {format} from "../../../../../../constants/options/indicatorUtils";

let Searchbar = injectIntl(props => {

    const dispatch = useDispatch();

    const {hideModal, modalIsOpen, showModal} = useContext(GlobalContext);
    const {intl, formView, setFormView, options, setData,data,onFormSubmit} = props
    const criteria = {options: ['indicators', 'names']};
    const resetCriteria = {options: ['indicator']};

    return (
        <div className="mb-7 bg-danger-o-20 pt-3 pb-1 pl-5 pr-5">
            <div className="row">
                <div className="col-md-12">
                    {data && data.length>0 ?
                        <div className="float-right ml-4">
                            <button type="button" className="btn btn-success"
                                    onClick={()=>onFormSubmit()}>
                                <span className="material-icons orange600">save</span>
                            </button></div>:null}
            <UploadCSV {...{setData, format}}/>

            </div>
            </div>

            <div className="row">



                {/*<div className="col-sm-6">*/}
                    {/*<AsyncInputSearch {...{options}}/>*/}
                {/*</div>*/}

                {/*<div className="col-sm-2">*/}
                    {/*<CheckBoxFilter {...{criteria}} i18nable={true}/>*/}
                {/*</div>*/}

                {/*<div className="col-sm-2">*/}
                    {/*<CheckBoxFilter {...{criteria}} i18nable={true}/>*/}
                {/*</div>*/}

                <div className="float-right">

                </div>
                {/*<div className="col-sm-2 text-center">*/}


                    {/*<span onClick={() => {*/}
                        {/*dispatch(actions.resetItem(resetCriteria))*/}
                        {/*setFormView(!formView)*/}
                    {/*}}*/}
                          {/*className="px-6 font-weight-bold btn" title={getIntlMsg(intl, `app.label.${formView ? 'cancel' : 'new'}`)}>*/}

                        {formView ?<>
                            {/*<svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1">*/}
                                {/*<g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">*/}
                                    {/*<g transform="translate(12.000000, 12.000000) rotate(-45.000000) translate(-12.000000, -12.000000) translate(4.000000, 4.000000)"*/}
                                       {/*fill="#000000">*/}
                                        {/*<rect x="0" y="7" width="16" height="2" rx="1"/>*/}
                                        {/*<rect opacity="0.3"*/}
                                              {/*transform="translate(8.000000, 8.000000) rotate(-270.000000) translate(-8.000000, -8.000000) "*/}
                                              {/*x="0" y="7" width="16" height="2" rx="1"/>*/}
                                    {/*</g>*/}
                                {/*</g>*/}
                            {/*</svg>*/}
                            </>:<>
                            {/*<svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1">*/}
                                {/*<g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">*/}
                                    {/*<rect fill="#000000" x="4" y="11" width="16" height="2" rx="1"/>*/}
                                    {/*<rect fill="#000000" opacity="0.3"*/}
                                          {/*transform="translate(12.000000, 12.000000) rotate(-270.000000) translate(-12.000000, -12.000000) "*/}
                                          {/*x="4" y="11" width="16" height="2" rx="1"/>*/}
                                {/*</g>*/}
                            {/*</svg>*/}
                        </>}
                    {/*</span>*/}
                {/*</div>*/}

            </div>
        </div>

    );
})
export default React.memo(withRouter(Searchbar))
