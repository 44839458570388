import types from "../../actions/types/settings/itemPriceActionTypes";
import initialState from "../../../constants/utils/initialState";

const itemPriceReducer = (state = initialState.itemPriceReducer, action) => {

        switch (action.type) {

            /**
             * LOAD ITEMPRICES LIST
             * */
            case types.RESET_ITEMPRICE:
                return {...state, item: {}};

            /**
             * LOAD ITEMPRICES LIST
             * */
            case types.LOAD_ITEMPRICES:
                return {...state, loadingItems: action.payload};
            case types.LOAD_ITEMPRICES_SUCCEEDED:
                return {...state, items: action.payload?.content, loadingItems: false};
            case types.LOAD_ITEMPRICES_FAILED:
                return {...state, loadingItems: action.payload};



            /**  LOAD ITEMPRICE BY A SPECIFIC PROPERTY CASE */
            case types.LOAD_ITEMPRICE:
                return {...state, loadingItem: true};
            case types.LOAD_ITEMPRICE_SUCCEEDED:
                return {...state, item: action.payload, loadingItem: false};
            case types.LOAD_ITEMPRICE_FAILED:
                return {...state, loadingItem: false};


            /**  SAVE ITEMPRICE CASE */
            case types.SAVE_ITEMPRICE:
                return {...state, savingOrUpdating: true};
            case types.SAVE_ITEMPRICE_SUCCEEDED:
                return {
                    ...state, items: [...state.items, action.payload],
                    item: action.payload,
                    savingOrUpdating: false
                };
            case types.SAVE_ITEMPRICE_FAILED:
                return {...state, savingOrUpdating: false};



            /**  UPDATE ITEMPRICE CASE  */

            case types.UPDATE_ITEMPRICE:
                return {...state, savingOrUpdating: true};
            case types.UPDATE_ITEMPRICE_SUCCEEDED:
                return {
                    ...state,
                    items: state.items.map(item => item.id === action.payload.id ? action.payload : item),
                    item: action.payload,
                    savingOrUpdating:false
                };
            case types.UPDATE_ITEMPRICE_FAILED:
                return {...state, savingOrUpdating: false};


            /**  DELETE ITEMPRICE CASE  */
            case types.DELETE_ITEMPRICE:
                return {...state, deleting: true};
            case types.DELETE_ITEMPRICE_SUCCEEDED:
                return (action.payload.status === 200) ?
                    {...state, items: state.items.filter(item => item.id !== action.payload.item.id), deleting: false}
                    : state;
            case types.DELETE_ITEMPRICE_FAILED:
                return {...state, deleting: false};

            default:
                return state;
        }
    }
;

export default itemPriceReducer;
