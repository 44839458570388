/**
 * Created by inglouidor on 3/16/21.
 */
import React from 'react';
import Followup from "../followup";
import Testing from "../testing";
import Becoming from "../becoming";
import Staff from "../staff";

const CovidIndicator = ({indicator}) => (

    <React.Fragment>
        <Followup indicator={indicator}/>
        <Testing indicator={indicator}/>
        <Becoming indicator={indicator}/>
        <Staff indicator={indicator}/>
    </React.Fragment>
)

export default CovidIndicator