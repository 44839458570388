import * as React from "react";
import {Link} from "react-router-dom";
import {FormattedMessage} from "react-intl";


const InnerMenu = props => {

    const {title, url} = props

    return (
        <li className="menu-item" aria-haspopup="true">
            <Link to={url}
                  className="menu-link">
                <i className="menu-bullet menu-bullet-dot">
                    <span/>
                </i>
                <span
                    className="menu-text">
                    <FormattedMessage id={`app.label.${title}`}/>
                </span>
            </Link>
        </li>
    )
}

export default React.memo(InnerMenu)

