import React from "react"
import {Field} from "redux-form";
import {FormattedMessage} from 'react-intl';
import {renderField} from "../../../../common/utils/FormComponent";
import {withRouter} from "react-router";
import PersonalInfosForm from "../../request/forms/core/personal";

let FormItems = ({item, validate}) => {

	return (
		<div className={`custom_fieldset mt-4`}>
			<PersonalInfosForm/>

		</div>

	);
}
export default React.memo(withRouter(FormItems))
