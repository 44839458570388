import * as React from "react";
import {injectIntl} from "react-intl";
import {withRouter} from "react-router";
import {getStaffInfoBarBases} from "../../../../../../../constants/options/infobarItems";
import Item from "./Item";
import moment from 'moment';
import {getIntlMsg} from "../../../../../../../constants/utils";


const InfoBar = injectIntl((props) => {

    const {intl, item} = props;
    const {payment} = item;
    const {testType: {name}, codeLaboratory, type} = item;
    const {specimenCenter: {code}} = item;
    const {trxCode, created, paymentType} = payment


    const formatRequestDate = moment(created).format('DD/MM/YYYY')

    const barBases = getStaffInfoBarBases(type);
    const infos = [
        codeLaboratory,
        formatRequestDate,
        name,
        getIntlMsg(intl, `app.label.${paymentType?.toLowerCase()}`),
        code, getIntlMsg(intl, `app.label.${type?.toLowerCase()}_en`)
    ];
    const colored = ['primary', 'success', 'info', 'primary', 'info', 'primary'];
    let barItems = []

    for (let i = 0; i < infos.length; i++) {
        const value = infos[i];
        const base = barBases[i];
        const color = colored[i];
        barItems.push({...base, value, color})
    }

    const isNegative = !item?.diagnostic ? 'bg-secondary-o-45'
        : !item?.diagnostic?.result ? 'bg-info-0-45'
            : item?.diagnostic?.result?.status === 'NEGATIVE' ? 'bg-success-o-45'
                : item?.diagnostic?.result?.status === 'POSITIVE' ? 'bg-danger-o-45' : ''


    return (
        <div className={`d-flex align-items-center flex-wrap p-3 ${isNegative}`}>
            {barItems && barItems.map((item, i) => <Item {...item} key={i}/>)}
        </div>
    )


})


export default React.memo(withRouter(InfoBar))
