import * as React from "react";
import {Doughnut} from "react-chartjs-2";
import {FormattedMessage} from "react-intl";


const StatusDistribution = props => {

    const {intl, statusData} = props


    const legend = {
        display: true,
        position: "left",
        labels: {
            fontColor: "#323130",
            fontSize: 14
        }
    };


    return (

        <div className="card card-custom">
            <div className="card-header">
                <div className="card-title">
                    <h3 className="card-label">
                        <FormattedMessage id="app.label.request_by_status_security"/> {" "}
                        <small>
                            <FormattedMessage id="app.label.request_by_status_db_desc"/>
                        </small>
                    </h3>
                </div>
            </div>
            <div className="card-body">
                <Doughnut data={[]} legend={legend}/>
            </div>
        </div>

    )
        ;
}

export default React.memo(StatusDistribution)

