import * as React from "react";
import  {useContext} from "react";
import {FormattedMessage, injectIntl} from "react-intl";
import {withRouter} from "react-router";
import ChangeStateModal from "../../../../../common/modal/ChangeState";
import actions from "../../../../../../../thunks/common/coreThunks";
import {getIntlMsg} from "../../../../../../../constants/utils";
import SpecimenModal from "../../../forms/specimen"
import ResultFormModal from "../../../forms/result/create"
import {useReactToPrint} from "react-to-print";
import {useRef} from "react";
import SpecimenTag from "../../../reports/SpecimenTag";
import {useParams} from "react-router-dom";
import {useDispatch} from "react-redux";
import {GlobalContext} from "../../../../../../index";


const EmptyCallout = injectIntl(props => {

    const {showModal} = useContext(GlobalContext);

    const {intl, item, handleStateChange} = props;
    const tagRef = useRef();
    const handlePrintTag = useReactToPrint({
        content: () => tagRef.current,
    });
    const {page} = useParams();
    const dispatch = useDispatch()
    const params = {intl, item: {id: item?.id}, options: ['requests']}

    const loadCurrentElement = (type) =>{
        dispatch(actions.loadItem(params))
        showModal(type)
    }



    return (
        <div className="btn-group btn-group-sm" aria-label="Basic example">

            {!item?.diagnostic &&
            <button type="button" className="btn btn-sm btn-light-primary"
                    onClick={()=>loadCurrentElement('specimen')}

                 >
                <FormattedMessage id="app.label.register_specimen"/>
            </button>
            }

            {item?.diagnostic && !item?.diagnostic?.result && item?.testType?.name === 'Ag' &&
            <button type="button" className="btn btn btn-sm btn-light-primary" onClick={handlePrintTag}>
                <FormattedMessage id="app.label.print_tag"/>
            </button>
            }


            {item?.diagnostic && !item?.diagnostic?.result && item?.testType?.name === 'Ag' &&
            <button type="button" className="btn btn btn-sm btn-light-primary"
                onClick={()=>loadCurrentElement('result')}>
                <FormattedMessage id="app.label.register_result"/>
            </button>
            }


            {item?.diagnostic?.result &&
            <button type="button" className="btn btn btn-sm btn-light-primary"
                    data-toggle="modal" data-target={`#results${item?.id}`}
                    title={getIntlMsg(intl, `app.label.send_to_results`)}>
                <FormattedMessage id="app.label.send_to_results"/>
            </button>
            }


            {item?.diagnostic && !item?.diagnostic?.result && item?.testType?.name === 'PCR' &&
            <button type="button" className="btn btn btn-sm btn-light-primary"
                    data-toggle="modal" data-target={`#laboratory${item?.id}`}
                    title={getIntlMsg(intl, `app.label.send_to_laboratory`)}>
                <FormattedMessage id="app.label.send_to_laboratory"/>
            </button>
            }

            <div style={{display: "none", overflow: "hidden", height: 0}}>
                <SpecimenTag ref={tagRef} {...{item}}/>
            </div>

            <ChangeStateModal {...{title: 'results', handleStateChange,id:item?.id}}/>
            <ChangeStateModal {...{title: 'laboratory', handleStateChange,id:item?.id}}/>
        </div>

    );
});


export default React.memo(withRouter(EmptyCallout))
