import React from "react"
import {Field} from "redux-form";
import {FormattedMessage, injectIntl} from 'react-intl';
import {renderCheckbox, renderField} from "../../../../common/utils/FormComponent";
import {withRouter} from "react-router";
import {getIntlMsg} from "../../../../../constants/utils";

let renderPhone = injectIntl(props => {
    const {intl, fields, meta: {error}} = props


    return (
        <div className="row">
            <div className="mb-5">
                <h5><FormattedMessage id={`app.label.phones`}/></h5>
                {fields.length < 3 &&
                <span className="btn btn-outline-primary btn-sm" onClick={() => fields.push()}>
                    <FormattedMessage id={`app.label.click_to_add_new_phone`}/>
                </span>
                }
            </div>

            {fields.map((phone, index) => (
                <div className="col-sm-12" key={index}>

                    <div className="row">

                        <div className="col-sm-1">
                            <h1 className="mt-5 text-center">{index + 1}</h1>
                        </div>

                        <div className="col-sm-2">
                            <Field className="form-control form-control-sm" id="area_code" name={`${phone}.areaCode`}
                                   component={renderField} type="text" label="area_code" placeholder="area_code" meta/>
                        </div>
                        <div className="col-sm-3">
                            <Field className="form-control form-control-sm" id="phone_no" name={`${phone}.no`}
                                   component={renderField} type="text" label="phone_no" placeholder="phone_no" meta/>
                        </div>

                        <div className="col-sm-2 mt-12">
                            <Field component={renderCheckbox} name={`txnable`} label={`txnable`} id={`txnable`}/>
                        </div>
                        <div className="col-sm-1">
                            <button type="button" title={getIntlMsg(intl, 'app.label.remove_phone')}
                                    className="btn btn-icon btn-light-primary btn-circle mr-2 mt-8"
                                    onClick={() => fields.remove(index)}>
                                <i className="flaticon2-trash"/>
                            </button>
                        </div>
                    </div>
                </div>
            ))}
            {error && <li className="error">{error}</li>}
        </div>
    );
})
export default React.memo(withRouter(renderPhone))
