const actionTypes = {

    /**
     * @Author Rikenson JACQUES (The Tiger ^(..)^)
     * RESET SETTING ACTION TYPES
     * -------------------------------------------
     * */
    RESET_SETTING: "RESET_SETTING",

    /**
     * @Author Rikenson JACQUES (The Tiger ^(..)^)
     * LOAD SETTINGS ACTION TYPES
     * -------------------------------------------
     * */
    LOAD_SETTINGS_BY_ACRONYM: "LOAD_SETTINGS_BY_ACRONYM",
    LOAD_SETTINGS_BY_ACRONYM_SUCCEEDED: "LOAD_SETTINGS_BY_ACRONYM_SUCCEEDED",
    LOAD_SETTINGS_BY_ACRONYM_FAILED: "LOAD_SETTINGS_BY_ACRONYM_FAILED",


    /**
     * @Author Rikenson JACQUES (The Tiger ^(..)^)
     * LOAD SETTINGS ACTION TYPES
     * -------------------------------------------
     * */
    LOAD_SETTINGS: "LOAD_SETTINGS",
    LOAD_SETTINGS_SUCCEEDED: "LOAD_SETTINGS_SUCCEEDED",
    LOAD_SETTINGS_FAILED: "LOAD_SETTINGS_FAILED",

    /**
     * @Author Rikenson JACQUES (The Tiger ^(..)^)
     * LOAD _BY_ENTITY-NAME_CUSTOMER ACTION TYPES
     * -------------------------------------------
     * */
    LOAD_BY_NAME_CUSTOMER: "LOAD_BY_NAME_CUSTOMER",
    LOAD_BY_NAME_CUSTOMER_SUCCEEDED: "LOAD_BY_NAME_CUSTOMER_SUCCEEDED",
    LOAD_BY_NAME_CUSTOMER_FAILED: "LOAD_BY_NAME_CUSTOMER_FAILED",


    /**
     * @Author Rikenson JACQUES (The Tiger ^(..)^)
     * LOAD SETTINGS_NAMES ACTION TYPES
     * -------------------------------------------
     * */
    LOAD_SETTINGS_NAMES: "LOAD_SETTINGS_NAMES",
    LOAD_SETTINGS_NAMES_SUCCEEDED: "LOAD_SETTINGS_NAMES_SUCCEEDED",
    LOAD_SETTINGS_NAMES_FAILED: "LOAD_SETTINGS_NAMES_FAILED",


    /**
     * @Author Rikenson JACQUES (The Tiger ^(..)^)
     * LOAD SETTINGS_NAMES ACTION TYPES
     * -------------------------------------------
     * */
    LOAD_SETTINGS_BY_NAME_CUSTOMER: "LOAD_SETTINGS_BY_NAME_CUSTOMER",
    LOAD_SETTINGS_BY_NAME_CUSTOMER_SUCCEEDED: "LOAD_SETTINGS_BY_NAME_CUSTOMER_SUCCEEDED",
    LOAD_SETTINGS_BY_NAME_CUSTOMER_FAILED: "LOAD_SETTINGS_BY_NAME_CUSTOMER_FAILED",


    /**
     * @Author Rikenson JACQUES (The Tiger ^(..)^)
     * LOAD SETTINGS_NAMES ACTION TYPES
     * -------------------------------------------
     * */
    LOAD_SETTINGS_BY_NAME_SHEET: "LOAD_SETTINGS_BY_NAME_SHEET",
    LOAD_SETTINGS_BY_NAME_SHEET_SUCCEEDED: "LOAD_SETTINGS_BY_NAME_SHEET_SUCCEEDED",
    LOAD_SETTINGS_BY_NAME_SHEET_FAILED: "LOAD_SETTINGS_BY_NAME_SHEET_FAILED",


    /**
     * @Author Rikenson JACQUES (The Tiger ^(..)^)
     * LOAD SETTINGS_NAMES ACTION TYPES
     * -------------------------------------------
     * */
    LOAD_SETTINGS_BY_NAME_DOCUMENT: "LOAD_SETTINGS_BY_NAME_DOCUMENT",
    LOAD_SETTINGS_BY_NAME_DOCUMENT_SUCCEEDED: "LOAD_SETTINGS_BY_NAME_DOCUMENT_SUCCEEDED",
    LOAD_SETTINGS_BY_NAME_DOCUMENT_FAILED: "LOAD_SETTINGS_BY_NAME_DOCUMENT_FAILED",


    /**
     * @Author Rikenson JACQUES (The Tiger ^(..)^)
     * LOAD SINGLE SETTING ACTION TYPES
     * -------------------------------------------
     * */
    LOAD_SETTING: "LOAD_SETTING",
    LOAD_SETTING_SUCCEEDED: "LOAD_SETTING_SUCCEEDED",
    LOAD_SETTING_FAILED: "LOAD_SETTING_FAILED",


    /**
     * @Author Rikenson JACQUES (The Tiger ^(..)^)
     * SAVE SETTING ACTION TYPES
     * -------------------------------------------
     * */
    SAVE_SETTING: "SAVE_SETTING",
    SAVE_SETTING_SUCCEEDED: "SAVE_SETTING_SUCCEEDED",
    SAVE_SETTING_FAILED: "SAVE_SETTING_FAILED",


    /**
     * @Author Rikenson JACQUES (The Tiger ^(..)^)
     * UPDATE SETTING ACTION TYPES
     * -------------------------------------------
     * */
    UPDATE_SETTING: "UPDATE_SETTING",
    UPDATE_SETTING_SUCCEEDED: "UPDATE_SETTING_SUCCEEDED",
    UPDATE_SETTING_FAILED: "UPDATE_SETTING_FAILED",


    /**
     * @Author Rikenson JACQUES (The Tiger ^(..)^)
     * DELETE SETTING ACTION TYPES
     * -------------------------------------------
     * */
    DELETE_SETTING: "DELETE_SETTING",
    DELETE_SETTING_SUCCEEDED: "DELETE_SETTING_SUCCEEDED",
    DELETE_SETTING_FAILED: "DELETE_SETTING_FAILED",


};


export default actionTypes;
