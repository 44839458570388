import * as React from "react";
import {useState} from "react";
import useDocumentTitle from "../../../../../common/hooks/useDocumentTitle";
import actions from "../../../../../../thunks/common/coreThunks";
import {getIntlMsg} from "../../../../../../constants/utils";
import {injectIntl} from "react-intl";
import {useDispatch, useSelector} from "react-redux";
import {useEffect} from "react";
import StatusDistribution from "./Items/status";
import Spinner from "../../../../../common/utils/Spinner";
import ResultDistribution from "./Items/result";
import MapperDistribution from "./Items/mapper";

const FinancialDashboard = injectIntl(props => {
    const {intl} = props

    useDocumentTitle(getIntlMsg(intl, `app.label.requests_dashboard_desc`));
    const dispatch = useDispatch();
    const options = {options: ['cart', 'summary']};
    useEffect(() => {
        dispatch(actions.loadItems(options));
    }, [dispatch]);

    const {items, loading} = useSelector(state => ({
        loading: state?.requestReducer?.loadingCartSummary,
        items: state?.requestReducer?.cartSummary,
    }));


    const statusData = {
        labels: buildChartElement(items, intl)[0],
        datasets: [
            {
                label: '# of Votes',
                data: buildChartElement(items, intl)[1],
                backgroundColor: ['#3699ff', '#3b5998', '#ff7f26', '#f64e60', '#1bc5bd', '#e4e6ef'],
                borderColor: ['#3699ff', '#3b5998', '#ff7f26', '#f64e60', '#1bc5bd', '#e4e6ef'],
                borderWidth: 1,
            },
        ],
    }


    const amountData = {
        labels: amountBuildChartElement(items, intl)[0],
        datasets: [
            {
                label: '# of Votes',
                data: amountBuildChartElement(items, intl)[1],
                backgroundColor: ['#3699ff', '#3b5998', '#ff7f26', '#f64e60', '#1bc5bd', '#e4e6ef'],
                borderColor: ['#3699ff', '#3b5998', '#ff7f26', '#f64e60', '#1bc5bd', '#e4e6ef'],
                borderWidth: 1,
                minY : 100
            },
        ],
    }


    const [label, setLabel] = useState('request_by')

    const changeDashboard = (label) => {
        return (
            <React.Fragment>
                <div className="col-sm-6">
                    {items && !loading ? <StatusDistribution {...{statusData}}/> : <Spinner/>}
                </div>
                <div className="col-sm-6">
                    {items && !loading ? <ResultDistribution {...{amountData}}/> : <Spinner/>}
                </div>
                <div className="col-sm-12 mt-5">
                    {items && !loading ? <MapperDistribution {...{items}}/> :
                        <Spinner/>}
                </div>
            </React.Fragment>)
    }


    return (
        <div className="d-flex flex-column-fluid">
            <div className="container">
                <div className="row mt-10">
                    {changeDashboard(label)}
                </div>
            </div>
        </div>
    );
});


const buildChartElement = (statusItems, intl) => {

    let labels = [];
    let values = [];
    let sumAg = 0;
    let sumPcr = 0;
    if (statusItems)
        for (const [key, value] of Object.entries(statusItems)) {
            console.log("==============================", value)
            if (value.agCounter > 0) {
                sumAg = sumAg + value.agCounter;
            } else {
                sumPcr = sumPcr + value.pcrCounter;
            }
        }
    labels.push('Covid19-Ag');
    values.push(sumAg);
    labels.push('Covid19-PCR')
    values.push(sumPcr)
    return [labels, values]
}


const amountBuildChartElement = (statusItems, intl) => {

    let labels = [];
    let values = [];
    let sumGds = 0;
    let sumUsd = 0;
    if (statusItems)
        for (const [key, value] of Object.entries(statusItems)) {
            console.log("==============================", value)
            if (value.gdsAmount > 0) {
                sumGds = sumGds + value.gdsAmount;
            } else {
                sumUsd = sumUsd + value.usdAmount;
            }
        }


    labels.push('Total GDS');
    values.push(sumGds);
    labels.push('Total USD')
    values.push(sumUsd)
    return [labels, values]
}


export default React.memo(FinancialDashboard)

