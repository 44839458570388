import React from 'react'
import PageHeader from "../../../../common/report/PageHeader";
import {FormattedMessage} from "react-intl";
import moment from 'moment'
import PageFooter from "../../../../common/report/PageFooter";
import NotificationHeaderHeader from "../../../../common/report/NotificationHeader";
import {ComorbidityMapper, RequestMapper, SymptomsMapper} from "../../../../../../constants/models/request";


const styles = {
    wrapper: {
        width: "100%",
        margin: "0px auto",
        fontFamily: "Helvetica Neue",
        paddingTop: '40px',
        paddingBottom: '40px'

    },

    rowThreeColumn: {
        display: 'flex',
        marginRight: '2px', marginLeft: '3px',
        columnSize: {
            width: '325px'
        },
        columnSizeHeader: {
            width: '500px'
        }
    },
    titleWeight: {fontWeight: 'bold', fontSize: '16px'},
    threeColumnTd: {width: "325px"},
    rowMargin: {marginTop: "150px"},
    textLabel: {fontSize: '15px', marginLeft: '5px', fontWeight: 'normal'},
    textTitle: {fontSize: '16px'},
    headerTitle: {
        background: '#000000',
        color: '#FFFFFF',
        fontSize: '20px'
    }

}
const ContactsDomc = [
    {no: 1}, {no: 2}, {no: 3}, {no: 4}, {no: 5},
]


const Personal = props => {

    const {item} = props;

    const applicant = item ? new RequestMapper(item) : null;
    const sympts = item ? new SymptomsMapper(item?.notificationSheet) : null;

    return (
        <div style={styles.wrapper}>
            <div style={{...styles.rowThreeColumn, marginTop: "20px"}}>
                <div style={styles.rowThreeColumn.columnSizeHeader}>
                    <h5 style={styles.titleWeight}>
                        DATE DE NOTIFICATION :
                        <span
                            style={styles.textLabel}> {moment(sympts?.symptomsStartDate).format('DD/MM/yyyy')}</span>
                    </h5>

                </div>
                <div style={styles.rowThreeColumn.columnSizeHeader}>
                    <h5 style={styles.titleWeight}>
                        DATE D’INVESTIGATION :
                        <span
                            style={styles.textLabel}> {moment(sympts?.symptomsStartDate).format('DD/MM/yyyy')}</span>
                    </h5>

                </div>
            </div>
            <div style={{...styles.rowThreeColumn, marginTop: "20px"}}>
                <div style={styles.rowThreeColumn.columnSizeHeader}>
                    <h5 style={styles.titleWeight}>
                        NOM DE L’INSTITUTION :
                        <span>----------------------</span>
                    </h5>

                </div>
                <div style={styles.rowThreeColumn.columnSizeHeader}>
                    <h5 style={styles.titleWeight}>
                        DÉPARTEMENT :
                        <span
                            style={styles.textLabel}> {moment(item?.notificationSheet?.created).format('DD/MM/yyyy')}</span>
                    </h5>

                </div>
                <div style={styles.rowThreeColumn.columnSizeHeader}>
                    <h5 style={styles.titleWeight}>
                        COMMUNE :
                        <span
                            style={styles.textLabel}> {moment(item?.notificationSheet?.created).format('DD/MM/yyyy')}</span>
                    </h5>

                </div>
            </div>


            <h3 style={{
                fontWeight: "bold",
                marginBottom: "0px",
                marginTop: "15px", ...styles.headerTitle
            }}>INFORMATIONS SUR LA
                PERSONNE</h3>
            <div style={{border: '1px solid #000000'}}>

                <div style={{...styles.rowThreeColumn, marginTop: "40px",}}>
                    <div style={styles.rowThreeColumn.columnSizeHeader}>
                        <h5 style={styles.titleWeight}>
                            ID PATIENT OU # DOSSIER (SI INSTITUTION) :
                            <span style={styles.textLabel}>{applicant?.codeLaboratory?.toUpperCase()}</span>
                        </h5>

                    </div>

                    <div style={styles.rowThreeColumn.columnSize}>
                        <h5 style={styles.titleWeight}>
                            NIF ou CIN :
                            <span
                                style={styles.textLabel}>{item?.applicant?.socialInfos?.identifier[0]?.value}</span>
                        </h5>

                    </div>
                </div>
                <div style={{...styles.rowThreeColumn, marginTop: "40px"}}>

                    <div style={styles.rowThreeColumn.columnSize}>
                        <h5 style={styles.titleWeight}>
                            NOM :<span
                            style={styles.textLabel}>{item?.applicant?.personalInfos?.lastName}</span>
                        </h5>

                    </div>
                    <div style={styles.rowThreeColumn.columnSize}>
                        <h5 style={styles.titleWeight}>
                            PRÉNOM :<span
                            style={styles.textLabel}>{applicant?.firstName}</span>
                        </h5>
                    </div>

                    <div style={styles.rowThreeColumn.columnSize}>
                        <h5 style={styles.titleWeight}>
                            NATIONALITÉ :<span
                            style={styles.textLabel}>{applicant?.nationality}</span>
                        </h5>
                    </div>
                </div>
                <div style={{...styles.rowThreeColumn, marginTop: "40px"}}>
                    <div style={{...styles.rowThreeColumn.columnSize, width: '700px'}}>
                        <h5 style={styles.titleWeight}>
                            SEXE :<span style={styles.textLabel}>
                                <input type='checkbox'
                                       checked={applicant?.gender === 'MALE' ? true : false}/> Masculin</span>
                            <span style={styles.textLabel}>
                               <input type='checkbox'
                                      checked={applicant?.gender === 'FEMALE' ? true : false}/> Féminin
                            </span>
                            <span style={styles.textLabel}>
                               <input type='checkbox'/> (Femme enceinte) Age de la grossesse : --------- Semaines
                            </span>
                        </h5>
                    </div>

                    <div style={styles.rowThreeColumn.columnSize}>
                        <h5 style={styles.titleWeight}>
                            PÉRIODE POST-PARTUM :
                            <span style={styles.textLabel}> <input type='checkbox'/> OUI</span>
                            <span style={styles.textLabel}> <input type='checkbox'/> NON</span>
                        </h5>
                    </div>
                </div>

                <div style={{...styles.rowThreeColumn, marginTop: "40px"}}>

                    <div style={styles.rowThreeColumn.columnSize}>
                        <h5 style={styles.titleWeight}>
                            DATE DE NAISSANCE :<span style={styles.textLabel}>
                                {moment(applicant?.dob).format('DD/MM/yyyy')}
                            </span>
                        </h5>
                    </div>

                    <div style={{...styles.rowThreeColumn.columnSize, width: '700px'}}>
                        <h5 style={styles.titleWeight}>
                            AGE : <span style={styles.textLabel}>{applicant?.age} </span>
                            <span style={styles.textLabel}> <input type='checkbox'/> Jour {`(<1 mois)`}</span>
                            <span style={styles.textLabel}> <input type='checkbox'/> Mois {`(<1 an)`}</span>
                            <span style={styles.textLabel}> <input type='checkbox'/> Année</span>
                        </h5>
                    </div>

                </div>

                <div style={{...styles.rowThreeColumn, marginTop: "40px"}}>

                    <div style={{...styles.rowThreeColumn.columnSize, width: '460px'}}>
                        <h5 style={styles.titleWeight}>
                            QUARANTAINE :
                            <span style={styles.textLabel}> <input type='checkbox'/> Oui</span>
                            <span style={styles.textLabel}> <input type='checkbox'/> Non</span>
                        </h5>
                    </div>

                    <div style={{...styles.rowThreeColumn.columnSize, width: '580px'}}>
                        <h5 style={styles.titleWeight}>
                            DATE QUARANTAINE :
                            <span style={styles.textLabel}> ------/--------/---------</span>
                        </h5>
                    </div>

                    <div style={{...styles.rowThreeColumn.columnSize, width: '700px'}}>
                        <h5 style={styles.titleWeight}>
                            TYPE QUARANTAINE :
                            <span style={styles.textLabel}> <input type='checkbox'/> Institution</span>
                            <span style={styles.textLabel}> <input type='checkbox'/> Domiciliaire</span>
                        </h5>
                    </div>

                </div>


                <div style={{...styles.rowThreeColumn, marginTop: "40px"}}>

                    <div style={{...styles.rowThreeColumn.columnSize, width: '860px', marginRight: '5px'}}>
                        <h5 style={styles.titleWeight}>
                            HOSPITALISÉ(E) avant le résultat du test COVID-19 :
                            <span style={styles.textLabel}> <input type='checkbox'/> Oui</span>
                            <span style={styles.textLabel}> <input type='checkbox'/> Non</span>
                        </h5>
                    </div>

                    <div style={{...styles.rowThreeColumn.columnSize, width: '460px', marginRight: '5px'}}>
                        <h5 style={styles.titleWeight}>
                            DATE D’ADMISSION :
                            <span style={styles.textLabel}> ----/------/-----</span>
                        </h5>
                    </div>
                    <div style={{...styles.rowThreeColumn.columnSize, width: '350px'}}>
                        <h5 style={styles.titleWeight}>
                            LIEU :
                            <span style={styles.textLabel}> ---------------</span>
                        </h5>
                    </div>


                </div>

                <div style={{...styles.rowThreeColumn, marginTop: "40px"}}>

                    <div style={{...styles.rowThreeColumn.columnSize, width: '300px', marginRight: '5px'}}>
                        <h5 style={styles.titleWeight}>
                            TÉLÉPHONE :<span
                            style={styles.textLabel}>{applicant?.phone}</span>
                        </h5>
                    </div>

                    <div style={{...styles.rowThreeColumn.columnSize, width: '640px', marginRight: '5px'}}>
                        <h5 style={styles.titleWeight}>
                            ADRESSE PRÉCISE EN HAÏTI (Domicile ou Hôtel) :
                            <span style={styles.textLabel}> {applicant?.address}</span>
                        </h5>
                    </div>
                </div>


                <div style={{...styles.rowThreeColumn, marginTop: "40px"}}>

                    <div style={{...styles.rowThreeColumn.columnSize, width: '400px', marginRight: '5px'}}>
                        <h5 style={styles.titleWeight}>
                            COMMUNE :<span
                            style={styles.textLabel}>{applicant?.district}</span>
                        </h5>
                    </div>

                    <div style={{...styles.rowThreeColumn.columnSize, width: '400px', marginRight: '5px'}}>
                        <h5 style={styles.titleWeight}>
                            DÉPARTEMENT :
                            <span style={styles.textLabel}>{applicant?.county}</span>
                        </h5>
                    </div>
                    <div style={{...styles.rowThreeColumn.columnSize, width: '400px', marginRight: '5px'}}>
                        <h5 style={styles.titleWeight}>
                            SECTION COMMUNALE :
                            <span style={styles.textLabel}>{applicant?.subMunicipality}</span>
                        </h5>
                    </div>
                </div>

                <div style={{...styles.rowThreeColumn, marginTop: "40px"}}>

                    <div style={{...styles.rowThreeColumn.columnSize, width: '700px', marginRight: '5px'}}>
                        <h5 style={styles.titleWeight}>
                            OCCUPATION : <span style={styles.textLabel}> -----------------</span>
                        </h5>
                    </div>

                    <div style={{...styles.rowThreeColumn.columnSize, width: '500px', marginRight: '5px'}}>
                        <h5 style={styles.titleWeight}>
                            PERSONNEL DE SANTÉ :
                            <span style={styles.textLabel}><input type='checkbox'/> Oui</span>
                            <span style={styles.textLabel}><input type='checkbox'/> Non</span>
                        </h5>
                    </div>

                </div>
                <div style={{...styles.rowThreeColumn, marginTop: "40px"}}>

                    <div style={{...styles.rowThreeColumn.columnSize, width: '700px', marginRight: '5px'}}>
                        <h5 style={styles.titleWeight}>
                            PAYS OÙ IL(ELLE) TRAVAILLE :<span
                            style={styles.textLabel}>----------------------</span>
                        </h5>
                    </div>

                    <div style={{...styles.rowThreeColumn.columnSize, width: '600px', marginRight: '5px'}}>
                        <h5 style={styles.titleWeight}>
                            VILLE :<span style={styles.textLabel}> {applicant?.district}</span>
                        </h5>
                    </div>
                    <div style={{...styles.rowThreeColumn.columnSize, width: '550px', marginRight: '5px'}}>
                        <h5 style={styles.titleWeight}>
                            INSTITUTION :<span style={styles.textLabel}>------------------</span>
                        </h5>
                    </div>

                </div>

                <div style={{...styles.rowThreeColumn, marginTop: "40px"}}>

                    <div style={{...styles.rowThreeColumn.columnSize, width: '700px', marginRight: '5px'}}>
                        <h5 style={styles.titleWeight}>
                            PERSONNE À CONTACTER :<span
                            style={styles.textLabel}>-------------------</span>
                        </h5>
                    </div>

                    <div style={{...styles.rowThreeColumn.columnSize, width: '700px', marginRight: '5px'}}>
                        <h5 style={styles.titleWeight}>
                            TEL :<span style={styles.textLabel}>-------------------</span>
                        </h5>
                    </div>

                </div>

                <div style={{...styles.rowThreeColumn, marginTop: "40px"}}>

                    <div style={{...styles.rowThreeColumn.columnSize, width: '700px', marginRight: '5px'}}>
                        <h5 style={styles.titleWeight}>
                            EMAIL :<span
                            style={styles.textLabel}>{applicant?.email}</span>
                        </h5>
                    </div>

                    <div style={{...styles.rowThreeColumn.columnSize, width: '700px', marginRight: '5px'}}>
                        <h5 style={styles.titleWeight}>
                            ADRESSE PRÉCISE :<span style={styles.textLabel}>--------------------</span>
                        </h5>
                    </div>

                </div>

                <div style={{...styles.rowThreeColumn, marginTop: "40px"}}>

                    <div style={{...styles.rowThreeColumn.columnSize, width: '700px', marginRight: '5px'}}>
                        <h5 style={styles.titleWeight}>
                            Nombre de personnes contact (Maison, Travail, Autres)
                            <span>
                                        -----------------------------
                                    </span>
                        </h5>
                    </div>

                </div>

                <div style={{...styles.rowThreeColumn, marginTop: "40px"}}>

                    <div style={{...styles.rowThreeColumn.columnSize, width: '100%', marginRight: '5px'}}>
                        <table border={1} width={'100%'}>
                            <tr>
                                <th>No</th>
                                <th>ID (NIF/CIN)</th>
                                <th>NOM ET PRENOM</th>
                                <th>Type de Relation avec le contact</th>
                                <th>Téléphone</th>
                            </tr>
                            {ContactsDomc.map(v => {
                                return (
                                    <tr>
                                        <td>{v.no}</td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                    </tr>)
                            })}

                        </table>

                    </div>

                </div>

            </div>

        </div>
    )
}

export default Personal;

