import React, {useEffect,useContext} from "react"
import {Field} from "redux-form";
import {renderDestination, renderField, renderSelect} from "../../../../../../common/utils/FormComponent";
import {withRouter} from "react-router";
import {useDispatch, useSelector} from "react-redux";
import {validate} from "../validator";
import {countries} from "../../../../../../../constants/options/countries";
import actions from "../../../../../../../thunks/common/coreThunks";
import {FormattedMessage, injectIntl} from "react-intl";
import {getHours, getIntlMsg} from "../../../../../../../constants/utils";
import {GlobalContext} from "../../../../../../index";

let FormItems = injectIntl(props => {

    const {intl, item, touched, error} = props
    const {userSession: {specimencenter}} = useContext(GlobalContext);

    const dispatch = useDispatch();


    const criteria = {options: ['specimencenters',specimencenter?specimencenter:''],actionParams:['specimencenters']};

    useEffect(() => {
        dispatch(actions.loadItem(criteria));
    }, [dispatch]);

    const {loading, items} = useSelector(state => ({
        items: state.spCenterReducer.item,
        loading: state.spCenterReducer.loadingItems,
    }));

    const {values} = useSelector(state => ({
        values: state.form?.cartForm?.values
    }));


    const isTraveler = values.type === 'TRAVELER'

// console.log("+============++++++++++++++++====================",items.analyses)

    const getAnalyseById = id => {

        if (items) {
            const values = [...items]
            return values.find(value => value.id === id)?.analyses || []
        }
    }

    return !loading && items && (
        <React.Fragment>


            <div className="row">

                {isTraveler &&
                <>
                    <div className="col-sm-6">
                        <Field id="destination" name="destination"
                               component={renderDestination} label="destination" validate={validate.error}
                               items={countries}/>
                    </div>
                </>
                }


                <div className="col-sm-6">
                    <div className="form-group">
                        <label htmlFor="test_type" className="font-weight-bold">
                            <FormattedMessage id="app.label.test_type"/> :
                        </label>
                        <Field name="testType" component="select" className="form-control">
                            {items && items.analyses.length>0 ? items.analyses.map((item, i) =>
                                <option value={item?.id} key={i}>
                                    {item && item?.description.toUpperCase()}
                                </option>
                            ):null}
                        </Field>
                        {touched && error &&
                        <span className="form-text text-primary">
                                <FormattedMessage id={error}/>
                        </span>}
                    </div>
                </div>
            </div>


        </React.Fragment>

    );
})
export default React.memo(withRouter(FormItems))
