export const hasEntryAccess = (roles, currentEntry) => {

    let status = false;
    let common = ['requests-dashboard']
    const receptionistEntries = [...common, 'reception', 'traveler', 'staff', 'registration', 'financial-dashboard', 'edit', 'leadership-dashboard'];
    const archivistEntries = [...common, 'archived', 'traveler', 'edit'];
    const cashierEntries = ['cart', 'traveler', 'edit', 'financial-dashboard','cart-report'];
    const labTechnicianEntries = [...common, 'specimens', 'leadership-dashboard'];
    const labTechnicianSupervisorEntries = [...common, 'laboratory','financial_dashboard','leadership-dashboard'];
    const resultProviderEntries = [...common, 'results', 'financial_dashboard', 'leadership-dashboard'];
    const dataOfficerEntries = [...common, 'notification', 'notification-form', 'feel-sheet', 'leadership-dashboard'];
    const financialOfficerEntries = ['financial-dashboard'];
    const leadershipMemberEntries = ['leadership-dashboard'];
    const dataManagerEntries = ['sharing', 'partners'];
    const dataCleanerEntries = ['unfinished'];
    const indicatorUploaderEntries = ['indicators', 'upload-test'];
    const operationChiefEntries = [
        ...receptionistEntries,
        ...archivistEntries,
        ...cashierEntries,
        ...labTechnicianEntries,
        ...labTechnicianSupervisorEntries,
        ...resultProviderEntries,
        ...dataOfficerEntries,
        ...dataCleanerEntries,
        'reports',
        'applicants'
    ];

    const admEntries = [
        'zl',
        'generals',
        'edit',
        'settings',
        'global_dashboard',
        'security-dashboard',
        'requests-dashboard',
        'observations-dashboard',
        ...operationChiefEntries,
        ...financialOfficerEntries,
        ...leadershipMemberEntries,
        ...dataManagerEntries,
        ...indicatorUploaderEntries,
    ];
    const superAdminEntries = [
        ...admEntries
    ];

    roles.forEach((role) => {
            switch (role) {


                case 'RECEPTIONIST': {
                    receptionistEntries.forEach((entry) => {
                        if (entry === currentEntry)
                            status = true;
                    });
                    break;
                }


                case 'ARCHIVIST': {
                    admEntries.forEach((entry) => {
                        if (entry === currentEntry)
                            status = true;
                    });
                    break;
                }

                case 'CASHIER': {
                    cashierEntries.forEach((entry) => {
                        if (entry === currentEntry)
                            status = true;
                    });
                    break;
                }


                case 'LAB_TECHNICIAN': {
                    labTechnicianEntries.forEach((entry) => {
                        if (entry === currentEntry)
                            status = true;
                    });
                    break;
                }


                case 'LAB_TECHNICIAN_SUPERVISOR': {
                    labTechnicianSupervisorEntries.forEach((entry) => {
                        if (entry === currentEntry)
                            status = true;
                    });
                    break;
                }

                case 'RESULT_PROVIDER': {
                    resultProviderEntries.forEach((entry) => {
                        if (entry === currentEntry)
                            status = true;
                    });
                    break;
                }
                case 'DATA_OFFICER': {
                    dataOfficerEntries.forEach((entry) => {
                        if (entry === currentEntry)
                            status = true;
                    });
                    break;
                }
                case 'DATA_CLEANER': {
                    dataCleanerEntries.forEach((entry) => {
                        if (entry === currentEntry)
                            status = true;
                    });
                    break;
                }

                case 'FINANCIAL_OFFICER': {
                    financialOfficerEntries.forEach((entry) => {
                        if (entry === currentEntry)
                            status = true;
                    });
                    break;
                }

                case 'LEADERSHIP_MEMBER': {
                    leadershipMemberEntries.forEach((entry) => {
                        if (entry === currentEntry)
                            status = true;
                    });
                    break;
                }

                case 'DATA_MANAGER': {
                    dataManagerEntries.forEach((entry) => {
                        if (entry === currentEntry)
                            status = true;
                    });
                    break;
                }

                case 'INDICATOR_UPLOADER': {
                    indicatorUploaderEntries.forEach((entry) => {
                        if (entry === currentEntry)
                            status = true;
                    });
                    break;
                }

                case 'OPERATION_CHIEF': {
                    operationChiefEntries.forEach((entry) => {
                        if (entry === currentEntry)
                            status = true;
                    });
                    break;
                }

                case 'ADMIN': {
                    admEntries.forEach((entry) => {
                        if (entry === currentEntry)
                            status = true;
                    });
                    break;
                }


                case 'SUPER_ADMINISTRATOR': {
                    superAdminEntries.forEach((entry) => {
                        if (entry === currentEntry)
                            status = true;
                    });
                    break;
                }
                default:
                    status = true;
            }
        }
    );

    return status;
};


export const canDisplay = (roles, label) => {

    let status = false;
    const receptionistEntries = ['new_request', 'financial_dashboard', 'leadership-dashboard', 'dashboards', 'request_management', 'dashboard', 'modules', 'reception', 'new_request'];
    const archivistEntries = ['financial_dashboard', 'request_management', 'dashboard', 'dashboards', 'modules', 'archived'];
    const cashierEntries = ['dashboards', 'financial_dashboard', 'request_management', 'dashboard', 'modules', 'cart', 'new_cart','reports','reports_management','cashier_repport','cart-report'];
    const labTechnicianEntries = ['dashboards', 'financial_dashboard', 'request_management', 'dashboard', 'modules', 'specimens'];
    const labTechnicianSupervisorEntries = ['financial_dashboard','dashboards', 'request_management', 'dashboard', 'modules', 'laboratory'];
    const resultProviderEntries = ['dashboards', 'leadership-dashboard`', 'financial_dashboard', 'financial_dashboard', 'request_management', 'dashboard', 'modules', 'results', 'result_menu'];
    const dataOfficerEntries = ['request_management', 'leadership-dashboard', 'financial_dashboard', 'new_patient', 'dashboards', 'observations_dashboard', 'observation_management', 'dashboard', 'notification', 'modules', 'fill_sheet', 'notification_sheet'];
    const dataCleanerEntries = ['dashboards', 'data_cleaner_dashboard', 'request_management', 'modules', 'unfinished'];

    const financialOfficerEntries = ['dashboard', 'dashboards', 'financial_dashboard'];
    const leadershipMemberEntries = ['dashboard', 'dashboards', 'leadership_dashboard'];
    const dataManagerEntries = ['sharing', 'dashboards', 'data_manager_dashboard', 'partners'];
    const indicatorCleanerEntries = ['dashboards', 'indicator_uploader_dashboard', 'indicator_management', 'upload_indicators', 'indicators', 'upload'];
    const operationChiefEntries = [
        ...receptionistEntries,
        ...archivistEntries,
        ...cashierEntries,
        ...labTechnicianEntries,
        ...labTechnicianSupervisorEntries,
        ...resultProviderEntries,
        ...dataOfficerEntries,
        ...dataCleanerEntries,
        'reports',
        'reports_management',
        'tests_performed',
    ]

    const admEntries = [
        'reference_tables',
        'remove',
        'offline',
        'reports',
        'settings',
        'security',
        'request_management',
        'observation_management',
        'global_dashboard',
        'security_dashboard',
        'archived_request',
        'new_cart',
        ...operationChiefEntries,
        ...financialOfficerEntries,
        ...leadershipMemberEntries,
        ...dataManagerEntries,
        ...indicatorCleanerEntries,
    ];
    const superAdmEntries = [
        ...admEntries
    ];

    roles.forEach((role) => {
            switch (role) {


                case 'RECEPTIONIST': {
                    receptionistEntries.forEach((entry) => {
                        if (entry === label)
                            status = true;
                    });
                    break;
                }

                case 'ARCHIVIST': {
                    archivistEntries.forEach((entry) => {
                        if (entry === label)
                            status = true;
                    });
                    break;
                }

                case 'CASHIER': {
                    cashierEntries.forEach((entry) => {
                        if (entry === label)
                            status = true;
                    });
                    break;
                }

                case 'LAB_TECHNICIAN': {
                    labTechnicianEntries.forEach((entry) => {
                        if (entry === label)
                            status = true;
                    });
                    break;
                }

                case 'LAB_TECHNICIAN_SUPERVISOR': {
                    labTechnicianSupervisorEntries.forEach((entry) => {
                        if (entry === label)
                            status = true;
                    });
                    break;
                }

                case 'RESULT_PROVIDER': {
                    resultProviderEntries.forEach((entry) => {
                        if (entry === label)
                            status = true;
                    });
                    break;
                }

                case 'DATA_OFFICER': {
                    dataOfficerEntries.forEach((entry) => {
                        if (entry === label)
                            status = true;
                    });
                    break;
                }


                case 'FINANCIAL_OFFICER': {
                    financialOfficerEntries.forEach((entry) => {
                        if (entry === label)
                            status = true;
                    });
                    break;
                }


                case 'LEADERSHIP_MEMBER': {
                    leadershipMemberEntries.forEach((entry) => {
                        if (entry === label)
                            status = true;
                    });
                    break;
                }


                case 'DATA_CLEANER': {
                    dataCleanerEntries.forEach((entry) => {
                        if (entry === label)
                            status = true;
                    });
                    break;
                }

                case 'DATA_MANAGER': {
                    dataManagerEntries.forEach((entry) => {
                        if (entry === label)
                            status = true;
                    });
                    break;
                }

                case 'INDICATOR_UPLOADER': {
                    indicatorCleanerEntries.forEach((entry) => {
                        if (entry === label)
                            status = true;
                    });
                    break;
                }


                case 'OPERATION_CHIEF': {
                    operationChiefEntries.forEach((entry) => {
                        if (entry === label)
                            status = true;
                    });
                    break;
                }

                case 'ADMIN': {
                    admEntries.forEach((entry) => {
                        if (entry === label)
                            status = true;
                    });
                    break;
                }


                case 'SUPER_ADMINISTRATOR': {
                    superAdmEntries.forEach((entry) => {
                        if (entry === label)
                            status = true;
                    });
                    break;
                }
                default:
                    status = true;
            }
        }
    );

    return status;
};


export const loadDefaultHomePage = (roles, institution) => {
    switch (roles[0]) {

        case 'ARCHIVIST':
        case 'DATA_OFFICER':
        case 'LAB_TECHNICIAN':
        case 'RESULT_PROVIDER':
        case 'LAB_TECHNICIAN_SUPERVISOR':
        case 'RECEPTIONIST': {
            return `/${institution}/leadership-dashboard`
        }

        case 'CASHIER':
        case 'FINANCIAL_OFFICER': {
            return `/${institution}/financial-dashboard`
        }

        case 'LEADERSHIP_MEMBER': {
            return `/${institution}/leadership-dashboard`
        }


        case 'DATA_MANAGER': {
            return `/${institution}/sharing`
        }


        case 'DATA_CLEANER': {
            return `/${institution}/unfinished`
        }

        case 'INDICATOR_UPLOADER': {
            return `/${institution}/indicators`
        }

        case 'ADMINISTRATOR': {
            return `/zl`
        }
        case 'SUPER_ADMINISTRATOR':
            return `/`;
        default:
            return null
    }
};

