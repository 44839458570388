import * as React from "react";
import {useContext} from "react";
import {Link} from "react-router-dom";
import {injectIntl} from "react-intl";
import {getIntlMsg} from "../../../../constants/utils";
import {GlobalContext} from "../../../index";


const ChatBtn = injectIntl(props => {

    const {intl} = props

    const {institution: {acronym}} = useContext(GlobalContext);


    return (
        <ul className="sticky-toolbar nav flex-column pl-2 pr-2 pt-3 pb-3 mt-4">
            <li className="nav-item" id="kt_sticky_toolbar_chat_toggler" data-toggle="tooltip" title={getIntlMsg(intl, `app.label.instant_chat`)}
                data-placement="left">
                <Link className="btn btn-sm btn-icon btn-bg-light btn-icon-primary btn-hover-secondary" to={`#`}
                   data-toggle="modal" data-target="#kt_chat_modal">
					<span className="svg-icon svg-icon-lg">
						<svg width="24px"
                             height="24px" viewBox="0 0 24 24" version="1.1">
							<g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
								<rect x="0" y="0" width="24" height="24"/>
								<path opacity="0.3" fillRule="evenodd" clipRule="evenodd"
                                      d="M14.4862 18L12.7975 21.0566C12.5304 21.54 11.922 21.7153 11.4386 21.4483C11.2977 21.3704 11.1777 21.2597 11.0887 21.1255L9.01653 18H5C3.34315 18 2 16.6569 2 15V6C2 4.34315 3.34315 3 5 3H19C20.6569 3 22 4.34315 22 6V15C22 16.6569 20.6569 18 19 18H14.4862Z"
                                      fill="black"/>
								<path fillRule="evenodd" clipRule="evenodd"
                                      d="M6 7H15C15.5523 7 16 7.44772 16 8C16 8.55228 15.5523 9 15 9H6C5.44772 9 5 8.55228 5 8C5 7.44772 5.44772 7 6 7ZM6 11H11C11.5523 11 12 11.4477 12 12C12 12.5523 11.5523 13 11 13H6C5.44772 13 5 12.5523 5 12C5 11.4477 5.44772 11 6 11Z"
                                      fill="black"/>
							</g>
						</svg>
					</span>
                </Link>
            </li>
        </ul>
    )
})

export default React.memo(ChatBtn)

