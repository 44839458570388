const currencyActionTypes = {

    /**
     * @Author Rikenson JACQUES (The Tiger ^(..)^)
     * RESET CURRENCY ACTION TYPES
     * -------------------------------------------
     * */
    RESET_CURRENCY: "RESET_CURRENCY",

    /**
     * @Author Rikenson JACQUES (The Tiger ^(..)^)
     * LOAD CURRENCIES ACTION TYPES
     * -------------------------------------------
     * */
    LOAD_CURRENCIES: "LOAD_CURRENCIES",
    LOAD_CURRENCIES_SUCCEEDED: "LOAD_CURRENCIES_SUCCEEDED",
    LOAD_CURRENCIES_FAILED: "LOAD_CURRENCIES_FAILED",


    /**
     * @Author Rikenson JACQUES (The Tiger ^(..)^)
     * LOAD SINGLE CURRENCY ACTION TYPES
     * -------------------------------------------
     * */
    LOAD_CURRENCY: "LOAD_CURRENCY",
    LOAD_CURRENCY_SUCCEEDED: "LOAD_CURRENCY_SUCCEEDED",
    LOAD_CURRENCY_FAILED: "LOAD_CURRENCY_FAILED",


    /**
     * @Author Rikenson JACQUES (The Tiger ^(..)^)
     * SAVE CURRENCY ACTION TYPES
     * -------------------------------------------
     * */
    SAVE_CURRENCY: "SAVE_CURRENCY",
    SAVE_CURRENCY_SUCCEEDED: "SAVE_CURRENCY_SUCCEEDED",
    SAVE_CURRENCY_FAILED: "SAVE_CURRENCY_FAILED",


    /**
     * @Author Rikenson JACQUES (The Tiger ^(..)^)
     * UPDATE CURRENCY ACTION TYPES
     * -------------------------------------------
     * */
    UPDATE_CURRENCY: "UPDATE_CURRENCY",
    UPDATE_CURRENCY_SUCCEEDED: "UPDATE_CURRENCY_SUCCEEDED",
    UPDATE_CURRENCY_FAILED: "UPDATE_CURRENCY_FAILED",


    /**
     * @Author Rikenson JACQUES (The Tiger ^(..)^)
     * DELETE CURRENCY ACTION TYPES
     * -------------------------------------------
     * */
    DELETE_CURRENCY: "DELETE_CURRENCY",
    DELETE_CURRENCY_SUCCEEDED: "DELETE_CURRENCY_SUCCEEDED",
    DELETE_CURRENCY_FAILED: "DELETE_CURRENCY_FAILED",


};


export default currencyActionTypes;
