import * as React from "react";
import {FormattedMessage} from "react-intl";


const TeamModal = props => {

    return (

        <div className="modal fade" id="team" tabIndex="-1" role="dialog"
             aria-labelledby="team" aria-modal="true" style={{paddingRight: "15px", display: "none"}}>
            <div className="modal-dialog modal-dialog-centered modal-xl" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLabel">
                            <FormattedMessage id={`app.label.team_med_infos`}/>
                        </h5>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                            <i aria-hidden="true" className="ki ki-close"/>
                        </button>
                    </div>
                    <div className="modal-body">
                        <div className="row">
                            <div className="col-xxl-4 col-xl-6 col-md-6 col-sm-6">
                                <div className="card card-custom gutter-b card-stretch">
                                    <div className="card-body pt-4">
                                        <div className="d-flex align-items-end py-2">
                                            <div className="d-flex align-items-center">
                                                <div className="d-flex flex-shrink-0 mr-5">
                                                    <div className="symbol symbol-circle symbol-lg-75">
                                                        <img src="/assets/media/users/rj.jpg"
                                                             alt="image"/>
                                                    </div>
                                                </div>
                                                <div className="d-flex flex-column">
                                                    <a href="#"
                                                       className="text-dark font-weight-bold text-hover-primary font-size-h4 mb-0">Rikenson Jacques</a>
                                                    <span
                                                        className="text-muted font-weight-bold">DEV Manager</span>
                                                </div>
                                            </div>
                                        </div>
                                        <p className="py-2"></p>
                                        <div className="py-2">
                                            <div className="d-flex align-items-center mb-2">
														<span className="flex-shrink-0 mr-2">
															<span className="svg-icon svg-icon-md">
                                                                <svg xmlns="http://www.w3.org/2000/svg"
                                                                     width="24px" height="24px" viewBox="0 0 24 24"
                                                                     version="1.1">
																	<g stroke="none" stroke-width="1" fill="none"
                                                                       fill-rule="evenodd">
																		<rect x="0" y="0" width="24" height="24"></rect>
																		<path
                                                                            d="M13.0799676,14.7839934 L15.2839934,12.5799676 C15.8927139,11.9712471 16.0436229,11.0413042 15.6586342,10.2713269 L15.5337539,10.0215663 C15.1487653,9.25158901 15.2996742,8.3216461 15.9083948,7.71292558 L18.6411989,4.98012149 C18.836461,4.78485934 19.1530435,4.78485934 19.3483056,4.98012149 C19.3863063,5.01812215 19.4179321,5.06200062 19.4419658,5.11006808 L20.5459415,7.31801948 C21.3904962,9.0071287 21.0594452,11.0471565 19.7240871,12.3825146 L13.7252616,18.3813401 C12.2717221,19.8348796 10.1217008,20.3424308 8.17157288,19.6923882 L5.75709327,18.8875616 C5.49512161,18.8002377 5.35354162,18.5170777 5.4408655,18.2551061 C5.46541191,18.1814669 5.50676633,18.114554 5.56165376,18.0596666 L8.21292558,15.4083948 C8.8216461,14.7996742 9.75158901,14.6487653 10.5215663,15.0337539 L10.7713269,15.1586342 C11.5413042,15.5436229 12.4712471,15.3927139 13.0799676,14.7839934 Z"
                                                                            fill="#000000"></path>
																		<path
                                                                            d="M14.1480759,6.00715131 L13.9566988,7.99797396 C12.4781389,7.8558405 11.0097207,8.36895892 9.93933983,9.43933983 C8.8724631,10.5062166 8.35911588,11.9685602 8.49664195,13.4426352 L6.50528978,13.6284215 C6.31304559,11.5678496 7.03283934,9.51741319 8.52512627,8.02512627 C10.0223249,6.52792766 12.0812426,5.80846733 14.1480759,6.00715131 Z M14.4980938,2.02230302 L14.313049,4.01372424 C11.6618299,3.76737046 9.03000738,4.69181803 7.1109127,6.6109127 C5.19447112,8.52735429 4.26985715,11.1545872 4.51274152,13.802405 L2.52110319,13.985098 C2.22450978,10.7517681 3.35562581,7.53777247 5.69669914,5.19669914 C8.04101739,2.85238089 11.2606138,1.72147333 14.4980938,2.02230302 Z"
                                                                            fill="#000000" fill-rule="nonzero"
                                                                            opacity="0.3"></path>
																	</g>
																</svg>
															</span>
														</span>
                                                <span className="text-muted font-weight-bold">+509 43 28 0323</span>
                                            </div>
                                            <div className="d-flex align-items-center mb-2">
														<span className="flex-shrink-0 mr-2">
															<span className="svg-icon svg-icon-md">
                                                                <svg xmlns="http://www.w3.org/2000/svg"
                                                                     width="24px" height="24px" viewBox="0 0 24 24"
                                                                     version="1.1">
																	<g stroke="none" stroke-width="1" fill="none"
                                                                       fill-rule="evenodd">
																		<rect x="0" y="0" width="24" height="24"></rect>
																		<path
                                                                            d="M5,6 L19,6 C20.1045695,6 21,6.8954305 21,8 L21,17 C21,18.1045695 20.1045695,19 19,19 L5,19 C3.8954305,19 3,18.1045695 3,17 L3,8 C3,6.8954305 3.8954305,6 5,6 Z M18.1444251,7.83964668 L12,11.1481833 L5.85557487,7.83964668 C5.4908718,7.6432681 5.03602525,7.77972206 4.83964668,8.14442513 C4.6432681,8.5091282 4.77972206,8.96397475 5.14442513,9.16035332 L11.6444251,12.6603533 C11.8664074,12.7798822 12.1335926,12.7798822 12.3555749,12.6603533 L18.8555749,9.16035332 C19.2202779,8.96397475 19.3567319,8.5091282 19.1603533,8.14442513 C18.9639747,7.77972206 18.5091282,7.6432681 18.1444251,7.83964668 Z"
                                                                            fill="#000000"></path>
																	</g>
																</svg>
															</span>
														</span>
                                                <a href="#"
                                                   className="text-muted text-hover-primary font-weight-bold">rjaques@pih.org</a>
                                            </div>
                                        </div>
                                        <div className="pt-2">
                                            <a href="tel:+50943280323" className="btn btn-primary font-weight-bolder mr-2">Contacter</a>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-xxl-4 col-xl-6 col-md-6 col-sm-6">
                                <div className="card card-custom gutter-b card-stretch">
                                    <div className="card-body pt-4">
                                        <div className="d-flex align-items-end py-2">
                                            <div className="d-flex align-items-center">
                                                <div className="d-flex flex-shrink-0 mr-5">
                                                    <div className="symbol symbol-circle symbol-lg-75">
                                                        <img src="/assets/media/users/jp.jpg"
                                                             alt="image"/>
                                                    </div>
                                                </div>
                                                <div className="d-flex flex-column">
                                                    <a href="#"
                                                       className="text-dark font-weight-bold text-hover-primary font-size-h4 mb-0">Jean-Paul Louidor</a>
                                                    <span
                                                        className="text-muted font-weight-bold">Analyst Programmer</span>
                                                </div>
                                            </div>
                                        </div>
                                        <p className="py-2"></p>
                                        <div className="py-2">
                                            <div className="d-flex align-items-center mb-2">
														<span className="flex-shrink-0 mr-2">
															<span className="svg-icon svg-icon-md">
                                                                <svg xmlns="http://www.w3.org/2000/svg"
                                                                     width="24px" height="24px" viewBox="0 0 24 24"
                                                                     version="1.1">
																	<g stroke="none" stroke-width="1" fill="none"
                                                                       fill-rule="evenodd">
																		<rect x="0" y="0" width="24" height="24"></rect>
																		<path
                                                                            d="M13.0799676,14.7839934 L15.2839934,12.5799676 C15.8927139,11.9712471 16.0436229,11.0413042 15.6586342,10.2713269 L15.5337539,10.0215663 C15.1487653,9.25158901 15.2996742,8.3216461 15.9083948,7.71292558 L18.6411989,4.98012149 C18.836461,4.78485934 19.1530435,4.78485934 19.3483056,4.98012149 C19.3863063,5.01812215 19.4179321,5.06200062 19.4419658,5.11006808 L20.5459415,7.31801948 C21.3904962,9.0071287 21.0594452,11.0471565 19.7240871,12.3825146 L13.7252616,18.3813401 C12.2717221,19.8348796 10.1217008,20.3424308 8.17157288,19.6923882 L5.75709327,18.8875616 C5.49512161,18.8002377 5.35354162,18.5170777 5.4408655,18.2551061 C5.46541191,18.1814669 5.50676633,18.114554 5.56165376,18.0596666 L8.21292558,15.4083948 C8.8216461,14.7996742 9.75158901,14.6487653 10.5215663,15.0337539 L10.7713269,15.1586342 C11.5413042,15.5436229 12.4712471,15.3927139 13.0799676,14.7839934 Z"
                                                                            fill="#000000"></path>
																		<path
                                                                            d="M14.1480759,6.00715131 L13.9566988,7.99797396 C12.4781389,7.8558405 11.0097207,8.36895892 9.93933983,9.43933983 C8.8724631,10.5062166 8.35911588,11.9685602 8.49664195,13.4426352 L6.50528978,13.6284215 C6.31304559,11.5678496 7.03283934,9.51741319 8.52512627,8.02512627 C10.0223249,6.52792766 12.0812426,5.80846733 14.1480759,6.00715131 Z M14.4980938,2.02230302 L14.313049,4.01372424 C11.6618299,3.76737046 9.03000738,4.69181803 7.1109127,6.6109127 C5.19447112,8.52735429 4.26985715,11.1545872 4.51274152,13.802405 L2.52110319,13.985098 C2.22450978,10.7517681 3.35562581,7.53777247 5.69669914,5.19669914 C8.04101739,2.85238089 11.2606138,1.72147333 14.4980938,2.02230302 Z"
                                                                            fill="#000000" fill-rule="nonzero"
                                                                            opacity="0.3"></path>
																	</g>
																</svg>
															</span>
														</span>
                                                <span className="text-muted font-weight-bold">+509 40 41 0517</span>
                                            </div>
                                            <div className="d-flex align-items-center mb-2">
														<span className="flex-shrink-0 mr-2">
															<span className="svg-icon svg-icon-md">
                                                                <svg xmlns="http://www.w3.org/2000/svg"
                                                                     width="24px" height="24px" viewBox="0 0 24 24"
                                                                     version="1.1">
																	<g stroke="none" stroke-width="1" fill="none"
                                                                       fill-rule="evenodd">
																		<rect x="0" y="0" width="24" height="24"></rect>
																		<path
                                                                            d="M5,6 L19,6 C20.1045695,6 21,6.8954305 21,8 L21,17 C21,18.1045695 20.1045695,19 19,19 L5,19 C3.8954305,19 3,18.1045695 3,17 L3,8 C3,6.8954305 3.8954305,6 5,6 Z M18.1444251,7.83964668 L12,11.1481833 L5.85557487,7.83964668 C5.4908718,7.6432681 5.03602525,7.77972206 4.83964668,8.14442513 C4.6432681,8.5091282 4.77972206,8.96397475 5.14442513,9.16035332 L11.6444251,12.6603533 C11.8664074,12.7798822 12.1335926,12.7798822 12.3555749,12.6603533 L18.8555749,9.16035332 C19.2202779,8.96397475 19.3567319,8.5091282 19.1603533,8.14442513 C18.9639747,7.77972206 18.5091282,7.6432681 18.1444251,7.83964668 Z"
                                                                            fill="#000000"></path>
																	</g>
																</svg>
															</span>
														</span>
                                                <a href="#"
                                                   className="text-muted text-hover-primary font-weight-bold">jpaullouidor@pih.org</a>
                                            </div>
                                        </div>
                                        <div className="pt-2">
                                            <a href="tel:+50940410517" className="btn btn-primary font-weight-bolder mr-2">Contacter</a>
                                        </div>
                                    </div>
                                </div>
                            </div>

                         <div className="col-xxl-4 col-xl-6 col-md-6 col-sm-6">
                                <div className="card card-custom gutter-b card-stretch">
                                    <div className="card-body pt-4">
                                        <div className="d-flex align-items-end py-2">
                                            <div className="d-flex align-items-center">
                                                <div className="d-flex flex-shrink-0 mr-5">
                                                    <div className="symbol symbol-circle symbol-lg-75">
                                                        <img src="/assets/media/users/jbps.jpeg"
                                                             alt="image"/>
                                                    </div>
                                                </div>
                                                <div className="d-flex flex-column">
                                                    <a href="#"
                                                       className="text-dark font-weight-bold text-hover-primary font-size-h4 mb-0">Jn Baptiste Prince S.</a>
                                                    <span
                                                        className="text-muted font-weight-bold">Admin System</span>
                                                </div>
                                            </div>
                                        </div>
                                        <p className="py-2"></p>
                                        <div className="py-2">
                                            <div className="d-flex align-items-center mb-2">
														<span className="flex-shrink-0 mr-2">
															<span className="svg-icon svg-icon-md">
                                                                <svg xmlns="http://www.w3.org/2000/svg"
                                                                     width="24px" height="24px" viewBox="0 0 24 24"
                                                                     version="1.1">
																	<g stroke="none" stroke-width="1" fill="none"
                                                                       fill-rule="evenodd">
																		<rect x="0" y="0" width="24" height="24"></rect>
																		<path
                                                                            d="M13.0799676,14.7839934 L15.2839934,12.5799676 C15.8927139,11.9712471 16.0436229,11.0413042 15.6586342,10.2713269 L15.5337539,10.0215663 C15.1487653,9.25158901 15.2996742,8.3216461 15.9083948,7.71292558 L18.6411989,4.98012149 C18.836461,4.78485934 19.1530435,4.78485934 19.3483056,4.98012149 C19.3863063,5.01812215 19.4179321,5.06200062 19.4419658,5.11006808 L20.5459415,7.31801948 C21.3904962,9.0071287 21.0594452,11.0471565 19.7240871,12.3825146 L13.7252616,18.3813401 C12.2717221,19.8348796 10.1217008,20.3424308 8.17157288,19.6923882 L5.75709327,18.8875616 C5.49512161,18.8002377 5.35354162,18.5170777 5.4408655,18.2551061 C5.46541191,18.1814669 5.50676633,18.114554 5.56165376,18.0596666 L8.21292558,15.4083948 C8.8216461,14.7996742 9.75158901,14.6487653 10.5215663,15.0337539 L10.7713269,15.1586342 C11.5413042,15.5436229 12.4712471,15.3927139 13.0799676,14.7839934 Z"
                                                                            fill="#000000"></path>
																		<path
                                                                            d="M14.1480759,6.00715131 L13.9566988,7.99797396 C12.4781389,7.8558405 11.0097207,8.36895892 9.93933983,9.43933983 C8.8724631,10.5062166 8.35911588,11.9685602 8.49664195,13.4426352 L6.50528978,13.6284215 C6.31304559,11.5678496 7.03283934,9.51741319 8.52512627,8.02512627 C10.0223249,6.52792766 12.0812426,5.80846733 14.1480759,6.00715131 Z M14.4980938,2.02230302 L14.313049,4.01372424 C11.6618299,3.76737046 9.03000738,4.69181803 7.1109127,6.6109127 C5.19447112,8.52735429 4.26985715,11.1545872 4.51274152,13.802405 L2.52110319,13.985098 C2.22450978,10.7517681 3.35562581,7.53777247 5.69669914,5.19669914 C8.04101739,2.85238089 11.2606138,1.72147333 14.4980938,2.02230302 Z"
                                                                            fill="#000000" fill-rule="nonzero"
                                                                            opacity="0.3"></path>
																	</g>
																</svg>
															</span>
														</span>
                                                <span className="text-muted font-weight-bold">+509 43 53 1042</span>
                                            </div>
                                            <div className="d-flex align-items-center mb-2">
														<span className="flex-shrink-0 mr-2">
															<span className="svg-icon svg-icon-md">
                                                                <svg xmlns="http://www.w3.org/2000/svg"
                                                                     width="24px" height="24px" viewBox="0 0 24 24"
                                                                     version="1.1">
																	<g stroke="none" stroke-width="1" fill="none"
                                                                       fill-rule="evenodd">
																		<rect x="0" y="0" width="24" height="24"></rect>
																		<path
                                                                            d="M5,6 L19,6 C20.1045695,6 21,6.8954305 21,8 L21,17 C21,18.1045695 20.1045695,19 19,19 L5,19 C3.8954305,19 3,18.1045695 3,17 L3,8 C3,6.8954305 3.8954305,6 5,6 Z M18.1444251,7.83964668 L12,11.1481833 L5.85557487,7.83964668 C5.4908718,7.6432681 5.03602525,7.77972206 4.83964668,8.14442513 C4.6432681,8.5091282 4.77972206,8.96397475 5.14442513,9.16035332 L11.6444251,12.6603533 C11.8664074,12.7798822 12.1335926,12.7798822 12.3555749,12.6603533 L18.8555749,9.16035332 C19.2202779,8.96397475 19.3567319,8.5091282 19.1603533,8.14442513 C18.9639747,7.77972206 18.5091282,7.6432681 18.1444251,7.83964668 Z"
                                                                            fill="#000000"></path>
																	</g>
																</svg>
															</span>
														</span>
                                                <a href="#"
                                                   className="text-muted text-hover-primary font-weight-bold">jbprince@pih.org</a>
                                            </div>
                                        </div>
                                        <div className="pt-2">
                                            <a href="tel:+50943531042" className="btn btn-primary font-weight-bolder mr-2">Contacter</a>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-light-primary font-weight-bold"
                                data-dismiss="modal">Fermer
                        </button>
                        {/*<button type="button" className="btn btn-primary font-weight-bold">Save changes</button>*/}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default React.memo(TeamModal)

