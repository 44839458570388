import {FormattedMessage, injectIntl} from "react-intl";
import {useDispatch} from "react-redux";
import * as React from "react";
import TablePager from "../../../../../common/pagination/TablePager";
import Item from "../../listing/Item";
import {withRouter} from "react-router";


const ListViewer = injectIntl((props) => {

    const dispatch = useDispatch();
    const {formView, setFormView, format, data, setData} = props;


    const criteria = {options: ['indicators']};
    const options = {options: ['indicators', 'names']};


    return (

        <div className="datatable datatable-bordered datatable-head-custom
        datatable-default datatable-primary datatable-loaded"
             id="kt_datatable_0" style={{}}>
            <table className="datatable-table" style={{display: "block"}}>
                <thead className="datatable-head">
                    <tr className="datatable-row" style={{left: "0px"}}>

                        <th className="datatable-cell datatable-cell-sort">
                            <span style={{width: "400px"}}>
                                <FormattedMessage id={`app.label.indicator`}/>
                            </span>
                        </th>

                        <th className="datatable-cell datatable-cell-sort">
                            <span style={{width: "100px"}}>
                                <FormattedMessage id={`app.label.collection_date`}/>
                            </span>
                        </th>

                        <th className="datatable-cell datatable-cell-sort">
                            <span style={{width: "100px"}}>
                                <FormattedMessage id={`app.label.quantity`}/>
                            </span>
                        </th>

                        <th className="datatable-cell datatable-cell-sort">
                            <span style={{width: "100px"}}>
                                <FormattedMessage id={`app.label.site`}/>
                            </span>
                        </th>

                        <th data-autohide-disabled="false"
                            className="datatable-cell datatable-cell-sort">
                            <span style={{width: "125px"}}>Actions</span>
                        </th>
                    </tr>
                </thead>
                <tbody className="datatable-body" style={{}}>
                {data.map((item, i) => <Item {...{...item, setFormView}} key={i}/>)}
                </tbody>
            </table>
            <TablePager/>
        </div>
    );
})

export default React.memo(withRouter(ListViewer))