import types from "../../actions/types/settings/actionTypes";
import initialState from "../../../constants/utils/initialState";

const reducer = (state = initialState.settingsReducer, action) => {

        switch (action.type) {

            /**
             * LOAD SETTING LIST
             * */
            case types.RESET_SETTING:
                return {...state, item: {}};

            /**
             * LOAD SETTING LIST
             * */
            case types.LOAD_SETTINGS:
                return {...state, loadingItems: action.payload};


            case types.LOAD_SETTINGS_SUCCEEDED:
                return {...state, items: action.payload, loadingItems: false};

            case types.LOAD_SETTINGS_FAILED:
                return {...state, loadingItems: action.payload};


            /**  LOAD SETTING BY A SPECIFIC PROPERTY CASE */
            case types.LOAD_SETTING:
                return {...state, loadingItem: true};
            case types.LOAD_SETTING_SUCCEEDED:
                return {...state, item: action.payload, loadingItem: false};
            case types.LOAD_SETTING_FAILED:
                return {...state, loadingItem: false};


            /**  LOAD SETTING BY A SPECIFIC PROPERTY CASE */
            case types.LOAD_SETTINGS_BY_ACRONYM:
                return {...state, loadingSetting: true};
            case types.LOAD_SETTINGS_BY_ACRONYM_SUCCEEDED:
                return {...state, setting: action.payload, loadingSetting: false};
            case types.LOAD_SETTINGS_BY_ACRONYM_FAILED:
                return {...state, loadingSetting: false};


            /**  SAVE SETTING CASE */
            case types.SAVE_SETTING:
                return {...state, savingOrUpdating: true};
            case types.SAVE_SETTING_SUCCEEDED:
                return {
                    ...state, items: [...state.items, action.payload],
                    item: action.payload,
                    savingOrUpdating: false
                };
            case types.SAVE_SETTING_FAILED:
                return {...state, savingOrUpdating: false};



            /**  UPDATE SETTING CASE  */

            case types.UPDATE_SETTING:
                return {...state, savingOrUpdating: true};
            case types.UPDATE_SETTING_SUCCEEDED:
                return {
                    ...state,
                    items: state.items.map(item => item.id === action.payload.id ? action.payload : item),
                    item: action.payload,
                    savingOrUpdating:false
                };
            case types.UPDATE_SETTING_FAILED:
                return {...state, savingOrUpdating: false};





            /**  DELETE SETTING CASE  */
            case types.DELETE_SETTING:
                return {...state, deleting: true};
            case types.DELETE_SETTING_SUCCEEDED:
                return (action.payload.status === 200) ?
                    {...state, items: state.items.filter(item => item.id !== action.payload.item.id), deleting: false}
                    : state;
            case types.DELETE_SETTING_FAILED:
                return {...state, deleting: false};


            default:
                return state;
        }
    }
;

export default reducer;
