import React from "react"
import {Field} from "redux-form";
import {renderField} from "../../../../../common/utils/FormComponent";
import {withRouter} from "react-router";

let FormItems = ({item, validate}) => {

	return (
		<div className={`custom_fieldset mt-4`}>

			<div className="row mt-3">
				<div className="col-sm-2">
					<Field className="form-control form-control-sm" id="acronym"
						name="acronym" component={renderField} type="text"
						label="acronym" placeholder="acronym"
						validate={validate.error}/>
				</div>
				<div className="col-sm-3">
					<Field className="form-control form-control-sm" id="description"
						   name="description" component={renderField} type="text"
						   label="description" placeholder="description"
						   validate={validate.error}/>
				</div>
				<div className="col-sm-4">
					<Field className="form-control form-control-sm" id="locality"
						   name="locality" component={renderField} type="text"
						   label="locality" placeholder="locality"
						   validate={validate.error}/>
				</div>
				<div className="col-sm-3">
					<Field className="form-control form-control-sm" id="address"
						   name="no" component={renderField} type="text"
						   label="address" placeholder="address"
						   validate={validate.error}/>
				</div>
			</div>

		</div>

	);
}
export default React.memo(withRouter(FormItems))
