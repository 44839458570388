import {getDateFromDateTime} from "../utils";
import moment from 'moment';

export class Request {

    constructor(params) {
        if (params) {
            const {
                id, phoneId, emailId, addressId, personalInfoId, socialInfoId,
                firstName, lastName, nickname, gender, dob, age, codeLaboratory,
                phone, email, nationality, county, district, municipality, subMunicipality, address,
                testType,symptoms, specimenCenter, type, paymentId, paymentType,uploadFile,
                trxCode, idType, identifierId,currentId
            } = params;

            this.id = id;
            this.codeLaboratory = codeLaboratory
            this.applicant = {

                personalInfos: {id: personalInfoId, firstName, lastName, nickname, gender, dob, age},

                socialInfos: {
                    id: socialInfoId,
                    address: {
                        id: addressId,
                        locality: `${subMunicipality},${municipality},${district},${county}`,
                        no: address
                    },
                    phones: [{id: phoneId, no: phone}],
                    emails: [{id: emailId, current: email}],
                    identifier: {id: identifierId, type: idType , value: currentId},
                    nationality,
                }
            }
            this.payment = {id: paymentId, paymentType, trxCode, file: uploadFile};
            this.specimenCenter = {id: specimenCenter};
            this.symptoms = symptoms === 'yes';
            this.testType = {id: testType};
            this.type = type
        }
    }


}

export class StaffRequestMapper {

    constructor(params) {
        if (params) {

            this.id = params?.id;
            this.personalInfoId = params?.applicant?.personalInfos?.id;
            this.socialInfoId = params?.applicant?.socialInfos?.id;
            this.paymentId = params?.payment?.id;
            this.phoneId = params?.applicant?.socialInfos?.phones[0]?.id;
            this.emailId = params?.applicant?.socialInfos?.emails[0]?.id;
            this.addressId = params?.applicant?.socialInfos?.address?.id;
            this.identifierId = params?.applicant?.socialInfos?.identifier?.id;
            this.idType = params?.applicant?.socialInfos?.identifier?.type;

            this.firstName = params?.applicant?.personalInfos?.firstName;
            this.lastName = params?.applicant?.personalInfos?.lastName;
            this.nickname = params?.applicant?.personalInfos?.nickname;
            this.gender = params?.applicant?.personalInfos?.gender;
            this.dob = params?.applicant?.personalInfos?.dob;
            this.age = params?.applicant?.personalInfos?.age;
            this.maturity = params?.applicant?.personalInfos?.age < 1 ? 'baby' : 'other';


            this.currentId = params?.applicant?.socialInfos?.identifier?.value;
            this.maritalStatus = params?.applicant?.socialInfos?.maritalStatus;
            this.county = params?.applicant?.socialInfos?.address?.locality.split(',')[3];
            this.district = (params?.applicant?.socialInfos?.address?.locality.split(',')[2]);
            this.municipality = params?.applicant?.socialInfos?.address?.locality.split(',')[1];
            this.subMunicipality = params?.applicant?.socialInfos?.address?.locality.split(',')[0];

            this.address = params?.applicant?.socialInfos?.address?.no;
            this.phone = params?.applicant?.socialInfos?.phones[0]?.no;
            this.email = params?.applicant?.socialInfos?.emails[0]?.current;
            this.nationality = params?.applicant?.socialInfos?.nationality;


            this.departure = params?.departure;
            this.appointmentDate = getDateFromDateTime(params?.appointmentDate);
            this.appointmentHour = moment(params?.appointmentDate).format('hh:00');
            this.destination = params?.destination;
            this.symptoms = params?.symptoms ? 'yes' : 'no';
            this.specimenCenter = params?.specimenCenter?.id;
            this.testType = params?.testType?.id;

            this.paymentType = params?.payment?.paymentType;
            this.trxCode = params?.payment?.trxCode;

            this.codeLaboratory = params?.codeLaboratory?.toUpperCase();
            this.result = params?.diagnostic?.result?.status;

        }
    }

}

