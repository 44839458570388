import React, {useEffect, useState} from "react"
import {Field, change} from "redux-form";
import {renderField} from "../../../../../../common/utils/FormComponent";
import {withRouter} from "react-router";
import {useDispatch, useSelector} from "react-redux";
import {FormattedMessage} from "react-intl";
import {DateUtil} from "../../../../../../../constants/utils";

let FormItems = props => {

    const {item,touched, error} = props

    const dispatch = useDispatch()
    const genders = ['MALE', 'FEMALE', 'OTHER']
    const maturities = ['baby', 'other']

    const {values} = useSelector(state => ({
        values: state.form?.requestForm?.values
    }));



    const onChangeDob = date => {
        dispatch(change("requestForm", "age", DateUtil.ageFromDate(date)));
    }

    const onChangeAge = age => {
            dispatch(change("requestForm", "dob", DateUtil.dateFromAge(age)));
    }


    return (
        <div>

            <div className="row">
                <div className="col-sm-5">
                    <Field className="form-control form-control-sm" id="lastname"
                           name="lastName" component={renderField} type="text"
                           label="lastname" placeholder="lastname" required/>
                </div>
                <div className="col-sm-7">
                    <Field className="form-control form-control-sm" id="firstname"
                           name="firstName" component={renderField} type="text"
                           label="firstname" placeholder="firstname" required/>
                </div>


                <div className="row mt-3">
                    <div className="col-sm-7">
                        <div className="form-group">
                            <label className="font-weight-bold">
                                <FormattedMessage id={`app.label.gender`}/>
                                <span className="text-primary"> * </span> :
                            </label>
                            <div className="radio-inline">
                                {genders.map((element, i) =>
                                    <label className="radio radio-lg" key={i}>
                                        <Field type="radio" component="input" name="gender" value={element}/>
                                        <span/>
                                        <FormattedMessage id={`app.label.${element}`}/>
                                    </label>
                                )}
                            </div>
                            {touched && error &&
                            <span className="form-text text-primary">
                            <FormattedMessage id={error}/>
                        </span>}
                        </div>
                    </div>

                    <div className="col-sm-5">
                        <Field className="form-control form-control-sm" id="nickname"
                               name="nickname" component={renderField} type="text"
                               label="nickname" placeholder="nickname" required/>
                    </div>
                </div>


                <div className="row mt-3">

                    <div className="col-sm-4">
                        <div className="form-group">
                            <label className="font-weight-bold">
                                <FormattedMessage id={`app.label.maturity`}/>
                                <span className="text-primary"> * </span> :
                            </label>
                            <div className="radio-inline">
                                {maturities.map((element, i) =>
                                    <label className="radio radio-lg" key={i}>
                                        <Field type="radio" component="input" name="maturity" value={element}/>
                                        <span/>
                                        <FormattedMessage id={`app.label.${element}`}/>
                                    </label>
                                )}
                            </div>
                            {touched && error &&
                            <span className="form-text text-primary">
                            <FormattedMessage id={error}/> Some help text goes here
                        </span>}
                        </div>
                    </div>

                    {values?.maturity === 'other' ?
                        <React.Fragment>
                            <div className="col-sm-6">
                                <Field className="form-control form-control-sm" id="dob" name="dob"
                                       component={renderField} type="date" label="dob" placeholder="dob"
                                       onChange={(e) => onChangeDob(e.target.value)}
                                       required/>
                            </div>
                            <div className="col-sm-2">
                                <Field className="form-control form-control-sm" id="age" name="age"
                                       component={renderField} type="number" label="age"
                                       onChange={(e) => onChangeAge(e.target.value)}
                                       placeholder="age" required/>
                            </div>
                        </React.Fragment>
                        :
                        <div className="col-sm-8">
                            <Field className="form-control form-control-sm" id="month" name="month"
                                   component={renderField} type="number" label="month_for_baby"
                                   placeholder="month_for_baby"/>
                        </div>
                    }
            </div>
            </div>

        </div>

    );
}
export default React.memo(withRouter(FormItems))
