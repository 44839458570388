import React from 'react'
import PageHeader from "../../../../common/report/PageHeader";
import {FormattedMessage} from "react-intl";
import moment from 'moment'
import PageFooter from "../../../../common/report/PageFooter";
import NotificationHeaderHeader from "../../../../common/report/NotificationHeader";
import {ComorbidityMapper, RequestMapper, SymptomsMapper} from "../../../../../../constants/models/request";


const styles = {
    wrapper: {
        width: "100%",
        margin: "0px auto",
        fontFamily: "Helvetica Neue",
        paddingTop: '40px',
        paddingBottom: '40px'

    },

    rowThreeColumn: {
        display: 'flex',
        marginRight: '2px', marginLeft: '3px',
        columnSize: {
            width: '325px'
        },
        columnSizeHeader: {
            width: '500px'
        }
    },
    titleWeight: {fontWeight: 'bold', fontSize: '16px'},
    threeColumnTd: {width: "325px"},
    rowMargin: {marginTop: "150px"},
    textLabel: {fontSize: '15px', marginLeft: '5px', fontWeight: 'normal'},
    textTitle: {fontSize: '16px'},
    headerTitle: {
        background: '#000000',
        color: '#FFFFFF',
        fontSize: '20px'
    }

}
const ContactsDomc = [
    {no: 1}, {no: 2}, {no: 3}, {no: 4}, {no: 5},
]


const Symptom = props => {
    const {item} = props;

    const applicant = item ? new RequestMapper(item) : null;
    const sympts = item ? new SymptomsMapper(item?.notificationSheet) : null;
    const comorbi = item ? new ComorbidityMapper(item?.notificationSheet?.comorbidityHabits) : null;

    console.log('============INIT========================', sympts)


    return (
        <div style={styles.wrapper}>

            <h3 style={{
                fontWeight: "bold",
                marginBottom: "0px",
                marginTop: "15px", ...styles.headerTitle
            }}>SIGNES/ SYMPTOMES ET COMORBIDITÉ</h3>
            <div style={{border: '1px solid #000000'}}>

                <div style={{...styles.rowThreeColumn, marginTop: "40px",}}>
                    <div style={styles.rowThreeColumn.columnSizeHeader}>
                        <h5 style={styles.titleWeight}>
                            LA PERSONNE EST SYMPTOMATIQUE :
                            <span style={styles.textLabel}>
                                <input type='checkbox'
                                       checked={applicant?.symptoms === 'yes' ? true : false}/> Oui</span>
                            <span style={styles.textLabel}>
                                <input type='checkbox'
                                       checked={applicant?.symptoms === 'no' ? true : false}/> Non</span>
                        </h5>

                    </div>

                    <div style={{...styles.rowThreeColumn.columnSize, width: '700px'}}>
                        <h5 style={styles.titleWeight}>
                            SI OUI, DATE DÉBUT SYMPTOMES :<span
                            style={styles.textLabel}>{sympts?.symptomsStartDate}</span>
                        </h5>
                    </div>
                </div>
                <div style={{...styles.rowThreeColumn, marginTop: "20px"}}>

                    <div style={{...styles.rowThreeColumn.columnSize, paddingBottom: '10px'}}>
                        <h5 style={styles.titleWeight}>
                            SIGNES ET SYMPTOMES:</h5>
                    </div>
                </div>

                <div style={{...styles.rowThreeColumn, marginTop: "10px"}}>

                    <div style={{...styles.rowThreeColumn.columnSize}}>
                        <h5 style={styles.titleWeight}>
                            Fièvre :
                            <span style={styles.textLabel}>
                                <input type='checkbox' checked={sympts?.fever === 'yes' ? true : false}/> Oui</span>
                            <span style={styles.textLabel}>
                                <input type='checkbox' checked={sympts?.fever === 'no' ? true : false}/> Oui</span>
                            <span style={styles.textLabel}>
                                <input type='checkbox'
                                       checked={sympts?.fever === 'null' ? true : false}/> Inconnu</span>
                        </h5>
                    </div>

                    <div style={styles.rowThreeColumn.columnSize}>
                        <h5 style={styles.titleWeight}>
                            Courbatures :
                            <span style={styles.textLabel}>
                                <input type='checkbox'
                                       checked={sympts?.curvatures === 'yes' ? true : false}/> Oui</span>
                            <span style={styles.textLabel}>
                                <input type='checkbox' checked={sympts?.curvatures === 'no' ? true : false}/> Oui</span>
                            <span style={styles.textLabel}>
                                <input type='checkbox' checked={sympts?.curvatures === 'null' ? true : false}/> Inconnu</span>
                        </h5>
                    </div>
                    <div style={styles.rowThreeColumn.columnSize}>
                        <h5 style={styles.titleWeight}>
                            Toux :
                            <span style={styles.textLabel}>
                                <input type='checkbox' checked={sympts?.cough === 'yes' ? true : false}/> Oui</span>
                            <span style={styles.textLabel}>
                                <input type='checkbox' checked={sympts?.cough === 'no' ? true : false}/> Oui</span>
                            <span style={styles.textLabel}>
                                <input type='checkbox'
                                       checked={sympts?.cough === 'null' ? true : false}/> Inconnu</span>
                        </h5>
                    </div>

                </div>


                <div style={{...styles.rowThreeColumn, marginTop: "40px"}}>

                    <div style={{...styles.rowThreeColumn.columnSize, width: '550px'}}>
                        <h5 style={styles.titleWeight}>
                            Difficultés Respiratoires :
                            <span style={styles.textLabel}>
                                <input type='checkbox'
                                       checked={sympts?.Loss_of_taste_agueusia === 'yes' ? true : false}/> Oui</span>
                            <span style={styles.textLabel}>
                                <input type='checkbox'
                                       checked={sympts?.Loss_of_taste_agueusia === 'no' ? true : false}/> Oui</span>
                            <span style={styles.textLabel}>
                                <input type='checkbox'
                                       checked={sympts?.Loss_of_taste_agueusia === 'null' ? true : false}/> Inconnu</span>
                        </h5>
                    </div>
                    <div style={styles.rowThreeColumn.columnSize}>
                        <h5 style={styles.titleWeight}>
                            Asthénie :
                            <span style={styles.textLabel}>
                                <input type='checkbox' checked={sympts?.asthenia === 'yes' ? true : false}/> Oui</span>
                            <span style={styles.textLabel}>
                                <input type='checkbox' checked={sympts?.asthenia === 'no' ? true : false}/> Oui</span>
                            <span style={styles.textLabel}>
                                <input type='checkbox'
                                       checked={sympts?.asthenia === 'null' ? true : false}/> Inconnu</span>
                        </h5>
                    </div>

                    <div style={{...styles.rowThreeColumn.columnSize}}>
                        <h5 style={styles.titleWeight}>
                            Diarrhée :
                            <span style={styles.textLabel}>
                                <input type='checkbox' checked={sympts?.diarrhea === 'yes' ? true : false}/> Oui</span>
                            <span style={styles.textLabel}>
                                <input type='checkbox' checked={sympts?.diarrhea === 'no' ? true : false}/> Oui</span>
                            <span style={styles.textLabel}>
                                <input type='checkbox'
                                       checked={sympts?.diarrhea === 'null' ? true : false}/> Inconnu</span>
                        </h5>
                    </div>
                </div>


                <div style={{...styles.rowThreeColumn, marginTop: "40px"}}>

                    <div style={{...styles.rowThreeColumn.columnSize}}>
                        <h5 style={styles.titleWeight}>
                            Céphalée :
                            <span style={styles.textLabel}>
                                <input type='checkbox' checked={sympts?.cephalea === 'yes' ? true : false}/> Oui</span>
                            <span style={styles.textLabel}>
                                <input type='checkbox' checked={sympts?.cephalea === 'no' ? true : false}/> Oui</span>
                            <span style={styles.textLabel}>
                                <input type='checkbox'
                                       checked={sympts?.cephalea === 'null' ? true : false}/> Inconnu</span>
                        </h5>
                    </div>
                    <div style={{...styles.rowThreeColumn.columnSize, width: '600px'}}>
                        <h5 style={styles.titleWeight}>
                            Perte du gout (Agueusie) :
                            <span style={styles.textLabel}>
                                <input type='checkbox'
                                       checked={sympts?.Loss_of_taste_agueusia === 'yes' ? true : false}/> Oui</span>
                            <span style={styles.textLabel}>
                                <input type='checkbox'
                                       checked={sympts?.Loss_of_taste_agueusia === 'no' ? true : false}/> Oui</span>
                            <span style={styles.textLabel}>
                                <input type='checkbox'
                                       checked={sympts?.Loss_of_taste_agueusia === 'null' ? true : false}/> Inconnu</span>
                        </h5>
                    </div>
                    <div style={{...styles.rowThreeColumn.columnSize}}>
                        <h5 style={styles.titleWeight}>
                            Anosmie :
                            <span style={styles.textLabel}>
                                <input type='checkbox' checked={sympts?.anosmia === 'yes' ? true : false}/> Oui</span>
                            <span style={styles.textLabel}>
                                <input type='checkbox' checked={sympts?.anosmia === 'no' ? true : false}/> Oui</span>
                            <span style={styles.textLabel}>
                                <input type='checkbox'
                                       checked={sympts?.anosmia === 'null' ? true : false}/> Inconnu</span>
                        </h5>
                    </div>
                </div>


                <div style={{...styles.rowThreeColumn, marginTop: "40px"}}>
                    <div style={{...styles.rowThreeColumn.columnSize}}>
                        <h5 style={styles.titleWeight}>
                            Autres symptômes :
                            <span style={styles.textLabel}>
                                {sympts?.otherSymptoms}</span>
                        </h5>
                    </div>
                </div>


                <div style={{...styles.rowThreeColumn, marginTop: "20px"}}>

                    <div style={{...styles.rowThreeColumn.columnSize, paddingBottom: '10px'}}>
                        <h5 style={styles.titleWeight}>
                            COMORBIDITÉ & HABITUDES :</h5>
                    </div>
                </div>

                <div style={{...styles.rowThreeColumn, marginTop: "10px"}}>

                    <div style={{...styles.rowThreeColumn.columnSize}}>
                        <h5 style={styles.titleWeight}>
                            <input type='checkbox' checked={comorbi?.hta}/>
                            <span style={styles.textLabel}>HTA</span>
                        </h5>
                    </div>
                    <div style={{...styles.rowThreeColumn.columnSize}}>
                        <h5 style={styles.titleWeight}>
                            <input type='checkbox' checked={comorbi?.respiratory_illnesses}/>
                            <span style={styles.textLabel}>Maladies respiratoires sévères</span>
                        </h5>
                    </div>
                    <div style={{...styles.rowThreeColumn.columnSize}}>
                        <h5 style={styles.titleWeight}>
                            <input type='checkbox' checked={comorbi?.cancer}/>
                            <span style={styles.textLabel}>Cancer</span>
                        </h5>
                    </div>
                    <div style={{...styles.rowThreeColumn.columnSize}}>
                        <h5 style={styles.titleWeight}>
                            <input type='checkbox' checked={comorbi?.hiv}/>
                            <span style={styles.textLabel}>Immunodéficience/VIH </span>
                        </h5>
                    </div>
                    <div style={{...styles.rowThreeColumn.columnSize}}>
                        <h5 style={styles.titleWeight}>
                            <input type='checkbox' checked={comorbi?.diabetes}/>
                            <span style={styles.textLabel}>Diabète </span>
                        </h5>
                    </div>
                </div>

                <div style={{...styles.rowThreeColumn, marginTop: "20px"}}>

                    <div style={{...styles.rowThreeColumn.columnSize}}>
                        <h5 style={styles.titleWeight}>
                            <input type='checkbox' checked={comorbi?.tb}/>
                            <span style={styles.textLabel}>TB</span>
                        </h5>
                    </div>
                    <div style={{...styles.rowThreeColumn.columnSize}}>
                        <h5 style={styles.titleWeight}>
                            <input type='checkbox' checked={comorbi?.Liver_cirrhosis}/>
                            <span style={styles.textLabel}>Cirrhose du foie</span>
                        </h5>
                    </div>
                    <div style={{...styles.rowThreeColumn.columnSize}}>
                        <h5 style={styles.titleWeight}>
                            <input type='checkbox' checked={comorbi?.malnutrition}/>
                            <span style={styles.textLabel}>Malnutrition</span>
                        </h5>
                    </div>
                    <div style={{...styles.rowThreeColumn.columnSize}}>
                        <h5 style={styles.titleWeight}>
                            <input type='checkbox' checked={comorbi?.heart_disease}/>
                            <span style={styles.textLabel}>Maladie Cardio-vasculaire </span>
                        </h5>
                    </div>
                    <div style={{...styles.rowThreeColumn.columnSize}}>
                        <h5 style={styles.titleWeight}>
                            <input type='checkbox' checked={comorbi?.heart_neurologic}/>
                            <span style={styles.textLabel}>Maladies neurologique ou neuromusculaire chroniques </span>
                        </h5>
                    </div>
                </div>

                <div style={{...styles.rowThreeColumn, marginTop: "20px"}}>

                    <div style={{...styles.rowThreeColumn.columnSize}}>
                        <h5 style={styles.titleWeight}>
                            <input type='checkbox' checked={comorbi?.kidney_disease}/>
                            <span style={styles.textLabel}>Maladie rénale </span>
                        </h5>
                    </div>
                    <div style={{...styles.rowThreeColumn.columnSize}}>
                        <h5 style={styles.titleWeight}>
                            <input type='checkbox' checked={comorbi?.obesity_bmi_grt_35}/>
                            <span style={styles.textLabel}>Obésité (IMC>35)</span>
                        </h5>
                    </div>
                    <div style={{...styles.rowThreeColumn.columnSize}}>
                        <h5 style={styles.titleWeight}>
                            <input type='checkbox' checked={comorbi?.allergic}/>
                            <span style={styles.textLabel}>Allergique</span>
                        </h5>
                    </div>
                    <div style={{...styles.rowThreeColumn.columnSize}}>
                        <h5 style={styles.titleWeight}>
                            <input type='checkbox' checked={comorbi?.smoking}/>
                            <span style={styles.textLabel}>Fumeur </span>
                        </h5>
                    </div>
                    <div style={{...styles.rowThreeColumn.columnSize}}>
                        <h5 style={styles.titleWeight}>
                            <input type='checkbox' checked={comorbi?.alcoholic}/>
                            <span style={styles.textLabel}>Alcoolique </span>
                        </h5>
                    </div>
                </div>

                <div style={{...styles.rowThreeColumn, marginTop: "20px"}}>
                    <div style={{...styles.rowThreeColumn.columnSize}}>
                        <h5 style={styles.titleWeight}>
                            <input type='checkbox'/> Autres, préciser
                            <span style={styles.textLabel}>--------------------------------------- </span>
                        </h5>
                    </div>
                </div>

            </div>

        </div>
    )
}

export default Symptom;

