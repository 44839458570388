import React from "react"
import {Field} from "redux-form";
import {renderField} from "../../../../../../common/utils/FormComponent";
import {withRouter} from "react-router";
import SocialItems from "../../core/SocialItems";
import {FormattedMessage} from "react-intl";

let FormItems = props => {
    const {touched, error} = props
    return (
        <div className={`custom_fieldset mt-4`}>

            <div className="row mt-3">
                <div className="col-sm-4 mt-3">
                    <div className="form-group">
                        <label>
                            <FormattedMessage id={`app.label.id_type`}/>
                            <span className="text-primary"> * </span> :
                        </label>
                        <div className="radio-inline">
                            {['CIN', 'NIF'].map((element, i) =>
                                <label className="radio radio-lg" key={i}>
                                    <Field type="radio" component="input" name="idType" value={element}/>
                                    <span/>
                                    <FormattedMessage id={`app.label.${element}`}/>
                                </label>
                            )}
                        </div>
                        {touched && error &&
                        <span className="form-text text-primary">
                            <FormattedMessage id={error}/>
                        </span>}
                    </div>
                </div>
                <div className="col-sm-4 mt-3">
                    <Field className="form-control form-control-sm" id="current_id" name="currentId"
                           component={renderField} type="text" label="current_id"
                           placeholder="current_id" required/>
                </div>
                <SocialItems/>
            </div>
        </div>

    );
}
export default React.memo(withRouter(FormItems))
