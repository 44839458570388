const currencyActionTypes = {

    /**
     * @Author Rikenson JACQUES (The Tiger ^(..)^)
     * RESET ITEMPRICE ACTION TYPES
     * -------------------------------------------
     * */
    RESET_ITEMPRICE: "RESET_ITEMPRICE",

    /**
     * @Author Rikenson JACQUES (The Tiger ^(..)^)
     * LOAD ITEMPRICES ACTION TYPES
     * -------------------------------------------
     * */
    LOAD_ITEMPRICES: "LOAD_ITEMPRICES",
    LOAD_ITEMPRICES_SUCCEEDED: "LOAD_ITEMPRICES_SUCCEEDED",
    LOAD_ITEMPRICES_FAILED: "LOAD_ITEMPRICES_FAILED",


    /**
     * @Author Rikenson JACQUES (The Tiger ^(..)^)
     * LOAD SINGLE ITEMPRICE ACTION TYPES
     * -------------------------------------------
     * */
    LOAD_ITEMPRICE: "LOAD_ITEMPRICE",
    LOAD_ITEMPRICE_SUCCEEDED: "LOAD_ITEMPRICE_SUCCEEDED",
    LOAD_ITEMPRICE_FAILED: "LOAD_ITEMPRICE_FAILED",


    /**
     * @Author Rikenson JACQUES (The Tiger ^(..)^)
     * SAVE ITEMPRICE ACTION TYPES
     * -------------------------------------------
     * */
    SAVE_ITEMPRICE: "SAVE_ITEMPRICE",
    SAVE_ITEMPRICE_SUCCEEDED: "SAVE_ITEMPRICE_SUCCEEDED",
    SAVE_ITEMPRICE_FAILED: "SAVE_ITEMPRICE_FAILED",


    /**
     * @Author Rikenson JACQUES (The Tiger ^(..)^)
     * UPDATE ITEMPRICE ACTION TYPES
     * -------------------------------------------
     * */
    UPDATE_ITEMPRICE: "UPDATE_ITEMPRICE",
    UPDATE_ITEMPRICE_SUCCEEDED: "UPDATE_ITEMPRICE_SUCCEEDED",
    UPDATE_ITEMPRICE_FAILED: "UPDATE_ITEMPRICE_FAILED",


    /**
     * @Author Rikenson JACQUES (The Tiger ^(..)^)
     * DELETE ITEMPRICE ACTION TYPES
     * -------------------------------------------
     * */
    DELETE_ITEMPRICE: "DELETE_ITEMPRICE",
    DELETE_ITEMPRICE_SUCCEEDED: "DELETE_ITEMPRICE_SUCCEEDED",
    DELETE_ITEMPRICE_FAILED: "DELETE_ITEMPRICE_FAILED",


};


export default currencyActionTypes;
