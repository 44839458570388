import * as React from "react";
import useDocumentTitle from "../../common/hooks/useDocumentTitle";
import {getIntlMsg} from "../../../constants/utils";
import {FormattedMessage, injectIntl} from "react-intl";
import {useParams, withRouter} from "react-router";
import {reduxForm} from "redux-form";
import {connect, useDispatch,} from "react-redux";
import {validate} from "./validator";
import BillingForm from "./base/billing";
import CategoryPageManager from "./refeTabs/category";
import CurrencyPageManager from "./refeTabs/currency";
import IndicatorPageManager from "./refeTabs/indicator";
import ItemPricePageManager from "./refeTabs/itemprice";
import {InstitutionMapper} from "../../../constants/models/institution";
import ParamsForm from "./base/params";


const SettingsManager = injectIntl((props) => {

    const {intl} = props;
    const dispatch = useDispatch();
    const {page, secondLevelPage} = useParams();
    useDocumentTitle(getIntlMsg(intl, `app.label.${page}`));


    const tabIds = [
        {id: "#params", label: "global_settings", icon: "flaticon2-list", status: "active", subItems: []},
        {id: "#billing", label: "billing", icon: "flaticon2-list", status: "", subItems: []},
        {
            id: "#investigator",
            label: "references",
            icon: "flaticon2-menu-2",
            status: "",
            subItems: [
                {id: "#category", label: "categories", icon: "flaticon2-cube-1", status: "", isDivided: false},
                {id: "#spcenter", label: "specimen_centers", icon: "flaticon2-pin-1", status: "", isDivided: false},
                {id: "#currency", label: "currencies", icon: "flaticon2-contrast", status: "", isDivided: false},
                {id: "#itemprice", label: "item_prices", icon: "flaticon2-tag", status: "", isDivided: false},
            ]
        },
        {
            id: "#dataimport",
            label: "importation",
            icon: "flaticon2-menu-2",
            status: "",
            subItems: [
                {id: "#testindicator", label: "test_indicators", icon: "flaticon2-pin-1", status: "", isDivided: false},
            ]
        }
    ]

    const tabs = [
        <BillingForm key={0}/>,
        <ParamsForm key={1}/>,
        <CategoryPageManager key={2}/>,
        // <SpecimenCenterPageManager key={3}/>,
        <CurrencyPageManager key={4}/>,
        <ItemPricePageManager key={5}/>,
        <IndicatorPageManager key={5}/>,
    ];


    return (
        <div className="d-flex flex-column-fluid mt-10">
            <div className="container">
                {/*<PersonalForm/>*/}
                <div className="card card-custom">
                    <div className="card-header card-header-tabs-line">
                        <div className="card-toolbar">
                            <ul className="nav nav-tabs nav-bold nav-tabs-line">
                                {tabIds.map((item, i) =>
                                    <li className={`nav-item ${item?.subItems.length > 0 && 'dropdown'}`} key={i}>

                                        {item?.subItems.length <= 0 ?
                                            <a className={`nav-link ${item.status}`} data-toggle="tab" href={item.id}>
                                            <span className="nav-icon">
                                                <i className={item?.icon}/>
                                            </span>
                                                <span className="nav-text">
                                                <FormattedMessage id={`app.label.${item?.label}`}/>
                                            </span>
                                            </a>
                                            :

                                            <a className="nav-link dropdown-toggle" data-toggle="dropdown" href="#"
                                               role="button" aria-haspopup="true" aria-expanded="false">
                                           <span className="nav-icon">
                                                <i className={item?.icon}/>
                                            </span>
                                                <span className="nav-text">
                                                <FormattedMessage id={`app.label.${item?.label}`}/>
                                            </span>
                                            </a>
                                        }

                                        <div className="dropdown-menu">
                                            {item?.subItems?.map((element, j) =>
                                                <React.Fragment key={j}>
                                                    <a className="dropdown-item" data-toggle="tab" href={element?.id}>
                                                      <span className="nav-icon">
                                                        <i className={element?.icon}/>
                                                      </span>
                                                        <span className="nav-text ml-3">
                                                          <FormattedMessage id={`app.label.${element?.label}`}/>
                                                      </span>
                                                    </a>
                                                    {element?.isDivided && <div className="dropdown-divider"/>}
                                                </React.Fragment>
                                            )}
                                        </div>

                                    </li>
                                )}
                            </ul>
                        </div>
                    </div>
                    <div className="card-body">
                        <div className="tab-content">
                            {tabs.map((item, i) => item)}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
});


const mapStateToProps = ({settingsReducer: {setting}}) => {

    const defaultState = {}
    const loaded = setting ? new InstitutionMapper(setting) : {};

    return {
        initialValues: loaded ? {...loaded} : {...defaultState}
    }
};

export default React.memo(withRouter(connect(mapStateToProps,)(reduxForm({
    form: "setting",
    enableReinitialize: true,
    validate,
})(SettingsManager))));

