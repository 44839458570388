import * as React from "react";
import useDocumentTitle from "../../../common/hooks/useDocumentTitle";
import {getIntlMsg} from "../../../../constants/utils";
import {FormattedMessage, injectIntl} from "react-intl";
import {useParams, withRouter} from "react-router";
import {reduxForm} from "redux-form";
import {connect, useDispatch, useSelector} from "react-redux";
import {validate} from "./validator";
import SubHeader from "../../common/subheader";
import SymptomForm from "./symptom";
import TravelForm from "./travel";
import InvestigatorForm from "./investigator";
import DiagnosticForm from "./diagnostic";
import DemographicForm from "./demographic";
import {getPatientValues} from "../../../../constants/options/subheaderValues";
import {useContext, useEffect} from "react";
import actions from "../../../../thunks/common/coreThunks";
import PersonalInfosForm from "./core/personal";
import {GlobalContext} from "../../../index";


const NotificationForm = injectIntl((props) => {

    const {intl, setView, view} = props;
    const dispatch = useDispatch();
    const {page, secondLevelPage} = useParams();
    useDocumentTitle(getIntlMsg(intl, `app.label.${page}`));

    const options = {intl, options: ['requests'], item: {id: secondLevelPage}}
    const {userSession} = useContext(GlobalContext);


    useEffect(() => {
        dispatch(actions.loadItem(options))
    }, [secondLevelPage]);


    const {item, loading, savingOrUpdating, status} = useSelector(state => ({
        loading: state.requestReducer.loadingItem,
        item: state.requestReducer.item,
        savingOrUpdating: state.requestReducer.savingOrUpdating,
    }));


    const tabIds = [
        {id: "#personal", label: "personal_infos", icon: "flaticon2-chat-1", status: "active"},
        {id: "#symptom", label: "symptoms_signs", icon: "flaticon2-chat-1", status: ""},
        {id: "#travel", label: "travel_history", icon: "flaticon2-chat-1", status: ""},
        {id: "#diagnostic", label: "diagnostic", icon: "flaticon2-chat-1", status: ""},
        {id: "#investigator", label: "investigator", icon: "flaticon2-chat-1", status: ""}
    ]

    const tabs = [
        <PersonalInfosForm key={0} saveOrUpdateItem={actions.saveOrUpdateItem}/>,
        <SymptomForm key={1} saveOrUpdateItem={actions.saveOrUpdateItem} item={item}/>,
        <TravelForm key={2} saveOrUpdateItem={actions.saveOrUpdateItem}/>,
        <DiagnosticForm key={3} saveOrUpdateItem={actions.saveOrUpdateItem}/>,
        <InvestigatorForm key={4} userSession={userSession} saveOrUpdateItem={actions.saveOrUpdateItem}/>
    ];


    return (
        <React.Fragment>
            <SubHeader {...{changeView: setView, view, btnOptions: getPatientValues}} noButton/>
            <div className="d-flex flex-column-fluid">
                <div className="container">
                    <div className="card card-custom">
                        <div className="card-header card-header-tabs-line">
                            <div className="card-toolbar">
                                <ul className="nav nav-tabs nav-bold nav-tabs-line">
                                    {tabIds.map((v, i) => {
                                            if (v.label === 'diagnostic' && item?.diagnostic !== null) {
                                                return (
                                                    <li className="nav-item" key={i}>
                                                        <a className={`nav-link ${v.status}`} data-toggle="tab" href={v.id}>
                                                    <span className="nav-icon">
                                                        <i className={v.icon}/>
                                                    </span>
                                                            <span className="nav-text">
                                                        <FormattedMessage id={`app.label.${v.label}`}/>
                                                    </span>
                                                        </a>
                                                    </li>)
                                            } else if (v.label !== 'diagnostic') {
                                                return (
                                                    <li className="nav-item" key={i}>
                                                        <a className={`nav-link ${v.status}`} data-toggle="tab" href={v.id}>
                                                    <span className="nav-icon">
                                                        <i className={v.icon}/>
                                                    </span>
                                                            <span className="nav-text">
                                                        <FormattedMessage id={`app.label.${v.label}`}/>
                                                    </span>
                                                        </a>
                                                    </li>)
                                            }
                                        }
                                    )}
                                </ul>
                            </div>
                        </div>
                        <div className="card-body">
                            <div className="tab-content">
                                {tabs.map((item, i) => item)}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
});


export default React.memo(NotificationForm);

