import * as validator from "../../../../../constants/utils/validator";


export const validate = values => {
    const errors = {};
    errors.areaCode = validator.validateString('area_code', values.areaCode, 3, 8, true);
    // errors.full_name = validator.validateString('covid_contact', values.first_name, 3, 150, true);
    return errors
};


