import React from "react"
import {injectIntl} from "react-intl";


const NotificationHeader = injectIntl(props => {

    const {zlOnly} = props
    const logos = [
        {
            src: '/assets/media/logos/mspp.png',
            alt: 'MSPP',
            css: {
                width: `120px`,
                marginTop: "30px",
                marginLeft: "70px",
                display: `${zlOnly ? 'none' : 'block'}`,
                float:'left'
            },
            logo: {width: "90px"},
            img: {width: "90px"}
        },
        {
            src: '/assets/media/logos/zl_logo.png',
            alt: 'ZL',
            css: {
                width: "200px",
                marginTop: "30px",
                marginRight: "50px",
                marginLeft:`${zlOnly ? '10px' : '0'}`,
                float: 'right'
            },
            logo: {width: "120px"},
            img: {width: "120px"}
        }
    ]

    const styles = {
        headerWrapper: {
            width: "100%",
            margin: "10px auto",
            position: "relative",
            float:'left'
        },
        subheaderWrapper: {
            width: "100%",
            margin: "0 auto",
            textAlign: "center",
            fontSize:"16px",
            fontFamily: "Helvetica Neue"
        },
        headerStyle:{
            border:'1px solid #000000',
            background:'#9e9b9b',
            color:'#1c1b1b',
            textAlign: 'center',
            width:'200px',
            margin:'auto',
            padding:'10px',
            fontWeight:'bold',
            fontSize: '18px'

        }


    }

    return (
        <div className="report_header">
            <div>
                <h2 style={styles.headerStyle}>COVID-19</h2>
            </div>

            <div style={styles.headerWrapper}>
                {logos.map((logo, i) =>
                    <div key={i} style={logo.css}>
                        <img src={logo.src} alt={logo.alt} style={logo.img}/>
                    </div>
                )}
            </div>
            <h3 style={styles.subheaderWrapper}>
                <h3 style={{fontWeight:"bold",fontSize:'24px'}}>MINISTERE DE LA SANTE PUBLIQUE ET DE LA POPULATION</h3>
                <div><h3 style={{fontWeight:"bold",fontSize:'19px'}}>DIRECTION D’EPIDEMIOLOGIE, DES LABORATOIRES ET DE LA RECHERCHE (DELR)</h3></div>
                <div><h3 style={{fontWeight:"bold",fontSize:'19px'}}>Fiche de Surveillance de COVID-19</h3></div>
                <hr/>
            </h3>

        </div>
    )
});


export default NotificationHeader

