import * as React from "react";
import {useParams, withRouter} from "react-router";
import {FormattedMessage, injectIntl} from "react-intl";
import {Link} from "react-router-dom";
import {getIntlMsg} from "../../../../constants/utils";
import {useContext} from "react";
import {GlobalContext} from "../../../index";
import {canDisplay} from "../../../../constants/utils/security";
import AsyncInputSearch from "../../../common/utils/AsyncInputSearch";


const ListSubHeader = injectIntl(props => {

    const {intl, items, btnOptions} = props
    const {title, btnTitle, icon, paths, flatUrl, hasChildren} = btnOptions;
    const {userSession: {roles}, institution:{acronym}} = useContext(GlobalContext);
    const {module, page} = useParams();



    const options = {options: [module, 'status', page]};


    const currentView = page === 'notification' ? 'notification-form' : page === 'reception' ? 'registration' : '';


    return (
        <div className="subheader py-6 py-lg-8 subheader-transparent" id="kt_subheader">
            <div
                className="container d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap">

                <div className="d-flex align-items-center flex-wrap mr-2">
                    <h5 className="text-dark font-weight-bold mt-2 mb-2 mr-5">
                        <FormattedMessage id={`app.label.${page}_list`}/>
                    </h5>
                    <div className="subheader-separator subheader-separator-ver mt-2 mb-2 mr-5 bg-gray-300"/>
                    <div className="d-flex align-items-center" id="kt_subheader_search">
                        <span className="text-dark-50 font-weight-bold" id="kt_subheader_total">
                            {items?.length || 0}{" "}
                            <FormattedMessage id={`app.label.total`}/>
                        </span>
                        <form className="ml-5">
                            <div className="input-group input-group-sm bg-white border-0 rounded min-w-175px">
                                {<AsyncInputSearch {...{options}}/>}
                            </div>
                        </form>
                    </div>
                </div>


                {flatUrl === null && (page === 'reception' || page === 'notification')?
                    canDisplay(roles, btnTitle) &&
                    <div className="d-flex align-items-center flex-wrap">
                        <div title={getIntlMsg(intl, `app.label.${btnTitle}_options`)}
                             data-placement="top">
                            <Link to={`/${acronym}/${module}/${currentView}`}
                                  className="btn btn-fixed-height btn-primary font-weight-bolder font-size-sm px-5 my-1">
                                <span className="svg-icon svg-icon-md"> {icon}</span>
                                <FormattedMessage id={`app.label.${btnTitle}`}/>
                            </Link>
                        </div>
                    </div>
                    : (page === 'reception' || page === 'notification') ?

                    <div className="d-flex align-items-center flex-wrap">
                        {canDisplay(roles, btnTitle) &&
                        <div className="dropdown dropdown-inline" data-toggle="tooltip" data-placement="top">
                            <Link to={flatUrl || ''}
                                  className="btn btn-fixed-height btn-primary font-weight-bolder font-size-sm px-5 my-1"
                                  data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                <span className="svg-icon svg-icon-md"> {icon}</span>
                                <FormattedMessage id={`app.label.${btnTitle}`}/>
                            </Link>


                            {hasChildren &&
                            <div className="dropdown-menu dropdown-menu-md dropdown-menu-right p-0 m-0">


                                <ul className="navi navi-hover">
                                    {paths.map((path, i) =>
                                        <li className="navi-item" key={i}>
                                            <Link to={path.url} className="navi-link">
                                                <span className="material-icons mr-4">{path.icon}</span>
                                            <span className="navi-text">
                                                <span className="">
                                                    <FormattedMessage id={`app.label.${path.label}`}/>
                                                </span>
                                            </span>
                                            </Link>
                                        </li>
                                    )}
                                </ul>
                            </div>}
                        </div>}
                    </div> : null
                }
            </div>
        </div>
    )
})

export default React.memo(withRouter(ListSubHeader))

