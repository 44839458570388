import * as React from "react";
import useDocumentTitle from "../../../../../common/hooks/useDocumentTitle";
import {getIntlMsg, nameAcronym} from "../../../../../../constants/utils";
import {FormattedMessage, injectIntl} from "react-intl";
import {useParams, withRouter} from "react-router";
import {Link} from "react-router-dom";
import {useContext} from "react";
import {GlobalContext} from "../../../../../index";


const Item = injectIntl((props) => {

    const {intl, applicant, payment, specimenCenter, state, symptoms, destination, departure, id} = props;
    const {page} = useParams();

    const {institution:{acronym}} = useContext(GlobalContext);
    useDocumentTitle(getIntlMsg(intl, `app.label.${page}`));

    return (
        <div className="card card-custom gutter-b">
            <div className="card-body">
                <div className="d-flex">
                    <div className="flex-shrink-0 mr-7">
                        <div className="symbol symbol-50 symbol-lg-120 symbol-circle symbol-light-primary">
                            <span className="font-size-h3 symbol-label font-weight-boldest">
                                {nameAcronym(applicant.firstName, applicant.lastName)}
                            </span>
                        </div>
                    </div>

                    <div className="flex-grow-1">
                        <div className="d-flex align-items-center justify-content-between flex-wrap mt-2">
                            <div className="mr-3">
                                <a href="#"
                                   className="d-flex align-items-center text-dark text-hover-primary font-size-h5 font-weight-bold mr-3">
                                    {`${applicant.firstName} ${applicant.lastName.toUpperCase()}`}
                                </a>
                                <div className="d-flex flex-wrap my-2">

                                    <a href="#"
                                       className="text-muted text-hover-primary font-weight-bold mr-lg-8 mr-5 mb-lg-0 mb-2">
                                        <span className="svg-icon svg-icon-md svg-icon-gray-500 mr-1">
                                            <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                                <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                                    <rect x="0" y="0" width="24" height="24"/>
                                                    <path
                                                        d="M21,12.0829584 C20.6747915,12.0283988 20.3407122,12 20,12 C16.6862915,12 14,14.6862915 14,18 C14,18.3407122 14.0283988,18.6747915 14.0829584,19 L5,19 C3.8954305,19 3,18.1045695 3,17 L3,8 C3,6.8954305 3.8954305,6 5,6 L19,6 C20.1045695,6 21,6.8954305 21,8 L21,12.0829584 Z M18.1444251,7.83964668 L12,11.1481833 L5.85557487,7.83964668 C5.4908718,7.6432681 5.03602525,7.77972206 4.83964668,8.14442513 C4.6432681,8.5091282 4.77972206,8.96397475 5.14442513,9.16035332 L11.6444251,12.6603533 C11.8664074,12.7798822 12.1335926,12.7798822 12.3555749,12.6603533 L18.8555749,9.16035332 C19.2202779,8.96397475 19.3567319,8.5091282 19.1603533,8.14442513 C18.9639747,7.77972206 18.5091282,7.6432681 18.1444251,7.83964668 Z"
                                                        fill="#000000"/>
                                                          <circle fill="#000000" opacity="0.3" cx="19.5" cy="17.5"
                                                                  r="2.5"/>
                                                </g>
                                            </svg>
                                        </span>
                                        {applicant?.socialInfos?.email || ''}
                                    </a>

                                    <a href="#"
                                       className="text-muted text-hover-primary font-weight-bold mr-lg-8 mr-5 mb-lg-0 mb-2">
                                        <span className="svg-icon svg-icon-md svg-icon-gray-500 mr-1">
                                            <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                                <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                                    <rect x="0" y="0" width="24" height="24"/>
                                                    <path
                                                        d="M21,12.0829584 C20.6747915,12.0283988 20.3407122,12 20,12 C16.6862915,12 14,14.6862915 14,18 C14,18.3407122 14.0283988,18.6747915 14.0829584,19 L5,19 C3.8954305,19 3,18.1045695 3,17 L3,8 C3,6.8954305 3.8954305,6 5,6 L19,6 C20.1045695,6 21,6.8954305 21,8 L21,12.0829584 Z M18.1444251,7.83964668 L12,11.1481833 L5.85557487,7.83964668 C5.4908718,7.6432681 5.03602525,7.77972206 4.83964668,8.14442513 C4.6432681,8.5091282 4.77972206,8.96397475 5.14442513,9.16035332 L11.6444251,12.6603533 C11.8664074,12.7798822 12.1335926,12.7798822 12.3555749,12.6603533 L18.8555749,9.16035332 C19.2202779,8.96397475 19.3567319,8.5091282 19.1603533,8.14442513 C18.9639747,7.77972206 18.5091282,7.6432681 18.1444251,7.83964668 Z"
                                                        fill="#000000"/>
                                                          <circle fill="#000000" opacity="0.3" cx="19.5" cy="17.5"
                                                                  r="2.5"/>
                                                </g>
                                            </svg>
                                        </span>
                                        {`${applicant?.socialInfos?.address?.no || ''}, ${applicant?.socialInfos?.address?.locality || ''}`}
                                    </a>


                                </div>
                            </div>
                            <div className="my-lg-0 my-1">
                                <Link to={`/${acronym}/requests/observations/${id}`} className="btn btn-sm btn-outline-primary font-weight-bolder mr-3">
                                    <FormattedMessage id={`app.label.follow_up`}/>
                                </Link>
                                <a href="#" className="btn btn-sm btn-success font-weight-bolder">
                                    <FormattedMessage id={`app.label.ping_applicant`}/>
                                </a>
                            </div>
                        </div>
                        <div className="d-flex align-items-center flex-wrap justify-content-between">
                            <div className="flex-grow-1 font-weight-bold text-dark-50 py-2 py-lg-2 mr-5">Sed ut
                                perspiciatis unde omnis iste natus error sit voluptatem.
                                <br/>Quis autem vel eum iure reprehenderit qui in.
                            </div>
                            <div className="d-flex mt-4 mt-sm-0">
                                <span className="font-weight-bold mr-4">
                                    <FormattedMessage id={`app.label.progress_level`}/>
                                </span>
                                <div className="progress progress-xs mt-2 mb-2 flex-shrink-0 w-150px w-xl-250px">
                                    <div className="progress-bar bg-success" role="progressbar" style={{width: "100%"}}
                                         aria-valuenow="50" aria-valuemin="0" aria-valuemax="100"/>
                                </div>
                                <span className="font-weight-bolder text-dark ml-4">100%</span>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>

    );
});


export default React.memo(withRouter(Item))
