import * as React from "react";
import {useContext, useState} from "react";
import {FormattedMessage, injectIntl} from "react-intl";
import {useParams, withRouter} from "react-router";
import {connect, useDispatch} from "react-redux";
import useDocumentTitle from "../../../../../../common/hooks/useDocumentTitle";
import {getIntlMsg} from "../../../../../../../constants/utils";
import {GlobalContext} from "../../../../../../index";
import {format} from "../../../../../../../constants/options/indicatorUtils";
import actions from "../../../../../../../thunks/request/thunks";
import {Modal} from "react-bootstrap";
import ModalFormFooter from "../../../../../../common/view/ModalFormFooter";
import {reduxForm} from "redux-form";
import ListViewer from "./ListViewer";
import UploadCSV from "../../../../../../common/utils/csvuploader";




const TestIndicatorUploaderModal = injectIntl((props) => {
    const {intl, handleSubmit, pristine, valid, submitting, reset} = props;
    const {page} = useParams();
    useDocumentTitle(getIntlMsg(intl, `app.label.${page}`));

    const {hideModal, modalIsOpen} = useContext(GlobalContext);
    const dispatch = useDispatch();

    const [data, setData] = useState([]);


    const onFormSubmit = element => {

        console.log(data)
        dispatch(actions.saveOrUpdateItem({options: ['indicators', 'upload'], intl, item: data}))
    };

    return (

        <Modal show={modalIsOpen.name === "indicator" ? modalIsOpen.open : false}
               onHide={() => hideModal('indicator')} size="lg" aria-labelledby="contained-modal-title-vcenter"
               centered>
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    <div className="row">
                        <div className="col-sm-7">
                            <h5 className="text-black-50 mb-5 mt-4">
                                <FormattedMessage id="app.label.all_test_indicators"/>
                            </h5>
                        </div>
                        <div className="col-sm-4">
                            <UploadCSV {...{setData, format}}/>
                        </div>
                    </div>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <form onSubmit={handleSubmit(onFormSubmit)}>
                    <div>
                        <ListViewer {...{data,setData, format}}/>
                        <ModalFormFooter {...{
                            loading: false, item: {}, pristine, valid, submitting, hideModal,
                            name: 'indicatorForm',
                            componentName: 'results',
                            moduleName: "indicator"
                        }}/>
                    </div>
                </form>

            </Modal.Body>

        </Modal>
    );
});

const mapStateToProps = ({currencyReducer: {item}}) => {
    const defaultState = {}
    return {
        initialValues: item !== null ? {...item} : {...defaultState}
    }
};

export default React.memo(withRouter(connect(mapStateToProps,)(reduxForm({
    form: "indicatorForm",
    enableReinitialize: true,
})(TestIndicatorUploaderModal))));































