import React from "react"
import {FormattedMessage} from "react-intl";


const Spinner = props => {

    const {status = 'primary', customStyle} = props

    return  <div style={customStyle}>
        <FormattedMessage id="app.label.loading"/>
    </div>
};


export default Spinner

