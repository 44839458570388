import React, {useEffect} from "react"
import {Field} from "redux-form";
import {FormattedMessage} from 'react-intl';

import actions from "../../../../../../thunks/settings/categoryThunks";
import {renderCheckbox, renderField, renderSelect} from "../../../../../common/utils/FormComponent";
import {withRouter} from "react-router";
import {useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";

let FormItems = props => {

    const {item, valid, validate, loading, pristine, submitting} = props
    const dispatch = useDispatch();
    const {id} = useParams();
    const options = {options: ['categories', 'names']};

    useEffect(() => {
        dispatch(actions.loadItems(options));
    }, [dispatch]);

    const categories = useSelector(state => state.categoryReducer.lovItems);
    const formattedCats = [];
    categories.forEach(category => {
        formattedCats.push({code: category, name: category})
    })


    return (
        <div className="bg-warning-o-20 p-5 mb-5">

            <div className="row">
                <div className="col-sm-12">
                    <h5 className="text-black-50 mb-5">
                        <FormattedMessage id="app.label.category_form"/>
                    </h5>
                    <hr/>
                </div>
            </div>

            <div className="row mt-3">

                <div className="col-sm-3">
                    <Field style="pl-4 pr-4" id="name" name="name" component={renderField}
                           type="text" label="name" placeholder="name" validate={validate.error}/>
                </div>

                <div className="col-sm-4">
                    <Field style="pl-4 pr-4" id="description" name="description"
                           component={renderField} type="text" label="description" placeholder="description"
                           validate={validate.error}/>
                </div>

                <div className="col-sm-2">
                    <Field id="entity_name" name="entityName"
                           component={renderSelect} label="entity_name" validate={validate.error}
                           items={formattedCats}/>
                </div>

                <div className="col-sm-1 mt-12">

                    <div className="form-group">
                        <div className="checkbox-inline">

                            <label className="checkbox checkbox-square">
                                <Field type="checkbox" component="input" name="status"/>
                                <span/>
                                <FormattedMessage id={`app.label.status`}/>
                            </label>

                        </div>
                    </div>


                </div>
                {valid && <div className="col-sm-2 mt-8">
                    <button type="submit"
                            className={`btn  btn-outline-warning btn-sm ${loading && 'spinner spinner-white spinner-right mr-3'}`}>
                        < FormattedMessage id={(loading && item && item.id) ? "app.label.updating"
                            : (loading && item && !item.id) ? "app.label.saving"
                                : (!loading && item && item.id) ? "app.label.update" : "app.label.save"}
                                           disabled={pristine || submitting}
                                           defaultMessage={`Save`}/>
                    </button>
                </div>}

            </div>

        </div>

    );
}
export default React.memo(withRouter(FormItems))
