import React from "react"
import {Field} from "redux-form";
import {FormattedMessage, injectIntl} from 'react-intl';
import {renderCheckbox, renderField, renderRadio, renderSelect} from "../../../../../common/utils/FormComponent";
import {withRouter} from "react-router";
import {getIntlMsg} from "../../../../../../constants/utils";

let renderEmail = injectIntl(props => {
    const {intl, fields, meta: {error}} = props


    return (
        <React.Fragment>
            <div className="row">
                <div className="mb-5">
                    <h5><FormattedMessage id={`app.label.emails`}/></h5>
                    {fields.length < 3 &&
                    <span className="btn btn-outline-primary btn-sm" onClick={() => fields.push()}>
                    <FormattedMessage id={`app.label.click_to_add_new_email`}/>
                </span>
                    }
                </div>
            </div>
            <div className="row">

                {fields.map((email, index) => (
                    <div className="col-sm-4" key={index}>

                        <div className="row">

                            <div className="col-sm-10">
                                <Field className="form-control form-control-sm" id="email" name={`${email}.current`}
                                       component={renderField} type="email" label="email"
                                       placeholder="email"/>
                            </div>

                            <div className="col-sm-1">
                                <button type="button" title={getIntlMsg(intl, 'app.label.remove_email')}
                                        className="btn btn-icon btn-light-primary btn-circle mr-2 mt-8"
                                        onClick={() => fields.remove(index)}>
                                    <i className="flaticon2-trash"/>
                                </button>
                            </div>
                        </div>
                    </div>
                ))}
                {error && <li className="error">{error}</li>}
            </div>
        </React.Fragment>
    );
})
export default React.memo(withRouter(renderEmail))
