import * as React from "react";

export function format(indicators) {

    const formattedArray = [];
    indicators.forEach(indicator => {

        let site, name;
        for (const [fKey, fVal] of Object.entries(indicator)) {

            if (fKey === 'site')
                site = {site: fVal}
            if (fKey === 'indicator')
                name = {name: fVal}
            if (fKey !== 'site' && fKey !== 'indicator')
                formattedArray.push({
                    ...site, ...name,
                    collectDate: new Date(fKey).toLocaleDateString('fr-CA'),
                    quantity: fVal
                })
        }
    })

    return formattedArray;

}