import moment from 'moment'
import {Diagnostic} from './diagnostic'
export class Result {

    constructor(params) {
        this.publishedDate = new Date(params?.resultDate)
        this.status = params?.result
        this.remark = params?.remark
    }


}


export const getUpdatedDiagnostic =(params, item) =>{
    return {...new Diagnostic(params), result:new  Result(params), id: item.id}
}




export class ResultMapper {

    constructor(params) {
        if(params){
            this.id = params.id;
            this.result = params?.diagnostic?.result?.status
            this.resultDate = moment(params?.diagnostic?.result?.publishedDate).format('yyyy-MM-DD')
            this.remark = params?.diagnostic?.result?.remark
        }
    }
}
