import React from "react"
import {withRouter} from "react-router";
import AsyncInputSearch from "../../../../common/utils/AsyncInputSearch";
import CheckBoxFilter from "../../../../common/utils/CheckBoxFilter";
import {injectIntl} from "react-intl";
import {getIntlMsg} from "../../../../../constants/utils";
import actions from "../../../../../thunks/common/coreThunks";
import {useDispatch} from "react-redux";
import SearchbarButton from "../../../common/buttons/SearchbarButton";

let Searchbar = injectIntl(props => {


    const dispatch = useDispatch();
    const {intl, institutionFormView, setInstitutionFormView, options} = props
    const criteria = {options: ['institutions', 'names']};
    const resetCriteria = {options: ['institution']};


    return (
        <div className="mb-7 bg-danger-o-20 pt-5 pb-5 pl-5 pr-5">
            <div className="row align-items-center">

                <div className="col-sm-5">
                    <AsyncInputSearch {...{options}}/>
                </div>

                <div className="col-sm-3">
                    <CheckBoxFilter {...{criteria}} i18nable={true}/>
                </div>

                <div className="col-sm-3">
                    <CheckBoxFilter {...{criteria}} i18nable={true}/>
                </div>

                <div className="col-sm-1">
                    <span onClick={() => {
                        setInstitutionFormView(!institutionFormView)
                        dispatch(actions.resetItem(resetCriteria))
                    }}
                          className="px-6 font-weight-bold btn"
                          title={getIntlMsg(intl, `app.label.${institutionFormView ? 'cancel' : 'new'}`)}>
                        <SearchbarButton formAction={institutionFormView}/>
                    </span>
                </div>

            </div>
        </div>

    );
})
export default React.memo(withRouter(Searchbar))
