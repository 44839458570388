import React from 'react'


const styles = {
    wrapper: {
        width: "600px",
        margin: "30px auto"
    },

    tagLabel: {
        fontSize: "35px",
        fontWeight: "bold",
        marginTop:"60px",
        border:"2px dashed #cecece",
        padding: "50px",
        textAlign: "center"
    }
}


class SpecimenTag extends React.Component {


    render() {
        const {item} = this.props;
        return (

            <div style={styles.wrapper}>
                <h1 style={styles.tagLabel}>{item.codeLaboratory.toUpperCase()}</h1>
                <h1 style={styles.tagLabel}>{item.codeLaboratory.toUpperCase()}</h1>
                <h1 style={styles.tagLabel}>{item.codeLaboratory.toUpperCase()}</h1>
                <h1 style={styles.tagLabel}>{item.codeLaboratory.toUpperCase()}</h1>
            </div>
        )
    }

}

export default SpecimenTag;

