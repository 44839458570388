import * as React from "react";
import {useRef} from "react";
import {FormattedMessage, injectIntl} from "react-intl";
import {withRouter} from "react-router";
import {getIntlMsg} from "../../../../../../../constants/utils";
import ChangeStateModal from "../../../../../common/modal/ChangeState";
import {useReactToPrint} from "react-to-print";
import RequestForm from "../../../reports/traveler/RequestForm";


const UnfinishedBtn = injectIntl(props => {

    const {intl, item, handleStateChange} = props;
    const {id, lastState} = item
    const receiptRef = useRef();


    const handlePrintReceipt = useReactToPrint({
        content: () => receiptRef.current,
    });

    return (

        item ? <div className="btn-group btn-group-sm" role="group" aria-label="Basic example">

            <div className="btn-group btn-group-sm" role="group">
                <button id="btnGroupDrop1" type="button"
                        className="btn btn-light-primary dropdown-toggle"
                        data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    <FormattedMessage id={`app.label.restore_to`}/>
                </button>

                <div className="dropdown-menu" aria-labelledby="btnGroupDrop1">
                    <span className="dropdown-item cursor-pointer"
                          data-toggle="modal" data-target={`#${lastState && lastState}${id}`}
                          title={getIntlMsg(intl, `app.label.send_to_${lastState && lastState.toLowerCase()}`)}>
                        <FormattedMessage id={`app.label.reception`}/>
                    </span>
                </div>

                <div style={{display: "none", overflow: "hidden", height: 0}}>
                    <RequestForm ref={receiptRef} {...{item}}/>
                </div>
            </div>

            <button type="button" className="btn btn-light-primary" onClick={handlePrintReceipt}>
                <FormattedMessage id={`app.label.print_form`}/>
            </button>

            <ChangeStateModal {...{title: lastState && lastState, handleStateChange, id}}/>
        </div> : null

    );
});


export default React.memo(withRouter(UnfinishedBtn))
