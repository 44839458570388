import * as React from "react";
import SubMenu from "./SubMenu";
import {Link} from "react-router-dom";
import InnerMenu from "./InnerMenu";
import {FormattedMessage} from "react-intl";


const ModuleMenu = props => {
    const {title, url, subMenu} = props
    return (
        <div className="menu-submenu">
            <i className="menu-arrow"/>
            <ul className="menu-subnav">

                <li className="menu-item menu-item-parent" aria-haspopup="true">
                    <span className="menu-link">
                        <span className="menu-text">Applications</span>
                    </span>
                </li>
                {/* Submenu */}

                <li className="menu-item menu-item-submenu" aria-haspopup="true"
                    data-menu-toggle="hover">
                    <Link to={url} className="menu-link menu-toggle">
                        <i className="menu-bullet menu-bullet-line">
                            <span/>
                        </i>
                        <span className="menu-text">
                            <FormattedMessage id={`app.label.${title}`}/>
                        </span>
                    </Link>
                </li>
            </ul>
        </div>
    )
}

export default React.memo(ModuleMenu)

