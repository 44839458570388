import React, {useEffect, useState} from "react"
import {Field, change} from "redux-form";
import {renderField} from "../../../../../../common/utils/FormComponent";
import {withRouter} from "react-router";
import {useDispatch, useSelector} from "react-redux";
import {FormattedMessage} from "react-intl";
import {DateUtil} from "../../../../../../../constants/utils";

let FormItems = props => {

    const {item,touched, error} = props

    const dispatch = useDispatch()
    const genders = ['MALE', 'FEMALE', 'OTHER']
    const maturities = ['baby', 'other']

    const {values} = useSelector(state => ({
        values: state.form?.requestForm?.values
    }));



    const onChangeDob = date => {
        dispatch(change("requestForm", "age", DateUtil.ageFromDate(date)));
    }

    const onChangeAge = age => {
            dispatch(change("requestForm", "dob", DateUtil.dateFromAge(age)));
    }


    return (
        <div>

            <div className="row">
                <div className="col-sm-5">
                    <Field className="form-control form-control-sm" id="lastname"
                           name="lastName" component={renderField} type="text"
                           label="lastname" placeholder="lastname" required/>
                </div>
                <div className="col-sm-7">
                    <Field className="form-control form-control-sm" id="firstname"
                           name="firstName" component={renderField} type="text"
                           label="firstname" placeholder="firstname" required/>
                </div>



            </div>
        </div>

    );
}
export default React.memo(withRouter(FormItems))
