import * as validator from "../../../../../../../constants/utils/validator";


export const validate = values => {
    // const errors = {};
    // errors.name = validator.validateString('name', values.name, 3, 150, true);
    // errors.description = validator.validateString('description', values.description, 3, 150);
    return []
};


