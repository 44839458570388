import * as React from "react";
import {Link} from "react-router-dom";


const HeaderMobile = () => {

    return (
        <div id="kt_header_mobile" className="header-mobile">
            <Link to={`/`}>
                <img alt="Logo" src={`/assets/media/logos/zl_logo.png`} className="logo-default max-h-30px"/>
            </Link>
            <div className="d-flex align-items-center">
                <button className="btn p-0 burger-icon burger-icon-left" id="kt_aside_mobile_toggle">
                    <span/>
                </button>
            </div>
        </div>
    )
}

export default React.memo(HeaderMobile)

