/**
 * Created by inglouidor on 3/15/21.
 */
import React from 'react'
import {LineChartjs} from "../charts";
import {FormattedMessage} from "react-intl";
import TableList from '../../../../../../common/tables';
import {addToObject} from '../../../../../../../../constants/utils'

const Followup = ({indicator}) => {


    // let data = [{
    //     site: 'CANGE',
    //     C19_FOLLOWED_UP: 20,
    //     C19_CONFIRMED_QUARANTINE: 50,
    //     C19_CONFIRMED_ISOLATION: 20,
    //     color: "#8e5ea2"
    // }, {
    //     site: 'HSN',
    //     C19_FOLLOWED_UP: 4,
    //     C19_CONFIRMED_QUARANTINE: 33,
    //     C19_CONFIRMED_ISOLATION: 8,
    //     color: "#3e95cd"
    // }, {site: 'HUM', C19_FOLLOWED_UP: 15, C19_CONFIRMED_QUARANTINE: 45, C19_CONFIRMED_ISOLATION: 11, color: "#10CC75"}];

    let data = [];
    if (indicator && indicator.followUp) {

        for (let i = 0; i < Object.keys(indicator.followUp).length; i++) {

            let obj = Object.keys(indicator.followUp)[i]
            let d = Object.values(indicator.followUp)[i];

            data.push(addToObject(d, "site", obj, i))
        }

    }


    const columns = [
        {
            title: 'Site',
            field: 'site', type: 'text'
        },
        {
            title: 'C19 Followup',
            field: 'C19_FOLLOWED_UP', type: 'text'
        },
        {
            title: 'Conf Quarantaine',
            field: 'C19_CONFIRMED_QUARANTINE', type: 'text'
        },
        {
            title: 'Conf Isol',
            field: 'C19_CONFIRMED_ISOLATION', type: 'text',
        },
        {
            title: 'Period',
            field: 'period', type: 'text'
        }
    ]
    const colors = ["#bf1409", "#4A2DDD", "#1F8DDD"];

    return (
        <React.Fragment>
            <div className="col-sm-6 pt-4">

                <div className="card card-custom">
                    <div className="card-header">
                        <div className="card-title">
                            <h3 className="card-label">
                                <FormattedMessage id="app.label.follow_up"/> {" "}
                                <small>
                                    <FormattedMessage id="app.label.follow_up"/>
                                </small>
                            </h3>
                        </div>
                    </div>
                    <div className="card-body">
                        <div style={{width:'100%'}}>
                            {data && data.length > 0 ?
                                <LineChartjs
                                    data={{
                                        labels: ["C19_FOLLOWED_UP", "C19_CONFIRMED_QUARANTINE", "C19_CONFIRMED_ISOLATION"],
                                        datasets: data.map((value,i) => (
                                            {
                                                label: value.site,
                                                lineTension: 0.3,
                                                borderColor:colors[i],
                                                backgroundColor: "transparent",
                                                borderWidth: 2,
                                                data: [value.C19_FOLLOWED_UP, value.C19_CONFIRMED_QUARANTINE, value.C19_CONFIRMED_ISOLATION]
                                            }))

                                    }}/> : null}
                        </div>
                    </div>
                </div>

            </div>

            <div className="col-sm-6 pt-4">
                <TableList data={data} columns={columns} title="Tableau de prise en charge"/>
            </div>
        </React.Fragment>)
}

export default Followup