import React from "react"
import {Field} from "redux-form";
import {FormattedMessage} from 'react-intl';
import {renderField} from "../../../../common/utils/FormComponent";
import {withRouter} from "react-router";

let FormItems = ({item, validate}) => {

	return (
		<div className={`custom_fieldset mt-4`}>

			<div className="row mt-3">
				<div className="col-sm-3">
					<Field className="form-control form-control-sm" id="investigator_firstname"
						name="investigator_firstname" component={renderField} type="text"  disabled={true}
						label="investigator_firstname" placeholder="investigator_firstname"
						validate={validate.error}/>
				</div>
				<div className="col-sm-3">
					<Field className="form-control form-control-sm" id="investigator_lastname"
						   name="investigator_lastname" component={renderField} type="text"
						   disabled={true}
						   label="investigator_lastname" placeholder="investigator_lastname"
						   validate={validate.error}/>
				</div>
				<div className="col-sm-3">
					<Field className="form-control form-control-sm" id="investigator_email"
						   name="investigator_email" component={renderField} type="email"
						   label="email" placeholder="email" disabled={true}
						   validate={validate.error}/>
				</div>
				<div className="col-sm-3">
					<Field className="form-control form-control-sm" id="investigator_phone"
						   name="investigator_phone" component={renderField} type="text"
						   label="investigator_phone" placeholder="investigator_phone"
						   validate={validate.error}/>
				</div>
				<div className="col-sm-12">
					<Field className="form-control form-control-sm" id="investigator_observation"
						   name="investigator_observation" component={renderField} type="text"
						   label="investigator_observation" placeholder="investigator_observation"
						   validate={validate.error}/>
				</div>
			</div>

			<div className="row mt-3">

			</div>

		</div>

	);
}
export default React.memo(withRouter(FormItems))
