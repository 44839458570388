export const app = {
    name: `COVID-19 EWRS`,
    initial: `COVID-19 EWRS`,
    copyright: `app.label.copyright`,
    version: `1.0.0`,
    year: `2020`,
    adminBase: `admin`,
    tmaRootUrl: `http://localhost:5000/api`,
    defaultRootUrl: window.BASE_URL ? window.BASE_URL : `http://localhost:9001/api/covid`,
};


export const customMenu = [
    {
        id: `obligation`,
        path: `/obligations`,
        dataTitle: `app.label.obligation_list`,
        icon: `flaticon-pie-chart-1`,
    },
];


export const moduleMenu = {

    obligation: [
        {
            type: `dropdown`,
            content: [
                {
                    type: `flat`,
                    path: `/categories`,
                    title: `categories`,
                    iconColor: `text-info-light`,
                    icon: `flaticon-calendar-with-a-clock-time-tools`,
                },
                {
                    type: `flat`,
                    path: `/intermediaries`,
                    title: `intermediaries`,
                    iconColor: `text-info-light`,
                    icon: `fa fa-building`,
                },
                {
                    type: `flat`,
                    path: `/subscribers`,
                    title: `subscribers`,
                    iconColor: `text-info-light`,
                    icon: `flaticon-users-1`,
                },
            ]
        },
    ],

    setting: [
        {
            type: `dropdown`,
            content: [
                {
                    type: `flat`,
                    path: `/settings`,
                    title: `settings`,
                    icon: `fa fa-cog`,
                },
            ]
        },
    ],
    dashboard: [],


};


export const assetsBasePath = {
    logos: `/assets/media/logos`,
    users: `/assets/media/users`
};


export const relationShips = [
    {name: 'niece', id: 'niece'},
    {name: 'aunt', id: 'aunt'},
    {name: 'brother', id: 'brother'},
    {name: 'sister', id: 'sister'},
];

export const countries = [
    {name: 'usa', id: 'usa'},
    {name: 'canada', id: 'canada'},
    {name: 'china', id: 'china'},
];

export const genders = [
    {name: 'male', id: 'MALE'},
    {name: 'female', id: 'FEMALE'},
    {name: 'other', id: 'OTHER'},
];


export const intermediaryMock = [
    {name: 'HOUSEBANK', id: 'housebank'},
    {name: 'CASABANK', id: 'casabank'},
];

export const identifierTypes = [
    {name: 'cin', id: 'CIN'},
    {name: 'nif', id: 'NIF'},
    {name: 'passport', id: 'PASSPORT'},
];


export const ctcTypes = [
    {name: 'quarantine', id: 'quarantine'},
    {name: 'isolation', id: 'isolation'},
];


export const entityStatus = ['online', 'offline'];


export default app;








