import * as validator from "../../../../../../constants/utils/validator";


export const validate = values => {
    const errors = {};
    errors.gender = values.gender === undefined;
    errors.maturity = values.maturity === undefined;
    errors.symptoms = values.symptoms === undefined;
    errors.uploadFile = values.uploadFile === {};
    errors.specimenCenter = values.specimenCenter === undefined;
    errors.currentId = values.currentId === undefined;
    errors.appointmentHour = values.appointmentHour === 'Invalid date';


    errors.firstName = validator.validateString('firstname', values.firstName, 3, 150, true);
    errors.lastName = validator.validateString('lastname', values.lastName, 3, 150, true);
    errors.nickname = validator.validateString('nickname', values.nickname, 3, 150);
    errors.dob = validator.validateDate('dob', values.dob, '1900-01-01', new Date(), true);
    errors.age = validator.validateNumber('age', values.age, 1, 150, true);

    errors.month = validator.validateNumber('month', values.month, -1, 11, true);

    errors.phone = validator.validateHtPhone('phone', values.phone, true);
    // errors.email = validator.validateHtPhone('email', values.email, true);
    errors.nationality = validator.validateString('nationality', values.nationality, 2, 150, true);
    errors.specimenCenter = validator.validateString('specimen_center', values.specimenCenter, 2, 150, true);

    errors.locality = validator.validateString('locality', values.locality, 3, 150, true);
    errors.address = validator.validateString('address', values.address, 3, 150, true);

    const minDate = new Date(Date.now() - 30 * 24 * 60 * 60 * 1000)  // can't request less than 3 days before trip
    const maxDate = new Date(Date.now() + 3 * 24 * 60 * 60 * 1000)  // can't request more than 7 days before trip

    const minAppointmentDate = new Date(Date.now() - 24 * 60 * 60 * 1000)  // can't request more than 365 days before trip
    const maxAppointmentDate = new Date(Date.now() + 20 * 24 * 60 * 60 * 1000)  // can't request more than 365 days before trip


    errors.departure = validator.validateDate('departure', values.departure, minDate, maxDate, true);
    errors.appointmentDate = validator.validateDate('appointmentDate', values.appointmentDate, minAppointmentDate, maxAppointmentDate, true);
    errors.appointmentDate = validator.validateDate('appointmentDate', values.appointmentDate, minDate, new Date(values.departure || null), true);


    errors.destination = validator.validateString('destination', values.destination, 2, 150, true);
    errors.testType = validator.validateString('testType', values.testType, 2, 150, true);

    errors.trxCode = validator.validateString('trx_code', values.trxCode, 3, 150, true);
    errors.currentId = validator.validateString('currentId', values.currentId, 3, 150, true);

    return errors
};


