import * as React from "react";
import useDocumentTitle from "../../../../common/hooks/useDocumentTitle";
import {getIntlMsg} from "../../../../../constants/utils";
import {injectIntl} from "react-intl";
import {Line} from 'react-chartjs-2';


const state = {
    labels: ['10-01-2021', '11-01-2021', '12-01-2021', '13-01-2021', '18-01-2021', '20-01-2021', '22-01-2021', '24-01-2021', '26-01-2021', '30-01-2021', '01-02-2021', '02-02-2021', '02-02-2021', '04-02-2021', '05-02-2021'],
    datasets: [
        {
            label: 'nombre de test',
            fill: true,
            lineTension: 0.5,
            backgroundColor: 'rgb(231,120,46)',
            borderColor: 'rgba(0,0,0,1)',
            borderWidth: 2,
            data: [5, 6, 8, 20, 10, 20, 40, 20, 12, 3, 4, 30, 30, 14, 10]
        }
    ]
}


const ObsHomePage = injectIntl(props => {
    const {intl} = props

    useDocumentTitle(getIntlMsg(intl, `app.label.obs_dashboard`));

    return (
        <div className="row">
            <div className="col-lg-12 ml-30 mr-10" style={{width: "900px"}}>
                <div>
                    <Line
                        data={state}
                        options={{
                            title: {
                                display: true,
                                text: 'Tableau de performance sur la demande de test covid pour les voyageurs par jours',
                                fontSize: 20
                            },
                            legend: {
                                display: true,
                                position: 'right'
                            }
                        }}
                    />
                </div>
            </div>

        </div>
    );
});

export default React.memo(ObsHomePage)

