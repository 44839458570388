import React from "react"
import {withRouter} from "react-router";
import {FormattedMessage, injectIntl} from "react-intl";
import ListOptions from "../../../../common/utils/ListOptions";

let Item = injectIntl(props => {

    const {id, acronym, description, phones, emails, address, status, setInstitutionFormView} = props

    return (
        <tr className="datatable-row" style={{left: "0px"}}>

            <td className="datatable-cell">
                <span style={{width: "137px"}}>
                    <div className="font-weight-boldest">{acronym.toUpperCase() || ''}</div>
                    {description || ''}
                </span>
            </td>

            <td className="datatable-cell">
               <span style={{width: "137px"}}>
                   <ul className="p-0 m-0">
                       {phones.map((phone, i) =>
                           <li key={i}> {`${phone.areaCode} ${phone.no}` || ''}</li>
                       )}
                   </ul>
                </span>
            </td>

            <td className="datatable-cell">
              <span style={{width: "200px"}}>
                  <ul className="p-0 m-0">
                       {emails.map((email, i) =>
                           <li className="list-item" key={i}> {`${email.current}` || ''}</li>
                       )}
                   </ul>
                </span>
            </td>

            <td className="datatable-cell">
              <span style={{width: "300px"}}>
                  {`${address.full}` || ''}
                </span>
            </td>


            <td className="datatable-cell">
                <span style={{width: "137px"}}>
                    <span
                        className={`label font-weight-bold label-lg  label-light-${status ? 'success' : 'danger'} label-inline`}>
                        {status
                            ? <FormattedMessage id="app.label.online"/>
                            : <FormattedMessage id="app.label.offline"/>
                        }
                    </span>
                </span>
            </td>

            <ListOptions {...{id, entities: 'institutions'}} viewAction={setInstitutionFormView}/>
        </tr>

    )
})
export default React.memo(withRouter(Item))
