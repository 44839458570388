import React from "react";
import {injectIntl} from "react-intl";


const Item = injectIntl((props) => {

    const {item} = props;

    return (
        <tr className="font-weight-boldest">
            <td className="pl-0 pt-7 text-center">{item?.firstname} {', '} {item?.lastname}</td>
            <td className={item?.agCounter === 1 ? 'text-right pt-7 text-center' : `text-danger text-right pt-7 text-center`}>{item?.agCounter === 1 ? item?.agCounter : '-'}</td>
            <td className={item?.pcrCounter === 1 ? 'text-right pt-7 text-center' : `text-danger text-right pt-7 text-center`}>{item?.pcrCounter === 1 ? item?.pcrCounter : '-'}</td>
            <td className={item?.gdsAmount !== null ? 'pr-0 pt-7 text-center' : `text-danger pr-0 pt-7 text-center`}>{item?.gdsAmount !== null ? item?.gdsAmount : '-'}</td>
            <td className={item?.usdAmount !== null ? 'pr-0 pt-7 text-center' : `text-danger pr-0 pt-7 text-center`}>{item?.usdAmount !== null ? item?.usdAmount : '-'}</td>
        </tr>
    )
})

export default Item