import * as React from "react";
import {FormattedMessage, injectIntl} from "react-intl";
import {withRouter} from "react-router";
import {validate} from "../validator";
import FormItems from "./Items";


const BaseForm = injectIntl((props) => {
    const {item,setPartnerFormView} = props;

    return (

        <div className="card card-custom mb-5">
            <div className="card-header flex-wrap border-0 pt-6 pb-0">

                <div className="card-title">
                    <span className="card-icon">
                        <i className="flaticon2-hospital text-primary"/>
                    </span>
                    <h3 className="card-label">
                        <FormattedMessage id={`app.label.institution`}/> {" "}
                        <small><FormattedMessage id={`app.label.institution_form_desc`}/></small>
                    </h3>
                </div>

                <div className="card-toolbar">
                    <span className="btn btn-primary font-weight-bolder" onClick={() => setPartnerFormView(false)}>
                    <span className="svg-icon svg-icon-md">
                        <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                            <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                <rect x="0" y="0" width="24" height="24"/>
                                <circle fill="#000000" cx="9" cy="15" r="6"/>
                                <path
                                    d="M8.8012943,7.00241953 C9.83837775,5.20768121 11.7781543,4 14,4 C17.3137085,4 20,6.6862915 20,10 C20,12.2218457 18.7923188,14.1616223 16.9975805,15.1987057 C16.9991904,15.1326658 17,15.0664274 17,15 C17,10.581722 13.418278,7 9,7 C8.93357256,7 8.86733422,7.00080962 8.8012943,7.00241953 Z"
                                    fill="#000000" opacity="0.3"/>
                            </g>
                        </svg>
                    </span>
                    <FormattedMessage id={`app.label.institutions_list`}/>
                    </span>
                </div>
            </div>
            <div className="card-body">
                <div>
                    <FormItems {...{validate, item}}/>
                </div>
            </div>
        </div>
    );
});


export default React.memo(withRouter(BaseForm))

