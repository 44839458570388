import React from "react"
import {useDispatch} from "react-redux";
import actions from "../../../thunks/common/coreThunks";
import {injectIntl} from "react-intl";


const AsyncInputSearch = injectIntl(props => {
    const {options, limit = 3, labeled} = props
    const dispatch = useDispatch();

    function findByCriteria(criteria) {
        if (criteria.length >= limit)
            dispatch(actions.loadItems({...options, criteria}));
        else if (criteria.length === limit - 1 || criteria.length === 0)
            dispatch(actions.loadItems({...options}));
    }


    return (
        <div className="d-flex align-items-center">
            {labeled && <label className="mr-3 mb-0 d-none d-md-block">Status:</label> }
            <input
                type="text"
                placeholder="Inserez les critères de recherche"
                className="form-control"
                style={{padding: '2px 10px'}}
                onChange={(e) => findByCriteria(e.target.value?.toLowerCase())}/>
        </div>

    )
})


export default AsyncInputSearch

