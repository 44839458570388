import * as React from "react";
import {useContext} from "react";
import {FormattedMessage, injectIntl} from "react-intl";
import {getIntlMsg} from "../../../../constants/utils";
import {GlobalContext} from "../../../index";


const ChatFooter = injectIntl(props => {

    const {intl} = props

    const {institution: {acronym}} = useContext(GlobalContext);


    return (
        <div className="card-footer align-items-center">
            <textarea className="form-control border-0 p-0" rows="2" placeholder={getIntlMsg(intl, 'app.label.type_message')}/>
            <div className="d-flex align-items-center justify-content-between mt-5">
                <div className="mr-3">
                    <a href="#" className="btn btn-clean btn-icon btn-md mr-1">
                        <i className="flaticon2-photograph icon-lg"/>
                    </a>
                    <a href="#" className="btn btn-clean btn-icon btn-md">
                        <i className="flaticon2-photo-camera icon-lg"/>
                    </a>
                </div>
                <div>
                    <button type="button"
                            className="btn btn-primary btn-md text-uppercase font-weight-bold chat-send py-2 px-6">
                        <FormattedMessage id={`app.label.send`}/>
                    </button>
                </div>
            </div>
        </div>
    )
})

export default React.memo(ChatFooter)

