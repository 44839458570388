import * as React from "react";
import {useParams, withRouter} from "react-router";
import {FormattedMessage, injectIntl} from "react-intl";
import {Link} from "react-router-dom";
import {getIntlMsg} from "../../../../constants/utils";


const SubHeader = injectIntl(props => {

    const {intl, noButton, changeView,view, btnOptions} = props
    const {title, btnTitle, icon, paths, flatUrl, listTitle, hasChildren} = btnOptions;
    const {module, page, secondLevelPage} = useParams()

    return (
        <div className="subheader py-2 py-lg-6 subheader-transparent" id="kt_subheader">
            <div
                className="container d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap">

                <div className="d-flex align-items-center flex-wrap mr-1">
                    <div className="d-flex align-items-baseline flex-wrap mr-5">
                        <h5 className="text-dark font-weight-bold my-1 mr-5">
                            {module && !page && !secondLevelPage ?
                                <FormattedMessage id={`app.label.${module}`}/>
                                : <FormattedMessage id={`app.label.${module}`}/>
                            }
                        </h5>
                        <ul className="breadcrumb breadcrumb-transparent breadcrumb-dot font-weight-bold p-0 my-2 font-size-sm">
                            {page && <li className="breadcrumb-item">
                                <a href="" className="text-muted">
                                    <FormattedMessage id={`app.label.${page}`}/>
                                </a>
                            </li>}
                            {secondLevelPage && <li className="breadcrumb-item">
                                <a href="" className="text-muted">
                                    <FormattedMessage id={`app.label.${secondLevelPage}`}/>
                                </a>
                            </li>}
                        </ul>
                    </div>
                </div>

                {!noButton && <div className="d-flex align-items-center flex-wrap">

                    {flatUrl === null ?
                        <div className="d-flex align-items-center flex-wrap">
                            <div title={getIntlMsg(intl, `app.label.${listTitle}_options`)}
                                 data-placement="top">
                            <span onClick={() => changeView(!view)}
                                  className="btn btn-fixed-height btn-primary font-weight-bolder font-size-sm px-5 my-1">
                                <span className="svg-icon svg-icon-md"> {icon}</span>
                                <FormattedMessage id={`app.label.${listTitle}`} />
                            </span>
                            </div>
                        </div>
                        :

                        <div className="dropdown dropdown-inline" data-toggle="tooltip"
                             title={getIntlMsg(intl, `app.label.${listTitle}_options`)}
                             data-placement="top">
                            <Link to={flatUrl}
                                  className="btn btn-fixed-height btn-primary font-weight-bolder font-size-sm px-5 my-1">
                                <span className="svg-icon svg-icon-md"> {icon}</span>
                                <FormattedMessage id={`app.label.${listTitle}`} />
                            </Link>
                            {hasChildren && <div className="dropdown-menu dropdown-menu-md dropdown-menu-right p-0 m-0">
                                <ul className="navi navi-hover">

                                    {paths.map((path, i) =>
                                        <li className="navi-item" key={i}>
                                            <Link to={path.url} className="navi-link">
                                            <span className="navi-text">
                                                <span className="">
                                                    <FormattedMessage id={`app.label.${path.label}`} />
                                                </span>
                                            </span>
                                            </Link>
                                        </li>
                                    )}

                                </ul>
                            </div>}
                        </div>
                    }
                </div>}
            </div>
        </div>
    )
})

export default React.memo(withRouter(SubHeader))

