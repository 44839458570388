import * as validator from "../../../../../constants/utils/validator";


export const validate = values => {
    const errors = {};
    // errors.symptomsStartDate=values.symptomsStartDate === undefined;
    errors.symptomsStartDate = validator.validateDate('symptomsStartDate', values.symptomsStartDate, '1900-01-01', new Date(), true);

    // errors.symptomsStartDate = validator.validateString('symptomsStartDate', values.symptomsStartDate, 3, 150, true);
    errors.covidContactId = validator.validateString('full_name', values.full_name, 3, 150, true);
    errors.full_name = validator.validateString('covid_contact', values.first_name, 3, 150, true);
    return errors
};


