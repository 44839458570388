import * as React from "react";
import {FormattedMessage, injectIntl} from "react-intl";
import {useParams, withRouter} from "react-router";
import actions from "../../../../../thunks/common/coreThunks";
import {useDispatch, useSelector} from "react-redux";
import Item from "./Item";
import SearchBar from "./SearchBar";
import {useEffect} from "react";
import Spinner from "../../../../common/utils/Spinner";
import TablePager from "../../../common/pagination/TablePager";


const InstitutionList = injectIntl((props) => {

    const dispatch = useDispatch();
    const {setInstitutionFormView} = props;
    const {page} = useParams();


    const criteria = {options: ['institutions']};
    const options = {options: ['institutions', 'names']};

    useEffect(() => {
        dispatch(actions.loadItems(criteria));
    }, [dispatch]);

    const {loading, items} = useSelector(state => ({
        loading: state.institutionReducer.loadingItems,
        items: state.institutionReducer.items,
    }));


    return (
        <div className="card card-custom">
            <div className="card-header flex-wrap border-0 pt-6 pb-0">

                <div className="card-title">
                    <h3 className="card-label">
                        <FormattedMessage id={`app.label.${page}_list`}/>
                        <span className="d-block text-muted pt-2 font-size-sm">
                            <FormattedMessage id={`app.label.${page}_list`}/>
                            {" "} &amp; {" "}
                            <FormattedMessage id={`app.label.management`}/>
                        </span></h3>
                </div>

                <div className="card-toolbar">
                    <span className="btn btn-primary font-weight-bolder" onClick={() => setInstitutionFormView(true)}>
                    <span className="svg-icon svg-icon-md">
                        <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                            <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                <rect x="0" y="0" width="24" height="24"/>
                                <circle fill="#000000" cx="9" cy="15" r="6"/>
                                <path
                                    d="M8.8012943,7.00241953 C9.83837775,5.20768121 11.7781543,4 14,4 C17.3137085,4 20,6.6862915 20,10 C20,12.2218457 18.7923188,14.1616223 16.9975805,15.1987057 C16.9991904,15.1326658 17,15.0664274 17,15 C17,10.581722 13.418278,7 9,7 C8.93357256,7 8.86733422,7.00080962 8.8012943,7.00241953 Z"
                                    fill="#000000" opacity="0.3"/>
                            </g>
                        </svg>
                    </span>
                    <FormattedMessage id={`app.label.add_new_institution`}/>
                    </span>
                </div>
            </div>

            <div className="card-body">
                <SearchBar/>
                <div
                    className="datatable datatable-bordered datatable-head-custom datatable-default datatable-primary datatable-loaded"
                    id="kt_datatable_0" style={{}}>
                    <table className="datatable-table" style={{display: "block"}}>
                        <thead className="datatable-head">
                        <tr className="datatable-row" style={{left: "0px"}}>

                            <th className="datatable-cell datatable-cell-sort">
                        <span style={{width: "137px"}}>
                            <FormattedMessage id={`app.label.name`}/>
                        </span>
                            </th>

                            <th className="datatable-cell datatable-cell-sort">
                                <span style={{width: "137px"}}>
                                    <FormattedMessage id={`app.label.phones`}/>
                                </span>
                            </th>

                            <th className="datatable-cell datatable-cell-sort">
                                <span style={{width: "200px"}}>
                                    <FormattedMessage id={`app.label.emails`}/>
                                </span>
                            </th>

                            <th className="datatable-cell datatable-cell-sort">
                                <span style={{width: "300px"}}>
                                    <FormattedMessage id={`app.label.address`}/>
                                </span>
                            </th>

                            <th className="datatable-cell datatable-cell-sort">
                                <span style={{width: "137px"}}>
                                    <FormattedMessage id={`app.label.status`}/>
                                </span>
                            </th>

                            <th data-autohide-disabled="false"
                                className="datatable-cell datatable-cell-sort">
                                <span style={{width: "125px"}}>Actions</span>
                            </th>
                        </tr>
                        </thead>
                        <tbody className="datatable-body" style={{}}>
                        {loading ?
                            <tr>
                                <td>
                                    <Spinner/>
                                </td>
                            </tr>
                            : items.map((item, i) => <Item {...{...item, setInstitutionFormView}} key={i}/>)}
                        </tbody>
                    </table>
                    <TablePager/>
                </div>
            </div>
        </div>

    );
})

export default React.memo(withRouter(InstitutionList))

