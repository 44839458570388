import {useContext, useEffect} from "react";
import {GlobalContext} from "../../index";

function useDocumentTitle(title) {


    const {institution:{acronym, description}} = useContext(GlobalContext);

    useEffect(() => {
        document.title = `( ${acronym.toUpperCase()} ) ${description} | ${title}`;
    });

}


export default useDocumentTitle;
