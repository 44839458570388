import * as React from "react";
import useDocumentTitle from "../../../../common/hooks/useDocumentTitle";
import actions from "../../../../../thunks/common/coreThunks";
import {getIntlMsg} from "../../../../../constants/utils";
import {injectIntl} from "react-intl";
import {useParams, withRouter} from "react-router";
import {connect, useDispatch, useSelector} from "react-redux";
import {reduxForm} from "redux-form";
import {validate} from "../validator";
import useEntityManager from "../../../../common/hooks/useEntityManager";
import FormItems from "./Items";
import FormFooter from "../../../common/form/FormFooter";
import {
    RequestMapper,
    Symptom,
    SymptomsMapper,
    RiskFactor,
    DiagnosticSheet,
    ComorbidityMapper, DiagnosticNotificationMapper
} from "../../../../../constants/models/request";
import {useEffect} from "react";



const DiagnosticForm = injectIntl((props) => {
    const {intl, handleSubmit, pristine, valid, submitting, reset, initialValues,history,module,saveOrUpdateItem} = props;
    const {page,secondLevelPage} = useParams();
    // useDocumentTitle(getIntlMsg(intl, `app.label.${page}`));

    const dispatch = useDispatch();

    const opts = {intl, options: ['categories', 'by-name', 'specimen'], item: {id: secondLevelPage}}


    useEffect(() => {
        dispatch(actions.loadItems(opts))
    }, [secondLevelPage]);



    const params = {options: [page]};
    // const {handleLoadItems} = useEntityManager(params, page)
    const {item, loading,specimenTypes} = useSelector(state => ({
        loading: state.observationReducer.loadingItem,
        item: state.requestReducer.item,
        specimenTypes: state.categoryReducer.specimenTypes,
    }));


    const onFormSubmit = element => {
        const entity=new DiagnosticSheet(element);
        entity.id=item?.id;

        dispatch(saveOrUpdateItem({
            options: ['requests','update-diagnostic'], intl, action: '', item:entity,
        }))
    };


    return (
        <div className="tab-pane fade show" id="diagnostic" role="tabpanel" aria-labelledby="diagnostic">

            <form onSubmit={handleSubmit(onFormSubmit)}>
                <div>
                    <FormItems {...{validate, item,specimenTypes}}/>
                    {/*<FormFooter {...{item, loading, pristine, submitting, reset, valid, dispatch,history,module}}/>*/}
                </div>
            </form>
        </div>
    );
});

const mapStateToProps = ({requestReducer: {item}}) => {
    const initVal = item ? new DiagnosticNotificationMapper(item?.diagnostic) : null;


    const defaultState = {}

    const initV = {
        ...initVal,
    }

    console.log("==============INIT===============", initV)

    return {
        initialValues: item !== null ? {...initV} : {...defaultState},
    }
};

export default React.memo(withRouter(connect(mapStateToProps,)(reduxForm({
    form: "observationForm",
    enableReinitialize: true,
    validate,
})(DiagnosticForm))));

