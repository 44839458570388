/**
 * Validate String size
 * @Param label
 * @Param value
 * @Param min
 * @Param max
 * @Param require
 */
export const validateString = (label, value = '', min = 0, max = 0, required = false) => {
    if (value === null) return false
    return (value.length === 0 && !required) ? ''
        : (required && value.trim().length === 0) ? `app.label.required`
            : (value.length < min) ? `app.label.length_longer`
                : (value.length > max) ? `app.label.length_shorter`
                    : false


};
/**
 * Validate String size
 * @Param label
 * @Param value
 * @Param min
 * @Param max
 * @Param require
 */
export const validateNumber = (label, value = undefined, min = -0, max = 0, required = false) => {

    // if (!value) return false
    return (value === 0 && !required) ? ''
        : !value ? `app.label.required`
            : (parseInt(value)) < min ? `app.label.greater`
                : (parseFloat(value)) > max ? `app.label.lower`
                    : false

};


/**
 * Validate String size
 * @Param label
 * @Param value
 * @Param min
 * @Param max
 * @Param require
 */
export const validateDate = (label, value = undefined, min = undefined, max = 0, required = false) => {
    const minDate = new Date(min).toISOString().split('T')[0];
    const maxDate = new Date(max).toISOString().split('T')[0];
    const currentDate = value && new Date(value).toISOString().split('T')[0];

    if (value === null) return false
    return (!value && !required) ? ''
        : !value ? `app.label.required`
            : currentDate < minDate ? `app.label.date_greater`
                : currentDate > maxDate ? `app.label.date_lower`
                    : false

};


/**
 * Validate phone
 * @Param label
 * @Param value
 * @Param require
 */
export const validateHtPhone = (label, value = '', required = false) => {
    let phoneReg = /^\d{4}-\d{4}$/g;

    return (value.length === 0 && !required) ? ''
        : (value.length === 0 && required) ? `app.label.required`
            : (!phoneReg.test(value)) ? 'app.label.phone_invalid'
                : false
};


/**
 * Validate email
 * @Param label
 * @Param value
 * @Param require
 */
export const validateEmail = (label, value = '', required = false) => {

    let emailReg = /^(([^<>()[\]\\.,:\s@"]+(\.[^<>()[\]\\.,:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/g;
    return (value.length === 0 && !required) ? ''
        : (value.length === 0 && required) ? `app.label.required`
            : (!emailReg.test(value)) ? 'app.label.email_invalid'
                : ''
};
