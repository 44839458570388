import * as React from "react";
import {FormattedMessage, injectIntl} from "react-intl";
import {useParams, withRouter} from "react-router";
import {useDispatch, useSelector} from "react-redux";
// import Item from "./Item";
// import SearchBar from "./SearchBar";
// import Spinner from "../../../../common/utils/Spinner";
// import TablePager from "../../../common/pagination/TablePager";
import useEntityManager from "../../../../common/hooks/useEntityManager";
import MaterialTable from 'material-table';
import {getIntlMsg, intl, Localization} from "../../../../../constants/utils";
import moment from "moment";
import {useEffect} from "react";
import actions from "../../../../../thunks/common/coreThunks";


const TestApplicantList = injectIntl((props) => {

    const dispatch = useDispatch();
    const {formView, setFormView} = props;
    const {module, id} = useParams();


    const criteria = {options: ['requests', 'status', 'archived']};
    const options = {options: ['indicators', 'names']};


    useEffect(() => {
        dispatch(actions.loadItems(criteria))
    }, []);

    const params = {options: [module]}
    // const {} = useEntityManager(params, module)

    const {loading, items} = useSelector(state => ({
        loading: state?.requestReducer?.loadingItems,
        items: state?.requestReducer?.items || [],
    }));


    const formattedItems = [];
    items.forEach(item => {
        if (moment(item?.appointmentDate).format('DD-MM-YYYY') === "17-05-2021") {
            formattedItems.push({
                firstName: item?.applicant?.personalInfos?.firstName,
                lastName: item?.applicant?.personalInfos?.lastName,
                phone: item?.applicant?.socialInfos?.phones[0]?.no,
                address: item?.applicant?.socialInfos?.address?.no,
                gender: item?.applicant?.personalInfos?.gender === "MALE" ? 'm' : 'f',
                age: item?.applicant?.personalInfos?.age,
                appointmentDate: moment(item?.appointmentDate).format('DD-MM-YYYY'),
                testType: item?.testType?.name,
                result: item?.diagnostic?.result?.status === "POSITIVE" ? "Positif" : item?.diagnostic?.result?.status === "NEGATIVE" ? "Négatif" : "Indeterminé",
                specimenCenter: item?.specimenCenter?.name !== "Bureau Central" ? "Bureau Central" : item?.specimenCenter?.name,
            })
        }
    })


    const columns = [
        {
            title: "Date",
            field: 'appointmentDate', type: 'text',
            grouping: true
        },
        {
            title: "NON",
            field: 'lastName', type: 'text',
            grouping: true
        },
        {
            title: "PRÉNOM",
            field: 'firstName', type: 'text',
            grouping: true
        },    {
            title: "SEXE",
            field: 'gender', type: 'text',
            grouping: true
        },
        {
            title: "AGE",
            field: 'age', type: 'text',
            grouping: true
        },
        {
            title: "TÉlÉPHONE",
            field: 'phone', type: 'text',
            grouping: true
        }, {
            title: "ADRESSE",
            field: 'address', type: 'text',
            grouping: true
        },
        {
            title: "TYPE TEST",
            field: 'testType', type: 'text',
            grouping: true
        },
        {
            title: "RÉSULTAT",
            field: 'result', type: 'text',
            cellStyle: rowData => ({
                backgroundColor: 'red',
                color: '#FFF'
            })

        },
        {
            title: "LIEUX COLLECTION",
            field: 'specimenCenter', type: 'text',
            grouping: true
        },
    ]

    return (
        <React.Fragment>
            <div className="d-flex flex-column-fluid pt-4">
                <div className="container">
                    <h5 className="text-black-50 mb-5">
                        {/*<FormattedMessage id="app.label.all_test_indicators"/>*/}
                    </h5>
                    {/*<SearchBar {...{formView, setFormView, options, items}}/>*/}
                    {tableElement(items) && formattedItems.length > 0 ?
                        <MaterialTable
                            title={'Rapport de test Covid-19'}
                            columns={columns}
                            data={items ? formattedItems : []}
                            icon='unfold_less'
                            localization={Localization('fr')}
                            optionsData={{}}
                            options={{
                                headerStyle: {
                                    backgroundColor: '#ff6900',
                                    color: '#FFF'
                                },

                                actionsColumnIndex: -1,
                                exportButton: true,
                                exportAllData: true,
                                grouping: true,
                                defaultExpanded: true,
                                pageSize: items?.length,
                                pageSizeOptions: [10, 20, 25, 50, 100]
                            }}

                        /> : null}
                    {/*<div  className="datatable datatable-bordered datatable-head-custom datatable-default datatable-primary datatable-loaded"*/}
                    {/*    id="kt_datatable_0" style={{}}>*/}
                    {/*    <table className="datatable-table" style={{display: "block"}}>*/}
                    {/*        <thead className="datatable-head pl-5 pr-5">*/}
                    {/*        <tr className="datatable-row" style={{left: "0px"}}>*/}

                    {/*            <th className="datatable-cell datatable-cell-sort">*/}
                    {/*            <span>*/}
                    {/*                <FormattedMessage id={`app.label.date`}/>*/}
                    {/*            </span>*/}
                    {/*            </th>*/}

                    {/*            <th className="datatable-cell datatable-cell-sort">*/}
                    {/*            <span>*/}
                    {/*                <FormattedMessage id={`app.label.lastname`}/>*/}
                    {/*            </span>*/}
                    {/*            </th>*/}

                    {/*            <th className="datatable-cell datatable-cell-sort">*/}
                    {/*            <span>*/}
                    {/*                <FormattedMessage id={`app.label.firstname`}/>*/}
                    {/*            </span>*/}
                    {/*            </th>*/}


                    {/*            <th className="datatable-cell datatable-cell-sort">*/}
                    {/*            <span>*/}
                    {/*                <FormattedMessage id={`app.label.test_type`}/>*/}
                    {/*            </span>*/}
                    {/*            </th>*/}

                    {/*            <th className="datatable-cell datatable-cell-sort">*/}
                    {/*            <span>*/}
                    {/*                <FormattedMessage id={`app.label.result`}/>*/}
                    {/*            </span>*/}
                    {/*            </th>*/}

                    {/*            <th data-autohide-disabled="false"*/}
                    {/*                className="datatable-cell datatable-cell-sort">*/}
                    {/*            <span>*/}
                    {/*                 <FormattedMessage id={`app.label.site`}/>*/}
                    {/*            </span>*/}
                    {/*            </th>*/}
                    {/*        </tr>*/}
                    {/*        </thead>*/}
                    {/*        <tbody className="datatable-body" style={{}}>*/}
                    {/*        {loading ?*/}
                    {/*            <tr>*/}
                    {/*                <td>*/}
                    {/*                    <Spinner/>*/}
                    {/*                </td>*/}
                    {/*            </tr>*/}
                    {/*            : items?.map((item, i) => <Item {...{...item, setFormView}} key={i}/>)}*/}
                    {/*        </tbody>*/}
                    {/*    </table>*/}
                    {/*    <TablePager/>*/}
                    {/*</div>*/}
                </div>

            </div>
        </React.Fragment>
    );
})

const tableElement = (items) => {

    let labels = [];
    let values = [];
    if (items)
        for (const [key, value] of Object.entries(items)) {
            labels.push('firstName')
            values.push(value.applicant.personalInfos.firstName)
            console.log("================", key, "-------------------", value)

        }
    return [labels, values]
}

export default React.memo(withRouter(TestApplicantList))

