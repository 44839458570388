import * as React from "react";
import {injectIntl} from "react-intl";
import {validate} from "../../validator";
import {useParams, withRouter} from "react-router";
import actions from "../../../../../../thunks/common/coreThunks";
import {connect, useDispatch, useSelector} from "react-redux";
import {reduxForm} from "redux-form";
import FormItems from "./Items";
import {getIntlMsg} from "../../../../../../constants/utils";
import useDocumentTitle from "../../../../../common/hooks/useDocumentTitle";
import {useEffect} from "react";
import {StaffRequestMapper, Request} from "../../../../../../constants/models/staffRequest";
import ModalFormFooter from "../../../../../common/view/ModalFormFooter";
import FormFooter from "../../../../common/form/FormFooter";
import SocialFormItems from "../social/Items";


const PersonalInfosForm = injectIntl((props) => {
    const {intl, handleSubmit, pristine, valid, error, resetItem, touched, submitting, reset, initialValues} = props;
    const {page, secondLevelPage} = useParams();
    // useDocumentTitle(getIntlMsg(intl, `app.label.${page}`));

    const dispatch = useDispatch();
    const options = {intl, options: ['requests'], item: {id: secondLevelPage}}

    useEffect(() => {
        dispatch(actions.loadItem(options))
    }, [secondLevelPage]);


    const {item, loading, savingOrUpdating, status} = useSelector(state => ({
        loading: state.requestReducer.loadingItem,
        item: state.requestReducer.item,
        savingOrUpdating: state.requestReducer.savingOrUpdating,
    }));


    const onFormSubmit = element => {
        const entity = new Request(element);
         entity.currentState = 'notify'
        dispatch(actions.saveOrUpdateItem({
            options: ['requests'], intl, action: '', id: item && item.id, item:entity,
        }))
    };


    return (
        <div className="tab-pane fade show active" id="personal" role="tabpanel">
            <form onSubmit={handleSubmit(onFormSubmit)}>
                <div>
                    <FormItems {...{item, touched, error}}/>
                    <SocialFormItems {...{item, touched, error}}/>
                    <FormFooter {...{item, loading, pristine, submitting, reset, valid, savingOrUpdating}}/>
                </div>
            </form>
        </div>
    );
});

const mapStateToProps = ({observationReducer, requestReducer}) => {
    const request = requestReducer.item;
    const item = observationReducer.item;

    let current = request?.type === 'TRAVELER' ? new StaffRequestMapper(request) : null
    console.log(current, '******')


    return {
        initialValues: item !== null ? {...item} : {...current}
    }
};

export default React.memo(withRouter(connect(mapStateToProps,)(reduxForm({
    form: "personalForm",
    enableReinitialize: true,
    validate,
})(PersonalInfosForm))));










