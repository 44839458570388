import * as React from "react";
import useDocumentTitle from "../../../../common/hooks/useDocumentTitle";
import actions from "../../../../../thunks/common/coreThunks";
import {getIntlMsg} from "../../../../../constants/utils";
import {injectIntl} from "react-intl";
import {useParams, withRouter} from "react-router";
import {connect, useDispatch, useSelector} from "react-redux";
import {reduxForm} from "redux-form";
import {validate} from "../validator";
import useEntityManager from "../../../../common/hooks/useEntityManager";
import FormItems from "./Items";
import FormFooter from "../../../common/form/FormFooter";
import {
    DiagnosticNotificationMapper,
    DiagnosticSheet,
    Investigator,
    InvestigatorMapper
} from "../../../../../constants/models/request";


const InvestigatorForm = injectIntl((props) => {
    const {
        intl,
        handleSubmit,
        pristine,
        valid,
        submitting,
        reset,
        initialValues,
        history,
        module,
        saveOrUpdateItem
    } = props;
    const {page, secondLevelPage} = useParams();
    // useDocumentTitle(getIntlMsg(intl, `app.label.${page}`));

    const dispatch = useDispatch();


    const params = {options: [page]};
    const {handleLoadItems} = useEntityManager(params, page)
    const {item, loading} = useSelector(state => ({
        loading: state.observationReducer.loadingItem,
        item: state.requestReducer.item,
    }));


    const onFormSubmit = element => {
        const entity = new Investigator(element);
        entity.id = item?.id;

        dispatch(actions.saveOrUpdateItem({
            options: ['requests','update','investigator'], intl, action: '', item: entity,
        }))
    };


    return (
        <div className="tab-pane fade show" id="investigator" role="tabpanel" aria-labelledby="investigator">
            <form onSubmit={handleSubmit(onFormSubmit)}>
                <div>
                    <FormItems {...{validate, item}}/>
                    <FormFooter {...{item, loading, pristine, submitting, reset, valid, dispatch, history, module}}/>
                </div>
            </form>
        </div>
    );
});

const mapStateToProps = ({requestReducer: {item}}, props) => {
    const initVal = item ? new InvestigatorMapper(item?.investigator) : null;

    const defaultState = {
        investigator_firstname: props?.userSession?.family_name || '',
        investigator_lastname: props?.userSession?.given_name || '',
        investigator_email: props?.userSession?.email || '',
    }

    const initV = {
        ...initVal,
        ...defaultState
    }

    return {initialValues: item !== null ? {...initV} : {...defaultState}}
};

export default React.memo(withRouter(connect(mapStateToProps,)(reduxForm({
    form: "investigatorForm",
    enableReinitialize: true,
    validate,
})(InvestigatorForm))));

