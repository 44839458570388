/**
 * Created by louidorpc on 2/10/21.
 * Edited by rjacques on 2/21/21.
 */
import React,{useContext} from 'react'
import UpdateResultForm from './form'
import {FormattedMessage} from "react-intl";
import {useSelector} from "react-redux";
import {GlobalContext} from "../../../../../../index";
import {Modal} from 'react-bootstrap';


const UpdateResultModal = () => {

    const {hideModal,modalIsOpen} = useContext(GlobalContext);

    const {loading,item,status} = useSelector(state => ({
        item: state.requestReducer?.item,
        loading: state.requestReducer?.loadingItem,
        status: state.requestReducer?.status,
    }))




    return (
        <React.Fragment>
            <Modal
                show={modalIsOpen.name === "updateResult"?modalIsOpen.open:false}
                onHide={() => hideModal('updateResult')}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                                <FormattedMessage id="app.label.adjust_result_of"
                                                  defaultValue="result adjust form"/> {""}
                                {`${item?.applicant?.personalInfos?.firstName} ${item?.applicant?.personalInfos?.lastName?.toUpperCase()}`}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                            <div data-scroll="true">
                                <UpdateResultForm {...{item,loading}}/>
                            </div>

                </Modal.Body>

            </Modal>
        </React.Fragment>
    )
}

export default UpdateResultModal