const actionTypes = {


    /**
     * @Author Jean-Paul LOUIDOR(Pablo ^(..)^)
     * LOAD REQUESTS STATUS OUT ACTION TYPES
     * -------------------------------------------
     * */
    RESET_RESULT: "RESET_RESULT",


    /**
     * @Author Jean-Paul LOUIDOR(Pablo ^(..)^)
     * LOAD REQUESTS STATUS OUT ACTION TYPES
     * -------------------------------------------
     * */
    LOAD_RESULTS: "LOAD_RESULTS",
    LOAD_RESULTS_SUCCEEDED: "LOAD_RESULTS_SUCCEEDED",
    LOAD_RESULTS_FAILED: "LOAD_RESULTS_FAILED",


    /**
     * @Author Jean-Paul LOUIDOR(Pablo ^(..)^)
     * LOAD REQUESTS STATUS OUT ACTION TYPES
     * -------------------------------------------
     * */
    LOAD_PENDING_RESULTS: "LOAD_PENDING_RESULTS",
    LOAD_PENDING_RESULTS_SUCCEEDED: "LOAD_PENDING_RESULTS_SUCCEEDED",
    LOAD_PENDING_RESULTS_FAILED: "LOAD_PENDING_RESULTS_FAILED",


    /**
     * @Author Jean-Paul LOUIDOR(Pablo ^(..)^)
     * LOAD REQUESTS STATUS OUT ACTION TYPES
     * -------------------------------------------
     * */
    LOAD_DONE_RESULTS: "LOAD_DONE_RESULTS",
    LOAD_DONE_RESULTS_SUCCEEDED: "LOAD_DONE_RESULTS_SUCCEEDED",
    LOAD_DONE_RESULTS_FAILED: "LOAD_DONE_RESULTS_FAILED",


    /**
     * @Author Jean-Paul LOUIDOR(Pablo ^(..)^)
     * LOAD REQUESTS STATUS OUT ACTION TYPES
     * -------------------------------------------
     * */
    LOAD_RESULT: "LOAD_RESULT",
    LOAD_RESULT_SUCCEEDED: "LOAD_RESULT_SUCCEEDED",
    LOAD_RESULT_FAILED: "LOAD_RESULT_FAILED",

    CHANGE_REQUEST_ARCHIVE_ALL:'CHANGE_REQUEST_ARCHIVE_ALL',
    CHANGE_REQUEST_ARCHIVE_ALL_SUCCEEDE:'CHANGE_REQUEST_ARCHIVE_ALL_SUCCEEDE',
    CHANGE_REQUEST_ARCHIVE_ALL_FAILLED:'CHANGE_REQUEST_ARCHIVE_ALL_FAILLED',


};


export default actionTypes;
