import * as React from "react";
import useDocumentTitle from "../../../../common/hooks/useDocumentTitle";
import actions from "../../../../../thunks/common/coreThunks";
import {getIntlMsg} from "../../../../../constants/utils";
import {injectIntl} from "react-intl";
import {useParams, withRouter} from "react-router";
import {connect, useDispatch, useSelector} from "react-redux";
import {reduxForm} from "redux-form";
import {validate} from "../validator";
import useEntityManager from "../../../../common/hooks/useEntityManager";
import FormItems from "./Items";
import FormFooter from "../../../common/form/FormFooter";
import {ComorbidityMapper, RequestMapper, Symptom, SymptomsMapper} from "../../../../../constants/models/request";
import {useEffect} from "react";


const SymptomForm = injectIntl((props) => {
    const {
        intl,
        handleSubmit,
        pristine,
        valid,
        submitting,
        reset,
        initialValues,
        history,
        module,
        symptomForm,
        id
    } = props;
    const {page, secondLevelPage} = useParams();
    // useDocumentTitle(getIntlMsg(intl, `app.label.${page}`));

    const dispatch = useDispatch();

    const options = {intl, options: ['categories', 'by-name', 'symptom'], item: {id: secondLevelPage}}
    const opts = {intl, options: ['categories', 'by-name', 'comorbidity'], item: {id: secondLevelPage}}


    useEffect(() => {
        dispatch(actions.loadItems(options))
        dispatch(actions.loadItems(opts))
    }, [secondLevelPage]);


    const params = {options: [page]};

    const {item, loading, symptoms, cormorbidities} = useSelector(state => ({
        loading: state.requestReducer.loadingItem,
        item: state.requestReducer.item,
        symptoms: state.categoryReducer.symptoms,
        cormorbidities: state.categoryReducer.cormorbidities,
    }));

    const onFormSubmit = element => {
        const entity = new Symptom(symptomForm);
        entity.id = item?.id
        dispatch(actions.saveOrUpdateItem({
            options: ['requests', 'notification', 'symptoms-comorbidity'], intl, action: '', item: entity,
        }))
    };

    return (
        <div className="tab-pane fade show" id="symptom" role="tabpanel" aria-labelledby="symptom">
            <form onSubmit={handleSubmit(onFormSubmit)}>
                <div>
                    <FormItems {...{validate, symptomForm, symptoms, cormorbidities}}/>
                    <FormFooter {...{item, loading, pristine, submitting, reset, valid, dispatch, history, module}}/>
                </div>
            </form>
        </div>
    );
});

const mapStateToProps = ({requestReducer: {item}, form}) => {

    const initVal = item ? new RequestMapper(item) : null;
    const sympts = item ? new SymptomsMapper(item?.notificationSheet) : null;
    const comorbi = item ? new ComorbidityMapper(item?.notificationSheet?.comorbidityHabits) : null;



    const defaultState = {}

    const initV = {
        ...sympts,
        ...initVal,
        ...comorbi,
    }


    return {
        initialValues: item !== null ? {...initV} : {...defaultState},
        symptomForm: form?.symptomForm?.values,
        id: initVal?.id
    }
};


export default React.memo(withRouter(connect(mapStateToProps,)(reduxForm({
    form: "symptomForm",
    enableReinitialize: true,
    validate,
})(SymptomForm))));

