import * as React from "react";
import {Link} from "react-router-dom";
import InnerMenu from "./InnerMenu";


const SubMenu = props => {

    const {title, url} = props

    return (
        <li className="menu-item menu-item-submenu" aria-haspopup="true"
            data-menu-toggle="hover">
            <Link to="javascript:;" className="menu-link menu-toggle">
                <i className="menu-bullet menu-bullet-line">
                    <span/>
                </i>
                <span className="menu-text">Users</span>
                {/*<span className="menu-label">*/}
                {/*    <span className="label label-rounded label-primary">6</span>*/}
                {/*</span>*/}
                {/*<i className="menu-arrow"/>*/}
            </Link>
            <div className="menu-submenu">
                <i className="menu-arrow"/>
                <ul className="menu-subnav">
                    {/* inner menu */}
                    {/*<InnerMenu/>*/}
                </ul>
            </div>
        </li>
    )
}

export default React.memo(SubMenu)

