const dashboardTypes = {

    REQUEST_MODULE: "requests",
    REQUEST_REGISTRATION_PAGE: "registration",
    REQUEST_REGISTRATION_CART_PAGE: "registration-cart",
    REQUEST_TRAVELER_REGISTRATION_PAGE: "traveler",
    REQUEST_STAFF_REGISTRATION_PAGE: "staff",
    REQUEST_DASHBOARD_PAGE: "requests-dashboard",
    REQUEST_REPORT_APPLICANT_PAGE: "applicants",
    CART_REPORT_PAGE: "cart-report",


    FINANCIAL_DASHBOARD_PAGE: "financial-dashboard",
    LEADERSHIP_DASHBOARD_PAGE: "leadership-dashboard",

    NOTIFICATION_REGISTRATION_PAGE: "feel-sheet",


    OBSERVATION_PAGE: "observations",
    OBSERVATION_PATIENT_PAGE: "patients",


    SETTINGS_MODULE: "settings",
    SETTINGS_EMAIL_PAGE: "email",
    SETTINGS_OTHER_PAGE: "other",


    REPORT_MODULE: "reports",
    SECURITY_MODULE: "security",
    SECURITY_DASHBOARD_PAGE: "security-dashboard",
    SECURITY_USER_DETAIL_PAGES: "details",
    SECURITY_INSTITUTION_PAGES: "institutions",


    SHARING_MODULE: "sharing",
    SHARING_PARTNER_PAGE: "partners",


};


export default dashboardTypes;
