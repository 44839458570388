import {useCallback, useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import actions from "../../../thunks/common/coreThunks";

function useEntityManager(params, page) {

    const dispatch = useDispatch();


    /**
     * @Author Rikenson JACQUES (The Tiger ^(..)^)
     * @Desc: load item from displayed list by id
     * */
    const handleLoadItem = useCallback(
        (item) => {
            dispatch(actions.loadItem(
                {...params, item: item}))
        }, [page, dispatch, params]
    );



    /**
     * @Author Rikenson JACQUES (The Tiger ^(..)^)
     * @Desc: load items list by page
     * */
    const handleLoadItems = useCallback(() => dispatch(actions.loadItems(params)), [dispatch, page]);


    /**
     * @Author Rikenson JACQUES (The Tiger ^(..)^)
     * @Desc: load item from displayed list by id
     * */
    const handleDeleteItem = useCallback(
        (item) => dispatch(actions.removeItem({...params, item: item})),
        [dispatch, params]
    );


    /**
     * @Author Rikenson JACQUES (The Tiger ^(..)^)
     * @Desc: delete item from displayed list by id
     * */
    useEffect(() => {
        page && (page !== 'settings') && handleLoadItems();
    }, [page, handleLoadItems]);

    return [handleLoadItem, handleLoadItems, handleDeleteItem]
}

export default useEntityManager;
