import types from "../../actions/types/observation/patientActionTypes";
import initialState from "../../../constants/utils/initialState";

const patientReducer = (state = initialState.patientReducer, action) => {

        switch (action.type) {

            /**
             * LOAD PATIENT LIST
             * */
            case types.RESET_PATIENT:
                return {...state, item: {}};

            /**
             * LOAD PATIENT LIST
             * */
            case types.LOAD_PATIENTS:
                return {...state, loadingItems: action.payload};
            case types.LOAD_PATIENTS_SUCCEEDED:
                return {...state, items: action.payload, loadingItems: false};
            case types.LOAD_PATIENTS_FAILED:
                return {...state, loadingItems: action.payload};


            /**  LOAD PATIENT BY A SPECIFIC PROPERTY CASE */
            case types.LOAD_PATIENT:
                return {...state, loadingItem: true};
            case types.LOAD_PATIENT_SUCCEEDED:
                return {...state, item: action.payload, loadingItem: false};
            case types.LOAD_PATIENT_FAILED:
                return {...state, loadingItem: false};


            /**  SAVE PATIENT CASE */
            case types.SAVE_PATIENT:
                return {...state, savingOrUpdating: true};
            case types.SAVE_PATIENT_SUCCEEDED:
                return {
                    ...state, items: [...state.items, action.payload],
                    item: action.payload,
                    savingOrUpdating: false
                };
            case types.SAVE_PATIENT_FAILED:
                return {...state, savingOrUpdating: false};



            /**  UPDATE PATIENT CASE  */

            case types.UPDATE_PATIENT:
                return {...state, savingOrUpdating: true};
            case types.UPDATE_PATIENT_SUCCEEDED:
                return {
                    ...state,
                    items: state.items.map(item => item.id === action.payload.id ? action.payload : item),
                    item: action.payload
                };
            case types.UPDATE_PATIENT_FAILED:
                return {...state, savingOrUpdating: false};



            /**  DELETE PATIENT CASE  */
            case types.DELETE_PATIENT:
                return {...state, deleting: true};
            case types.DELETE_PATIENT_SUCCEEDED:
                return (action.payload.status === 200) ?
                    {...state, items: state.items.filter(item => item.id !== action.payload.item.id), deleting: false}
                    : state;
            case types.DELETE_PATIENT_FAILED:
                return {...state, deleting: false};


            default:
                return state;
        }
    }
;

export default patientReducer;
