import * as React from "react";
import {useContext} from "react";
import ChatFooter from "./ChatFooter";
import Message from "./Message";
import {GlobalContext} from "../../../index";


const QuickChatAction = props => {

    const {} = props

    const {institution: {acronym}} = useContext(GlobalContext);

    const chat = {
        user: {
            picture: `rj.jpg`,
            name: `Rikenson JACQUES`,
            sentSince: `2 Heures`,
            message: `Zanmi sa k\'ap fet?`
        },
        friend: {
            picture: `jp.jpg`,
            name: `Jean-Paul LOUIDOR`,
            sentSince: `30 Sec.`,
            message: `Apa nou papa, poze. e ou?`
        },
    }


    return (
        <div className="modal modal-sticky modal-sticky-bottom-right" id="kt_chat_modal" role="dialog"
             data-backdrop="false">
            <div className="modal-dialog" role="document">
                <div className="modal-content">
                    <div className="card card-custom">
                        <div className="card-header align-items-center px-4 py-3">
                            <div className="text-left flex-grow-1">
                                <div className="dropdown dropdown-inline">
                                    <button type="button" className="btn btn-clean btn-sm btn-icon btn-icon-md"
                                            data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
										<span className="svg-icon svg-icon-lg">
											<svg width="24px" height="24px"
                                                 viewBox="0 0 24 24" version="1.1">
												<g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
													<polygon points="0 0 24 0 24 24 0 24"/>
													<path
                                                        d="M18,8 L16,8 C15.4477153,8 15,7.55228475 15,7 C15,6.44771525 15.4477153,6 16,6 L18,6 L18,4 C18,3.44771525 18.4477153,3 19,3 C19.5522847,3 20,3.44771525 20,4 L20,6 L22,6 C22.5522847,6 23,6.44771525 23,7 C23,7.55228475 22.5522847,8 22,8 L20,8 L20,10 C20,10.5522847 19.5522847,11 19,11 C18.4477153,11 18,10.5522847 18,10 L18,8 Z M9,11 C6.790861,11 5,9.209139 5,7 C5,4.790861 6.790861,3 9,3 C11.209139,3 13,4.790861 13,7 C13,9.209139 11.209139,11 9,11 Z"
                                                        fill="#000000" fillRule="nonzero" opacity="0.3"/>
													<path
                                                        d="M0.00065168429,20.1992055 C0.388258525,15.4265159 4.26191235,13 8.98334134,13 C13.7712164,13 17.7048837,15.2931929 17.9979143,20.2 C18.0095879,20.3954741 17.9979143,21 17.2466999,21 C13.541124,21 8.03472472,21 0.727502227,21 C0.476712155,21 -0.0204617505,20.45918 0.00065168429,20.1992055 Z"
                                                        fill="#000000" fillRule="nonzero"/>
												</g>
											</svg>
										</span>
                                    </button>

                                </div>

                            </div>
                            <div className="text-center flex-grow-1">
                                <div className="text-dark-75 font-weight-bold font-size-h5">{chat.user.name}</div>
                                <div>
                                    <span className="label label-dot label-success"/>
                                    <span className="font-weight-bold text-muted font-size-sm">Active</span>
                                </div>
                            </div>
                            <div className="text-right flex-grow-1">
                                <button type="button" className="btn btn-clean btn-sm btn-icon btn-icon-md"
                                        data-dismiss="modal">
                                    <i className="ki ki-close icon-1x"/>
                                </button>
                            </div>
                        </div>

                        <div className="card-body">
                            <div className="scroll scroll-pull" data-height="375" data-mobile-height="300">
                                <div className="messages">

                                    <Message user={chat.user} position={`align-items-start`} msgColor={`bg-light-primary`}/>
                                    <Message user={chat.friend} position={`align-items-end`} msgColor={`bg-light-success`}/>

                                </div>
                            </div>

                        </div>

                        <ChatFooter/>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default React.memo(QuickChatAction)

