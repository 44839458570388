import * as React from "react";
import useDocumentTitle from "../../../common/hooks/useDocumentTitle";
import {getIntlMsg} from "../../../../constants/utils";
import {injectIntl} from "react-intl";
import {useParams, withRouter} from "react-router";
import PartnerList from "./listing";
import PartnerForm from "./form";
import {useState} from "react";


const PartnerPageManager = injectIntl((props) => {
    const {intl} = props;
    const {module} = useParams();
    useDocumentTitle(getIntlMsg(intl, `app.label.${module}`));
    const [partnerFormView, setPartnerFormView] = useState(false)

    return (
            <div className="d-flex flex-column-fluid mt-10">
                <div className="container">
                    {partnerFormView
                        ? <PartnerForm {...{setPartnerFormView}}/>
                        : <PartnerList {...{partnerFormView, setPartnerFormView}}/>
                    }
                </div>
            </div>
    );
})

export default React.memo(withRouter(PartnerPageManager))

