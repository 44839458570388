import React from "react"
import {FormattedMessage, injectIntl} from "react-intl";


const ChangeStateModal = injectIntl(props => {

    const {title, handleStateChange,id} = props

    return (
        <div className="modal fade show" id={`${title}${id}`} tabIndex="-1" aria-labelledby={title}
             style={{display: "none", paddingRight: "15px"}} aria-modal="true" role="dialog">
            <div className="modal-dialog modal-dialog-centered modal-md" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id={title}>
                            <i className="flaticon2-warning mr-3 text-primary"/>
                            <FormattedMessage id={`app.label.send_to_${title}`}/>
                        </h5>
                    </div>
                    <div className="modal-body ">
                        <span className="text-center">
                            <FormattedMessage id={`app.label.caution_send_to_${title}`}/>
                        </span>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-outline-primary font-weight-bold" data-dismiss="modal">
                            <FormattedMessage id={`app.label.no`}/>
                        </button>
                        <button type="button" className="btn btn-primary font-weight-bold"
                                onClick={()=>handleStateChange(title)} data-dismiss="modal">
                            <FormattedMessage id={`app.label.yes`}/>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
});


export default ChangeStateModal

