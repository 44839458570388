import React, {useEffect} from "react"
import {useDispatch, useSelector} from "react-redux";
import {getIntlMsg} from "../../../constants/utils";
import {injectIntl} from "react-intl";
import actions from "../../../thunks/common/coreThunks";
import {useParams} from "react-router";


const CheckBoxFilter = injectIntl(({intl, criteria, i18nable = false}) => {
    const dispatch = useDispatch();
    const {page, id} = useParams();

    const withIdOptions = {options: [page, 'status', id]};
    const options = {options: [page]};

    useEffect(() => {
        dispatch(actions.loadItems(criteria));
    }, [dispatch]);


    const getItemsByEntityName = entityName => {
        switch (entityName) {
            case 'customers':
                return state => state.categoryReducer.customerTypes;
            case 'documents':
                return state => state.categoryReducer.documentTypes;
            case 'sheets':
                return state => state.categoryReducer.sheetTypes;
            case 'categories':
                return state => state.categoryReducer.lovItems;
            default:
                return state => state.categoryReducer.dropDownItems;
        }
    }

    const items = useSelector(getItemsByEntityName(page));

    function filterByCriteria(filter) {
        dispatch(actions.loadItems(id ? {...withIdOptions, filter} : {...options, filter}));

    }


    return (
        <select className="custom-select custom-select-sm ml-3 mr-0" id="sorting" style={{height:"40px"}}
                onChange={(e) => filterByCriteria(e.target.value)}>
            <option value="all">
                {getIntlMsg(intl, `app.label.all`)}
            </option>
            {items.map(item => (
                <option value={item?.name ? item.name : item} key={item?.id ? item.id : item}>
                    {i18nable ? getIntlMsg(intl, `app.label.${item?.name ? item.name.toLowerCase() : item.toLowerCase()}`) : item ? item.name : item}
                </option>
            ))}
        </select>
    )
});


export default CheckBoxFilter

