import React from "react"
import {Field} from "redux-form";
import {renderField} from "../../../../../../common/utils/FormComponent";
import {withRouter} from "react-router";
import {useSelector} from "react-redux";
import {FormattedMessage} from "react-intl";
import {validate} from "../validator";

let FormItems = props => {

    const {touched, error} = props
    const {values} = useSelector(state => ({
        values: state.form?.requestForm?.values
    }));

    const isTraveler = values.type === 'TRAVELER'

    const travelTypes = ['MONCASH', 'DEPOSIT', 'CASH', 'FREE']
    const staffTypes = ['FREE']
    const paymentTypes = isTraveler ? travelTypes : staffTypes



    return (
        <div>
            <div className="row">
                <div className="col-sm-12">

                    <div className="form-group">
                        <label className="font-weight-bold">
                            <FormattedMessage id={`app.label.payment_type`}/>
                            <span className="text-primary"> * </span> :
                        </label>
                        <div className="radio-inline">
                            {paymentTypes.map((element, i) =>
                                <label className="radio radio-lg" key={i}>
                                    <Field type="radio" component="input" name="paymentType" value={element}/>
                                    <span/>
                                    <FormattedMessage id={`app.label.${element}`}/>
                                </label>
                            )}
                        </div>
                        {touched && error &&
                        <span className="form-text text-primary">
                            <FormattedMessage id={error}/>
                        </span>}
                    </div>
                </div>

                {values?.paymentType === 'DEPOSIT' &&
                <div className="col-sm-12">
                    <Field className="form-control form-control-sm" id="trx_code" name="trxCode"
                           component={renderField} type="text" label="trx_code" placeholder="trx_code"
                           validate={validate.error} required/>
                </div>}

                {values?.paymentType === 'MONCASH' &&
                <div className="col-sm-12">
                    <Field className="form-control form-control-sm" id="trx_code" name="trxCode"
                           component={renderField} type="text" label="trx_code" placeholder="trx_code"
                           validate={validate.error} required/>
                </div>}
            </div>

        </div>

    );
}
export default React.memo(withRouter(FormItems))
