import * as React from "react";
import useDocumentTitle from "../../../../common/hooks/useDocumentTitle";
import {getIntlMsg} from "../../../../../constants/utils";
import {injectIntl} from "react-intl";
import {useParams, withRouter} from "react-router";
import ItemPriceList from "./listing";
import ItemPriceForm from "./form";
import {useState} from "react";


const ItemPricePageManager = injectIntl((props) => {
    const {intl} = props;
    const {module} = useParams();
    useDocumentTitle(getIntlMsg(intl, `app.label.${module}`));
    const [itemPriceFormView, setItemPriceFormView] = useState(false)

    return (
        <div className="tab-pane fade" id="itemprice" role="tabpanel" aria-labelledby="itemprice">
            <div className="cared card-custom pl-0">
                {itemPriceFormView && <ItemPriceForm {...{setItemPriceFormView}}/>}
                <ItemPriceList {...{itemPriceFormView, setItemPriceFormView}}/>
            </div>
        </div>
    );
})

export default React.memo(withRouter(ItemPricePageManager))

