const TEST_INDICATORActionTypes = {

    /**
     * @Author Rikenson JACQUES (The Tiger ^(..)^)
     * RESET TEST_INDICATOR ACTION TYPES
     * -------------------------------------------
     * */
    RESET_TEST_INDICATOR: "RESET_TEST_INDICATOR",

    /**
     * @Author Rikenson JACQUES (The Tiger ^(..)^)
     * LOAD TEST_INDICATORS ACTION TYPES
     * -------------------------------------------
     * */
    LOAD_TEST_INDICATORS: "LOAD_TEST_INDICATORS",
    LOAD_TEST_INDICATORS_SUCCEEDED: "LOAD_TEST_INDICATORS_SUCCEEDED",
    LOAD_TEST_INDICATORS_FAILED: "LOAD_TEST_INDICATORS_FAILED",


    /**
     * @Author Rikenson JACQUES (The Tiger ^(..)^)
     * LOAD SINGLE TEST_INDICATOR ACTION TYPES
     * -------------------------------------------
     * */
    LOAD_TEST_INDICATOR: "LOAD_TEST_INDICATOR",
    LOAD_TEST_INDICATOR_SUCCEEDED: "LOAD_TEST_INDICATOR_SUCCEEDED",
    LOAD_TEST_INDICATOR_FAILED: "LOAD_TEST_INDICATOR_FAILED",


    /**
     * @Author Rikenson JACQUES (The Tiger ^(..)^)
     * SAVE TEST_INDICATOR ACTION TYPES
     * -------------------------------------------
     * */
    SAVE_TEST_INDICATOR: "SAVE_TEST_INDICATOR",
    SAVE_TEST_INDICATOR_SUCCEEDED: "SAVE_TEST_INDICATOR_SUCCEEDED",
    SAVE_TEST_INDICATOR_FAILED: "SAVE_TEST_INDICATOR_FAILED",


    /**
     * @Author Rikenson JACQUES (The Tiger ^(..)^)
     * UPDATE TEST_INDICATOR ACTION TYPES
     * -------------------------------------------
     * */
    UPDATE_TEST_INDICATOR: "UPDATE_TEST_INDICATOR",
    UPDATE_TEST_INDICATOR_SUCCEEDED: "UPDATE_TEST_INDICATOR_SUCCEEDED",
    UPDATE_TEST_INDICATOR_FAILED: "UPDATE_TEST_INDICATOR_FAILED",


    /**
     * @Author Rikenson JACQUES (The Tiger ^(..)^)
     * DELETE TEST_INDICATOR ACTION TYPES
     * -------------------------------------------
     * */
    DELETE_TEST_INDICATOR: "DELETE_TEST_INDICATOR",
    DELETE_TEST_INDICATOR_SUCCEEDED: "DELETE_TEST_INDICATOR_SUCCEEDED",
    DELETE_TEST_INDICATOR_FAILED: "DELETE_TEST_INDICATOR_FAILED",


};


export default TEST_INDICATORActionTypes;
