export class Institution {

    constructor(params) {
        this.id = params.id;
        this.phones = params.phones ? [...params.phones] : [];
        this.banks = params.banks ? [...params.banks] : [];
        this.emails = params.emails ? [...params.emails] : [];
        this.address = {locality: params.locality, no: params.no};
        this.acronym = params.acronym;
        this.description = params.description;
        this.setting = {}
    }


}

export class InstitutionMapper {

    constructor(params) {
        this.id = params.id;
        this.phones = params.phones ? [...params.phones] : [];
        this.banks = params.banks ? [...params.banks] : [];
        this.emails = params.emails ? [...params.emails] : [];
        this.locality = params?.address?.locality;
        this.full = `${params?.address?.no} ${params?.address?.locality}`;
        this.no = params?.address?.no;
        this.acronym = params.acronym;
        this.description = params.description;
        this.setting = params.setting;
    }


}
