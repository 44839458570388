import React from "react"
import {Field, FieldArray} from "redux-form";
import {FormattedMessage} from 'react-intl';
import {renderRadio} from "../../../../common/utils/FormComponent";
import {withRouter} from "react-router";
import renderContact from "./contact";
import renderTrip from "./trip";

let FormItems = ({item, fields, validate, values, travelForm}) => {

    const status = ['yes', 'no', 'unknown']


    const options = [
        'contact_with_traveled_contaminated',
        'visit_covid19_health_center',
        'contact_with_contaminated',
    ]

    const performAddContact = fields => {
        // fields.push()
        console.log({...FieldArray}, '******************')
    }


    return (
        <React.Fragment>
            {values?.symptoms === 'yes' ?
                <div className={`custom_fieldset mt-4`}>

                    <div className="row mt-3">
                        <div className="col-sm-12 mb-3">
                            <h4><FormattedMessage id={`app.label.contact_14_days`}/></h4>
                        </div>

                        {options.map((item, i) =>
                            <div className="col-sm-4" key={i}>
                                <div className="form-group">
                                    <label>
                                        <FormattedMessage id={`app.label.${item}`}/>
                                        <span className="text-primary"> * </span> :
                                    </label>
                                    <div className="radio-inline">
                                        {status.map((element, ii) =>
                                            <label className="radio radio-lg" key={ii}>
                                                <Field type="radio" component="input" name={item} value={element}/>
                                                <span/>
                                                <FormattedMessage id={`app.label.${element}`}/>
                                            </label>
                                        )}
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>

                    <div className="row mt-3">
                        {travelForm?.visit_covid19_health_center === 'yes' || travelForm?.contact_with_contaminated === 'yes' || travelForm?.contactWithCovid19Dead === 'yes' ? <>

                            <div className="col-sm-12">
                                <FieldArray name="covid19Contacts" component={renderContact}/>
                            </div>
                        </> : null}
                    </div>

                    <div className="row mt-3">
                        {travelForm?.contact_with_traveled_contaminated === 'yes' ?
                            <div className="col-sm-12 mb-3">
                                <h4><FormattedMessage id={`app.label.travel_history_14_days`}/></h4>

                                <div className="col-sm-12">

                                    <FieldArray name="covid19trips" component={renderTrip}/>
                                </div>

                            </div> : null}
                    </div>

                </div>
                : null}
        </React.Fragment>

    );
}
export default React.memo(withRouter(FormItems))
