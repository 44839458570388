import * as React from "react";
import {Bar} from "react-chartjs-2";
import {FormattedMessage} from "react-intl";


const ResultDistribution = props => {

    const {intl, statusData} = props


    const legend = {
        display: true,
        position: "bottom",
        labels: {
            fontColor: "#127444",
            fontSize: 14
        }
    };


    return (

        <div className="card card-custom">
            <div className="card-header">
                <div className="card-title">
                    <h3 className="card-label">
                        <FormattedMessage id="app.label.request_by_status"/> {" "}
                        <small>
                            <FormattedMessage id="app.label.request_by_status_desc"/>
                        </small>
                    </h3>
                </div>
            </div>
            <div className="card-body">
                <Bar data={statusData} legend={legend}/>
            </div>
        </div>

    )
        ;
}

export default React.memo(ResultDistribution)

