import * as React from "react";
import {Bar} from "react-chartjs-2";
import {FormattedMessage} from "react-intl";


const ResultDistribution = props => {

    const {intl, amountData} = props


    const legend = {
        display: true,
        position: "bottom",
        labels: {
            fontColor: "#127444",
            fontSize: 14
        }
    };
   const options={
        scales: {
            yAxes: [{
                ticks: {
                    beginAtZero: true
                }
            }]
        }
    }


    return (

        <div className="card card-custom">
            <div className="card-header">
                <div className="card-title">
                    <h3 className="card-label">
                      Rapport de caisse par type de monnaie
                    </h3>
                </div>
            </div>
            <div className="card-body">
                <Bar data={amountData} legend={legend} options={options}/>
            </div>
        </div>

    )
        ;
}

export default React.memo(ResultDistribution)

