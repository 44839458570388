import * as React from "react";
import {useState} from "react";
import {FormattedMessage, injectIntl} from "react-intl";
import {useParams, withRouter} from "react-router";
import actions from "../../../../../../thunks/common/coreThunks";
import {useDispatch, useSelector} from "react-redux";
import Item from "./Item";
import SearchBar from "./SearchBar";
import {useEffect} from "react";
import {getIntlMsg} from "../../../../../../constants/utils";
import Spinner from "../../../../../common/utils/Spinner";
import TablePager from "../../../../common/pagination/TablePager";
import TableList from '../../../../common/tables'


const IndicatorList = injectIntl((props) => {

    const dispatch = useDispatch();
    const {intl, formView, setFormView} = props;
    const {page, id} = useParams();


    const criteria = {options: ['indicators']};
    const options = {options: ['indicators', 'names']};

    useEffect(() => {
        dispatch(actions.loadItems(criteria));
    }, [dispatch]);

    const {loading, items} = useSelector(state => ({
        loading: state?.testIndicatorReducer?.loadingItems,
        items: state?.testIndicatorReducer?.items,
    }));

    const [data, setData] = useState([]);


    const columns=[ {
        title: getIntlMsg(intl,'app.label.indicator'),
        field: 'name', type: 'text'
    },
        {
            title: getIntlMsg(intl,'app.label.collection_date'),
            field: 'collectDate', type: 'text'
        },
        {
            title: getIntlMsg(intl,'app.label.quantity'),
            field: 'quantity', type: 'text'
        },
        {
            title: getIntlMsg(intl,'app.label.site'),
            field: 'site', type: 'text'
        },
    ]

    const onFormSubmit = element => {

        console.log(data)
        dispatch(actions.saveOrUpdateItem({options: ['indicators', 'upload'], intl, item: data}))
    };

    console.log("++++++++++++++============",data)
    const tableData=[]

    return (
        <React.Fragment>

            <h5 className="text-black-50 mb-5">
                <FormattedMessage id="app.label.all_test_indicators"/>
            </h5>
            <div className="row">
                <div className="col-md-12 float-right">
                    <SearchBar {...{formView, setFormView, options,setData,data,onFormSubmit}}/>
                </div>
            {/*<div*/}
                {/*className="datatable datatable-bordered datatable-head-custom datatable-default datatable-primary datatable-loaded"*/}
                {/*id="kt_datatable_0" style={{}}>*/}
                <div className="col-md-12">
                <TableList columns={columns} data={data}/>
                </div>
            </div>
                {/*<table className="datatable-table" style={{display: "block"}}>*/}
                    {/*<thead className="datatable-head">*/}
                    {/*<tr className="datatable-row" style={{left: "0px"}}>*/}

                        {/*<th className="datatable-cell datatable-cell-sort">*/}
                        {/*<span style={{width: "400px"}}>*/}
                            {/*<FormattedMessage id={`app.label.indicator`}/>*/}
                        {/*</span>*/}
                        {/*</th>*/}

                        {/*<th className="datatable-cell datatable-cell-sort">*/}
                        {/*<span style={{width: "100px"}}>*/}
                            {/*<FormattedMessage id={`app.label.collection_date`}/>*/}
                        {/*</span>*/}
                        {/*</th>*/}

                        {/*<th className="datatable-cell datatable-cell-sort">*/}
                        {/*<span style={{width: "100px"}}>*/}
                            {/*<FormattedMessage id={`app.label.quantity`}/>*/}
                        {/*</span>*/}
                        {/*</th>*/}

                        {/*<th className="datatable-cell datatable-cell-sort">*/}
                        {/*<span style={{width: "100px"}}>*/}
                            {/*<FormattedMessage id={`app.label.site`}/>*/}
                        {/*</span>*/}
                        {/*</th>*/}

                        {/*<th data-autohide-disabled="false"*/}
                            {/*className="datatable-cell datatable-cell-sort">*/}
                            {/*<span style={{width: "125px"}}>Actions</span>*/}
                        {/*</th>*/}
                    {/*</tr>*/}
                    {/*</thead>*/}
                    {/*<tbody className="datatable-body" style={{}}>*/}
                    {/*{loading ?*/}
                        {/*<tr>*/}
                            {/*<td>*/}
                                {/*<Spinner/>*/}
                            {/*</td>*/}
                        {/*</tr>*/}
                        {/*: items.map((item, i) => <Item {...{...item, setFormView}} key={i}/>)}*/}
                    {/*</tbody>*/}
                {/*</table>*/}
                {/*<TablePager/>*/}
            {/*</div>*/}
        </React.Fragment>
    );
})

export default React.memo(withRouter(IndicatorList))

