import * as React from "react";
import {FormattedMessage, injectIntl} from "react-intl";
import {withRouter} from "react-router";
import {getIntlMsg} from "../../../../../../../constants/utils";
import ChangeStateModal from "../../../../../common/modal/ChangeState";
import {useReactToPrint} from "react-to-print";
import {useRef} from "react";
import RequestForm from "../../../reports/traveler/RequestForm";


const ArchivedBtn = injectIntl(props => {

    const {intl, item, handleStateChange} = props;
    const receiptRef = useRef();

    const handlePrintForm = () => {
    }

    const handlePrintReceipt = useReactToPrint({
        content: () => receiptRef.current,
    });

    return (

        <div className="btn-group btn-group-sm" role="group" aria-label="Basic example">

            <div className="btn-group btn-group-sm" role="group">
                <button id="btnGroupDrop1" type="button"
                        className="btn btn-light-primary dropdown-toggle"
                        data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    <FormattedMessage id={`app.label.restore_to`}/>
                </button>

                <div className="dropdown-menu" aria-labelledby="btnGroupDrop1">
                    {/*<span className="dropdown-item cursor-pointer"*/}
                    {/*      data-toggle="modal" data-target={`#reception${item?.id}`}*/}
                    {/*      title={getIntlMsg(intl, `app.label.send_to_reception`)}>*/}
                    {/*    <FormattedMessage id={`app.label.reception`}/>*/}
                    {/*</span>*/}
                    {/*<span className="dropdown-item cursor-pointer"*/}
                    {/*      data-toggle="modal" data-target={`#cart${item?.id}`}*/}
                    {/*      title={getIntlMsg(intl, `app.label.send_to_cart`)}>*/}
                    {/*    <FormattedMessage id={`app.label.cart`}/>*/}
                    {/*</span>*/}
                    {/*<span className="dropdown-item cursor-pointer"*/}
                    {/*      data-toggle="modal" data-target={`#specimens${item?.id}`}*/}
                    {/*      title={getIntlMsg(intl, `app.label.send_to_specimens`)}>*/}
                    {/*    <FormattedMessage id={`app.label.specimens`}/>*/}
                    {/*</span>*/}
                    <span className="dropdown-item cursor-pointer"
                          data-toggle="modal" data-target={`#results${item?.id}`}
                          title={getIntlMsg(intl, `app.label.send_to_results`)}>
                        <FormattedMessage id={`app.label.results`}/>
                    </span>
                    {/*<span className="dropdown-item cursor-pointer"*/}
                    {/*      data-toggle="modal" data-target={`#notification${item?.id}`}*/}
                    {/*      title={getIntlMsg(intl, `app.label.send_to_notification`)}>*/}
                    {/*    <FormattedMessage id={`app.label.notification`}/>*/}
                    {/*</span>*/}
                </div>

                <div  style={{ display: "none",overflow: "hidden", height: 0 }}>
                    <RequestForm ref={receiptRef} {...{item}}/>
                </div>
            </div>

            <button type="button" className="btn btn-light-primary" onClick={handlePrintReceipt}>
                <FormattedMessage id={`app.label.print_form`}/>
            </button>

            <ChangeStateModal {...{title: 'reception', handleStateChange,id:item?.id}}/>
            <ChangeStateModal {...{title: 'cart', handleStateChange,id:item?.id}}/>
            <ChangeStateModal {...{title: 'specimens', handleStateChange,id:item?.id}}/>
            <ChangeStateModal {...{title: 'results', handleStateChange,id:item?.id}}/>
            <ChangeStateModal {...{title: 'notification', handleStateChange,id:item?.id}}/>
        </div>

    );
});


export default React.memo(withRouter(ArchivedBtn))
