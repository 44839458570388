import React from "react";
import moment from "moment";
import Item from "./Item";
import PageHeader from "../../../../common/report/PageHeader";
import {FormattedMessage} from "react-intl";


class Index extends React.Component {


    render() {

        const {clients,email,name} = this.props;

        let sumAg = 0;
        let sumPcr = 0;
        let sumGds = 0;
        let sumUsd = 0;


        return (
            <div className="d-flex flex-column-fluid">

                <div className="container">

                    <div className="card card-custom overflow-hidden">
                        <div className="card-body p-0">

                            <div className="row justify-content-center py-8 px-8 10 px-md-0">
                                <div className="col-md-12">
                                    <div
                                        className="d-flex justify-content-between pb-5 pb-md-5 flex-column flex-md-row">
                                        <h1 className="display-4 font-weight-boldest mb-10" style={{textAlign:'right'}}>RAPPORT DE CAISSE</h1>
                                        <div className="d-flex flex-column align-items-md-end px-0 float-right">
                                            <a href="#" className="mb-5">
                                                <img src="/assets/media/logos/zl.png"
                                                     className="h-100px rounded" alt=""/>
                                            </a>
                                            <span className="d-flex flex-column align-items-md-end">
															<span><b>HOPITAL UNIVERSITAIRE DE MIREBALAIS (HUM)</b></span>
															<span>Laboratoire Régional de Référence Stephen Robert et Pilar Crespi Robert</span>
															<span>Laboratoire de Microbiologie et de Biologie Moléculaire (BSL-2, BSL3)</span>
														</span>
                                        </div>
                                    </div>
                                    <div className="border-bottom w-100"></div>
                                    <div className="d-flex justify-content-between pt-6">
                                        <div className="d-flex flex-column flex-root">
                                            <span className="font-weight-bolder mb-2">Date</span>
                                            <span
                                                className="">{moment(new Date()).format('DD/MM/YYYY')}</span>
                                        </div>
                                        <div className="d-flex flex-column flex-root">

                                        </div>
                                        <div className="d-flex flex-column flex-root">
                                            <span className="font-weight-bolder mb-2">Caissier (ère).</span>
                                            <span className="">{name && name}
                                                <br></br>{email && email}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row justify-content-center py-8 px-8 py-md-5 px-md-0">
                                <div className="col-md-9">
                                    <div className="table-responsive">
                                        <table className="table">
                                            <thead>
                                            <tr>
                                                <th className="pl-0 font-weight-bold  text-uppercase text-center">Client</th>
                                                <th className="text-right font-weight-bold  text-uppercase text-center">Test
                                                    Ag
                                                </th>
                                                <th className="text-right pr-0 font-weight-bold  text-uppercase text-center">Test
                                                    PCR
                                                </th>
                                                <th className="text-right pr-0 font-weight-bold  text-uppercase text-center">Montant
                                                    GDS
                                                </th>
                                                <th className="text-right pr-0 font-weight-bold  text-uppercase text-center">Montant
                                                    USD
                                                </th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {clients.length > 0 ? clients.map(v => <Item item={v}/>) : null}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                            <div className="row justify-content-center bg-gray-100 py-8 px-8 py-md-10 px-md-0">
                                <div className="col-md-9">
                                    <div className="table-responsive">
                                        <table className="table">
                                            <thead>
                                            <tr>
                                                <th className="font-weight-bold text-center text-uppercase"></th>
                                                <th className="font-weight-bold text-center text-uppercase">Test Ag</th>
                                                <th className="font-weight-bold text-center text-uppercase">Test PCR</th>
                                                <th className="font-weight-bold text-center text-uppercase">Montant GDS
                                                </th>
                                                <th className="font-weight-bold text-center text-uppercase">Montant USD
                                                </th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            <tr className="font-weight-bolder">
                                                <td>Total</td>
                                                <td className={'font-size-h3 text-center'}>{clients && clients.map(value => {

                                                    if (value.agCounter === 1) {
                                                        sumAg = parseInt(sumAg) + parseInt(value.agCounter);

                                                    }
                                                })}{sumAg}</td>
                                                <td className={'font-size-h3 text-center'}>{clients && clients.map(value => {
                                                    if (value.pcrCounter === 1) {
                                                        sumPcr = sumPcr + value.pcrCounter;
                                                    }
                                                })}{sumPcr}</td>
                                                <td className="font-size-h3 font-weight-boldest text-center">{clients && clients.map(value => {
                                                    let s = 0;
                                                    if (value.gdsAmount !== null) {
                                                        sumGds = sumGds + value.gdsAmount;
                                                    }
                                                })} {sumGds}</td>
                                                <td className="font-size-h3 font-weight-boldest text-center">{clients && clients.map(value => {
                                                    let s = 0;
                                                    if (value.usdAmount !== null) {
                                                        sumUsd = sumUsd + value.usdAmount;
                                                    }
                                                })}{sumUsd}</td>
                                            </tr>

                                            </tbody>
                                        </table>
                                    </div>

                                </div>
                            </div>
                            <div className="pt-10" style={{width:'300px'}}>
                                <h5>
                                    Signature :
                                </h5>
                                <hr style={{marginTop: "50px"}}/>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
export default Index