import * as React from "react";
import {useContext} from "react";
import Thumbnail from "./Thumbnail";
import {FormattedMessage} from "react-intl";
import {Link} from "react-router-dom";
import {GlobalContext} from "../../../index";


const QuickNotificationAction = props => {

    const {} = props

    const {institution: {acronym}} = useContext(GlobalContext);
    const notifications = [
        {notifyOn: "09:30 AM", description: "Louidor effectue une demande de test covid19"},
        {notifyOn: "4:30 PM", description: "Rikenson vient de terminer son prelevement"},
    ]

    return (
        <div id="kt_quick_panel" className="offcanvas offcanvas-left pt-5 pb-10">
            <div
                className="offcanvas-header offcanvas-header-navs d-flex align-items-center justify-content-between mb-5">
                <ul className="nav nav-bold nav-tabs nav-tabs-line nav-tabs-line-3x nav-tabs-primary flex-grow-1 px-10"
                    role="tablist">
                    <li className="nav-item">
                        <Link className="nav-link active" data-toggle="tab" to={`/${acronym}/notifications`}>
                            <FormattedMessage id={`app.label.notifications`}/>
                        </Link>
                    </li>
                </ul>
                <div className="offcanvas-close mt-n1 pr-5">
                    <a href="#" className="btn btn-xs btn-icon btn-light btn-hover-primary" id="kt_quick_panel_close">
                        <i className="ki ki-close icon-xs text-muted"/>
                    </a>
                </div>
            </div>

            <div className="offcanvas-content px-10">
                <div className="tab-content">

                    {/*<Tab Item*/}
                    <div className="tab-pane fade show pt-3 pr-5 mr-n5 active" id="kt_quick_panel_logs" role="tabpanel">
                        <div className="mb-15">
                            <h5 className="font-weight-bold mb-5">
                                <FormattedMessage id={`app.label.last_notifications`}/>
                            </h5>
                            <div className="timeline timeline-5">
                                <div className="timeline-items">
                                    {notifications.map((notification, i) => <Thumbnail {...notification} key={i}/>)}
                                    {/*Message::Item*/}


                                    {/*<Thumbnail/>*/}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default React.memo(QuickNotificationAction)

