import * as React from "react";
import {useParams, withRouter} from "react-router";
import pages from "../../../../constants/options/pages";
import ObsHomePage from "./dashboard";

export const ObsPageManager = () => {
    const {page} = useParams()

    return _onLoadPage(page);
}

function _onLoadPage(page) {
    switch (page) {
        case pages.
            OBSERVATION_PATIENT_PAGE:
            return null;
        default: {
            return <ObsHomePage/>
        }
    }


}


export default React.memo(withRouter(ObsPageManager));
