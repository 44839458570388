import coreThunk from "../common/coreThunks"
import actions from "../../redux/actions/creators/request/actionCreator";
import * as api from "../../constants/api";
import {toast} from "react-toastify";
import {getIntlMsg} from "../../constants/utils";

/**
 * @Author Rikenson JACQUES (The Tiger ^(..)^)
 * @Desc: Fetch all items
 * @return function(*): Q.Promise<void> Promise<AxiosResponse<T>>
 * */
const addDiagnostic = params => async dispatch => {
    const {intl} = params;
    try {
        dispatch(actions.addDiagnostic(params));
        const response = await api.patchEntity({...params});
        dispatch(actions.addDiagnosticSucceeded({...params, payload: response.data}));
        toast.success(getIntlMsg(intl, `app.label.add_diagnostic_succeeded`.replace("-", "_")));
    } catch (e) {
        console.log(e);
        dispatch(actions.addDiagnosticFailed(params));
        toast.success(getIntlMsg(intl, `app.label.add_diagnostic_succeeded`.replace("-", "_")));
    }
};


/**
 * @Author Rikenson JACQUES (The Tiger ^(..)^)
 * @Desc: Fetch all items
 * @return function(*): Q.Promise<void> Promise<AxiosResponse<T>>
 * */
const addResult = params => async dispatch => {
    const {intl} = params;
    try {
        dispatch(actions.addResult(params));
        const response = await api.patchEntity({...params});
        dispatch(actions.addDiagnosticSucceeded({...params, payload: response.data}));
        toast.success(getIntlMsg(intl, `app.label.add_result_succeeded`.replace("-", "_")));
    } catch (e) {
        console.log(e);
        dispatch(actions.addResultFailed(params));
        toast.error(getIntlMsg(intl, `app.label.add_result_failed`.replace("-", "_")));
    }
};


export default {...coreThunk, addDiagnostic, addResult};
