import * as React from "react";
import {useContext} from "react";
import useDocumentTitle from "../../../../../common/hooks/useDocumentTitle";
import actions from "../../../../../../thunks/request/thunks";
import {getIntlMsg} from "../../../../../../constants/utils";
import {FormattedMessage, injectIntl} from "react-intl";
import {useParams, withRouter} from "react-router";
import {connect, useDispatch, useSelector} from "react-redux";
import {reduxForm} from "redux-form";
import {validate} from "./validator";
import FormItems from "./Items";
import {Diagnostic} from "../../../../../../constants/models/diagnostic";
import ModalFormFooter from "../../../../../common/view/ModalFormFooter";
import {Modal} from 'react-bootstrap';
import {GlobalContext} from "../../../../../index";


const SpecimenModal = injectIntl((props) => {
    const {intl, handleSubmit, pristine, valid, submitting, reset} = props;
    const {page} = useParams();
    useDocumentTitle(getIntlMsg(intl, `app.label.${page}`));

    const {hideModal, modalIsOpen} = useContext(GlobalContext);


    const dispatch = useDispatch();
    const params = {intl, options: ['requests']}

    const {item, status} = useSelector(state => ({
        item: state.requestReducer?.item,
        status: state.requestReducer?.status,
    }))


    const onFormSubmit = element => {

        const model = new Diagnostic({...element, name: item?.testType?.name})
        if (item) {
            dispatch(actions.addDiagnostic({options: ['requests', 'diagnostic', item?.id], intl, item: model}))
            dispatch(actions.resetItem(params))
            dispatch(reset)
        }
    };

    const ResetForm = () => {
        reset('specimenForm');
        dispatch(actions.resetItem(params))
        hideModal('specimen')
    }

    if (status === 400) ResetForm()

    return (

        <Modal show={modalIsOpen.name === "specimen" ? modalIsOpen.open : false}
               onHide={() => hideModal('specimen')} size="lg" aria-labelledby="contained-modal-title-vcenter"
               centered>
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    <FormattedMessage id={`app.label.new_specimen_form`}/>{" "}
                    <FormattedMessage id={`app.label.for`}/>{" "}
                    {`${item?.applicant?.personalInfos.firstName}  ${item?.applicant?.personalInfos.lastName.toUpperCase()}`}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <form onSubmit={handleSubmit(onFormSubmit)}>
                    <div>
                        <FormItems {...{validate, loading: false, pristine, valid, submitting, item}}/>
                        <ModalFormFooter {...{
                            loading: false, item: {}, pristine, valid, submitting, hideModal,
                            name: 'specimenForm',
                            componentName: 'results',
                            moduleName: "specimen"
                        }}/>
                    </div>
                </form>

            </Modal.Body>

        </Modal>
    );
});

const mapStateToProps = ({currencyReducer: {item}}) => {
    const defaultState = {
        nasopharyngeeDate: new Date().toISOString().split('T')[0],
        oropharyngealDate: new Date().toISOString().split('T')[0],
        serumDate: new Date().toISOString().split('T')[0],
        labsReceptionDate: new Date().toISOString().split('T')[0],
    }
    return {
        initialValues: item !== null ? {...item} : {...defaultState}
    }
};

export default React.memo(withRouter(connect(mapStateToProps,)(reduxForm({
    form: "specimenForm",
    enableReinitialize: true,
    validate,
})(SpecimenModal))));

