import * as React from "react";
import {useContext} from "react";
import {FormattedMessage, injectIntl} from "react-intl";
import {withRouter} from "react-router";
import ChangeStateModal from "../../../../../common/modal/ChangeState";
import {getIntlMsg} from "../../../../../../../constants/utils";
import Index from "../../../forms/result/update";
import {useRef} from "react";
import {useReactToPrint} from "react-to-print";
import ResultSheet from "../../../reports/ResultSheet";
import actions from "../../../../../../../thunks/common/coreThunks";
import {useDispatch, useSelector} from "react-redux";
import {GlobalContext} from "../../../../../../index";

const ResultBtn = injectIntl(props => {

    const {showModal} = useContext(GlobalContext);

    const {intl, item, handleStateChange} = props
    const resultFormRef = useRef();
    const dispatch = useDispatch();

    const handlePrintResult = useReactToPrint({
        content: () => resultFormRef.current,
    });

    const params = {intl, item: {id: item?.id}, options: ['requests']}
    const loadCurrentElement = (type) =>{
        dispatch(actions.loadItem(params))
        showModal(type)
    }


    return (
        <div className="btn-group btn-group-sm" role="group" aria-label="Basic example">

            <button type="button" className="btn btn-sm btn-light-primary"
                    onClick={()=>loadCurrentElement('updateResult')}>
                <FormattedMessage id="app.label.adjust_result" defaultMessage={''}/>
            </button>

            <button type="button" className="btn btn-light-primary" onClick={handlePrintResult}>
                <FormattedMessage id="app.label.print_result" defaultMessage={''}/>
            </button>

            <button type="button" className="btn btn-light-primary"
                    data-toggle="modal" data-target={`#archived${item?.id}`}
                    title={getIntlMsg(intl, `app.label.send_to_archived`)}>
                <FormattedMessage id="app.label.archive_request" defaultMessage={''}/>
            </button>

            <div style={{display: "none", overflow: "hidden", height: 0}}>
                <ResultSheet ref={resultFormRef} {...{item}}/>
            </div>


            <ChangeStateModal {...{id: item.id, title: 'archived', handleStateChange}}/>
        </div>

    );
});


export default React.memo(withRouter(ResultBtn))
