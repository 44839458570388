import * as React from "react";
import useDocumentTitle from "../../../../common/hooks/useDocumentTitle";
import actions from "../../../../../thunks/common/coreThunks";
import {getIntlMsg} from "../../../../../constants/utils";
import {FormattedMessage, injectIntl} from "react-intl";
import {useParams, withRouter} from "react-router";
import {reduxForm} from "redux-form";
import {connect, useDispatch, useSelector,} from "react-redux";
import {validate} from "./validator";
import BaseForm from "./base";
import BillingForm from "./billing";
import ParamsForm from "./params";
import FormFooter from "../../../common/form/FormFooter";
import {Institution, InstitutionMapper} from "../../../../../constants/models/institution";


const InstitutionForm = injectIntl((props) => {

    const {intl, handleSubmit, pristine, valid, submitting, reset, setInstitutionFormView, initialValues} = props;
    const dispatch = useDispatch();
    const {page, secondLevelPage} = useParams();
    useDocumentTitle(getIntlMsg(intl, `app.label.${page}`));


    const tabIds = [
        {id: "#billing", label: "billing", icon: "flaticon2-list", status: "active", subItems: []},
        {id: "#params", label: "settings", icon: "flaticon2-settings", status: "", subItems: []},
    ]

    const {item, loading} = useSelector(state => ({
        loading: state.institutionReducer.loadingItem,
        item: state.institutionReducer.item,
    }));



    const tabs = [
        <BillingForm key={0} {...{item}}/>,
        <ParamsForm key={1}  {...{item}}/>,
    ];


    const onFormSubmit = element => {
        dispatch(actions.saveOrUpdateItem({
            options: [page], intl, action: item && item.id ? 'update' : 'save', item: new Institution(element),
        }))
    };


    return (
        !loading ? <React.Fragment>
            <BaseForm {...{setInstitutionFormView}}/>
            <div className="card card-custom">
                <div className="card-header card-header-tabs-line">
                    <div className="card-toolbar">
                        <ul className="nav nav-tabs nav-bold nav-tabs-line">
                            {tabIds.map((item, i) =>
                                <li className={`nav-item ${item?.subItems.length > 0 && 'dropdown'}`} key={i}>

                                    {item?.subItems.length <= 0 ?
                                        <a className={`nav-link ${item.status}`} data-toggle="tab" href={item.id}>
                                            <span className="nav-icon">
                                                <i className={item?.icon}/>
                                            </span>
                                            <span className="nav-text">
                                                <FormattedMessage id={`app.label.${item?.label}`}/>
                                            </span>
                                        </a>
                                        :

                                        <a className="nav-link dropdown-toggle" data-toggle="dropdown" href="#"
                                           role="button" aria-haspopup="true" aria-expanded="false">
                                           <span className="nav-icon">
                                                <i className={item?.icon}/>
                                            </span>
                                            <span className="nav-text">
                                                <FormattedMessage id={`app.label.${item?.label}`}/>
                                            </span>
                                        </a>
                                    }

                                    <div className="dropdown-menu">
                                        {item.subItems.map((element, j) =>
                                            <React.Fragment key={j}>
                                                <a className="dropdown-item" data-toggle="tab" href={element.id}>
                                                      <span className="nav-icon">
                                                        <i className={element?.icon}/>
                                                      </span>
                                                    <span className="nav-text ml-3">
                                                          <FormattedMessage id={`app.label.${element?.label}`}/>
                                                      </span>
                                                </a>
                                                {element.isDivided && <div className="dropdown-divider"/>}
                                            </React.Fragment>
                                        )}
                                    </div>

                                </li>
                            )}
                        </ul>
                    </div>
                </div>
                <div className="card-body">
                    <form onSubmit={handleSubmit(onFormSubmit)}>
                        <div className="tab-content">
                            {tabs.map((item, i) => item)}
                        </div>
                        <FormFooter {...{loading, item, pristine, valid, submitting, reset}}/>
                    </form>
                </div>
            </div>
        </React.Fragment> : null
    );
});


const mapStateToProps = ({institutionReducer: {item}}) => {

    const defaultState = {}
    const loaded = item ? new InstitutionMapper(item) : {};

    return {
        initialValues: loaded ? {...loaded} : {...defaultState}
    }
};

export default React.memo(withRouter(connect(mapStateToProps,)(reduxForm({
    form: "institution",
    enableReinitialize: true,
    validate,
})(InstitutionForm))));

