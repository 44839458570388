import * as React from "react";
import {useContext, useEffect} from "react";
import useDocumentTitle from "../../../../../common/hooks/useDocumentTitle";
import actions from "../../../../../../thunks/common/coreThunks";
import {getIntlMsg} from "../../../../../../constants/utils";
import {injectIntl} from "react-intl";
import {useParams, withRouter} from "react-router";
import {reduxForm} from "redux-form";
import {connect, useDispatch, useSelector,} from "react-redux";
import {validate} from "./validator";
import PersonalInfosForm from "../core/personal";
import BaseForm from "./current";
import {Request, StaffRequestMapper} from "../../../../../../constants/models/staffRequest";
import SubHeader from "../../../../common/subheader";
import {getRequestValues} from "../../../../../../constants/options/subheaderValues";
import SocialInfosForm from "./social";
import {GlobalContext} from "../../../../../index";


const RequestStaffForm = injectIntl((props) => {

    const {
        intl, handleSubmit, pristine, valid, submitting, reset,
        asyncValidating, touched, error, setView, view, history
    } = props;


    const dispatch = useDispatch();
    const {module, action} = useParams();
    const params = {intl, options: ['request']}
    const options = {intl, options: [module], item: {id: action}}
    useDocumentTitle(getIntlMsg(intl, `app.label.${module}`));

    const {userSession: {specimencenter}} = useContext(GlobalContext);

    useEffect(() => {
        if (action)
            dispatch(actions.loadItem(options))
        else
            dispatch(actions.resetItem(params))
    }, [action]);


    const {item, loading, savingOrUpdating, status} = useSelector(state => ({
        loading: state.requestReducer.loadingItem,
        item: state.requestReducer.item,
        savingOrUpdating: state.requestReducer.savingOrUpdating,
        status: state.requestReducer.status,
    }));


    const onFormSubmit = element => {

        let entity=new Request(element);
        entity.specimenCenter={id:specimencenter};

        dispatch(actions.saveOrUpdateItem({
            options: [module], intl, action: item && item.id ? 'update' : 'save', item:entity
        }))
    }

    const ResetForm = () => {
        reset('requestForm');
        dispatch(actions.resetItem(options))
    }

    if (status === 400) ResetForm()
    return (
        !loading ?

            <React.Fragment>
                <SubHeader {...{setView, btnOptions: getRequestValues}} noButton/>
                <div className="d-flex flex-column-fluid">
                    <div className="container">
                        <form onSubmit={handleSubmit(onFormSubmit)}>
                            <div className="row">

                                <div className="col-lg-6 col-md-12 col-sm-12 pr-lg-0">
                                    <div className="row">
                                        <div className="col-sm-12 mb-3">
                                            <PersonalInfosForm {...{item, touched, error}}/>
                                        </div>
                                        <div className="col-sm-12 mb-3">
                                            <SocialInfosForm {...{item}}/>
                                        </div>
                                    </div>

                                </div>


                                <div className="col-sm-12 col-md-12 col-lg-6">
                                    <div className="row">
                                        <div className="col-sm-12 mb-3">
                                            <BaseForm  {...{
                                                item, pristine, loading, valid, submitting,
                                                reset, resetItem: actions.resetItem(params), history,
                                                module, dispatch, touched, error, savingOrUpdating
                                            }}/>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </form>
                    </div>
                </div>

            </React.Fragment>
            : null
    );
});


const mapStateToProps = ({requestReducer: {item},spCenterReducer}, props) => {


    const initVal = item ? new StaffRequestMapper(item) : null;
    const defaultState = {
        maturity: 'other',
        nationality: 'Haiti',
        county: 'Ouest',
        district: 'Arcahaie',
        municipality: 'Arcahaie',
        subMunicipality: 'Boucassin',
        type: 'STAFF',
        paymentType:'FREE',
        testType:spCenterReducer?.item?.analyses[0]?.id||''
    }

    return {
        initialValues: item !== null ? {...initVal} : {...defaultState}
    }
};

export default React.memo(withRouter(connect(mapStateToProps,)(reduxForm({
    form: "requestForm",
    enableReinitialize: true,
    // asyncValidate,
    validate,
    // asyncChangeFields: ['bookingMoment']
})(RequestStaffForm))));

