const spCenterActionTypes = {

    /**
     * @Author Rikenson JACQUES (The Tiger ^(..)^)
     * RESET SPECIMENCENTER ACTION TYPES
     * -------------------------------------------
     * */
    RESET_SPECIMENCENTER: "RESET_SPECIMENCENTER",

    /**
     * @Author Rikenson JACQUES (The Tiger ^(..)^)
     * LOAD SPECIMENCENTERS ACTION TYPES
     * -------------------------------------------
     * */
    LOAD_SPECIMENCENTERS: "LOAD_SPECIMENCENTERS",
    LOAD_SPECIMENCENTERS_SUCCEEDED: "LOAD_SPECIMENCENTERS_SUCCEEDED",
    LOAD_SPECIMENCENTERS_FAILED: "LOAD_SPECIMENCENTERS_FAILED",

    /**
     * @Author Rikenson JACQUES (The Tiger ^(..)^)
     * LOAD _BY_ENTITY-NAME_CUSTOMER ACTION TYPES
     * -------------------------------------------
     * */
    LOAD_BY_NAME_CUSTOMER: "LOAD_BY_NAME_CUSTOMER",
    LOAD_BY_NAME_CUSTOMER_SUCCEEDED: "LOAD_BY_NAME_CUSTOMER_SUCCEEDED",
    LOAD_BY_NAME_CUSTOMER_FAILED: "LOAD_BY_NAME_CUSTOMER_FAILED",


    /**
     * @Author Rikenson JACQUES (The Tiger ^(..)^)
     * LOAD SPECIMENCENTERS_NAMES ACTION TYPES
     * -------------------------------------------
     * */
    LOAD_SPECIMENCENTERS_NAMES: "LOAD_SPECIMENCENTERS_NAMES",
    LOAD_SPECIMENCENTERS_NAMES_SUCCEEDED: "LOAD_SPECIMENCENTERS_NAMES_SUCCEEDED",
    LOAD_SPECIMENCENTERS_NAMES_FAILED: "LOAD_SPECIMENCENTERS_NAMES_FAILED",


    /**
     * @Author Rikenson JACQUES (The Tiger ^(..)^)
     * LOAD SPECIMENCENTERS_NAMES ACTION TYPES
     * -------------------------------------------
     * */
    LOAD_SPECIMENCENTERS_BY_NAME_CUSTOMER: "LOAD_SPECIMENCENTERS_BY_NAME_CUSTOMER",
    LOAD_SPECIMENCENTERS_BY_NAME_CUSTOMER_SUCCEEDED: "LOAD_SPECIMENCENTERS_BY_NAME_CUSTOMER_SUCCEEDED",
    LOAD_SPECIMENCENTERS_BY_NAME_CUSTOMER_FAILED: "LOAD_SPECIMENCENTERS_BY_NAME_CUSTOMER_FAILED",


    /**
     * @Author Rikenson JACQUES (The Tiger ^(..)^)
     * LOAD SPECIMENCENTERS_NAMES ACTION TYPES
     * -------------------------------------------
     * */
    LOAD_SPECIMENCENTERS_BY_NAME_SHEET: "LOAD_SPECIMENCENTERS_BY_NAME_SHEET",
    LOAD_SPECIMENCENTERS_BY_NAME_SHEET_SUCCEEDED: "LOAD_SPECIMENCENTERS_BY_NAME_SHEET_SUCCEEDED",
    LOAD_SPECIMENCENTERS_BY_NAME_SHEET_FAILED: "LOAD_SPECIMENCENTERS_BY_NAME_SHEET_FAILED",


    /**
     * @Author Rikenson JACQUES (The Tiger ^(..)^)
     * LOAD SPECIMENCENTERS_NAMES ACTION TYPES
     * -------------------------------------------
     * */
    LOAD_SPECIMENCENTERS_BY_NAME_DOCUMENT: "LOAD_SPECIMENCENTERS_BY_NAME_DOCUMENT",
    LOAD_SPECIMENCENTERS_BY_NAME_DOCUMENT_SUCCEEDED: "LOAD_SPECIMENCENTERS_BY_NAME_DOCUMENT_SUCCEEDED",
    LOAD_SPECIMENCENTERS_BY_NAME_DOCUMENT_FAILED: "LOAD_SPECIMENCENTERS_BY_NAME_DOCUMENT_FAILED",


    /**
     * @Author Rikenson JACQUES (The Tiger ^(..)^)
     * LOAD SINGLE SPECIMENCENTER ACTION TYPES
     * -------------------------------------------
     * */
    LOAD_SPECIMENCENTER: "LOAD_SPECIMENCENTER",
    LOAD_SPECIMENCENTER_SUCCEEDED: "LOAD_SPECIMENCENTER_SUCCEEDED",
    LOAD_SPECIMENCENTER_FAILED: "LOAD_SPECIMENCENTER_FAILED",


    /**
     * @Author Rikenson JACQUES (The Tiger ^(..)^)
     * SAVE SPECIMENCENTER ACTION TYPES
     * -------------------------------------------
     * */
    SAVE_SPECIMENCENTER: "SAVE_SPECIMENCENTER",
    SAVE_SPECIMENCENTER_SUCCEEDED: "SAVE_SPECIMENCENTER_SUCCEEDED",
    SAVE_SPECIMENCENTER_FAILED: "SAVE_SPECIMENCENTER_FAILED",


    /**
     * @Author Rikenson JACQUES (The Tiger ^(..)^)
     * UPDATE SPECIMENCENTER ACTION TYPES
     * -------------------------------------------
     * */
    UPDATE_SPECIMENCENTER: "UPDATE_SPECIMENCENTER",
    UPDATE_SPECIMENCENTER_SUCCEEDED: "UPDATE_SPECIMENCENTER_SUCCEEDED",
    UPDATE_SPECIMENCENTER_FAILED: "UPDATE_SPECIMENCENTER_FAILED",


    /**
     * @Author Rikenson JACQUES (The Tiger ^(..)^)
     * DELETE SPECIMENCENTER ACTION TYPES
     * -------------------------------------------
     * */
    DELETE_SPECIMENCENTER: "DELETE_SPECIMENCENTER",
    DELETE_SPECIMENCENTER_SUCCEEDED: "DELETE_SPECIMENCENTER_SUCCEEDED",
    DELETE_SPECIMENCENTER_FAILED: "DELETE_SPECIMENCENTER_FAILED",


};


export default spCenterActionTypes;
