import * as React from "react";
import useDocumentTitle from "../../../../common/hooks/useDocumentTitle";
import actions from "../../../../../thunks/common/coreThunks";
import {getIntlMsg} from "../../../../../constants/utils";
import {injectIntl} from "react-intl";
import {useParams, withRouter} from "react-router";
import {connect, useDispatch, useSelector} from "react-redux";
import {reduxForm} from "redux-form";
import {validate} from "../validator";
import useEntityManager from "../../../../common/hooks/useEntityManager";
import FormItems from "./Items";
import FormFooter from "../../../common/form/FormFooter";
import {RequestMapper, Symptom, SymptomsMapper, RiskFactor,RiskFactorMapper} from "../../../../../constants/models/request";
import contact from "./contact";
import {useEffect} from "react";


const TravelForm = injectIntl((props) => {
    const {
        intl,
        handleSubmit,
        pristine,
        valid,
        submitting,
        reset,
        initialValues,
        history,
        module,
        saveOrUpdateItem
    } = props;
    const {page,secondLevelPage} = useParams();
    // useDocumentTitle(getIntlMsg(intl, `app.label.${page}`));

    const dispatch = useDispatch();

    const options = {intl, options: ['categories', 'by-name', 'risk'], item: {id: secondLevelPage}}


    useEffect(() => {
        dispatch(actions.loadItems(options))
    }, [secondLevelPage]);



    const params = {options: [page]};
    const {handleLoadItems} = useEntityManager(params, page)
    const {item, loading, values,risks,travelForm} = useSelector(state => ({
        loading: state.observationReducer.loadingItem,
        item: state.requestReducer.item,
        values: state.form?.symptomForm?.values,
        travelForm: state.form?.travelForm?.values,
        risks: state.categoryReducer.risks,
    }));


    const onFormSubmit = element => {
        const entity = new RiskFactor({...element,id:risks[0].id});
        entity.id=item?.id

        dispatch(actions.saveOrUpdateItem({
            options: ['requests','notification','risk-factor'], intl, action: '', item: entity,
        }))
    };


    return (
        <div className="tab-pane fade show" id="travel" role="tabpanel" aria-labelledby="travel">
            <form onSubmit={handleSubmit(onFormSubmit)}>
                <div>
                    <FormItems {...{validate, item, values,travelForm}}/>
                    <FormFooter {...{item, loading, pristine, submitting, reset, valid, dispatch, history, module}}/>
                </div>
            </form>
        </div>
    );
});

const mapStateToProps = ({requestReducer: {item}}) => {
    const defaultState = {}
    return {
        initialValues: JSON.stringify(item) !== '{}' ? {...item,...new RiskFactorMapper(item?.notificationSheet?.riskFactor)} : {...defaultState},

    }
};

export default React.memo(withRouter(connect(mapStateToProps,)(reduxForm({
    form: "travelForm",
    enableReinitialize: true,
    validate,
})(TravelForm))));

