/**
 * Created by inglouidor on 3/15/21.
 */
import React from 'react'
import {DoughnutChartjs, BarChartjs} from "../charts";
import {FormattedMessage} from "react-intl";
import TableList from '../../../../../../common/tables';
import {addToObject} from '../../../../../../../../constants/utils'
const Followup = ({indicator}) => {

    // let data=[{site:'CANGE',C19_PCR:20,C19_PCR_POSITIVE:50,C19_RTR:45,C19_RTRT:10,C19_RTR_POSITIVE:20,C19_GXPRT:5,C19_GXPRT_POSITIVE:10,color:"#8e5ea2"},{site:'HSN',C19_PCR:20,C19_PCR_POSITIVE:50,C19_RTR:45,C19_RTRT:10,C19_RTR_POSITIVE:20,C19_GXPRT:5,C19_GXPRT_POSITIVE:10,color:"#3e95cd"},{site:'HUM',C19_PCR:20,C19_PCR_POSITIVE:50,C19_RTR:45,C19_RTRT:10,C19_RTR_POSITIVE:20,C19_GXPRT:5,C19_GXPRT_POSITIVE:10,color:"#10CC75"}];

    let data = [];

    if (indicator && indicator.testing) {

        for (let i = 0; i < Object.keys(indicator.testing).length; i++) {

            let obj = Object.keys(indicator.testing)[i]
            let d = Object.values(indicator.testing)[i];

            data.push(addToObject(d, "site", obj, i))

        }

    }

    const legend = {
        display: true,
        position: "bottom",
        labels: {
            fontColor: "#dd5309",
            background: "#dd5309",
            fontSize: 14
        }
    };

    const color = ["#8e5ea2", "#3e95cd", "#10CC75"];
    const statusData = {
        labels: ["C19_PCR", "C19_PCR_POSITIVE", "C19_RTR", "C19_RTR_POSITIVE", "C19_GXPRT", "C19_GXPRT_POSITIVE"],
        datasets: data && data.map((value, i) => {
            return {
                label: value.site,
                data: [value.C19_PCR, value.C19_PCR_POSITIVE, value.C19_RTR, value.C19_RTR_POSITIVE, value.C19_GXPRT, value.C19_GXPRT_POSITIVE],
                backgroundColor: color[i]
            }
        })
    };


    const columns = [
        {
            title: 'Site',
            field: 'site', type: 'text'
        },
        {
            title: 'PCR',
            field: 'C19_PCR', type: 'text'
        },
        {
            title: 'PCR+',
            field: 'C19_PCR_POSITIVE', type: 'text'
        },
        {
            title: 'TR',
            field: 'C19_RTR', type: 'text',
        },
        {
            title: 'TR+',
            field: 'C19_RTR_POSITIVE', type: 'text'
        },
        {
            title: 'Gxpt',
            field: 'C19_GXPRT', type: 'text'
        },
        {
            title: 'Gxpt+',
            field: 'C19_GXPRT_POSITIVE', type: 'text'
        }
    ]


    return (
        <React.Fragment>
            <div className="col-sm-6 pt-4">

                <div className="card card-custom">
                    <div className="card-header">
                        <div className="card-title">
                            <h3 className="card-label">
                                <FormattedMessage id="app.label.test"/> {" "}
                            </h3>
                        </div>
                    </div>
                    <div className="card-body">
                        <div>
                            <BarChartjs data={statusData}/>
                        </div>
                    </div>
                </div>

            </div>

            <div className="col-sm-6 pt-4">

                <TableList data={data} columns={columns} title="Tableau de Test"/>

            </div>

        </React.Fragment>)
}

export default Followup