import React, {useContext} from "react";
import {Route} from "react-router";
import {Redirect} from "react-router-dom";
import {hasEntryAccess, loadDefaultHomePage} from "../../../constants/utils/security";
import {userSession} from "../../../constants/utils";
import {useSelector} from "react-redux";
import {GlobalContext} from "../../index";


const PrivateRoute = ({component: Component, ...rest}) => {



    const {userSession: {roles, keycloak}, institution:{acronym}} = useContext(GlobalContext);
    const {location: {pathname}} = {...rest};


    const customPath = pathname.substring(pathname.lastIndexOf("/") + 1, pathname.length);
    const entry = pathname === '/' ? 'admin' : customPath.replace(/\//g, '');
    return (
        (roles.length === 0) ?
            <Redirect to="/"/>
            : keycloak.isTokenExpired() ? keycloak.logout()
            : hasEntryAccess(roles, entry) ? <Route {...rest} render={(props) => (<Component {...props}/>)}/>
                : <Redirect to={loadDefaultHomePage(roles, acronym)}/>

    )
};

export default PrivateRoute;