import * as React from "react";

export function getInfoBarBases(institution) {
    return [
        {
            title: "txn_code",
            hasSubTitle:false,
            subTitle: "GDS",
            url: `#`,
            icon: 'flaticon2-file-2'
        },
        {
            title: "amount",
            hasSubTitle:true,
            subTitle: "GDS",
            url: `#`,
            icon:'flaticon2-tag'
        },
        {
            title: "payment_type",
            hasSubTitle:false,
            subTitle: "$",
            url: `#`,
            icon: 'flaticon2-cube-1'
        },
        {
            title: "departure",
            hasSubTitle:false,
            subTitle: "$",
            url: `#`,
            icon: 'flaticon2-calendar-7'
        },
        {
            title: "destination",
            hasSubTitle:false,
            subTitle: "$",
            url: `#`,
            icon: 'flaticon2-paper-plane'
        },
        {
            title: "test_type",
            hasSubTitle:false,
            subTitle: "$",
            url: `#`,
            icon: 'flaticon2-zig-zag-line-sign'
        },
    ]


}


export function getStaffInfoBarBases(type) {
    return [
        {
            title: "laboratory_code",
            hasSubTitle:false,
            subTitle: "",
            url: `#`,
            icon: 'flaticon2-file-2'
        },
        {
            title: "created",
            hasSubTitle:false,
            subTitle: "",
            url: `#`,
            icon:'flaticon2-calendar-8'
        },
        {
            title: "test_type",
            hasSubTitle:false,
            subTitle: "$",
            url: `#`,
            icon: 'flaticon2-contrast'
        },
        {
            title: "payment_type",
            hasSubTitle:false,
            subTitle: "$",
            url: `#`,
            icon: 'flaticon2-tag'
        },
        {
            title: "specimen_center",
            hasSubTitle:false,
            subTitle: "$",
            url: `#`,
            icon: 'flaticon2-pin-1'
        },
        {
            title: "request_type",
            hasSubTitle:false,
            subTitle: "$",
            url: `#`,
            icon: type === 'STAFF'? 'flaticon2-avatar': 'flaticon2-paper-plane'
        },
    ]


}