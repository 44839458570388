import * as React from "react";
import {useContext, useRef} from "react";
import {FormattedMessage, injectIntl} from "react-intl";
import {useReactToPrint} from 'react-to-print';
import {withRouter} from "react-router";
import ChangeStateModal from "../../../../../common/modal/ChangeState";
import {getIntlMsg} from "../../../../../../../constants/utils";
import RequestNotice from "../../../reports/traveler/RequestNotice";
import Notice from "../../../reports/traveler/Notice";
import {Link, useParams} from "react-router-dom";
import actions from "../../../../../../../thunks/common/coreThunks";
import {GlobalContext} from "../../../../../../index";
import {useDispatch} from "react-redux";


const CartBtn = injectIntl(props => {
    const {institution: {acronym},userSession} = useContext(GlobalContext);
    const dispatch = useDispatch()
    const {module} = useParams()
    const {intl, item, handleStateChange} = props;
    const {id} = item
    const requestFormRef = useRef();
    const receiptRef = useRef();
    const params = {intl, item: {id}, options: [module]}

    const handlePrintRequestForm = useReactToPrint({
        content: () => requestFormRef.current,
    });


    return (

        <div className="btn-group  btn-group-sm" role="group" aria-label="Basic example">

            {item?.lastState === null ?
                <button type="button" className="btn btn-light-primary" onClick={handlePrintRequestForm}>
                    <FormattedMessage id={`app.label.print_cart`}/>
                </button> : null}
            {item?.lastState === null || item?.lastState === 'RECEPTION' || item?.lastState === 'CART'

                ? <React.Fragment>
                    <button type="button" className="btn btn-light-primary"
                            data-toggle="modal" data-target={`#reception${id}`}
                            title={getIntlMsg(intl, `app.label.send_to_reception`)}>
                        <FormattedMessage id={`app.label.send_to_reception`}/>
                    </button>
                    <Link
                        to={`/${acronym}/${module}/registration-cart/${item?.type === 'TRAVELER' ? 'traveler' : 'staff' || ''}/${id}/edit`}
                        className="btn btn-light-primary"
                        onClick={() => {
                            dispatch(actions.loadItem(params))
                        }}>
                        <FormattedMessage id={`app.label.adjust_cart`}/>
                    </Link>
                </React.Fragment>

                : item?.lastState === "RECEPTION" ?
                    <Link
                        to={`/${acronym}/${module}/registration-cart/${item?.type === 'TRAVELER' ? 'traveler' : 'staff' || ''}/${id}/edit`}
                        className="btn btn-light-primary"
                        onClick={() => {
                            dispatch(actions.loadItem(params))
                        }}>
                        <FormattedMessage id={`app.label.adjust_request`}/>
                    </Link> : null

            }


            {item?.lastState === 'CART'
                ? <ChangeStateModal {...{id, title: 'notification', handleStateChange}}/>
                : <ChangeStateModal {...{id, title: 'reception', handleStateChange}}/>
            }

            <div style={{display: "none", overflow: "hidden", height: 0}}>
                <Notice ref={requestFormRef} {...{item,userSession}}/>
            </div>

            {/*<div style={{display: "none", overflow: "hidden", height: 0}}>*/}
            {/*    <RequestNotice ref={receiptRef} {...{item,serSession}}/>*/}
            {/*</div>*/}
        </div>

    );
});


export default React.memo(withRouter(CartBtn))
