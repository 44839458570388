import React, {useEffect} from "react"
import {change, Field} from "redux-form";
import {renderField, renderRadio} from "../../../../../common/utils/FormComponent";
import {withRouter} from "react-router";
import {FormattedMessage} from "react-intl";
import {useDispatch, useSelector} from "react-redux";
import actions from "../../../../../../thunks/common/coreThunks";
import {renderError} from './validator'

let FormItems = props => {

    const dispatch = useDispatch()
    const {validate, touched, error} = props

    const options = {options: ['categories', 'by-name', 'specimen']};

    useEffect(() => {
        dispatch(actions.loadItems(options));
    }, [dispatch]);

    const {loading, items, selectedItem} = useSelector(state => ({
        loading: state.categoryReducer.loadingSpecimenTypes,
        items: state.categoryReducer.specimenTypes,
        selectedItem: state.requestReducer?.item,
    }));

    const {values} = useSelector(state => ({
        observation: state.form?.specimenForm?.values,
    }));

    const specimenStatus = ['YES', 'NO']
    const analyses = selectedItem?.testType?.name === 'PCR'
        ? items
        : items.filter((item=> item.name === 'nasopharyngee'))
    


    return (
        <div className={`custom_fieldset mt-4`}>
            <div className="row mt-3">
                {analyses && analyses.map((item, i) =>
                    <React.Fragment>
                        <div className="col-sm-5" key={i}>

                            <div className="form-group">
                                <label className="font-weight-bold">
                                    <FormattedMessage id={item.description}/> :
                                </label>
                                <div className="radio-inline">
                                    {specimenStatus.map((element, i) =>
                                        <label className="radio radio-lg" key={i}>
                                            <Field type="radio" component="input"
                                                   name={`${item.name}`}
                                                   value={`${item.id};${element}`}/>
                                            <span/>
                                            <FormattedMessage id={`app.label.${element.toLowerCase()}`}/>
                                            <Field name={`${item.name}`} component={renderError}/>
                                        </label>
                                    )}
                                </div>
                                {touched && error &&
                                <span className="form-text text-primary">
                                                    <FormattedMessage id={error}/>
                                             </span>}
                            </div>

                        </div>

                        {<div className="col-sm-7">
                            <Field className="form-control form-control-sm" id="symptoms_start_date"
                                   name={`${item.name}Date`}
                                   component={renderField} type="date" label={`specimen_date`}
                                   placeholder={`specimen_${item.name}_date`}
                                   validate={validate.error}/>
                        </div>}
                    </React.Fragment>
                )}
                <div className="col-sm-12">
                    <Field className="form-control form-control-sm" id="symptoms_start_date"
                           name={`labsReceptionDate`}
                           component={renderField} type="date" label={`laboratory_specimen_reception_date`}
                           placeholder={`laboratory_specimen_reception_date`} validate={validate.error}/>
                </div>
            </div>
        </div>

    );
}
export default React.memo(withRouter(FormItems))
