const patientActionTypes = {


    /**
     * @Author Rikenson JACQUES (The Tiger ^(..)^)
     * RESET PATIENT ACTION TYPES
     * -------------------------------------------
     * */
    RESET_PATIENT: "RESET_PATIENT",


    /**
     * @Author Rikenson JACQUES (The Tiger ^(..)^)
     * LOAD PATIENTS ACTION TYPES
     * -------------------------------------------
     * */
    LOAD_PATIENTS: "LOAD_PATIENTS",
    LOAD_PATIENTS_SUCCEEDED: "LOAD_PATIENTS_SUCCEEDED",
    LOAD_PATIENTS_FAILED: "LOAD_PATIENTS_FAILED",


    /**
     * @Author Rikenson JACQUES (The Tiger ^(..)^)
     * LOAD PATIENTS STATUS OUT ACTION TYPES
     * -------------------------------------------
     * */
    LOAD_PENDING_PATIENTS: "LOAD_PENDING_PATIENTS",
    LOAD_PENDING_PATIENTS_SUCCEEDED: "LOAD_PENDING_PATIENTS_SUCCEEDED",
    LOAD_PENDING_PATIENTS_FAILED: "LOAD_PENDING_PATIENTS_FAILED",


    /**
     * @Author Rikenson JACQUES (The Tiger ^(..)^)
     * LOAD PATIENTS STATUS DONE ACTION TYPES
     * -------------------------------------------
     * */
    LOAD_SPECIMEN_DONE_PATIENTS: "LOAD_SPECIMEN_DONE_PATIENTS",
    LOAD_SPECIMEN_DONE_PATIENTS_SUCCEEDED: "LOAD_SPECIMEN_DONE_PATIENTS_SUCCEEDED",
    LOAD_SPECIMEN_DONE_PATIENTS_FAILED: "LOAD_SPECIMEN_DONE_PATIENTS_FAILED",


    /**
     * @Author Rikenson JACQUES (The Tiger ^(..)^)
     * LOAD PATIENTS STATUS DONE ACTION TYPES
     * -------------------------------------------
     * */
    LOAD_DONE_PATIENTS: "LOAD_DONE_PATIENTS",
    LOAD_DONE_PATIENTS_SUCCEEDED: "LOAD_DONE_PATIENTS_SUCCEEDED",
    LOAD_DONE_PATIENTS_FAILED: "LOAD_DONE_PATIENTS_FAILED",


    /**
     * @Author Rikenson JACQUES (The Tiger ^(..)^)
     * LOAD SINGLE PATIENT ACTION TYPES
     * -------------------------------------------
     * */
    LOAD_PATIENT: "LOAD_PATIENT",
    LOAD_PATIENT_SUCCEEDED: "LOAD_PATIENT_SUCCEEDED",
    LOAD_PATIENT_FAILED: "LOAD_PATIENT_FAILED",


    /**
     * @Author Rikenson JACQUES (The Tiger ^(..)^)
     * SAVE PATIENT ACTION TYPES
     * -------------------------------------------
     * */
    SAVE_PATIENT: "SAVE_PATIENT",
    SAVE_PATIENT_SUCCEEDED: "SAVE_PATIENT_SUCCEEDED",
    SAVE_PATIENT_FAILED: "SAVE_PATIENT_FAILED",


    /**
     * @Author Rikenson JACQUES (The Tiger ^(..)^)
     * UPDATE PATIENT ACTION TYPES
     * -------------------------------------------
     * */
    UPDATE_PATIENT: "UPDATE_PATIENT",
    UPDATE_PATIENT_SUCCEEDED: "UPDATE_PATIENT_SUCCEEDED",
    UPDATE_PATIENT_FAILED: "UPDATE_PATIENT_FAILED",


    /**
     * @Author Rikenson JACQUES (The Tiger ^(..)^)
     * DELETE PATIENT ACTION TYPES
     * -------------------------------------------
     * */
    DELETE_PATIENT: "DELETE_PATIENT",
    DELETE_PATIENT_SUCCEEDED: "DELETE_PATIENT_SUCCEEDED",
    DELETE_PATIENT_FAILED: "DELETE_PATIENT_FAILED",

};


export default patientActionTypes;
