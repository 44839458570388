import React, {useState} from "react"
import {change, Field} from "redux-form";
import {renderField, renderRadio} from "../../../../common/utils/FormComponent";
import {withRouter} from "react-router";
import {FormattedMessage} from "react-intl";
import {useDispatch, useSelector} from "react-redux";

let FormItems = ({item, validate, specimenTypes}) => {

    const prcResults = ['positive', 'negative', 'unknown']

    const [specimenEntities, setSpecimenEntities] = useState([])

    const {observation} = useSelector(state => ({
        observation: state.form?.observationForm?.values,
    }));
    const dispatch = useDispatch()


    const handlerAdOrEditSpecimen = (obj) => {

        let objs = [];

        if (obj) {
            const entity = {
                category: {id: obj?.id},
                collectionDate: obj?.collectionDate,
                result: obj?.status?.toUpperCase()
            }

            if (specimenEntities.length > 0) {
                const index = specimenEntities.findIndex(value => value.category.id === obj?.id);
                if (index >= 0) {
                    objs = [...specimenEntities, entity]
                    objs[index].result = obj.status ? obj.status?.toUpperCase() : objs[index].result?.toUpperCase();
                    objs[index].collectionDate = obj.collectionDate ? obj.collectionDate : objs[index].collectionDate;
                    objs.splice(index + 1, 1);
                    //
                } else {
                    objs = [...specimenEntities, entity]
                }
            } else {
                objs = [...specimenEntities, entity];
            }
            setSpecimenEntities(objs);
            dispatch(change("observationForm", "specimens", objs));
        }
    }


    return (
        <div className={`custom_fieldset mt-4`}>
            <div className="row">

                <div className="col-sm-5">
                    <h4><FormattedMessage id={`app.label.test_specimen`}/></h4>
                    <hr/>
                    <div className="row mt-3">
                        {specimenTypes && specimenTypes?.map((item, i) =>
                            <React.Fragment key={i}>
                                <div className="col-sm-6">
                                    <div className="form-group">
                                        <label>
                                            <FormattedMessage id={`app.label.${`specimen_${item.name}`}`}/>
                                            <span className="text-primary"> * </span> :
                                        </label>
                                        <div className="radio-inline">
                                            {item?.status && item?.status?.map((el, ii) =>

                                                <label className="radio" key={i}>
                                                    <Field type="radio" component="input" id={`specimen_${item.name}`}
                                                           name={item.code} value={el}
                                                           onChange={() => handlerAdOrEditSpecimen({
                                                               id: item.id,
                                                               status: el,
                                                               collectionDate: ''
                                                           })}/>
                                                    <span/>
                                                    <FormattedMessage id={`app.label.${el}`}/>
                                                </label>
                                            )}
                                        </div>

                                    </div>
                                </div>
                                <div className="col-sm-6">
                                    <Field className="form-control form-control-sm" id="symptoms_start_date"
                                           name={`${item.code}collectionDate`}
                                           component={renderField} type="date" label={`specimen_date`}
                                           placeholder={`specimen_${item}_date`}
                                           onChange={(e) => handlerAdOrEditSpecimen({
                                               id: item.id,
                                               collectionDate: e.target.value
                                           })}
                                           validate={validate.error}/>
                                </div>

                            </React.Fragment>
                        )}
                    </div>

                </div>
                <div className="col-sm-6 offset-1">
                    {item?.diagnostic?.result?
                    <div className="row mt-1">

                        <div className="col-sm-6">
                            <Field className="form-control form-control-sm" id="symptoms_start_date"
                                   name={`labsReceptionDate`}
                                   component={renderField} type="date" label={`laboratory_specimen_reception_date`}
                                   placeholder={`laboratory_specimen_reception_date`} validate={validate.error}/>
                        </div>

                        <div className="col-sm-12">
                            <div className="form-group">
                                <label>
                                    <FormattedMessage id={`app.label.${item?.testType?.name?.toLowerCase()}_result`}/>
                                    <span className="text-primary"> * </span> :
                                </label>
                                <div className="radio-inline">
                                    {prcResults.map((el, i) =>

                                        <label className="radio" key={i}>
                                            <Field type="radio" component="input" id={`pcr_result`} name={`pcr_result`}
                                                   value={el} validate={validate.error}/>
                                            <span/>
                                            <FormattedMessage id={`app.label.${el}`}/>
                                        </label>
                                    )}
                                </div>

                            </div>
                        </div>
                        <div className="col-sm-6">
                            <Field className="form-control form-control-sm" id="pcr_result_date"
                                   name="publishedDate"
                                   component={renderField} type="date" label="pcr_result_date"
                                   placeholder="pcr_result_date" validate={validate.error}/>
                        </div>
                    </div>
                       : null}
                </div>


                {/*{observation?.pcr_result === prcResults[0] && <div className="col-sm-6 offset-1">*/}
                {/*    <div className="row">*/}
                {/*        <div className="col-sm-12">*/}
                {/*            <h4><FormattedMessage id={`app.label.others`}/></h4>*/}
                {/*            <hr/>*/}
                {/*            <div className="row mt-3">*/}

                {/*                <div className="col-sm-12">*/}
                {/*                    <div className="form-group">*/}
                {/*                        <label>*/}
                {/*                            <FormattedMessage id={`app.label.${`clinical_evolution`}`}/>*/}
                {/*                            <span className="text-primary"> * </span> :*/}
                {/*                        </label>*/}
                {/*                        <div className="radio-inline">*/}
                {/*                            {clinicalStates.map((el, i) =>*/}

                {/*                                <label className="radio" key={i}>*/}
                {/*                                    <Field type="radio" component="input"  id={`clinical_evolution`} name={`clinical_evolution`} value={el} validate={validate.error}/>*/}
                {/*                                    <span/>*/}
                {/*                                    <FormattedMessage id={`app.label.${el}`}/>*/}
                {/*                                </label>*/}

                {/*                            )}*/}
                {/*                        </div>*/}

                {/*                    </div>*/}
                {/*                </div>*/}

                {/*                <div className="col-sm-12">*/}

                {/*                    <div className="row">*/}
                {/*                        <div className="col-sm-6">*/}
                {/*                            <div className="form-group">*/}
                {/*                                <label>*/}
                {/*                                    <FormattedMessage id={`app.label.${`isolation_location`}`}/>*/}
                {/*                                    <span className="text-primary"> * </span> :*/}
                {/*                                </label>*/}
                {/*                                <div className="radio-inline">*/}
                {/*                                    {isolationLocations.map((el, i) =>*/}

                {/*                                        <label className="radio" key={i}>*/}
                {/*                                            <Field type="radio" component="input"  id={`isolation_location`} name={`isolation_location`} value={el} validate={validate.error}/>*/}
                {/*                                            <span/>*/}
                {/*                                            <FormattedMessage id={`app.label.${el}`}/>*/}
                {/*                                        </label>*/}

                {/*                                    )}*/}
                {/*                                </div>*/}

                {/*                            </div>*/}

                {/*                        </div>*/}
                {/*                        {observation?.isolation_location === isolationLocations[1] &&*/}
                {/*                        <div className="col-sm-6">*/}
                {/*                            <Field className="form-control form-control-sm" id="symptoms_start_date"*/}
                {/*                                   name="institution"*/}
                {/*                                   component={renderField} type="text" label="institution"*/}
                {/*                                   placeholder="institution"*/}
                {/*                                   validate={validate.error}/>*/}
                {/*                        </div>}*/}
                {/*                    </div>*/}
                {/*                </div>*/}

                {/*                <div className="col-sm-12">*/}

                {/*                    <div className="row">*/}
                {/*                        <div className="col-sm-4">*/}
                {/*                            <Field className="form-control form-control-sm" id="hospitalization_date"*/}
                {/*                                   name="hospitalization_date"*/}
                {/*                                   component={renderField} type="date" label="hospitalization_date"*/}
                {/*                                   placeholder="hospitalization_date"*/}
                {/*                                   validate={validate.error}/>*/}
                {/*                        </div>*/}
                {/*                        <div className="col-sm-4">*/}

                {/*                            <div className="form-group">*/}
                {/*                                <label>*/}
                {/*                                    <FormattedMessage id={`app.label.${`ventilation_status`}`}/>*/}
                {/*                                    <span className="text-primary"> * </span> :*/}
                {/*                                </label>*/}
                {/*                                <div className="radio-inline">*/}
                {/*                                    {ventilationStatus.map((el, i) =>*/}

                {/*                                        <label className="radio" key={i}>*/}
                {/*                                            <Field type="radio" component="input"  id={`ventilation_status`} name={`ventilation_status`} value={el} validate={validate.error}/>*/}
                {/*                                            <span/>*/}
                {/*                                            <FormattedMessage id={`app.label.${el}`}/>*/}
                {/*                                        </label>*/}

                {/*                                    )}*/}
                {/*                                </div>*/}

                {/*                            </div>*/}
                {/*                        </div>*/}
                {/*                        {observation?.ventilation_status === ventilationStatus[0] &&*/}
                {/*                        <div className="col-sm-4">*/}
                {/*                            <Field className="form-control form-control-sm" id="ventilation_day_counter"*/}
                {/*                                   name="ventilation_day_counter"*/}
                {/*                                   component={renderField} type="number" label="ventilation_day_counter"*/}
                {/*                                   placeholder="ventilation_day_counter"*/}
                {/*                                   validate={validate.error}/>*/}
                {/*                        </div>}*/}
                {/*                    </div>*/}
                {/*                </div>*/}

                {/*                <div className="col-sm-12">*/}

                {/*                    <div className="row">*/}
                {/*                        <div className="col-sm-4">*/}
                {/*                            <div className="form-group">*/}
                {/*                                <label>*/}
                {/*                                    <FormattedMessage id={`app.label.${`intensive_care_status`}`}/>*/}
                {/*                                    <span className="text-primary"> * </span> :*/}
                {/*                                </label>*/}
                {/*                                <div className="radio-inline">*/}
                {/*                                    {intensiveCareStatus.map((el, i) =>*/}

                {/*                                        <label className="radio" key={i}>*/}
                {/*                                            <Field type="radio" component="input"  id={`intensive_care_status`} name={`intensive_care_status`} value={el} validate={validate.error}/>*/}
                {/*                                            <span/>*/}
                {/*                                            <FormattedMessage id={`app.label.${el}`}/>*/}
                {/*                                        </label>*/}

                {/*                                    )}*/}
                {/*                                </div>*/}

                {/*                            </div>*/}

                {/*                        </div>*/}
                {/*                        {observation?.intensive_care_status === intensiveCareStatus[0] &&*/}
                {/*                        <div className="col-sm-8">*/}
                {/*                            <Field className="form-control form-control-sm"*/}
                {/*                                   id="intensive_care_day_counter"*/}
                {/*                                   name="intensive_care_day_counter"*/}
                {/*                                   component={renderField} type="number"*/}
                {/*                                   label="intensive_care_day_counter"*/}
                {/*                                   placeholder="intensive_care_day_counter"*/}
                {/*                                   validate={validate.error}/>*/}
                {/*                        </div>}*/}
                {/*                    </div>*/}
                {/*                </div>*/}


                {/*            </div>*/}
                {/*        </div>*/}


                {/*        <div className="col-sm-12">*/}
                {/*            <h4><FormattedMessage id={`app.label.exit_test_result`}/></h4>*/}
                {/*            <hr/>*/}
                {/*            <div className="row mt-3">*/}

                {/*                <div className="col-sm-12">*/}
                {/*                    <div className="form-group">*/}
                {/*                        <label>*/}
                {/*                            <FormattedMessage id={`app.label.${`exit_pcr_result`}`}/>*/}
                {/*                            <span className="text-primary"> * </span> :*/}
                {/*                        </label>*/}
                {/*                        <div className="radio-inline">*/}
                {/*                            {prcResults.map((el, i) =>*/}

                {/*                                <label className="radio" key={i}>*/}
                {/*                                    <Field type="radio" component="input"  id={`exit_pcr_result`} name={`exit_pcr_result`} value={el} validate={validate.error}/>*/}
                {/*                                    <span/>*/}
                {/*                                    <FormattedMessage id={`app.label.${el}`}/>*/}
                {/*                                </label>*/}

                {/*                            )}*/}
                {/*                        </div>*/}

                {/*                    </div>*/}

                {/*                </div>*/}
                {/*                <div className="col-sm-12">*/}
                {/*                    <div className="row">*/}
                {/*                        <div className="col-sm-7">*/}

                {/*                            <div className="form-group">*/}
                {/*                                <label>*/}
                {/*                                    <FormattedMessage id={`app.label.${`patient_released_state`}`}/>*/}
                {/*                                    <span className="text-primary"> * </span> :*/}
                {/*                                </label>*/}
                {/*                                <div className="radio-inline">*/}
                {/*                                    {releasedState.map((el, i) =>*/}

                {/*                                        <label className="radio" key={i}>*/}
                {/*                                            <Field type="radio" component="input"  id={`patient_released_state`} name={`patient_released_state`} value={el} validate={validate.error}/>*/}
                {/*                                            <span/>*/}
                {/*                                            <FormattedMessage id={`app.label.${el}`}/>*/}
                {/*                                        </label>*/}

                {/*                                    )}*/}
                {/*                                </div>*/}

                {/*                            </div>*/}

                {/*                        </div>*/}
                {/*                        {observation?.patient_released_state === releasedState[0] &&*/}
                {/*                        <div className="col-sm-5">*/}

                {/*                            <Field className="form-control form-control-sm" id="released_date"*/}
                {/*                                   name="released_date"*/}
                {/*                                   component={renderField} type="date" label="released_date"*/}
                {/*                                   placeholder="restored_date" validate={validate.error}/>*/}
                {/*                        </div>}*/}
                {/*                        {observation?.patient_released_state === releasedState[1] &&*/}
                {/*                        <div className="col-sm-5">*/}
                {/*                            <Field className="form-control form-control-sm" id="death_date"*/}
                {/*                                   name="death_date"*/}
                {/*                                   component={renderField} type="date" label="death_date"*/}
                {/*                                   placeholder="death_date" validate={validate.error}/>*/}
                {/*                        </div>}*/}
                {/*                    </div>*/}

                {/*                </div>*/}
                {/*            </div>*/}
                {/*        </div>*/}

                {/*    </div>*/}
                {/*</div>}*/}
            </div>


        </div>

    );
}
export default React.memo(withRouter(FormItems))
