import React from "react";
import {CSVLink} from "react-csv";
import {FormattedMessage} from "react-intl";
import { BsFileEarmarkText } from "react-icons/bs";

const CSVButton = ({items, dataType}) => {
    return (
        <CSVLink data={items} type="button" className="btn btn-primary font-weight-bolder"
                 style={{float: 'right'}}
                 filename={`${dataType}-list.csv`}>
            <span className="mr-2">
                <BsFileEarmarkText style={{fontSize: "18px"}}/>
            </span>
            <FormattedMessage id={`app.label.export_to_csv`} defaultMessage={`Export CSV`}/>
        </CSVLink>
    )
};

export default CSVButton;
