import React from "react"
import {Field} from "redux-form";
import {renderField} from "../../../../../../common/utils/FormComponent";
import {withRouter} from "react-router";
import {FormattedMessage} from "react-intl";

let FormItems = props => {

    const {item, validate, touched, error} = props

    const testResults = ['POSITIVE', 'NEGATIVE', 'UNKNOWN']

    return (
        <div className={`custom_fieldset mt-4`}>
            <div className="row mt-3">
                <div className="col-sm-5">

                    <div className="form-group">
                        <label className="font-weight-bold">
                            <FormattedMessage id={`app.label.result_${item?.testType?.name.toLowerCase()}`}/> :
                        </label>
                        <div className="radio-inline">
                            {testResults.map((element, i) =>
                                <label className="radio radio-lg" key={i}>
                                    <Field type="radio" component="input"
                                           name="result"
                                           value={element}/>
                                    <span/>
                                    <FormattedMessage id={`app.label.${element.toLocaleLowerCase()}`}/>
                                </label>
                            )}
                        </div>
                        {touched && error &&
                        <span className="form-text text-primary">
                            <FormattedMessage id={error}/>
                        </span>
                        }
                    </div>
                </div>
                <div className="col-sm-7">
                    <Field className="form-control form-control-sm" id="pcr_result_date"
                           name="resultDate"
                           component={renderField} type="date"
                           label={`result_${item?.testType?.name.toLowerCase()}_date`}
                           placeholder="pcr_result_date" validate={validate.error}/>
                </div>
            </div>
        </div>

    );
}
export default React.memo(withRouter(FormItems))
