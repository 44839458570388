import * as React from "react";
import {Link} from "react-router-dom";
import {getIntlMsg} from "../../../../constants/utils";
import {injectIntl} from "react-intl";
import {useParams} from "react-router";


const PrimaryMenuItem = injectIntl((props) => {
    const {intl, url = '#', title, icon, active, target, hasTarget, history, submenu} = props
    console.log("=====================", submenu)

    const hasActive = p => {
        return submenu?.includes(p)
    }
    const hasActive1 = p => {
        return submenu?.includes(p)
    }

    const hasActive2 = p => {
        return submenu?.includes(p)
    }
    const path = history.location.pathname?.split('/')[3];
    const path1 = history.location.pathname?.split('/')[2];
    const path2 = history.location.pathname?.split('/')[1];


    return (
        <li className="nav-item mb-3" data-toggle="tooltip" data-placement="right" data-container="body"
            data-boundary="window" title={getIntlMsg(intl, `app.label.${title}`)}>

            {hasTarget ?
                <Link to={url}
                      className={`nav-link btn btn-icon btn-icon-white btn-hover-transparent-white ${hasActive(path) ? 'active' : hasActive1(path1) ? 'active' : hasActive2(path2) ? 'active' : ''}`}
                      data-toggle="tab" data-target={target} role="tab">
				<span className="svg-icon svg-icon-xl">
                    {icon}
				</span>
                </Link>

                :

                <Link to={url} className={`nav-link btn btn-icon btn-icon-white btn-hover-transparent-white`}>
                    <span className="svg-icon svg-icon-xl"> {icon}</span>
                </Link>

            }


        </li>);
})

export default React.memo(PrimaryMenuItem)

