import React from "react"
import {Field} from "redux-form";
import {useParams, withRouter} from "react-router";
import haitiLocalities from "@snippetify/haiti-localities"
import {useSelector} from "react-redux";
import {renderDestination, renderField} from "../../../../../common/utils/FormComponent";
import {countries} from "../../../../../../constants/options/countries";

let SocialItems = () => {


    const {secondLevelPage} = useParams();
    const {values} = useSelector(state => ({
        values: state.form?.requestForm?.values
    }));

    return (
        <>
            <div className="col-sm-4 mt-3">
                <Field className="form-control form-control-sm" id="phone"
                       name="phone" component={renderField} type="text"
                       label="phone" placeholder="phone" required/>
            </div>

            <div className="col-sm-4 mt-3">
                <Field className="form-control form-control-sm" id="email"
                       name="email" component={renderField} type="email"
                       label="email" placeholder="email" required/>
            </div>
            <div className="col-sm-4 mt-3">
                <Field id="nationality" name="nationality"
                       component={renderDestination} label="nationality" items={countries}/>
            </div>

            <div className="col-sm-4 mt-4">
                <Field id="county" name="county"
                       component={renderDestination} label="county" items={haitiLocalities.getCounties()}/>
            </div>
            <div className="col-sm-4 mt-3">
                <Field id="district" name="district"
                       component={renderDestination} label="district"
                       items={haitiLocalities?.getDistrictsByCounty(values?.county)}/>
            </div>
            <div className="col-sm-4 mt-3">
                <Field id="municipality" name="municipality"
                       component={renderDestination} label="municipality"
                       items={haitiLocalities?.getMunicipalitiesByDistrict(values?.district)}/>
            </div>
            <div className="col-sm-4 mt-3">
                <Field id="subMunicipality" name="subMunicipality"
                       component={renderDestination} label="sub_municipality"
                       items={haitiLocalities?.getSubMunicipalitiesByMunicipality(values?.municipality)}/>
            </div>

            <div className="col-sm-12">
                <Field className="form-control form-control-sm" id="address"
                       name="address" component={renderField} type="text"
                       label="address" placeholder="address" required/>
            </div>
        </>

    );
}

export default React.memo(withRouter(SocialItems))
