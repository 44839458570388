import * as React from "react";
import useDocumentTitle from "../../../../common/hooks/useDocumentTitle";
import {getIntlMsg} from "../../../../../constants/utils";
import {injectIntl} from "react-intl";
import {useParams, withRouter} from "react-router";
import IndicatorList from "./listing";
import IndicatorForm from "./form";
import {useState} from "react";


const IndicatorPageManager = injectIntl((props) => {
    const {intl} = props;
    const {module} = useParams();
    useDocumentTitle(getIntlMsg(intl, `app.label.${module}`));
    const [formView, setFormView] = useState(false)

    return (
        <div className="tab-pane fade" id="testindicator" role="tabpanel" aria-labelledby="testindicator">
            <div className="cared card-custom pl-0">
                {formView && <IndicatorForm {...{setFormView}}/>}
                <IndicatorList {...{formView, setFormView}}/>
            </div>
        </div>
    );
})

export default React.memo(withRouter(IndicatorPageManager))

