import * as React from "react";
import {injectIntl} from "react-intl";
import {withRouter} from "react-router";
import {useContext} from "react";
import {getSharingItems} from "../../../../constants/options/secondaryMenuItems";
import ModuleItem from "../footer/module";
import {GlobalContext} from "../../../index";


const SecondarySharing = injectIntl((props) => {
    const {intl, tab, history} = props

    const {institution: {acronym}} = useContext(GlobalContext);
    const path = history.location.pathname?.split('/')[2];
    console.log("=============PPPPPPPPPPP============", path)
    return (
        <div className={`tab-pane fade ${path === 'sharing' ? 'show active' : ''}`} id="sharing" key={1}>
            <div className="aside-menu-wrapper flex-column-fluid px-3 px-lg-10 py-5"
                 id="kt_aside_menu_wrapper">
                <div id="kt_aside_menu" className="aside-menu min-h-lg-800px" data-menu-vertical="1"
                     data-menu-scroll="1">
                    <ul className="menu-nav">
                        {getSharingItems(acronym).map((items, i) =>
                            <ModuleItem {...items} key={i}/>)
                        }
                    </ul>
                </div>
            </div>
        </div>

    );
})

export default React.memo(withRouter(SecondarySharing))

