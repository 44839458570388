import React from 'react'
import PageHeader from "../../../../common/report/PageHeader";


class RequestNotice extends React.Component {


    render() {
        const {applicant, payment} = this.props;

        return (

            <div className="request_notice">
                <PageHeader zlOnly/>
                <h1>Imprimer Recu</h1>
            </div>
        )
    }
}

export default RequestNotice;

