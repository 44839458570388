import * as React from "react";
import useDocumentTitle from "../../../../common/hooks/useDocumentTitle";
import {getIntlMsg} from "../../../../../constants/utils";
import {FormattedMessage, injectIntl} from "react-intl";
import {useParams, withRouter} from "react-router";


const UserRegistration = injectIntl((props) => {

    const {intl} = props;
    const {page} = useParams();
    useDocumentTitle(getIntlMsg(intl, `app.label.${page}`));

    return (
      <h1>Registration page</h1>

    );
});


export default React.memo(withRouter(UserRegistration))
