import React from "react"
import {FormattedMessage, injectIntl} from "react-intl";
import actions from "../../../../thunks/common/coreThunks";
import {useDispatch} from "react-redux";


const DeleteItemModal = injectIntl(props => {

    const {formAction} = props


    return (
        formAction ?
            <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                    <g transform="translate(12.000000, 12.000000) rotate(-45.000000) translate(-12.000000, -12.000000) translate(4.000000, 4.000000)"
                       fill="#000000">
                        <rect x="0" y="7" width="16" height="2" rx="1"/>
                        <rect opacity="0.3"
                              transform="translate(8.000000, 8.000000) rotate(-270.000000) translate(-8.000000, -8.000000) "
                              x="0" y="7" width="16" height="2" rx="1"/>
                    </g>
                </g>
            </svg>
            :
            <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                    <rect fill="#000000" x="4" y="11" width="16" height="2" rx="1"/>
                    <rect fill="#000000" opacity="0.3"
                          transform="translate(12.000000, 12.000000) rotate(-270.000000) translate(-12.000000, -12.000000) "
                          x="4" y="11" width="16" height="2" rx="1"/>
                </g>
            </svg>
    )
});


export default DeleteItemModal

