import {getDateFromDateTime} from "../utils";
import moment from 'moment';

export class Request {

    constructor(params) {
        if (params) {
            const {
                id, phoneId, emailId, addressId, identifierId, personalInfoId, socialInfoId, paymentId,
                firstName, lastName, nickname, gender, dob, age, codeLaboratory,
                phone, email, nationality, currentId, county, district, municipality, subMunicipality, address,
                paymentType, trxCode, testType, appointmentHour, appointmentDate,
                departure, destination, symptoms, specimenCenter, uploadFile, type
            } = params;

            this.id = id;
            this.codeLaboratory = codeLaboratory
            this.applicant = {

                personalInfos: {id: personalInfoId, firstName, lastName, nickname, gender, dob, age},

                socialInfos: {
                    id: socialInfoId,
                    address: {
                        id: addressId,
                        locality: `${subMunicipality},${municipality},${district},${county}`,
                        no: address
                    },
                    phones: [{id: phoneId, no: phone}],
                    emails: [{id: emailId, current: email}],
                    identifier: {id: identifierId, type: 'PASSPORT', value: currentId},
                    nationality,
                }
            }
            // this.payment = {id: paymentId, paymentType, trxCode, file: uploadFile};
            this.specimenCenter = {id: specimenCenter};
            this.departure = departure;
            this.destination = destination;
            this.symptoms = symptoms === 'yes';
            this.testType = {id: testType};
            this.appointmentDate = `${appointmentDate}T${appointmentHour}:00`;
            this.type = type
        }
    }


}


export class Cashier {

    constructor(params) {
        if (params) {
            const {
                id, personalInfoId,
                firstName, lastName,
                paymentType, destination, specimenCenter, type, testType, trxCode, currency
            } = params;
            this.id = id;
            this.applicant = {
                personalInfos: {id: personalInfoId, firstName, lastName},

            }
            this.payment = {paymentType, trxCode, currency: {id: currency}};
            this.specimenCenter = {id: specimenCenter};
            this.destination = destination;
            this.type = type
            this.testType = {"id": testType}
        }
    }


}


export class RiskFactorMapper {

    constructor(params) {
        if (params) {
            const {
                hadTightCovid19ConfirmedContact,
                visitedCovid19place,
                hadTraveledCovid19Contact,
                hadTripToCovid19Country, hadTightCovid19DeadContact,
                covid19Contacts,
                trips
            } = params;

            this.covid19Contacts = covid19Contacts;
            this.contact_with_contaminated = hadTightCovid19ConfirmedContact?.toLowerCase();
            this.visit_covid19_health_center = visitedCovid19place?.toLowerCase();
            this.contact_with_traveled_contaminated = hadTraveledCovid19Contact?.toLowerCase();
            this.traveledToCovid19Countries = hadTripToCovid19Country?.toLowerCase();
            this.contactWithCovid19Dead = hadTightCovid19DeadContact?.toLowerCase();
            this.covid19trips = trips;


        }

    }

}

export class RiskFactor {

    constructor(params) {
        if (params) {
            const {
                contactWithCovid19Dead, contact_with_contaminated,
                contact_with_traveled_contaminated, covid19Contacts,
                covid19trips, traveledToCovid19Countries, visit_covid19_health_center, id
            } = params;

            this.riskFactor = {
                category: {
                    id: id
                },

                covid19Contacts: covid19Contacts,
                hadTightCovid19ConfirmedContact: contact_with_contaminated?.toUpperCase(),
                visitedCovid19place: visit_covid19_health_center?.toUpperCase(),
                hadTraveledCovid19Contact: contact_with_traveled_contaminated?.toUpperCase(),
                hadTripToCovid19Country: traveledToCovid19Countries?.toUpperCase(),
                hadTightCovid19DeadContact: contactWithCovid19Dead?.toUpperCase(),
                trips: covid19trips


            }

        }

    }

}

export class DiagnosticSheet {

    constructor(params) {
        if (params) {
            const {
                labsReceptionDate, publishedDate,
                remark, pcr_result, specimens,
                collectionDate, result
            } = params;
            this.labsReceptionDate = labsReceptionDate;
            this.result = {
                publishedDate: publishedDate,
                remark: remark,
                status: pcr_result?.toUpperCase()
            }

            this.specimens = specimens;


        }

    }

}

export class Investigator {

    constructor(params) {
        if (params) {
            const {
                investigator_observation,
                investigator_phone
            } = params;
            this.observation = investigator_observation;
            this.socialInfos = {
                phones: [
                    {
                        areaCode: "+509",
                        no: investigator_phone,
                        txnable: true
                    }
                ]
            }

        }

    }

}

export class Symptom {

    constructor(params) {
        if (params) {
            const {
                symptomsStartDate, signsSymptoms,
                comorbidityHabits, otherSymptoms,
                otherHabits
            } = params;

            this.symptomsStartDate = symptomsStartDate;
            this.comorbidityHabits = comorbidityHabits;
            this.signsSymptoms = signsSymptoms;
            this.otherSymptoms = otherSymptoms;
            this.otherHabits = otherHabits
        }

    }

}

export class SymptomsMapper {

    constructor(params) {
        if (params) {

            const index = params?.signsSymptoms?.findIndex(v => v.category.code === 'anosmia');
            const index1 = params?.signsSymptoms?.findIndex(v => v.category.code === 'fever');
            const index2 = params?.signsSymptoms?.findIndex(v => v.category.code === 'curvatures');
            const index3 = params?.signsSymptoms?.findIndex(v => v.category.code === 'asthenia');
            const index4 = params?.signsSymptoms?.findIndex(v => v.category.code === 'cephalea');
            const index5 = params?.signsSymptoms?.findIndex(v => v.category.code === 'Loss_of_taste_agueusia');
            const index6 = params?.signsSymptoms?.findIndex(v => v.category.code === 'diarrhea');
            const index7 = params?.signsSymptoms?.findIndex(v => v.category.code === 'cough');

            this.anosmia = params?.signsSymptoms[index]?.result?.toLowerCase();
            this.fever = params?.signsSymptoms[index1]?.result?.toLowerCase();
            this.curvatures = params?.signsSymptoms[index2]?.result?.toLowerCase();
            this.asthenia = params?.signsSymptoms[index3]?.result?.toLowerCase();
            this.cephalea = params?.signsSymptoms[index4]?.result?.toLowerCase();
            this.Loss_of_taste_agueusia = params?.signsSymptoms[index5]?.result?.toLowerCase();
            this.diarrhea = params?.signsSymptoms[index6]?.result?.toLowerCase();
            this.cough = params?.signsSymptoms[index7]?.result?.toLowerCase();
            this.symptomsStartDate = params?.symptomsStartDate || undefined;
            this.otherHabits = params?.otherHabits;
            this.otherSymptoms = params?.otherSymptoms;

        }
    }
}

export class ComorbidityMapper {

    constructor(params) {
        if (params) {
            console.log("=======================--------------", params)
            const index = params?.findIndex(v => v.category.code === 'smoking');
            const index1 = params?.findIndex(v => v.category.code === 'kidney_disease');
            const index2 = params?.findIndex(v => v.category.code === 'hiv');
            const index3 = params?.findIndex(v => v.category.code === 'tb');
            const index4 = params?.findIndex(v => v.category.code === 'allergic');
            const index5 = params?.findIndex(v => v.category.code === 'hta');
            const index6 = params?.findIndex(v => v.category.code === 'respiratory_illnesses');
            const index7 = params?.findIndex(v => v.category.code === 'malnutrition');
            const index8 = params?.findIndex(v => v.category.code === 'heart_disease');
            const index9 = params?.findIndex(v => v.category.code === 'Liver_cirrhosis');
            const index10 = params?.findIndex(v => v.category.code === 'obesity_bmi_grt_35');
            const index11 = params?.findIndex(v => v.category.code === 'alcoholic');
            const index12 = params?.findIndex(v => v.category.code === 'cancer');
            const index13 = params?.findIndex(v => v.category.code === 'diabetes');
            const index14 = params?.findIndex(v => v.category.code === 'heart_neurologic');
            //
            this.smoking = params[index]?.result === "YES" ? true : false;
            this.kidney_disease = params[index1]?.result === 'YES' ? true : false;
            this.hiv = params[index2]?.result === 'YES' ? true : false;
            this.tb = params[index3]?.result === 'YES' ? true : false;
            this.allergic = params[index4]?.result === 'YES' ? true : false;
            this.hta = params[index5]?.result === 'YES' ? true : false;
            this.respiratory_illnesses = params[index6]?.result === 'YES' ? true : false;
            this.malnutrition = params[index7]?.result === 'YES' ? true : false;
            this.heart_disease = params[index8]?.result === 'YES' ? true : false;
            this.Liver_cirrhosis = params[index9]?.result === 'YES' ? true : false;
            this.obesity_bmi_grt_35 = params[index10]?.result === 'YES' ? true : false;
            this.alcoholic = params[index11]?.result === 'YES' ? true : false;
            this.cancer = params[index12]?.result === 'YES' ? true : false;
            this.diabetes = params[index13]?.result === 'YES' ? true : false;
            this.heart_neurologic = params[index14]?.result === 'YES' ? true : false;
        }
    }
}


export class DiagnosticNotificationMapper {

    constructor(params) {
        if (params) {

            this.labsReceptionDate = params?.labsReceptionDate;
            this.publishedDate = params?.result?.publishedDate;
            this.pcr_result = params?.result?.status?.toLowerCase();
            const index = params?.specimens?.findIndex(v => v.category.code === 'POP');
            const index1 = params?.specimens?.findIndex(v => v.category.code === 'PNP');

            this.POP = params?.specimens[index]?.result?.toLowerCase();
            this.POPcollectionDate = params?.specimens[index]?.collectionDate
            this.PNP = params?.specimens[index1]?.result?.toLowerCase();
            this.PNPcollectionDate = params?.specimens[index1]?.collectionDate
        }
    }
}


export class InvestigatorMapper {

    constructor(params) {
        if (params) {

            this.investigator_observation = params?.observation;
            this.investigator_phone = params?.socialInfos?.phones[0]?.no;
        }
    }
}


export class RequestMapper {

    constructor(params) {
        if (params) {

            this.id = params?.id;
            this.personalInfoId = params?.applicant?.personalInfos?.id;
            this.socialInfoId = params?.applicant?.socialInfos?.id;
            this.paymentId = params?.payment?.id;
            this.phoneId = params?.applicant?.socialInfos?.phones[0]?.id;
            this.emailId = params?.applicant?.socialInfos?.emails[0]?.id;
            this.addressId = params?.applicant?.socialInfos?.address?.id;
            this.identifierId = params?.applicant?.socialInfos?.identifier?.id;

            this.firstName = params?.applicant?.personalInfos?.firstName;
            this.lastName = params?.applicant?.personalInfos?.lastName;
            this.nickname = params?.applicant?.personalInfos?.nickname;
            this.gender = params?.applicant?.personalInfos?.gender;
            this.dob = params?.applicant?.personalInfos?.dob;
            this.age = params?.applicant?.personalInfos?.age;
            const mt = params?.applicant?.personalInfos?.age;
            this.maturity = mt ? mt < 1 ? 'baby' : 'other' : 'other';

            this.currentId = params?.applicant?.socialInfos?.identifier?.value;
            this.maritalStatus = params?.applicant?.socialInfos?.maritalStatus;
            this.county = params?.applicant?.socialInfos?.address?.locality.split(',')[3] || 'Ouest';
            this.district = (params?.applicant?.socialInfos?.address?.locality.split(',')[2]) || 'Arcahaie';
            this.municipality = params?.applicant?.socialInfos?.address?.locality.split(',')[1] || 'Arcahaie';
            this.subMunicipality = params?.applicant?.socialInfos?.address?.locality.split(',')[0] || 'Boucassin';

            this.address = params?.applicant?.socialInfos?.address?.no;
            this.phone = params?.applicant?.socialInfos?.phones[0]?.no;
            this.email = params?.applicant?.socialInfos?.emails[0]?.current;
            this.nationality = params?.applicant?.socialInfos?.nationality || 'Haiti';


            this.departure = params?.departure;
            this.appointmentDate = params?.appointmentDate ? getDateFromDateTime(params?.appointmentDate) : null;
            this.appointmentHour = moment(params?.appointmentDate).format('hh:00');
            this.destination = params?.destination;
            this.symptoms = params?.symptoms ? 'yes' : 'no';
            this.specimenCenter = params?.specimenCenter?.id;
            this.testType = params?.testType?.id;

            this.paymentType = params?.payment?.paymentType || 'DEPOSIT';
            this.trxCode = params?.payment?.trxCode;

            this.codeLaboratory = params?.codeLaboratory?.toUpperCase();
            this.result = params?.diagnostic?.result?.status;
            this.type = params?.type || 'TRAVELER';


        }
    }

}

