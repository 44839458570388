import * as React from "react";
import {FormattedMessage, injectIntl} from "react-intl";
import {withRouter} from "react-router";


const Item = injectIntl((props) => {

    const {title, subTitle, icon, value, hasSubTitle, color} = props;

    return (
        <div className="d-flex align-items-center flex-lg-fill mr-5 my-1">
            <span className="mr-4">
                <i className={`${icon} text-${color}`} style={{fontSize: "20px"}}/>
            </span>

            <div className="d-flex flex-column text-dark-75">
                <span className="font-size-sm">
                    <FormattedMessage id={`app.label.${title}`}/>
                </span>
                <span className="font-weight-bold font-size-h5">
                    {hasSubTitle && <span className="text-dark-50 font-weight-bold mr-3">
                        {subTitle}
                    </span>}
                    {value === true ? 'Oui' : value === false ? 'Non': value}
                </span>
            </div>
        </div>

    );
});


export default React.memo(withRouter(Item))
