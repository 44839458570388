/**
 * Created by inglouidor on 3/16/21.
 */
/**
 * Created by inglouidor on 3/15/21.
 */
import React from 'react'
import MaterialTable from 'material-table';
import {Localization} from '../../../../constants/utils'


const TableList = ({data, columns, title}) => {


    return (
        <MaterialTable
            title={title}
            columns={columns}
            data={data ? data : []}
            icon='unfold_less'
            localization={Localization('fr')}
            options={{
                headerStyle: {
                    backgroundColor: '#ff6900',
                    color: '#FFF',
                    fontWeight: 400,
                    fontSize: '1rem',
                    textTransform: 'initial',
                },
                rowStyle: {
                    fontWeight: 400,
                    fontSize: '1rem',
                    textTransform: 'initial',
                    color: "#3f4254"
                },
                pageSizeOptions: [2, 20, 25, 50, 100],

                exportButton: true,
                exportAllData: true
            }}


        />

    )
}
export default TableList;