import * as React from "react";
import {Link} from "react-router-dom";
import {injectIntl} from "react-intl";
import {getIntlMsg} from "../../../../constants/utils";
import {GlobalContext} from "../../../index";
import {useContext} from "react";


const Index = injectIntl((props) => {

    const {intl} = props
	const {userSession: {roles, name, keycloak,gender}, institution} = useContext(GlobalContext);

    return (
        <div className="aside-footer d-flex flex-column align-items-center flex-column-auto py-4 py-lg-10">
			<span className="aside-toggle btn btn-icon btn-primary btn-hover-primary shadow-sm"
                  id="kt_aside_toggle" data-toggle="tooltip" data-placement="right"
                  data-container="body" data-boundary="window" title={getIntlMsg(intl, `app.label.toggle_aside`)}>
				<i className="ki ki-bold-arrow-back icon-sm"/>
			</span>

            {/*Aside Toggle*/}
            {/*<Link to={`/`} className="btn btn-icon btn-icon-white btn-hover-transparent-white mb-3"*/}
            {/*      id="kt_quick_actions_toggle" data-toggle="tooltip" data-placement="right" data-container="body"*/}
            {/*      data-boundary="window" title={getIntlMsg(intl, 'app.label.quick_actions')}>*/}
			{/*	<span className="svg-icon svg-icon-xl">*/}
			{/*		<svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1">*/}
			{/*			<g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">*/}
			{/*				<rect x="0" y="0" width="24" height="24"/>*/}
			{/*				<rect fill="#000000" opacity="0.3" x="13" y="4" width="3" height="16" x="1.5"/>*/}
			{/*				<rect fill="#000000" x="8" y="9" width="3" height="11" rx="1.5"/>*/}
			{/*				<rect fill="#000000" x="18" y="11" width="3" height="9" rx="1.5"/>*/}
			{/*				<rect fill="#000000" x="3" y="13" width="3" height="7" rx="1.5"/>*/}
			{/*			</g>*/}
			{/*		</svg>*/}
			{/*	</span>*/}
            {/*</Link>*/}

            {/*begin::Quick Panel*/}
            <Link to={`#`}
                  className="btn btn-icon btn-icon-white btn-hover-transparent-white mb-3 position-relative"
                  id="kt_quick_panel_toggle" data-toggle="tooltip" data-placement="right" data-container="body"
                  data-boundary="window" title={getIntlMsg(intl, 'app.label.notifications')}>
				<span className="svg-icon svg-icon-xl">
					<svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
						<g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
							<rect x="0" y="0" width="24" height="24"/>
							<rect fill="#000000" x="4" y="4" width="7" height="7" rx="1.5"/>
							<path
                                d="M5.5,13 L9.5,13 C10.3284271,13 11,13.6715729 11,14.5 L11,18.5 C11,19.3284271 10.3284271,20 9.5,20 L5.5,20 C4.67157288,20 4,19.3284271 4,18.5 L4,14.5 C4,13.6715729 4.67157288,13 5.5,13 Z M14.5,4 L18.5,4 C19.3284271,4 20,4.67157288 20,5.5 L20,9.5 C20,10.3284271 19.3284271,11 18.5,11 L14.5,11 C13.6715729,11 13,10.3284271 13,9.5 L13,5.5 C13,4.67157288 13.6715729,4 14.5,4 Z M14.5,13 L18.5,13 C19.3284271,13 20,13.6715729 20,14.5 L20,18.5 C20,19.3284271 19.3284271,20 18.5,20 L14.5,20 C13.6715729,20 13,19.3284271 13,18.5 L13,14.5 C13,13.6715729 13.6715729,13 14.5,13 Z"
                                fill="#000000" opacity="0.3"/>
						</g>
					</svg>
				</span>
                <span
                    className="label label-sm label-light-danger label-rounded font-weight-bolder position-absolute top-0 right-0 mt-1 mr-1">3</span>
            </Link>
            <Link to={`#`} className="btn btn-icon btn-icon-white btn-hover-transparent-white w-40px h-40px"
                  id="kt_quick_user_toggle" data-toggle="tooltip" data-placement="right" data-container="body"
                  data-boundary="window" title={getIntlMsg(intl, 'app.label.user_profile')}>
                <div className="symbol symbol-30 bg-gray-100">
                    <div className="symbol-label">
                        <img alt="Logo" src={`/assets/media/avatars/${gender==='male'?'male.png':'wonman.png'}`} className="h-75 align-self-end"/>
                    </div>
                    <i className="symbol-badge bg-success"/>
                </div>
            </Link>
        </div>
    )
})

export default React.memo(Index)

