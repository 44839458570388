import * as React from "react";
import pages from "../../../constants/options/pages";
import {useContext} from "react";
import {useParams, withRouter} from "react-router";
import UserRegistration from "./users/registration";
import SecurityDashboard from "./dashboard";
import UserDetails from "./users/details";
import InstitutionPageManager from "./institution";
import {GlobalContext} from "../../index";


const SecurityManager = () => {

    const {institution: {acronym, description}} = useContext(GlobalContext);


    const {page} = useParams();

    return _onLoadPage(page)
}


function _onLoadPage(page) {
    switch (page) {
        case pages.REQUEST_REGISTRATION_PAGE:
            return <UserRegistration/>
        case pages.SECURITY_USER_DETAIL_PAGES:
            return <UserDetails/>
        case pages.SECURITY_INSTITUTION_PAGES:
            return <InstitutionPageManager/>
        default: {
            return <SecurityDashboard/>
        }
    }


}

export default React.memo(withRouter(SecurityManager))

