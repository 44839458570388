import types from "../../actions/types/security/institutionActionTypes";
import initialState from "../../../constants/utils/initialState";

const institutionReducer = (state = initialState.institutionReducer, action) => {

        switch (action.type) {

            /**
             * LOAD INSTITUTIONS LIST
             * */
            case types.RESET_INSTITUTION:
                return {...state, item: {}};

            /**
             * LOAD INSTITUTIONS LIST
             * */
            case types.LOAD_INSTITUTIONS:
                return {...state, loadingItems: action.payload};
            case types.LOAD_INSTITUTIONS_SUCCEEDED:
                return {...state, items: action.payload?.content, loadingItems: false};
            case types.LOAD_INSTITUTIONS_FAILED:
                return {...state, loadingItems: action.payload};



            /**  LOAD INSTITUTION BY A SPECIFIC PROPERTY CASE */
            case types.LOAD_INSTITUTION:
                return {...state, loadingItem: true};
            case types.LOAD_INSTITUTION_SUCCEEDED:
                return {...state, item: action.payload, loadingItem: false};
            case types.LOAD_INSTITUTION_FAILED:
                return {...state, loadingItem: false};


            /**  SAVE INSTITUTION CASE */
            case types.SAVE_INSTITUTION:
                return {...state, savingOrUpdating: true};
            case types.SAVE_INSTITUTION_SUCCEEDED:
                return {
                    ...state, items: [...state.items, action.payload],
                    item: action.payload,
                    savingOrUpdating: false
                };
            case types.SAVE_INSTITUTION_FAILED:
                return {...state, savingOrUpdating: false};



            /**  UPDATE INSTITUTION CASE  */

            case types.UPDATE_INSTITUTION:
                return {...state, savingOrUpdating: true};
            case types.UPDATE_INSTITUTION_SUCCEEDED:
                return {
                    ...state,
                    items: state.items.map(item => item.id === action.payload.id ? action.payload : item),
                    item: action.payload,
                    savingOrUpdating:false
                };
            case types.UPDATE_INSTITUTION_FAILED:
                return {...state, savingOrUpdating: false};


            /**  DELETE INSTITUTION CASE  */
            case types.DELETE_INSTITUTION:
                return {...state, deleting: true};
            case types.DELETE_INSTITUTION_SUCCEEDED:
                return (action.payload.status === 200) ?
                    {...state, items: state.items.filter(item => item.id !== action.payload.item.id), deleting: false,savingOrUpdating:false}
                    : state;
            case types.DELETE_INSTITUTION_FAILED:
                return {...state, deleting: false,savingOrUpdating:false};

            default:
                return state;
        }
    }
;

export default institutionReducer;
