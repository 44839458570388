import * as React from "react";
import useDocumentTitle from "../../../common/hooks/useDocumentTitle";
import actions from "../../../../thunks/common/coreThunks";
import {getIntlMsg} from "../../../../constants/utils";
import {injectIntl} from "react-intl";
import {useDispatch, useSelector} from "react-redux";
import {useEffect} from "react";
import StatusDistribution from "./Items/status";
import Spinner from "../../../common/utils/Spinner";
import SubHeader from "../../common/subheader";


const SharingDashboard = injectIntl(props => {
    const {intl} = props

    useDocumentTitle(getIntlMsg(intl, `app.label.sharing_dashboard_desc`));
    const dispatch = useDispatch();
    const criteria = {options: ['dashboard']}

    useEffect(() => {
        dispatch(actions.loadItems(criteria));
    }, [dispatch]);

    const {items, statusItems, loading} = useSelector(state => ({
        loading: state?.requestDbReducer?.loading,
        items: state?.requestDbReducer?.items,
        statusItems: state?.requestDbReducer?.items?.status,
    }));


    const legend = {
        display: true,
        position: "left",
        labels: {
            fontColor: "#323130",
            fontSize: 14
        }
    };


    /****************
     * Build chart element components
     * **/
    const buildChartElement = (items = [], type, intl) => {

        let elements = [];
        for (let i = 0; i < items.length; i++) {
            elements.push(type === 'label' ? getIntlMsg(intl, `app.label.${items[i].label}`) : items[i].counter)
        }

        return elements

    }

    const statusData = {
        labels: buildChartElement(statusItems, 'label', intl),
        datasets: [
            {
                label: '# of Votes',
                data: buildChartElement(statusItems, ''),
                backgroundColor: ['#69b3fe', '#42d697', '#fea569'],
                borderColor: ['#69b3fe', '#42d697', '#fea569'],
                borderWidth: 1,
            },
        ],
    }


    return (
        <div className="d-flex flex-column-fluid">
            <div className="container">
                <div className="row mt-10">
                    <div className="col-sm-4">
                        {statusData && !loading ? <StatusDistribution statusData/> : <Spinner/>}
                    </div>
                </div>
            </div>
        </div>
    );
});

export default React.memo(SharingDashboard)

