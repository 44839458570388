const institutionActionTypes = {

    /**
     * @Author Rikenson JACQUES (The Tiger ^(..)^)
     * RESET INSTITUTION ACTION TYPES
     * -------------------------------------------
     * */
    RESET_INSTITUTION: "RESET_INSTITUTION",

    /**
     * @Author Rikenson JACQUES (The Tiger ^(..)^)
     * LOAD INSTITUTIONS ACTION TYPES
     * -------------------------------------------
     * */
    LOAD_INSTITUTIONS: "LOAD_INSTITUTIONS",
    LOAD_INSTITUTIONS_SUCCEEDED: "LOAD_INSTITUTIONS_SUCCEEDED",
    LOAD_INSTITUTIONS_FAILED: "LOAD_INSTITUTIONS_FAILED",


    /**
     * @Author Rikenson JACQUES (The Tiger ^(..)^)
     * LOAD SINGLE INSTITUTION ACTION TYPES
     * -------------------------------------------
     * */
    LOAD_INSTITUTION: "LOAD_INSTITUTION",
    LOAD_INSTITUTION_SUCCEEDED: "LOAD_INSTITUTION_SUCCEEDED",
    LOAD_INSTITUTION_FAILED: "LOAD_INSTITUTION_FAILED",


    /**
     * @Author Rikenson JACQUES (The Tiger ^(..)^)
     * SAVE INSTITUTION ACTION TYPES
     * -------------------------------------------
     * */
    SAVE_INSTITUTION: "SAVE_INSTITUTION",
    SAVE_INSTITUTION_SUCCEEDED: "SAVE_INSTITUTION_SUCCEEDED",
    SAVE_INSTITUTION_FAILED: "SAVE_INSTITUTION_FAILED",


    /**
     * @Author Rikenson JACQUES (The Tiger ^(..)^)
     * UPDATE INSTITUTION ACTION TYPES
     * -------------------------------------------
     * */
    UPDATE_INSTITUTION: "UPDATE_INSTITUTION",
    UPDATE_INSTITUTION_SUCCEEDED: "UPDATE_INSTITUTION_SUCCEEDED",
    UPDATE_INSTITUTION_FAILED: "UPDATE_INSTITUTION_FAILED",


    /**
     * @Author Rikenson JACQUES (The Tiger ^(..)^)
     * DELETE INSTITUTION ACTION TYPES
     * -------------------------------------------
     * */
    DELETE_INSTITUTION: "DELETE_INSTITUTION",
    DELETE_INSTITUTION_SUCCEEDED: "DELETE_INSTITUTION_SUCCEEDED",
    DELETE_INSTITUTION_FAILED: "DELETE_INSTITUTION_FAILED",


};


export default institutionActionTypes;
