import * as React from "react";
import {FormattedMessage} from "react-intl";


const Thumbnail = props => {

	const {title, icon} = props


    return (
        <div className="row gutter-b">
            <div className="col-6">
                <a href="#"
                   className="btn btn-block btn-light btn-hover-primary text-dark-50 text-center py-10 px-5">
							<span className="svg-icon svg-icon-3x svg-icon-primary m-0">
								{icon}
							</span>
                    <span className="d-block font-weight-bold font-size-h6 mt-2">
						<FormattedMessage id={`app.label.${title}`}/>
					</span>
                </a>
            </div>
        </div>
    )
}

export default React.memo(Thumbnail)

