import * as React from "react";
import {useContext} from "react";
import {injectIntl} from "react-intl";
import {GlobalContext} from "../../../index";


const Message = injectIntl(props => {

    const {intl, position, msgColor, user:{picture, name, sentSince, message}} = props

    const {institution: {acronym}} = useContext(GlobalContext);


    return (
        <div className={`d-flex flex-column mb-5 ${position}`}>
            <div className="d-flex align-items-center">
                <div>
                    <span className="text-muted font-size-sm mr-2">{sentSince}</span>
                    <a href="#"
                       className="text-dark-75 text-hover-primary font-weight-bold font-size-h6">
                        {name}
                    </a>
                </div>
                <div className="symbol symbol-circle symbol-40 ml-3">
                    <img alt="Pic" src={`/assets/media/users/${picture}`}/>
                </div>
            </div>
            <div
                className={`mt-2 rounded p-5 ${msgColor} text-dark-50 font-weight-bold font-size-lg text-right max-w-400px`}>
                {message}
            </div>
        </div>
    )
})

export default React.memo(Message)

