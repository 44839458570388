import * as React from "react";
import {FormattedMessage} from "react-intl";


const ContactModal = props => {

    return (

        <div className="modal fade" id="contact" tabIndex="-1" role="dialog"
             aria-labelledby="contact" aria-modal="true" style={{paddingRight: "15px", display: "none"}}>
            <div className="modal-dialog modal-dialog-centered modal-xl" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLabel">
                            <FormattedMessage id={`app.label.contact_contact`}/>
                        </h5>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                            <i aria-hidden="true" className="ki ki-close"/>
                        </button>
                    </div>
                    <div className="modal-body">
                        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has
                            been the industry's standard dummy text ever since the 1500s, when an unknown printer took a
                            galley of type and scrambled it to make a type specimen book. It has survived not only five
                            centuries, but also the leap into electronic typesetting, remaining essentially
                            unchanged.</p>
                        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has
                            been the industry's standard dummy text ever since the 1500s, when an unknown printer took a
                            galley of type and scrambled it to make a type specimen book. It has survived not only five
                            centuries, but also the leap into electronic typesetting, remaining essentially
                            unchanged.</p>
                        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has
                            been the industry's standard dummy text ever since the 1500s, when an unknown printer took a
                            galley of type and scrambled it to make a type specimen book. It has survived not only five
                            centuries, but also the leap into electronic typesetting, remaining essentially
                            unchanged.</p>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-light-primary font-weight-bold"
                                data-dismiss="modal">Close
                        </button>
                        <button type="button" className="btn btn-primary font-weight-bold">Save changes</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default React.memo(ContactModal)

