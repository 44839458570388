export const Style = {

    notice: {

        headerWrapper: {
            margin: "0px auto",
            display: "flex",
            width: "80mm",
            fontFamily: "Calibri",
        },
        subheaderWrapper: {
            width: "80mm",
            fontFamily: "Calibri",
            margin: "0 auto",
            textAlign: "center",
            paddingBottom:'5px',
            fontSize: "18px",
            color:'#000',
            borderBottom:'2px dashed #000'
            // fontFamily: "Helvetica Neue"
        },
        logo: {
            src: '/assets/media/logos/zl.png',
            alt: 'ZL',
            css: {
                width: `'50px`,
                textAlign: 'center',
                marginBottom:'10px'
            },
            logo: {width: "120px"},
            img: {width: "50px"}
        }
    }

}