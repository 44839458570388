import React from "react"
import {Field} from "redux-form";
import {withRouter} from "react-router";
import {FormattedMessage} from "react-intl";
import {renderDestination, renderField} from "../../../../../common/utils/FormComponent";
import {countries} from "../../../../../../constants/options/countries";
import haitiLocalities from "@snippetify/haiti-localities";
import {useSelector} from "react-redux";

let SocialFormItems = props => {
    const {touched, error} = props

    const {values} = useSelector(state => ({
        values: state.form?.personalForm?.values
    }));


    return (
        <div className={`custom_fieldset mt-4`}>
            <div className="row mt-5">
                <div className="col-sm-12 pt-3 bg-primary-o-60">
                    <h4><FormattedMessage id={`app.label.social_infos_long`}/></h4>
                </div>

            </div>

            <div className="row mt-2">
                <div className="col-sm-2 mt-3">
                    <div className="form-group">
                        <label>
                            <FormattedMessage id={`app.label.id_type`}/>
                            <span className="text-primary"> * </span> :
                        </label>
                        <div className="radio-inline">
                            {['CIN', 'NIF'].map((element, i) =>
                                <label className="radio radio-lg" key={i}>
                                    <Field type="radio" component="input" name="idType" value={element}/>
                                    <span/>
                                    <FormattedMessage id={`app.label.${element}`}/>
                                </label>
                            )}
                        </div>
                        {touched && error &&
                        <span className="form-text text-primary">
                            <FormattedMessage id={error}/>
                        </span>}
                    </div>
                </div>
                <div className="col-sm-2 mt-3">
                    <Field className="form-control form-control-sm" id="current_id" name="currentId"
                           component={renderField} type="text" label="current_id"
                           placeholder="current_id" required/>
                </div>

                <div className="col-sm-2 mt-3">
                    <Field className="form-control form-control-sm" id="phone"
                           name="phone" component={renderField} type="text"
                           label="phone" placeholder="phone" required/>
                </div>

                <div className="col-sm-3 mt-3">
                    <Field className="form-control form-control-sm" id="email"
                           name="email" component={renderField} type="email"
                           label="email" placeholder="email" required/>
                </div>
                <div className="col-sm-3 mt-3">
                    <Field id="nationality" name="nationality"
                           component={renderDestination} label="nationality" items={countries}/>
                </div>

                <div className="col-sm-3 mt-4">
                    <Field id="county" name="county"
                           component={renderDestination} label="county" items={haitiLocalities.getCounties()}/>
                </div>
                <div className="col-sm-3 mt-3">
                    <Field id="district" name="district"
                           component={renderDestination} label="district"
                           items={haitiLocalities?.getDistrictsByCounty(values?.county)}/>
                </div>
                <div className="col-sm-3 mt-3">
                    <Field id="municipality" name="municipality"
                           component={renderDestination} label="municipality"
                           items={haitiLocalities?.getMunicipalitiesByDistrict(values?.district)}/>
                </div>
                <div className="col-sm-3 mt-3">
                    <Field id="subMunicipality" name="subMunicipality"
                           component={renderDestination} label="sub_municipality"
                           items={haitiLocalities?.getSubMunicipalitiesByMunicipality(values?.municipality)}/>
                </div>

                <div className="col-sm-12">
                    <Field className="form-control form-control-sm" id="address"
                           name="address" component={renderField} type="text"
                           label="address" placeholder="address" required/>
                </div>
            </div>
        </div>

    );
}
export default React.memo(withRouter(SocialFormItems))
