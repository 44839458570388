import React from "react"
import {FieldArray} from "redux-form";
import {withRouter} from "react-router";
import renderBank from "./bank";
import renderPhone from "./phone";

let FormItems = () => {


    return (
        <div className={`custom_fieldset mt-4`}>

            <div className="row mt-3">
                <div className="col-sm-12">
                    <FieldArray name="phones" component={renderPhone}/>
                </div>

                <div className="col-sm-12">
                    <FieldArray name="banks" component={renderBank}/>
                </div>
            </div>

        </div>

    );
}
export default React.memo(withRouter(FormItems))
